import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Autocomplete,
  Rating,
  DialogActions,
  Button,
  Chip,
} from "@mui/material";
import { net } from "../../../database";
import {
  ClassesContext,
  CustomersContext,
  StaffContext,
  StudentsContext,
} from "../../../Context";
import HtComplete from "./HtComplete";

export default function NewMessageDialog(props) {
  const {
    l,
    language,
    messageDialogOpen,
    setMessageDialogOpen,
    description,
   
  } = props;
  const [message, setMessage] = React.useState({
    type: "Message",
    tags: [],
    importance: 0,
    description: description,
    who: [],
  });
  const { students } = useContext(StudentsContext);
  const { customers } = useContext(CustomersContext);
  const { staff } = useContext(StaffContext);
  const { classes } = useContext(ClassesContext);

  let st = staff.filter(s=>s.status===0).map(s=>({id:s._id,name: s.name}))

  React.useEffect(() => {
    setMessage({ ...message, description, tags: [] });
  }, [messageDialogOpen]);
  if (!students || !customers) return <div></div>;

/*   let tags = buildTags(students, customers, classes, staff); */

  const sendNewMessage = () => {
     console.log(message);
    message.who = message.who.map((m) => ({ _id: m.id }));
   
    net.post("/messages", message).then((result) => {
      if (result.sent) {
        setMessage({
          type: "Message",
          tags: [],
          importance: 0,
          description: "",
          who: [],
        });
        setMessageDialogOpen(false);
      }
    });
  };

  return (
    <Dialog open={messageDialogOpen} fullWidth>
      <DialogTitle>{l.newMessageOrComplaint[language]}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <FormControl>
              <FormLabel id="messageorcomplaint">
                {l.typeOfMessage[language]}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="messageorcomplaint"
                value={message.type}
                name="radio-buttons-group"
                onChange={(e) =>
                  setMessage({ ...message, type: e.target.value })
                }
              >
                <FormControlLabel
                  value="Message"
                  control={<Radio />}
                  label={l.message[language]}
                />
                <FormControlLabel
                  value="Complaint"
                  control={<Radio />}
                  label={l.complaint[language]}
                />
              </RadioGroup>
            </FormControl>
            <div>
              <Typography component="legend">
                {l.importance[language]}
              </Typography>
              <Rating
                name={"Importance"}
                sx={{ marginTop: 1 }}
                value={message.importance}
                onChange={(e) =>
                  setMessage({ ...message, importance: e.target.value })
                }
              />
            </div>
          </div>
          <HtComplete tagged={message.tags} setTagged={(tags)=> setMessage(prev=>({...prev,tags}))} />
         {/*  <Autocomplete
            multiple
            id="tags-standard"
            options={tags}
            getOptionLabel={(option) => option.name}
            value={message.tags}
            onChange={(e, value) => {
              setMessage({ ...message, tags: value });
            }}
            renderInput={(params) => (
              <TextField {...params} label={l.tags[language]} />
            )}
            renderTags={(value, getTagProps) => {
              let background = {
                New: "linear-gradient(80deg, rgba(4,122,0,1) 0%, rgba(22,180,7,1) 100%)",
                Active: "linear-gradient(to left, #00b4db, #0083b0)",
                Pending: "linear-gradient(to right, #fc4a1a, #f7b733)",
                Lead: "linear-gradient(to right, #ff416c, #ff4b2b)",
                Stopped: "linear-gradient(to right, #ff416c, #ff4b2b)",
                htClass: "linear-gradient(to right, #616161, #9bc5c3)",
              };

              return value.map((option, index) => (
                <Chip
                  style={{
                    background:
                      option.type === "Student"
                        ? background[option.status]
                        : background.htClass,
                    color: "white",
                  }}
                  variant="outlined"
                  label={`${option.name}`}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderOption={(props, option) => {
              const { name, status, type } = option;
              let color = "#000000";
              if (type === "Student" && status === "New") color = "#009e2f";
              if (type === "Student" && status === "Active") color = "#006f85";
              if (type === "Student" && status === "Pending") color = "#f79400";
              if (type === "Student" && ["Lead", "Stopped"].includes(status))
                color = "#f7004a";
              if (type === "HtClass") color = "#555555";
              return (
                <span {...props} style={{ color }}>
                  {name}
                </span>
              );
            }}
          /> */}

          <TextField
            fullWidth
            multiline
            rows={4}
            label={l.description[language]}
            value={message.description}
            onChange={(e) =>
              setMessage({ ...message, description: e.target.value })
            }
          ></TextField>
          <Autocomplete
            multiple
            id="tags-standard"
            options={st}
            getOptionLabel={(option) => option.name}
            value={message.who}
            onChange={(e, value) => setMessage({ ...message, who: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  l.staffMembersWhoWillDealWithThisMessageOrComplaint[language]
                }
              />
            )}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  style={{
                    background: "linear-gradient(to right, #616161, #9bc5c3)",

                    color: "white",
                  }}
                  variant="outlined"
                  label={`${option.name}`}
                  {...getTagProps({ index })}
                />
              ));
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setMessageDialogOpen(false)}>Close</Button>
        <Button
          color="success"
          onClick={() => sendNewMessage()}
          disabled
          {...(message.who &&
            message.who.length > 0 && {
              disabled: false,
            })}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function buildTags(students, customers, classes, staff) {
  if (!students) return [];

  let tags = [];

  students
    .filter((s) => ["New", "Active"].includes(s.status))
    .forEach((student) => {
      let c = {
        id: student._id,
        name: `${student.name} ${student.classes
          .map((c) => c.name)
          .toString()}`,
        htClass: student.classes.map((c) => ({ id: c._id, name: c.name })),
        status: student.status,
        type: "Student",
      };
      tags.push(c);
    });
  students
    .filter((s) => s.status === "Pending")
    .forEach((student) => {
      let c = {
        id: student._id,
        name: `${student.name} ${student.classes
          .map((c) => c.name)
          .toString()}`,
        htClass: student.classes.map((c) => ({ id: c._id, name: c.name })),
        status: student.status,
        type: "Student",
      };
      tags.push(c);
    });
  classes.forEach((customer) => {
    let c = {
      id: customer._id,
      name: `${customer.name}`,
      type: "HtClass",
    };
    tags.push(c);
  });
  staff.forEach((customer) => {
    let c = {
      id: customer._id,
      name: `${customer.name}`,
      type: "User",
    };
    tags.push(c);
  });
 /*  customers.forEach((customer) => {
    let c = {
      id: customer._id,
      name: `${customer.name ? customer.name : "No name"} ${customer.phone}`,
      type: "Customer",
    };
    tags.push(c);
  }); */
  students
    .filter((s) => ["Lead", "Stopped"].includes(s.status))
    .forEach((student) => {
      let c = {
        id: student._id,
        name: `${student.name} ${student.classes
          .map((c) => c.name)
          .toString()}`,
        htClass: student.classes.map((c) => ({ id: c._id, name: c.name })),
        status: student.status,
        type: "Student",
      };
      tags.push(c);
    });

  return tags;
}
