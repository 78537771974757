import React from "react";
import { net } from "../../../database";
import { useParams } from "react-router-dom";
import Loading from "../../content/Loading";
import {
  IconButton,
  Box,
  LinearProgress,
  Typography,
  AppBar,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Box sx={{ width: "100%", mr: 1, mt: 0.05 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 0, p: 0 }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function MakeUpClassAttendance(props) {
  //const {l,language} = props;
  let { id } = useParams();
  const [muClass, setMuClass] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/makeup-class/${id}`).then((res) => setMuClass(res));
  }, [id]);
  if (!muClass) return <Loading />;
  let progress = calculateProgress(muClass);
  let color = "error";
  if (progress > 50) color = "warning";
  if (progress > 99) color = "success";

  console.log(muClass);
  return (
    <div style={{ padding: 5 }}>
      <AppBar sx={{ alignItems: "center" }}>
        <div style={{ width: "80vw", textAlign: "center" }}>
          <h3 style={{ marginBottom: 0 }}>Attendance for {muClass.level}</h3>
          <LinearProgressWithLabel
            value={progress}
            color={color}
            /*   style={{ marginTop: 0, marginBottom: 10 }} */
          />
        </div>
      </AppBar>
      <div style={{ height: 80 }}></div>
      <div style={{ overflowY: "scroll" }}>
        {muClass.students.map((student) => (
          <DrawAttendance
            student={student}
            muClass={muClass}
            setMuClass={setMuClass}
          />
        ))}
      </div>
    </div>
  );
}

function DrawAttendance(props) {
  let { student, muClass, setMuClass } = props;
  let buttons = [
    {
      letter: <DoneIcon style={{ fontSize: 30 }} />,
      color: "green",
      attendance: 1,
    },
    { letter: "L", color: "limegreen", attendance: -1 },
    { letter: "P", color: "orange", attendance: 2 },
    { letter: "O", color: "red", attendance: 3 },
  ];

  buttons.forEach((button) => {
    if (student.attendance && student.attendance === button.attendance)
      button.selected = true;
  });

  let doAttendance = (attendance) => {
    net
      .post(`/makeupclass/${muClass._id}/attendance/${student._id}`, {
        attendance,
      })
      .then((res) => setMuClass(res));
  };

  return (
    <div
      className="dflex-sb"
      style={{
        fontSize: 18,
        borderBottom: "1px solid #aaa",
        alignItems: "center",
        height: 80,
      }}
    >
      <div style={{ width: 200 }}>{student.student.name}</div>
      <div
        style={{ display: "flex", width: 200, justifyContent: "space-between" }}
      >
        {buttons.map((button) => (
          <div style={{ marginRight: 5 }}>
            {button.selected ? (
              <IconButton
                style={{
                  background: button.color,
                  color: "white",
                  fontSize: 24,
                  width: 45,
                  height: 45,
                }}
                onClick={() => doAttendance(button.attendance)}
              >
                {button.letter}
              </IconButton>
            ) : (
              <IconButton
                style={{
                  color: button.color,

                  fontSize: 24,
                  width: 45,
                  height: 45,
                }}
                onClick={() => doAttendance(button.attendance)}
              >
                {button.letter}
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
function calculateProgress(htClass) {
  console.log(htClass.students);
  let attendance = 0;
  htClass.students.forEach((s) => {
    if (s.attendance) attendance++;
  });
  if (attendance === 0) return attendance;
  return (100 / htClass.students.length) * attendance;
}
