import React, { useContext } from "react";
import { MenuItem, TextField } from "@mui/material";

import { CenterContext } from "../../../Context";

export default function LocationChoice(props) {
  const {value,setValue,all} = props
  const { center } = useContext(CenterContext);
  let { locations } = center;

  if (!center) return <div></div>;
  return (
    <div>
      <TextField
        select
        label="Location"
        value={value}
        onChange={(e)=>setValue(e.target.value)}
        {...props}
        size="small"
        style={{width:200}}
      >
        {locations.map((location) => (
          <MenuItem
            key={location._id}
            value={location._id}
          >
            {location.name}
          </MenuItem>
        ))}
       
      </TextField>
    </div>
  );
}
