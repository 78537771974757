import React from "react";
import Select from "../../../components/Select";
import SelectFromList from "../../../components/SelectFromList";
import ChooseDate from "../../../components/ChooseDate";


export default function Scholarship(props) {
    const {scholarshipDate,setScholarshipDate,value,id} = props

    return (<div style={{marginTop:15}}>
        <SelectFromList {...props} label="Scholarship" choices={[{ _id: false, name: "No" }, { _id: true, name: "Yes" }]} style={{width:200, marginRight:10}} size="small" />
        {value && <ChooseDate value={scholarshipDate} setValue={setScholarshipDate} url={`/students/${id}`} field="scholarshipDate" />}
    </div>)
}