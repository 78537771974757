
import { addDays, format, isToday } from "date-fns";
import React from "react";
import ChooseDate from "./ChooseDate";
import { IconButton } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";



/**
 * Description placeholder
 * @date 8/8/2023 - 8:58:32 PM
 *
 * @export
 * @param {date} value
 * @param {function} setValue optional
 * @param {boolean} buttons show buttons to move forward and backwards days
 * @param {string} align align the date in the picker
 * @returns {*}
 */
export default function HtDate(props) {
  let { value, setValue, buttons,style={},future,align } = props;
 
  if (!value) return (<React.Fragment>No date</React.Fragment>);
  let dateIn = value;
    if (typeof value === "string") dateIn = new Date(value);

    let showDate = "Invalid Date"
    try {
        showDate = format(dateIn, "dd/MM/yyyy");
    } catch (err) {
        //console.log(err)
    }
  if (!setValue) {
    return <>{showDate}</>;
  }
  return (
    <div style={{display:"flex", gap:5}}>
      {buttons ? <DrawButton direction="down" setValue={setValue} value={value} future={future} /> : null}
      <ChooseDate
        value={value}
        setValue={setValue}
        style={style}
        align={align}
      />
      {buttons ? <DrawButton direction="up" setValue={setValue} value={value} future={future} /> : null}
    </div>
  );
}

function DrawButton({ direction, setValue, value,future }) {

  const ad = () => {
    setValue(addDays(value,direction==="up" ? 1 : -1))
  }

  return (
    <IconButton
      onClick={ad}
      {...(isToday(value) && direction==="up" && !future && {
        disabled: true,
      })}
    >
      {direction === "down" ? (
        <ArrowCircleLeftIcon />
      ) : (
        <ArrowCircleRightIcon />
      )}
    </IconButton>
  );
}