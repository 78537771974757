import React from "react";
import Buttons from "./Buttons";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { net } from "../../../../../database";
import SLink from "../../../components/SLink";
import UploadAvatar from "../../../components/UploadAvatar";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


export default function AttendanceButtons2(props) {
  let { student, htClass, setHtClass, showDate } = props
  const mobile = 
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )

  return (
    <div style={{borderBottom: "1px solid #ccc",paddingBottom :5}}>
      <div style={{ display: "flex", alignItems:"center", padding :5 }}>
        {/* <StudentNamePhone student={student._id} /> */}
        <UploadAvatar id={student._id} style={{ marginRight: 10 }} />
       
        <SLink id={student._id} style={{width:250}} showPhone status />
        <Buttons
        student={student}
        htClass={htClass}
        setHtClass={setHtClass}
         showDate={showDate}
        />
        {!mobile ? <AbsentReason {...props} mobile={mobile} /> : null}
      </div>
     {mobile ? <AbsentReason {...props} mobile={mobile}/> : null}
   </div>
  );
}

function AbsentReason({ student, setHtClass, htClass,mobile }) {

  const [absentReason,setAbsentReason] = React.useState(student.absentReason || "")

  const updateAbsentReason = () => {
    net
      .post(`/students/absentReason/${student.attendanceId}`, {
        absentReason,
      })
      .then((attendance) => {
        let students = htClass.students;
        let st = students.find((s) => s._id === attendance.student);
        st.absentReason = attendance.absentReason;
        setHtClass({ ...htClass, students });
      });
  };
  if(!student.attendance || student.attendance<2) return null
  if(mobile)return <TextField size="small" /* sx={{ ml: 2 }} */ /* style={{ width:"90%" }}  */ fullWidth label="Absent reason" value={absentReason} onChange={(e)=>setAbsentReason(e.target.value)} onBlur={updateAbsentReason}/>
  return <TextField size="small" sx={{ ml: 2 }} style={{flexGrow:1}} label="Absent reason" value={absentReason} onChange={(e)=>setAbsentReason(e.target.value)} onBlur={updateAbsentReason} InputProps={{
    endAdornment: <InputAdornment position="end"><RefundResult student={student} /></InputAdornment>,
          }}/>

}


function RefundResult({student}) {
  if (student.attendance === 3) return <Tooltip title="No refund for notifying after class start time"><CancelIcon style={{ color: "red",cursor: "pointer",marginTop:0 }} /></Tooltip>
  if(student.cantRefund) return <Tooltip title="Can't refund due to more than 2 absences this month"><CancelIcon style={{ color: "red",cursor: "pointer" }} /></Tooltip>
  return <Tooltip title={`Refunded: ${student.refundAmount ? (0-student.refundAmount).toLocaleString(): 0} for notifying before the class`}><CheckCircleIcon style={{color: "green",cursor: "pointer"}} /></Tooltip>
}