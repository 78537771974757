import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Button, TextField } from "@mui/material";
import StudentAvatar from "../student/components/StudentAvatar";

export default function NoShows() {
  const [absent, setAbsent] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/noshows").then((r) => setAbsent(r));
  }, []);

  if (!absent) return <Loading />;
  console.log(absent);
  return (
    <div style={{ padding: 10 }}>
      {absent.map((a) => (
        <div style={{ display: "flex" }}>
          <div style={{ width: 300 }}>
            <StudentAvatar student={a} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexGrow: 1,
            }}
          >
            <TextField
              variant="standard"
              label="Reason for no show / Notes"
              fullWidth={true}
            ></TextField>
          </div>
        </div>
      ))}
    </div>
  );
}
