import React from "react";
import { formatTime } from "../../../functions";

/**
 * 
 * @param {string} time the time to format
 * @param {boolean} short make the format shorter  
 * @returns 
 */

export default function Time(props) {
  let { time, short } = props;
  if (!time) return <div>Unknown</div>;
  try {
    let ftime = short ? formatTime(time).replace("pm","").replace("am","") : formatTime(time);
    return <React.Fragment >{ftime}</React.Fragment>;
  } catch {
    return <div>Incorrect time</div>;
  }
}
