import { format } from "date-fns";
import React from "react";
import Staff from "../../../../components/Staff";

export default function Cashier(props) {
  const { receipt } = props;

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <div>{format(new Date(receipt.date), "dd/MM/yyyy hh:mmaaa")}</div>
      <div>Thu ngân: {<Staff id={receipt.cashier} />}</div>
    </div>
  );
}
