import React from "react";
import Button from "@mui/material/Button";
import NewStaffDialog from "./components/NewStaffDialog";
import { Link } from "react-router-dom";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  MenuItem,
} from "@mui/material";
import Loading from "../../Loading";
import { net } from "../../../../database";
import StopUserButton from "../../components/StopUserButton";

import Salaries from "./Salaries";

export default function Staff(props) {
  const [openNSD, setOpenNSD] = React.useState(false);
  const [myStaff, setMyStaff] = React.useState(undefined);
  const [managers, setManagers] = React.useState([]);
  const [selectedStaff, setSelectedStaff] = React.useState(undefined);
  const [openManagerDialog, setOpenManagerDialog] = React.useState(false);
  const [manager, setManager] = React.useState("");

  React.useEffect(() => {
    props.net.get("/mystaff/" + props.user._id).then((staff) => {
      console.log(staff);
      setMyStaff(staff.users);
      setManagers(staff.managers);
    });
  }, [props.net, props.user._id, openNSD]);

  if (!myStaff) return <Loading />;
  console.log(myStaff)
  const addToManager = () => {
    let a = {
      staffId: selectedStaff._id,
      managerId: manager,
    };
    net.post(`/addstafftomanager`, a).then((oldStaff) => {
      let position = myStaff.findIndex((s) => s._id === oldStaff._id);
      myStaff.splice(position, 1);
      setMyStaff(myStaff);
      setSelectedStaff(undefined);
      setOpenManagerDialog(false);
    });
  };

  return (
    <div>
      <h1>My Staff</h1>
      {myStaff.map((member) => (
        <div
          key={member._id}
          style={{ display: "flex", justifyContent: "space-between",marginBottom:10 }}
        >
          <div style={{width:200}}>
          <Link className="neat" to={`/manage/staff/${member._id}`}>
            {member.name}
          </Link>
        </div>
         <Salaries member={member} setMyStaff={setMyStaff} myStaff={myStaff} />
            <div>
            <Button
              onClick={() => {
                setSelectedStaff(member);
                setOpenManagerDialog(true);
              }}
            >
              change manager
            </Button>

            <StopUserButton
              id={member._id}
              result={(res) =>
                setMyStaff(myStaff.filter((s) => s._id !== res._id))
              }
            />
          </div>
        </div>
      ))}
      <Button variant="contained" onClick={() => setOpenNSD(true)}>
        New Staff Member
      </Button>
      <NewStaffDialog
        open={openNSD}
        setOpen={setOpenNSD}
        net={props.net}
        user={props.user}
      />
      {selectedStaff ? (
        <Dialog open={openManagerDialog}>
          <DialogTitle>Choose manager for {selectedStaff.name}</DialogTitle>
          <DialogContent>
            Reports to:
            <div>
              <TextField
                select
                variant="standard"
                fullWidth
                id="manager"
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              >
                {managers.map((manager) => (
                  <MenuItem key={manager._id} value={manager._id}>
                    {manager.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedStaff(undefined);
                setOpenManagerDialog(false);
              }}
            >
              Close
            </Button>
            <Button color="success" onClick={() => addToManager()}>
              Add to manager
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}
