import React from "react";
import { net } from "../../../../../database";
import { Table, Button } from "antd";
import Loading from "../../../Loading";
import { exportFile } from "table-xlsx";
import { format } from "date-fns";
import { formatPhone } from "../../billCustomers/functions";
import NumberFormat from "react-number-format";
import { MenuItem, TextField } from "@mui/material";

export default function StaffList(props) {
  const { center, setCenter } = props;
  const [users, setUsers] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/usersdetails").then((res) => {
      res.forEach((r, i) => {
        r.stt = i + 1;
      });
      setUsers(res);
    });
  }, []);
  const onExportFileClick = () => {
    let uout = [];
    for (let user of users) {
      let location = center.locations.find((l) => l._id === user.location);
      let department = center.departments.find(
        (d) => d._id === user.department
      );
      let reportsTo = users.find((u) => u._id === user.reportsTo);
      uout.push({
        ...user,
        stt: user.stt,
        fullName: user.fullName || user.name,
        department: department ? department.name : "",
        location: location ? location.name : "",
        reportsTo: reportsTo ? reportsTo.name : "",
        dateOfBirth: user.dateOfBirth
          ? format(new Date(user.dateOfBirth), "dd/MM/yyyy")
          : "",
        phone: user.phone ? formatPhone(user.phone) : "",
      });
    }
    let cout = [
      {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Họ Tên",
        dataIndex: "fullName",
      },
      {
        title: "Số ĐT",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Chức vụ",
        dataIndex: "position",
        key: "position",
      },
      {
        title: "DOB",
        dataIndex: "dateOfBirth",
        key: "dateOfBirth",
      },
      {
        title: "Mail",
        dataIndex: "login",
        key: "login",
      },
      {
        title: "Địa Chỉ",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Bộ phận",
        dataIndex: "department",
        key: "department",
      },
      {
        title: "Reports to",
        dataIndex: "reportsTo",
        key: "reportsTo",
      },
      {
        title: "Cơ sở làm việc",
        dataIndex: "location",
        key: "location",
      },
      {
        title: "Lương",
        dataIndex: "salary",
        key: "salary",
      },
      {
        title: "Thưởng",
        dataIndex: "bonus",
        key: "bonus",
      },
      {
        title: "Chấm công",
        dataIndex: "",
        key: "",
      },
    ];

    exportFile({
      columns: cout,
      dataSource: uout,
      fileName: `staff.xlsx`,
    });
  };
  if (!users || !center) return <Loading />;

  const update = (field, data, id) => {
    net.post("/user/" + id, { [field]: data }).then((r) => {
      console.log(r);
    });
    let user = users.find((u) => u._id === id);
    user[field] = data;
    setUsers(users.map((u) => (u._id === user._id ? user : u)));
  };
  const cols = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Họ Tên",
      dataIndex: "fullName",
      render: (text, record) => {
        return (
          <div style={{ width: 100 }}>
            {record.fullName ? text : record.name}{" "}
          </div>
        );
      },
    },
    {
      title: "Số ĐT",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => {
        return (
          <div style={{ width: 100 }}>
            {text && text !== "" ? formatPhone(text) : ""}{" "}
          </div>
        );
      },
    },
    {
      title: "Chức vụ",
      dataIndex: "position",
      key: "position",
      render: (text, record) => {
        return (
          <TextField
            value={text}
            size="small"
            onChange={(e) => update("position", e.target.value, record._id)}
            style={{ width: 100 }}
            InputProps={{ style: { fontSize: 12 } }}
          />
        );
      },
    },
    {
      title: "BOD",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (text, record) => {
        return (
          <div>
            {text && text !== "" ? format(new Date(text), "dd/MM/yyyy") : ""}{" "}
          </div>
        );
      },
    },
    {
      title: "Mail",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Địa Chỉ",
      dataIndex: "address",
      key: "address",
      render: (text, record) => {
        return <div style={{ width: 100 }}>{text}</div>;
      },
    },
    {
      title: "Bộ phận",
      dataIndex: "department",
      key: "department",
      render: (text, record) => {
        return (
          <TextField
            select
            value={text ? text : ""}
            size="small"
            onChange={(e) => update("department", e.target.value, record._id)}
            style={{ width: 100 }}
            InputProps={{ style: { fontSize: 12 } }}
          >
            {center.departments.map((department) => (
              <MenuItem value={department._id} key={department._id}>
                {department.name}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      title: "Reports to",
      dataIndex: "reportsTo",
      key: "reportsTo",
      render: (text, record) => {
        return (
          <TextField
            select
            value={text ? text : ""}
            size="small"
            onChange={(e) => update("reportsTo", e.target.value, record._id)}
            style={{ width: 100 }}
            InputProps={{ style: { fontSize: 12 } }}
          >
            {users
              .toSorted(
                (u) =>
                  !u.roles.includes("Manager") - u.roles.includes("Manager")
              )
              .map((user) => (
                <MenuItem value={user._id} key={user._id}>
                  {user.name}
                </MenuItem>
              ))}
          </TextField>
        );
      },
    },
    {
      title: "Cơ sở làm việc",
      dataIndex: "location",
      key: "location",
      render: (text, record) => {
        return (
          <TextField
            select
            value={text ? text : ""}
            size="small"
            onChange={(e) => update("location", e.target.value, record._id)}
            style={{ width: 100 }}
            InputProps={{ style: { fontSize: 12 } }}
          >
            {center.locations.map((location) => (
              <MenuItem value={location._id} key={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      title: "Lương",
      dataIndex: "salary",
      key: "salary",
      render: (text, record) => {
        return (
          <NumberFormat
            value={text}
            thousandSeparator
            onValueChange={(value, e) =>
              update("salary", value.value, record._id)
            }
            style={{ border: "none" }}
          />
        );
      },
    },
    {
      title: "Thưởng",
      dataIndex: "bonus",
      key: "bonus",
      render: (text, record) => {
        return (
          <NumberFormat
            value={text}
            thousandSeparator
            onValueChange={(value, e) =>
              update("bonus", value.value, record._id)
            }
            style={{ border: "none" }}
          />
        );
      },
    },
    {
      title: "Chấm công",
      dataIndex: "",
      key: "",
    },
  ];

  return (
    <div>
      <div className="dflex-sb" style={{ alignItems: "center" }}>
        <h1>Staff List</h1>
        <Button onClick={() => onExportFileClick()}>Export to Excel</Button>
      </div>
      <Table columns={cols} dataSource={users} />
    </div>
  );
}
