import React, { useContext } from "react";
import Lang from "../../components/Lang";
import DrawStoppedStudent from "./DrawStoppedStudent";
import { Button } from "@mui/material";
import Month from "../../components/Month";
import { exportFile } from "table-xlsx";
import { format } from "date-fns";
import { ClassesContext } from "../../../../Context";

import Loading from "../../Loading";
import StudentsLostAtLocation from "./StudentsLostAtLocation";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";

export default function LostStudents(props) {
  const { month, year } = props;
  const { findClasses } = useContext(ClassesContext)
  const [stopped, setStopped, error, loading] = useDB(
    `/stopped-students/${year}/${month}`
  );

  if(loading) return <Loading />
  if(error) return <Error />

  const exportStopped = () => {
     exportFile({
      columns: getColumns(),
      dataSource: stopped.map(s=>({...s, classes: findClasses(s.classes).map((c) => c.name).toString(),stoppedReason:  s.statusHistory.length > 0
        ? s.statusHistory[s.statusHistory.length - 1].reason
        : s.leaveReason,statusChanged: format(new Date(s.statusChanged),"dd/MM/yyyy") })),
      fileName: `stopped_${new Date().getMonth()+1}_${new Date().getFullYear()}.xlsx`,
    });
  }



return (
  <div>
    <div style={{display:"flex", justifyContent: "space-between",alignItems:"center"}}>
   {/*  <h3>
      Lost students in <Month value={month+1} /> ({stopped.length})
      </h3> */}
    <StudentsLostAtLocation students={stopped} />
    <Button size="small" sx={{height:30}} onClick={exportStopped}>Export</Button>
  </div>
    <div
      style={{ display: "flex" }}
      className="new-lost-students-table-title"
    >
      <div style={{ width: 300,minWidth:300 }}>
        <Lang w="name" />
      </div>
      <div style={{ width: 200 }}>
        <Lang w="status" />
      </div>
      <div style={{ width: 150 }}>
        <Lang w="dateTheyStopped" />
      </div>
      <div style={{ flexGrow: 1 }}>
     
      </div>

     
    </div>

    {stopped.map((student) => (
      <DrawStoppedStudent student={student} setStopped={setStopped} key={student._id} />
    ))}
  </div>
);
}

function getColumns() {
  return [{
     field: "name",
        key: "name",
        title: "Name",
  },
    {
      field: "status",
        key: "status",
      title: "Status",
    },
  { field: "classes",
        key: "classes",
      title: "Classes"
    },
  { field: "stoppedReason",
        key: "stoppedReason",
      title: "Reason"
    },
    {
      field: "statusChanged",
        key: "statusChanged",
        title: "Date",}]
}