import React from "react";


export default function Error(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      There was an error loading your page, this error has been recorded
    </div>
  );
}
