import {Button,Dialog,DialogActions,DialogContent,DialogTitle, Stack, TextField} from "@mui/material";
import React from "react";
import mut from "../../hooks/mut";
import DoneIcon from "@mui/icons-material/Done";

export default function PasswordDialog(props) {
  const { open, setOpen } = props;
    const [password, setPassword] = React.useState("")
  const [password2, setPassword2] = React.useState("");
  const [success,setSuccess] = React.useState(false)
  let ok = password !== "" && password === password2 ? true : false
  
  const update = async () => {
    let result = await mut("post", "/user/updpw", { password })
    if (result.result) {
      setSuccess(true)
      setTimeout(() => { setOpen(false); setSuccess(false); setPassword("");setPassword2("") },2000)
    }
  }

  if(success) return <Dialog open={open} fullWidth><Success /></Dialog>

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>Change password</DialogTitle>

      <DialogContent>
        <Stack
          spacing={2}
          style={{ marginTop: 10 }}
        >
          <TextField
            type="password"
            label="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          ></TextField>
          <TextField
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            label="Repeat password"
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          ></TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={update}
          {...(!ok && {
            disabled: true,
          })}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Success(props) {
  
  return (
    <div>
      {" "}
      <DialogTitle>Change password</DialogTitle>
      <DialogContent style={{display: "flex", flexDirection:"column", alignItems:"center"}}>
        <div style={{background:"green", width:100, height:100, borderRadius: 50, display: "flex", alignItems:"center", justifyContent: "center"}}>
  
          <DoneIcon style={{fontSize:40, color: "white"}} />
        </div>
        <h2 style={{fontWeight: 300, color:"green"}}>Success</h2>
      </DialogContent>
    </div>
  );
}