import React, { useContext } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import HtTabs from "../../components/HtTabs";
import { CenterContext } from "../../../../Context";
import replaceById from "../../hooks/replaceById";



export default function Survey(props) {


const [classes, setClasses, error, loading] = useDB(`/class-survey`)
const {locations} = useContext(CenterContext)

if (loading) return <Loading />;
if (error) return <Error />;

    



console.log(classes);


    return (
      <div>
        <HtTabs
          labels={locations.map(
            (l) => `${l.name} (${classes.filter((c) => c.location === l._id).length})`
          )}
          components={locations.map((l) => (
            <Classes classes={classes.filter((c) => c.location === l._id)} setClasses={setClasses} />
          ))}
        />
      </div>
    );
}

function Classes({ classes, setClasses }) {
  return (
    <div>
      {classes.map((c) => (
          <div>{c.name} <button onClick={()=>replaceById(setClasses,{name:"blah",_id: c._id})}>update</button></div>
      ))}
    </div>
  );
}
