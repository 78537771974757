import React, { useContext, useState } from "react";
import SelectFromList from "../../components/SelectFromList";
import { StaffContext } from "../../../../Context";
import ChooseDate from "../../components/ChooseDate";
import Text from "../../components/Text";
import PhoneNumber from "../../components/PhoneNumber";
import Lang from "../../components/Lang";
import Locations from "../../components/Locations";
import TimeChooser from "../../components/TimeChooser";
import DatePicker from "../../components/DatePicker";
import AddButton from "../../components/AddButton";



export default function DrawTest({contact, showResult=false, showRating=true}) {


 const { getStaffRole } = useContext(StaffContext);

  const [consultantNotes, setConsultantNotes] = useState(
    contact.consultantNotes || ""
  );
  const [consultant, setConsultant] = useState(contact.consultant || "");
  const [consultantForTest, setConsultantForTest] = useState(contact.consultantForTest || "");
  const [testTime, setTestTime] = useState(
    contact.testTime|| ""
  );
  const [testLocation, setTestLocation] = useState(
    contact.testLocation || ""
  );
  const [contacted, setContacted] = useState(
    new Date(contact.consultantContacted) || ""
  );
  const [testDate, setTestDate] = useState(contact.testDate || "");
  const [result, setResult] = useState(contact.result || "");
   const results = [
     { _id: "New student", name: <Lang w="newStudent" /> },
     { _id: "Potential", name: <Lang w="potential" /> },
     { _id: "Not interested", name: <Lang w="notInterested" /> },
     { _id: "Cannot contact", name: <Lang w="cannotContact" /> },
     { _id: "Reschedule", name: <Lang w="reschedule" /> },
   ];

  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
        marginBottom: 15,
        marginRight: 10,
        borderBottom: "1px solid #ccc",
        paddingBottom: 5,
      }}
    >
      <div style={{ width: 150 }}>
        <div>{contact.name}</div>
        <PhoneNumber value={contact.phone} />

        <div style={{ width: 100, fontSize: 11 }}>{contact.location}</div>
      </div>

      <div style={{ width: 200, fontSize: 11 }}>
        <div>{<Lang w="notes" />}</div>
        <div>{contact.notes}</div>
      </div>
      <div style={{ width: 150 }}>
        <SelectFromList
          label={<Lang w="consultant" />}
          choices={getStaffRole("Sales")}
          fullWidth
          url={`/marketingcontacts/${contact._id}`}
          field="consultant"
          value={consultant}
          setValue={setConsultant}
          size="small"
          style={{ marginBottom: 10 }}
        />
        <ChooseDate
          label={<Lang w="dateContacted" />}
          url={`/marketingcontacts/${contact._id}`}
          field="consultantContacted"
          value={contacted}
          setValue={setContacted}
          size="small"
        />
      </div>
      <div style={{ flexGrow: 0.6 }}>
        <Text
          label="Kết quả"
          fullWidth
          multiline
          rows={2}
          fontSize={12}
          url={`/marketingcontacts/${contact._id}`}
          field="consultantNotes"
          value={consultantNotes}
          setValue={setConsultantNotes}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <DatePicker
          value={new Date(testDate)}
          setValue={setTestDate}
          url={`/marketingcontacts/${contact._id}`}
          field="testDate"
          label={<Lang w="testDate" />}
        />
        <TimeChooser
          value={testTime}
          setValue={setTestTime}
          label={<Lang w="time" />}
          size="small"
          url={`/marketingcontacts/${contact._id}`}
          field="testTime"
        />
      </div>
      <div style={{ width: 150 }}>
        <SelectFromList
          label={<Lang w="testedBy" />}
          choices={getStaffRole("Sales")}
          fullWidth
          url={`/marketingcontacts/${contact._id}`}
          field="consultantForTest"
          value={consultantForTest}
          setValue={setConsultantForTest}
          size="small"
          style={{ marginBottom: 10 }}
        />
        <Locations
          value={testLocation}
          setValue={setTestLocation}
          url={`/marketingcontacts/${contact._id}`}
          field="testLocation"
          size="small"
        />
      </div>
      <div>
        <SelectFromList
          choices={results}
          value={result}
          setValue={setResult}
          field="result"
          url={`/marketingcontacts/${contact._id}`}
          none
          style={{ width: 150, minWidth: 150 }}
          size="small"
          label={<Lang w="result" />}
        />
      </div>
      {result==="New student" && <AddButton />}
    </div>
  );
}