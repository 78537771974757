import {Button,Dialog,DialogActions,DialogContent,DialogTitle, Step, StepLabel, Stepper} from "@mui/material";
import React, { useState } from "react";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import mut from "../../hooks/mut";
import { useNavigate } from "react-router-dom";

export default function NewClassDialog2(props) {
  const { open, setOpen } = props;
  const [step, setStep] = useState(0)
  const [newClass, setNewClass] = useState({status:"Coming"})
 
  const steps = [
    <StepOne
      newClass={newClass}
      setNewClass={setNewClass}
    />,
    <StepTwo
      newClass={newClass}
      setNewClass={setNewClass}
    />,
    <StepThree
      newClass={newClass}
      setNewClass={setNewClass}
    />,
  ];

  

  

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>Add new class</DialogTitle>
      <DialogContent>
        <Stepper
          activeStep={step}
          alternativeLabel
        >
          {["Basic info","Timetable","Confirm"].map((label,i) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[step]}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => { setOpen(false); setNewClass({ status: "Coming" }); setStep(0) }}
        >
          Close
        </Button>
        <Buttons
          step={step}
          setStep={setStep}
          newClass={newClass} 
        />
      </DialogActions>
    </Dialog>
  );
}

function Buttons(props){
  const { step, setStep, newClass } = props
  const navigate = useNavigate();
  const finish = () => {
  const startDate = new Date(newClass.startDate.getFullYear(),newClass.startDate.getMonth(),newClass.startDate.getDate(),7)
    mut("post", "/classes", { ...newClass, fee: { fee: newClass.fee, feeChangeDate: new Date() }, startDate }, (res) =>
     navigate(`/manage/classes/edit/${res._id}`)

  );
};

return (
  <div>
    <Button color="warning" onClick={() => setStep((prev) => prev - 1)}
      {...(step < 1 && { disabled: true })}
    >Back
    </Button>
  
    {step < 2 ? <Button
      color="success"
      onClick={() => setStep((prev) => prev + 1)}
      {...(checkError(newClass,step) && {
      disabled: true 
      })}
    >
      Next
    </Button> :
      <Button
        color="success"
        onClick={finish}
        {...(!newClass.startDate && {
        disabled: true 
        })}
      >
        Finish
      </Button>
    }
  </div>
);
}

function checkError(newClass,step) {
  if (step === 0) {
    if (!newClass.name || !newClass.location || !newClass.teacher || !newClass.fee) return true
  }
   if (step === 1) {
     if (
       !newClass.classDays || newClass.classDays.length<1
    
     )
       return true;
   }
  return false
}