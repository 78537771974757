import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Button, Table } from "antd";
import MonthChoice from "../../components/MonthChoice";
import { exportFile } from "table-xlsx";

export default function NewClasses(props) {
  const { language } = props;

  const [classes, setClasses] = React.useState(undefined);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth());
  let navigate = useNavigate();

  React.useEffect(() => {
    net.get(`/classes/schedule/${year}/${month}`).then((res) => {
      setClasses(res);
    });
  }, [month, year]);
  if (!classes) return <Loading />;
  console.log(classes);
  let starting = buildRows(classes.starting, true);
  let finishing = buildRows(classes.ending, false);

  const onExportFileClick = (reportType) => {
    for (let s of starting) {
      if (s.lessons === 0) s.lessons = "0";
      if (s.students === 0) s.students = "0";
    }
    exportFile({
      columns: cols,
      dataSource:
        reportType === "Coming"
          ? starting.filter((row) => parseInt(row.lessons) === 0)
          : starting.filter((row) => row.lessons > 0),
      fileName: `${reportType}.xlsx`,
    });
  };

  return (
    <div>
      <div className="dflex-sb" style={{ alignItems: "center" }}>
        <h1>Classes starting and finishing</h1>
        {/*   <MonthChoice
          year={year}
          month={month}
          setYear={setYear}
          setMonth={setMonth}
          language={language}
          future
        /> */}
      </div>
      <div className="dflex-sb">
        <h3>Coming</h3>
        <Button onClick={() => onExportFileClick("Coming")}>Export</Button>
      </div>
      <Table
        columns={cols}
        dataSource={starting.filter((row) => row.lessons === 0)}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/class/${record.id}`);
            },
          };
        }}
      />
      <div className="dflex-sb">
        <h3>Started</h3>
        <Button onClick={() => onExportFileClick("Started")}>Export</Button>
      </div>
      <Table
        columns={cols}
        dataSource={starting.filter((row) => row.lessons > 0)}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/class/${record.id}`);
            },
          };
        }}
      />
      <h3>Finishing</h3>
      <Table columns={cols} dataSource={finishing} pagination={false} />
    </div>
  );
}

const buildRows = (classes, starting) => {
  let rows = [];
  for (let htClass of classes) {
    let date = starting ? htClass.startDate : htClass.endDate;
    date = date ? format(new Date(date), "dd/MM/yyyy") : "No Date";
    rows.push({
      id: htClass._id,
      name: htClass.name,
      date,
      students: htClass.students.length,
      lessons: htClass.lessons,
    });
  }
  return rows;
};

const cols = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Students",
    dataIndex: "students",
    key: "students",
  },
  {
    title: "Lessons",
    dataIndex: "lessons",
    key: "lessons",
  },
];

/* function DrawClass(props) {
  let { htClass, startDate } = props;
  return (
    <div>
      <Link to={`/class/${htClass._id}`} className="neat">
        {htClass.name}
      </Link>
      <div>
        {startDate
          ? format(new Date(htClass.startDate), "dd/MM/yyyy")
          : format(new Date(htClass.endDate), "dd/MM/yyyy")}
      </div>
      <div>{htClass.students.length}</div>
    </div>
  );
} */
