import { IconButton } from "@mui/material";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import DeleteIcon from "@mui/icons-material/Delete";
import { net } from "../../../../../database";
import { UserContext } from "../../../../../Context";

export default function BillsDetail(props) {

  let { student, setStudent } = props;
  const {user} = useContext(UserContext)

  const deleteBill = (id) => {
    net.del(`/bills/${id}`).then((unpaidBills) => {
      setStudent({ ...student, unpaidBills });
    });
  };

  return (
    <div>
      {props.bills.map((b, i) => (
        <div
          key={`b_id${i}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 5,
            fontSize: 14,
            lineHeight: 1,
            alignItems: "center",
          }}
        >
          <div style={{ width: 150 }}>{b.billType}</div>
          <div style={{ flexGrow: 1 }}>{b.description}</div>
          <div style={{ width: 100, textAlign: "right" }}>
            <NumberFormat
              value={b.amount}
              thousandSeparator={true}
              displayType="text"
            />
          </div>
          {user.roles.includes("Accountant") ? (
            <IconButton sx={{ ml: 1 }} onClick={() => deleteBill(b._id)}>
              <DeleteIcon style={{ fontSize: 16, color: "red" }} />
            </IconButton>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
