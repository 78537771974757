import React, { useContext } from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { format } from "date-fns";
import { Button } from "@mui/material";
import StudentAvatar from "../student/components/StudentAvatar";
import { formatPhone } from "../billCustomers/functions";
import { exportFile } from "table-xlsx";
import ChatBoxR from "../../components/ChatBoxR";
import "./pending.css";
import { VariableSizeList as List } from "react-window";
import { StaffContext } from "../../../../Context";
import FormatDate from "../../components/FormatDate";
import PendingReturnDate from "../student/components/PendingReturnDate";

export default function Pending(props) {
 
  const [students, setStudents] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/students/status-min/Pending`).then((res) => setStudents(res));
  }, []);
  if (!students) return <Loading />;

  let data = [
    ...students.map((student) => ({
      name: student.name,
      phone: formatPhone(student.phone),
      classes: student.classes.map((c) => c.name).toString(),
      madePending: format(new Date(student.statusChanged), "dd/MM/yyyy"),
      returnDate:
        student.statusHistory[student.statusHistory.length - 1] &&
        student.statusHistory[student.statusHistory.length - 1].returnDate
          ? format(
              new Date(
                student.statusHistory[
                  student.statusHistory.length - 1
                ].returnDate
              ),
              "dd/MM/yyyy"
            )
          : "",
      rd:
        student.statusHistory[student.statusHistory.length - 1] &&
        student.statusHistory[student.statusHistory.length - 1].returnDate
          ? new Date(
              student.statusHistory[student.statusHistory.length - 1].returnDate
            )
          : "",
      pendingChat: student.pendingChat ? student.pendingChat : false,
      reason:
        student.statusHistory &&
        student.statusHistory[student.statusHistory.length - 1]
          ? student.statusHistory[student.statusHistory.length - 1].reason
          : "",
      _id: student._id,
      changedBy:
        student.statusHistory &&
        student.statusHistory[student.statusHistory.length - 1]
          ? student.statusHistory[student.statusHistory.length - 1].changedBy
              .name
          : "",
    })),
  ];

  let cols = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Class",
      dataIndex: "classes",
      key: "classes",
      sorter: (a, b) => a.classes.localeCompare(b.classes),
    },
    {
      title: "Made Pending",
      dataIndex: "madePending",
      key: "madePending",
      sorter: (a, b) => a.madePending.localeCompare(b.madePending),
    },
    ,
    {
      title: "Return date",
      dataIndex: "returnDate",
      key: "returnDate",
      sorter: (a, b) => a.returnDate - b.returnDate,
    },
  ];

  const onExportFileClick = () => {
    exportFile({
      columns: cols,
      dataSource: data,
      fileName: `Pending.xlsx`,
    });
  };

  data = data.sort((a, b) => (a.rd > b.rd ? 1 : b.rd > a.rd ? -1 : 0));

  const startChat = (student) => {
    net.post(`/student/${student._id}/add-pending-chat`).then((res) => {
      setStudents((prev) => {
        let st = students.find((s) => s._id === student._id);
        st.pendingChat = res._id;
        return prev.map((s) => (s._id === student._id ? st : s));
      });
    });
  };

  const Row = ({ index, style }) => (
    <DrawStudent
      student={students[index]}
      startChat={startChat}
      key={students[index]._id}
      style={style}
    />
  );

  return (
    <div>
      <div className="dflex-sb">
        <div></div>
        <Button onClick={() => onExportFileClick()}>Export</Button>
      </div>
      <div className="pending-container-labels">
        <div style={{ width: 300 }}>Name</div>
        <div style={{ width: 100 }}>Class</div>
        <div style={{ width: 100 }}>Date pending</div>
        <div style={{ width: 150 }}>Return date</div>
        <div style={{ flexGrow: 1 }}>Notes</div>
        <div style={{ width: 400 }}>Chat</div>
      </div>
      <List
        itemCount={students.length}
        height={window.innerHeight - 180}
        itemSize={(index) => {
          if (!students[index].pendingChat) return 75;
          return 350;
        }}
        /*     style={{ padding: 0, margin: 0 }} */
      >
        {Row}
      </List>
    </div>
  );
}

function DrawStudent(props) {
  let { student, startChat, style } = props;
  let { staff } = useContext(StaffContext);
  let statusChanged = student.statusHistory[student.statusHistory.length - 1];
  student = { ...statusChanged, ...student };
  const [returnDate, setReturnDate] = React.useState(
    new Date(student.returnDate)
  );

 
  return (
    <div className="pending-container" style={style}>
      {/*  <div>{student.name}</div>
      <div>{student.phone}</div> */}
      <StudentAvatar student={student} />
      <div style={{ minWidth: 100, width: 100 }}>
        {student.classes.map((c) => c.name).toString()}
      </div>
      <div style={{ minWidth: 100, width: 100 }}>
        <div>
          <FormatDate value={student.startDate} />
        </div>
        <div style={{ fontSize: 11 }}>{student.changedBy.name}</div>
      </div>
      <div style={{ minWidth: 100, width: 150 }}>
        <PendingReturnDate
          value={returnDate}
          setValue={setReturnDate}
          studentId={student._id}
        />
      </div>
      <div style={{ flexGrow: 1, fontSize: 14 }}>{student.reason}</div>
      <div style={{ minWidth: 400 }}>
        {student.pendingChat ? (
          <ChatBoxR
            chatId={student.pendingChat}
            staff={staff}
            {...props}
            key={student.pendingChat}
          />
        ) : (
          <Button onClick={() => startChat(student)}>Start Chat</Button>
        )}
      </div>
    </div>
  );
}
