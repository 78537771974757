import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../../database";
import StopUserButton from "../../../components/StopUserButton";

export default function Staff() {
  const [staff, setStaff] = React.useState(undefined);
  const [openManagerDialog, setOpenManagerDialog] = React.useState(false);
  const [selectedStaff, setSelectedStaff] = React.useState(undefined);
  const [manager, setManager] = React.useState("");

  React.useEffect(() => {
    net.get("/staffwithnomanager").then((staff) => {
      console.log(staff);
      setStaff(staff);
    });
  }, []);

  if (!staff) return <div></div>;

  const addToManager = () => {
    let a = {
      staffId: selectedStaff._id,
      managerId: manager,
    };
    net.post(`/addstafftomanager`, a).then((oldStaff) => {
      let position = staff.staffWithNoManager.findIndex(
        (s) => s._id === oldStaff._id
      );
      staff.staffWithNoManager.splice(position, 1);
      setStaff(staff);
      setSelectedStaff(undefined);
      setOpenManagerDialog(false);
    });
  };

  return (
    <div>
      <h3>Staff with no manager</h3>

      {staff.staffWithNoManager.map((member) => (
        <div
          key={member._id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid",
          }}
        >
          <div style={{ padding: 10 }}>{member.name}</div>
          <div>
            <Button
              onClick={() => {
                setSelectedStaff(member);
                setOpenManagerDialog(true);
              }}
            >
              choose manager
            </Button>
            <StopUserButton
              id={member._id}
              result={(res) =>
                setStaff({
                  ...staff,
                  staffWithNoManager: staff.staffWithNoManager.filter(
                    (s) => s._id !== res._id
                  ),
                })
              }
            />
          </div>
        </div>
      ))}

      {selectedStaff ? (
        <Dialog open={openManagerDialog}>
          <DialogTitle>Choose manager for {selectedStaff.name}</DialogTitle>
          <DialogContent>
            Reports to:
            <div>
              <TextField
                select
                fullWidth
                id="manager"
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              >
                {staff.managers.map((manager) => (
                  <MenuItem key={manager._id} value={manager._id}>
                    {manager.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedStaff(undefined);
                setOpenManagerDialog(false);
              }}
            >
              Close
            </Button>
            <Button color="success" onClick={() => addToManager()}>
              Add to manager
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}
