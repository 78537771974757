import React from "react";
import BillsToBePaid from "./BillsToBePaid";
import { Box } from "@mui/material";
import DrawStudentsNameAndTotal from "./DrawStudentsNameAndTotal";
import DrawNextBillDate from "./DrawNextBillDate";

export default function DrawBills(props) {
  let { payment } = props;
  return (
    <div>
      {payment.students
        .filter((student) => student.unpaidBills.length > 0)
        .map((student, i) => (
          <Box
            border={1}
            borderColor="#CCC"
            borderRadius={2}
            sx={{ m: 1, p: 1 }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ flexGrow: 1 }}>
              <DrawStudentsNameAndTotal student={student} {...props} />
              {!payment.excludedStudents.includes(student._id) ? (
                <BillsToBePaid student={student} {...props} />
              ) : (
                ""
              )}
            </div>
            <DrawNextBillDate student={student} {...props} />
          </Box>
        ))}
    </div>
  );
}
