import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function Costs(props) {
  let { costs, center } = props;
  /*  payments.forEach((payment) => {
    payment.id = payment.payments._id;
    payment.amount = payment.payments.amount;
    payment.date = new Date(payment.payments.date);
    payment.name = payment.name ? payment.name : "No name";
  }); */
  /*  */
  costs.forEach((cost) => {
    cost.id = cost._id;
    cost.date = new Date(cost.date);
    cost.costTypeName = center.costType[cost.costType];
    cost.paymentMethodName = center.paymentTypes[cost.paymentMethod];
  });

  const rows = costs;
  const columns = [
    { field: "date", headerName: "Date", width: 120, type: "date" },
    { field: "costTypeName", headerName: "Type", width: 120 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "paymentMethodName", headerName: "Paid By", width: 120 },
    { field: "amount", headerName: "Amount", width: 120, type: "number" },
  ];
  return (
    <div style={{ height: 5000, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}
