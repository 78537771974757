import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { format } from "date-fns";
import { socket } from "../../../../App";

export default function Users(props) {
  //const {l,language} = props;
  const [users, setUsers] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/user-stats").then((res) => setUsers(res));
    socket.on("connected-users", (res) => setUsers(res));
    return () => socket.off("connected-users");
  }, []);

  if (!users) return <Loading />;
  console.log(users);
  return (
    <div>
      {users.map((user) => (
        <div style={{ display: "flex" }} key={user._id}>
          <div style={{ width: 300 }}>{user.name}</div>
          <div>
            {user.joined
              ? format(new Date(user.joined), "h:mm:ssaaa dd/MM/yyyy")
              : ""}
          </div>
        </div>
      ))}
    </div>
  );
}
