import { IconButton } from "@mui/material";
import React from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { monthnames } from "../../../../enums";

export default function MonthPicker(props) {
  //const {l,language} = props;
  let { month, setMonth, year, setYear, language } = props;
  let disabled =
    month === new Date().getMonth() && year === new Date().getFullYear()
      ? true
      : false;

  const back = () => {
    if (month > 0) {
      setMonth(month - 1);
    } else {
      setMonth(11);
      setYear(year - 1);
    }
  };

  const forward = () => {
    if (month < 11) {
      setMonth(month + 1);
    } else {
      setMonth(0);
      setYear(year + 1);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={back}>
        <ArrowCircleLeftIcon />
      </IconButton>
      <div style={{ width: 150, textAlign: "center" }}>
        {monthnames[month + 1][language]} {year}
      </div>
      <IconButton disabled={disabled} onClick={forward}>
        <ArrowCircleRightIcon />
      </IconButton>
    </div>
  );
}
