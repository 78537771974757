import React, { useContext } from "react";
import { weekdays } from "../../../../../enums";
import { LanguageContext } from "../../../../../Context";
import ClassesOnDay from "./ClassesOnDay";
import FreeTime from "./FreeTime";

export default function Classes(props) {
const {location,search, teacherFilter,status} = props;

    const {language} = useContext(LanguageContext)

return <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: 0,
    margin: 0,
    overflowY: "scroll",
            
            }}
          >
            {weekdays.map((day, i) => (
              <div key={i} style={{ flexGrow: 1, flexBasis: 1, marginLeft: 2 }}>
                <div style={{ textAlign: "center", padding: 2, fontSize: 14 }}>
                  {day[language]}
                </div>
                {status !== "Free time" ?
                  <ClassesOnDay
                    day={i}
                    location={location}
                    search={search}
                    teacherFilter={teacherFilter}
                    {...props}
                  /> : <FreeTime day={i} />}
              </div>
            ))}
          </div>
}