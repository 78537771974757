import React, { useContext } from "react";

import { ClassesContext } from "../../../Context";
import PhoneNumber from "../../content/components/PhoneNumber";
import { statusColors } from "../../../enums";


export default function StudentCard(props) {
    let { student, setTagged, setSearch,selected,tagged,setSelected,index } = props;
    const {className} = useContext(ClassesContext)
    let classes = student.classes.map(cl=> className(cl));
    student.type = "Student"
 

    return (
      <div
        className={selected ? "student-card-selected" : "student-card"}
        onClick={() => {
          setTagged([...tagged, student]);
            setSearch((prev) => "");
            setSelected(prev=>0)
        }}
          /*   style={{ backgroundColor: selected ? "#eee" : "inherit" }} */
            id={student._id}
      >
        <div>
          <div style={{ color: statusColors[student.status] }}>
            {student.name}
          </div>
          <div className="phone-number">
            <PhoneNumber value={student.phone} />
          </div>
        </div>
        <div>{classes.toString()}</div>
      </div>
    );
}