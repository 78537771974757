import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loading from "../../Loading";
import DrawMakeUpClass from "./DrawMakeUpClass";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
export default function MakeUpClasses(props) {
  const { l, language, center, net } = props;
  const [value, setValue] = React.useState(
    parseInt(localStorage.getItem("location")) || 0
  );
  const [students, setStudents] = React.useState([]);
  const [teachers, setTeachers] = React.useState([]);

  React.useEffect(() => {
    net.get("/manage/makeupclasses").then((result) => {
      setTeachers(result.teachers);
      setStudents(result.students);
    });
  }, []);

  const handleChange = (e, newValue) => {
    localStorage.setItem("location", newValue);
    setValue(newValue);
  };

  if (!center) return <Loading />;

  return (
    <div>
      <h1>{l.makeupClasses[language]}</h1>
      <Tabs value={value} onChange={handleChange} aria-label="Timetable tabs">
        {/* <Tab label="All" {...a11yProps(0)} /> */}
        {center.locations.map((l, i) => (
          <Tab label={l.name} key={l._id} {...a11yProps(i)} />
        ))}
      </Tabs>
      {center.locations.map((location, li) => (
        <TabPanel value={value} index={li} key={l._id}>
          <DrawUnscheduled
            {...props}
            location={location}
            students={students}
            scheduled={false}
            teachers={teachers}
            setStudents={setStudents}
          />
          <DrawUnscheduled
            {...props}
            location={location}
            students={students}
            scheduled={true}
            teachers={teachers}
            setStudents={setStudents}
          />
        </TabPanel>
      ))}
    </div>
  );
}

const DrawUnscheduled = (props) => {
  let { language, l, students, location, scheduled } = props;
  let locationStudents = [];
  students.forEach((student) => {
    let classes = [];
    if (!scheduled)
      classes = student.makeUpClasses.filter(
        (cl) => cl.location === location._id && !cl.scheduledDate
      );
    if (scheduled)
      classes = student.makeUpClasses.filter(
        (cl) => cl.location === location._id && cl.scheduledDate
      );

    if (classes.length > 0) {
      classes.forEach((cl) => {
        locationStudents.push({
          ...cl,
          name: student.name,
          phone: student.phone,
          student_id: student._id,
        });
      });
    }
  });
  if (locationStudents.length === 0) return <div></div>;
  return (
    <Accordion {...(!scheduled && { expanded: true })}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {scheduled
            ? l.scheduledClasses[language]
            : l.unscheduledClasses[language]}{" "}
          ({locationStudents.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DrawStudents locationStudents={locationStudents} {...props} />
      </AccordionDetails>
    </Accordion>
  );
};

const DrawStudents = (props) => {
  let { locationStudents } = props;
  locationStudents = locationStudents.sort(
    (a, b) => new Date(a.scheduledDate) - new Date(b.scheduledDate)
  );
  return (
    <div>
      {locationStudents.map((student) => (
        <DrawMakeUpClass
          key={student._id}
          student={student}
          {...props}
          refundButton
          showTeacher
        />
      ))}
    </div>
  );
};
