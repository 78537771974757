
import { format } from "date-fns";
import React from "react";
import Staff from "../Staff";

export default function Chat(props) {
  const { chat, user } = props;

  let flexDirection = "row";
  if (chat.user && chat.user._id === user._id) flexDirection = "row-reverse";

  return (
    <div style={{ display: "flex", flexDirection }}>
      {/*  <Avatar
        size="small"
        src={chat.user && chat.user.image ? `./images/${chat.user.image}` : ""}
      ></Avatar> */}
      <div className="chat-container">
        <div className="chat-header">
          {chat.user && chat.user !== user._id ? <Staff id={chat.user} /> : ""}{" "}
        </div>
        <div className="chat-content">{chat.message}</div>
        <div className="chat-timestamp">
          {" "}
          {format(new Date(chat.createdAt), "h:mm aaa EEEE dd/MM/yyyy")}
        </div>
      </div>
    </div>
  );
}
