import { apiKey } from "./App";

export async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

const api = "/api";

async function post(url, data = {}) {
  const response = await fetch(api + url, {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiKey,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

async function put(url, data = {}) {
  const response = await fetch(api + url, {
    method: "PUT",

    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiKey,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

async function patch(url, data = {}) {
  const response = await fetch(api + url, {
    method: "PATCH",

    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiKey,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

async function del(url) {
  const response = await fetch(api + url, {
    method: "DELETE",

    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiKey,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.json();
}

let waiting = []

async function get(url) {
 
    const response = await fetch(api + url, {
      headers: { authorization: "Bearer " + apiKey },
    });

    if (response.status === 401) {
    
      // window.location.assign("http://localhost:4000/login");
      return;
    }

  if (response.status === 200) {
    /* console.log(response.json()) */
    let json = await response.json()
    
    return json;
  }
  console.log(response.status)
  }
 

/* async function gql(data = {}) {
  const response = await fetch("http://localhost:5000/graphql", {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + apiKey,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
 */
export const net = { post, get, del, patch, put };
