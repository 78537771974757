import React from "react";
import Info from "../../../components/Info";
import { Stack } from "@mui/material";
import Location from "../../../components/Location";
import Staff from "../../../components/Staff";
import VND from "../../../components/VND";
import DrawTimeTable from "./DrawTimeTable";
import ChooseDate from "../../../components/ChooseDate";
import { addDays } from "date-fns";



export default function StepThree(props) {
    const { newClass, setNewClass } = props;
    const enable = []
 let days = newClass.classDays.map((cd) => cd.day);
 let dt = new Date();
 for (let i = 0; i < 120; i++) {
   if (days.includes(dt.getDay())) enable.push(dt);
   dt = addDays(dt, 1);
 }
    return (
      <div>
        <Stack spacing={2}>
          <Info
            title="Name"
            body={newClass.name}
          />
          <Info
            title="Location"
            body={<Location id={newClass.location} />}
          />
          <Info
            title="Teacher"
            body={<Staff id={newClass.teacher} />}
          />
          <Info
            title="Status"
            body={newClass.status}
          />
          <Info
            title="Fee"
            body={<VND value={newClass.fee} />}
          />
          <ChooseDate
            value={newClass.startDate || new Date()}
            setValue={(startDate) =>
              setNewClass((prev) => ({ ...prev, startDate }))
            }
            label="Start Date"
            align="right"
                    size="normal"
                    enable={enable}
          />
          <DrawTimeTableBox newClass={newClass} />
        </Stack>
      </div>
    );
}

function DrawTimeTableBox({ newClass, setNewClass }) {
  if (!newClass.classDays) return <div></div>;
  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: 10,
        marginTop: 20,
      }}
    >
      <div style={{ fontSize: 11 }}>TimeTable</div>
      {newClass.classDays
        .sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0))
        .map((cd, i) => (
          <DrawTimeTable
            classDay={cd}
            setNewClass={setNewClass}
            index={i}
          />
        ))}
    </div>
  );
}