import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { Button, ButtonGroup, Checkbox } from "@mui/material";
import { format } from "date-fns";
import PhoneNumber from "../../components/PhoneNumber";
import mut from "../../hooks/mut";
import Absent from "../absent/Absent";



export default function Bonus(props) {

const [paid,setPaid] = useState("unpaid")
const [bonus, setBonus, error, loading] = useDB(`/marketing-bonus/${paid}`)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(bonus);


return (
  <div>
    <ButtonGroup
      variant="contained"
      aria-label="Basic button group"
    >
      <Button onClick={() => setPaid("unpaid")}>unpaid</Button>
      <Button onClick={() => setPaid("paid")}>paid</Button>
      <Button onClick={() => setPaid("all")}>all</Button>
    </ButtonGroup>

        <h3>Total: {bonus.length}</h3>
     
    <DrawBonus bonus={bonus} />
  </div>
);
}


function DrawBonus({ bonus }) {

    let staffIds = [...new Set(bonus.map((b) => b.marketingPerson._id))];
    
    return <div>{
        staffIds.map(id=> <DrawStaff id={id} bonus={bonus.filter(b=>b.marketingPerson._id===id)} />)
    }</div>
}

function DrawStaff({ id, bonus }) {
    

    const update = async (e,id) => {
      
        let res = await mut("post", `/marketing-bonus-paid/${id}`, { bonusPaid: e.target.checked })
        console.log(res)
    }


    return (
      <div>
        <h3>
          {bonus[0].marketingPerson.name} ({bonus.length})
        </h3>
        <Titles />
        {bonus.map((b) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 100 }}>
              {format(new Date(b.localDate), "dd/MM/yyyy")}
            </div>
            <div style={{ width: 200 }}>{b.location}</div>
            <div style={{ width: 150 }}>
              <PhoneNumber value={b.phone} />
            </div>
            <div style={{ width: 100 }}>
              {format(new Date(b.testDate), "dd/MM/yyyy")}
            </div>
            <div style={{ width: 100 }}>
              <Checkbox
                checked={b.bonusPaid}
                onChange={(e) => update(e, b._id)}
              />
            </div>
          </div>
        ))}
      </div>
    );
}

function Titles() {
    const titles = [{ label: "Date", width: 100 }, { label: "Location", width: 200 }, { label: "Number", width: 150 }, { label: "Date of test", width: 100 }, { label: "Bonus paid", width: 100 }]
    return <div style={{display:"flex"}}>
        {titles.map(t => <div style={{ width: t.width, fontSize:11 }} key={t}>{t.label}</div>)}
    </div>
}