import { Chip, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import "./htComplete.css";

/**
 * 
 * @param {array} tags array of strings, selected tags
 * @param {function} setTags function to update selected tags
 * @param {array} choices array of strings to choose from 
 * @param {function} setChoices update choices
 * @param {string} label
 * @returns 
 */

export default function HtAutoComplete({tags,setTags, choices, setChoices,label, placeholder=""}) {
/*     const [search, setSearch] = useState("");
    const [selected, setSelected] = useState(0); */
    //console.log(choices)
    if(!choices) return <div></div>
  return (
      <div style={{padding:5}}>
          <div style={{fontSize:12, color:"#888"}}>{label}
              
          </div>
          <div style={{height:300,padding:10, marginTop:0}}>           
              {tags.map(t => <Chip key={t} label={t} onDelete={() => { setTags(tags.filter(tag => tag !== t)) }} style={{margin: 5}} />)}
          </div>
          <TextField select fullWidth onChange={(e)=>setTags([...tags,e.target.value])} label={placeholder}>
              {choices.filter(c=>!tags.includes(c)).map(c => <MenuItem value={c} key={c}>{c}</MenuItem>)}
          </TextField>
    </div>
  );
}
