import React, { useContext } from "react";
import { weekdays } from "../../../enums";
import { LanguageContext } from "../../../Context";

/**
 * 
 * @param {number} day The day of the week 
 * @returns React fragment with the day of the week
 */

export default function DayOfWeek({day}) {
  const {language} = useContext(LanguageContext)
  const d = weekdays[day][language];
  return <>{d}</>;
}
