import React, { useContext } from "react";
import {
  Box,
  Stack,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { net } from "../../../../../database";
import { CenterContext } from "../../../../../Context";

export default function SalesInfo(props) {
  let { student } = props;
  const {center} = useContext(CenterContext)
  const [schoolDialog, setSchoolDialog] = React.useState(false);
  const [newLocalSchool, setNewLocalSchool] = React.useState("");
  const [localSchools, setLocalSchools] = React.useState(center.localSchools);
  //const [status, setStatus] = React.useState(props.init.center.status);
  const [hobbies, setHobbies] = React.useState(center.hobbies);
  const [newHobbyDialog, setNewHobbyDialog] = React.useState(false);
  const [newHobby, setNewHobby] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [hobbyError, setHobbyError] = React.useState(false);
  const [hobbyErrorMessage, setHobbyErrorMessage] = React.useState("");
  let errors = [];

  const closeSchoolDialog = () => {
    setNewLocalSchool("");
    setSchoolDialog(false);
  };

  const addNewLocalSchool = () => {
    net
      .post("/center/localschools/add", { newSchool: newLocalSchool })
      .then((r) => {
        if (r.modifiedCount === 1) {
          setLocalSchools([...localSchools, newLocalSchool]);
        }
        setNewLocalSchool("");
        setSchoolDialog(false);
      });
  };

  const closeHobbyDialog = () => {
    setNewHobby("");
    setNewHobbyDialog(false);
  };

  const addNewHobby = () => {
    net.post("/center/hobbies/add", { newHobby }).then((r) => {
      if (!r.err) {
        setHobbies([...hobbies, newHobby]);
        setNewHobby("");
        setNewHobbyDialog(false);
        setHobbyError(false);
        setHobbyErrorMessage("");
      } else {
        setHobbyError(true);
        setHobbyErrorMessage(r.err);
      }
    });
  };

  /*  const toggleEdit = () => {
    if (edit) {
      setEdit(false);
      props.updateStudent();
    } else {
      setEdit(true);
    }
  }; */

  const updateField = (e, field) => {
    let update = {
      [field]: e.target.value,
    };
    net
      .post(`/student/${student._id}/fieldupdate`, update)
      .then((r) => console.log(r));
  };

  const updateHobbies = (e, hobbies) => {
    let update = {
      hobbies: student.hobbies,
    };
    net
      .post(`/student/${student._id}/fieldupdate`, update)
      .then((r) => console.log(r));
  };

  console.log(student);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        {/*  <div>
          <IconButton
            onClick={toggleEdit}
            {...(edit === true && { color: "success" })}
          >
            {edit ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </div> */}
      </div>
      {/*  <Box border={1} borderRadius={5} padding={1} marginTop={1}> */}
      <div style={{ fontSize: 11 }}>Basic information</div>
      <Stack spacing={2}>
        <div style={{ display: "flex" }}>
          <TextField
            variant="standard"
            value={student.school}
            label="School"
            onChange={(e) =>
              props.setStudent({ ...student, school: e.target.value })
            }
            {...(errors.includes("name") && {
              error: true,
              helperText: "Please choose the student's school",
            })}
            onBlur={(e) => updateField(e, "school")}
            select
            fullWidth
            /*  {...(edit === false && {
                InputProps: {
                  readOnly: true,
                },
              })} */
          >
            {localSchools.map((school) => (
              <MenuItem key={"ls" + school} value={school}>
                {school}
              </MenuItem>
            ))}
          </TextField>
          <IconButton
            size="small"
            sx={{ width: 50 }}
            onClick={() => setSchoolDialog(true)}
          >
            <AddIcon />
          </IconButton>
          <Dialog open={schoolDialog} onClose={closeSchoolDialog}>
            <DialogTitle>Add new local school</DialogTitle>
            <DialogContent>
              <TextField
                variant="standard"
                value={newLocalSchool}
                onChange={(e) => setNewLocalSchool(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeSchoolDialog}>Cancel</Button>
              <Button onClick={addNewLocalSchool}>Add</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Stack>
      {/*  </Box> */}
      {/*  <Box border={1} borderRadius={5} padding={1} marginTop={1}> */}
      <br></br>
      <div style={{ fontSize: 11 }}>Learning</div>
      <Stack spacing={2}>
        <TextField
          variant="standard"
          value={student.selfStudy}
          label="Can self study at home?"
          onChange={(e) =>
            props.setStudent({ ...student, selfStudy: e.target.value })
          }
          onBlur={(e) => updateField(e, "selfStudy")}
          {...(errors.includes("selfStudy") && {
            error: true,
            helperText: "Please choose if the student can self study at home",
          })}
          select
          fullWidth
          /*  {...(edit === false && {
              InputProps: {
                readOnly: true,
              },
            })} */
        >
          {["No", "Yes"].map((selfStudy, i) => (
            <MenuItem key={"ls" + selfStudy} value={i}>
              {selfStudy}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="standard"
          value={student.desiredTimetable}
          label="Desired timetable of the student"
          onChange={(e) =>
            props.setStudent({
              ...student,
              desiredTimetable: e.target.value,
            })
          }
          onBlur={(e) => updateField(e, "desiredTimetable")}
          {...(errors.includes("desiredTimetable") && {
            error: true,
            helperText: "Please enter the desired timetable of the student",
          })}
          /*   {...(edit === false && {
              InputProps: {
                readOnly: true,
              },
            })} */
        />
        <TextField
          variant="standard"
          value={student.learningHistory}
          label="Learning history of the student"
          onChange={(e) =>
            props.setStudent({
              ...student,
              learningHistory: e.target.value,
            })
          }
          onBlur={(e) => updateField(e, "learningHistory")}
          {...(errors.includes("learningHistory") && {
            error: true,
            helperText: "Please enter the learning history of the student",
          })}
          /*  {...(edit === false && {
              InputProps: {
                readOnly: true,
              },
            })} */
        />
        <TextField
          variant="standard"
          value={student.strengthsAndWeaknesses}
          label="Strengths and weaknesses"
          onChange={(e) =>
            props.setStudent({
              ...student,
              strengthsAndWeaknesses: e.target.value,
            })
          }
          onBlur={(e) => updateField(e, "strengthsAndWeaknesses")}
          {...(errors.includes("strengthsAndWeaknesses") && {
            error: true,
            helperText:
              "Please enter the strengths and weaknesses of the student",
          })}
          /*  {...(edit === false && {
              InputProps: {
                readOnly: true,
              },
            })} */
        />
        <TextField
          variant="standard"
          value={student.otherClasses}
          label="Other classes that they study and timetables"
          onChange={(e) =>
            props.setStudent({
              ...student,
              otherClasses: e.target.value,
            })
          }
          onBlur={(e) => updateField(e, "otherClasses")}
          {...(errors.includes("otherClasses") && {
            error: true,
            helperText: "Please enter the other classes of the student",
          })}
          /*  {...(edit === false && {
              InputProps: {
                readOnly: true,
              },
            })} */
        />
      </Stack>
      {/*  </Box> */}
      {/*   <Box border={1} borderRadius={5} padding={1} marginTop={1}> */}
      <br></br>
      <div style={{ fontSize: 11 }}>Other information</div>
      <div style={{ display: "flex" }}>
        <Autocomplete
          multiple
          fullWidth
          options={hobbies}
          onChange={(e, value) =>
            props.setStudent({ ...student, hobbies: value })
          }
          onBlur={(e, value) => updateHobbies(e, value)}
          value={student.hobbies}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Hobbies"
              placeholder="Hobbies"
            />
          )}
        />
        <IconButton onClick={() => setNewHobbyDialog(true)}>
          <AddIcon />
        </IconButton>
        <Dialog open={newHobbyDialog} onClose={closeHobbyDialog}>
          <DialogTitle>Add new hobby</DialogTitle>
          <DialogContent>
            <TextField
              variant="standard"
              value={newHobby}
              onChange={(e) => setNewHobby(e.target.value)}
              {...(hobbyError && {
                error: true,
                helperText: hobbyErrorMessage,
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeHobbyDialog}>Cancel</Button>
            <Button onClick={addNewHobby}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* </Box> */}
    </div>
  );
}
