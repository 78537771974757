import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import CommentIcon from "@mui/icons-material/Comment";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CallIcon from "@mui/icons-material/Call";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

/**
 * Returns a Material UI icon based on the name given
 * @param {{name:string}} props
 * @returns {React.ReactElement}
 */
export default function Icon({name}) {

    if(name == "home") return(<HomeIcon/>)
    if(name == "timetable") return(<AccessTimeIcon/>)
    if(name == "attendance") return(<AssignmentIcon/>)
    if(name == "money") return(<AttachMoneyIcon/>)
    if(name == "staff") return(<PersonIcon/>)
    if(name == "settings") return(<SettingsIcon/>)
    if(name == "feedback") return(<FeedbackIcon/>)
    if(name == "comments") return(<CommentIcon/>)
    if(name == "bike") return(<TwoWheelerIcon/>)
    if(name == "call") return (<CallIcon />) 
    if(name == "student") return (<SchoolIcon />)

return(<div>Icon</div>)
}