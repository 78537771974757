import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";

import { net } from "../../../../database";
import ChooseDateForClass from "../../components/ChooseDateForClass";
import Info from "../../components/Info";
import StaffChooserContext from "../../components/StaffChooserContext";

export default function ChooseClassDialog(props) {
  const { open, setOpen, lead, setLeads } = props;
  const [htClass, setHtClass] = React.useState("");
  const [firstClassDate, setFirstClassDate] = React.useState("");
  const [htClasses, setHtClasses] = React.useState([]);
  const [consultant,setConsultant] = React.useState("")

  React.useEffect(() => {
    net.get("/classes").then((res) => {
      for (let r of res) {
        r.label = r.name;
        r.id = r._id;
      }
      setHtClasses(
        res.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      );
    });
  }, [lead]);

  if (!lead) return <div></div>;

  const chooseClass = () => {
    if (htClass === "" || firstClassDate === "" || consultant==="") return;
    net
      .post(`/leads/${lead._id}`, {
        stage: 3,
        classes: [htClass],
        firstClassDate,
        readyForClass:1,consultant
      })
      .then((res) => {
        setHtClass((prev) => "");
        setFirstClassDate((prev) => "");
        setOpen(false);
        setLeads((prev) => prev.filter((l) => l._id !== lead._id));
      });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add {lead.name} to a class</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Autocomplete
            options={htClasses}
            renderInput={(params) => (
              <TextField {...params} label="Class to add student to" />
            )}
            value={htClass}
            onChange={(e, newValue) => {
              setFirstClassDate((prev) => "");
              setHtClass((prev) => newValue);
            }}
            sx={{ mt: 1 }}
          />

          {htClass ? (
            <Info
              title="First class they will attend"
              body={
                <ChooseDateForClass
                  htClass={htClass}
                  value={firstClassDate}
                  setValue={setFirstClassDate}
                  style={{ width: "100%" }}
                />
              }
            />
          ) : null}

          <StaffChooserContext value={consultant} setValue={setConsultant} label="Consultant" filter="Sales" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={() => chooseClass()}>
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
