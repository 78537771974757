import React from "react";

import Loading from "../../Loading";
import DrawStudent from "./DrawStudent";
import Title from "./Title";
import Month from "../../components/Month";
import useDB from "../../hooks/useDB";


export default function PendingToActive(props) {
    const {year,month} = props
    const [students, setStudents, error, loading] = useDB(
      `/pending-to-active/${year}/${month}`
    );

    if (loading) return <Loading />

return(<div><h3>Students who changed from pending to active in <Month value={month+1} /> ({students.length})</h3><Title />{students.map(s=> <DrawStudent student={s} />)}</div>)
}