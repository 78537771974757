import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export default function RevenueToday(props) {
  //const {l,language} = props;
  const [revenue, setRevenue] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/revenue-today").then((res) => setRevenue(res));
  }, []);
  if (!revenue) return <Loading />;
  console.log(revenue)
  let students = [];
  for (let r of revenue.revenue) {
    let student = students.find((s) => s._id === r.student._id);
    if (student) {
      student.bills.push(r);
    } else {
      students.push({ ...r.student, bills: [r], deleted: [] });
    }
  }
  for (let d of revenue.deleted) {
    let student = students.find((s) => s._id === d.student._id);
    if (student) {
      student.deleted.push(d);
    } else {
      students.push({ ...d.student, deleted: [d], bills: [] });
    }
  }
  console.log(students);
  return (
    <div>
      {students.map((student, i) => (
        <div>
          <h3>
            <Link to={"/student/" + student._id} className="neat">
              {student.name}
            </Link>
          </h3>
          {student.bills.length > 0 ? (
            <div>
              <div>
                {student.bills.map((bill) => (
                  <DrawBill bill={bill} key={bill._id} />
                ))}
              </div>
              <h4 className="dflex-sb">
                <div>Total:</div>
                <NumberFormat
                  value={student.bills.reduce((a, b) => a + b.amount, 0)}
                  thousandSeparator
                  displayType="text"
                />
              </h4>
            </div>
          ) : null}
          {student.deleted.length > 0 ? (
            <div>
              <div>
                {student.deleted.map((bill) => (
                  <DrawBill bill={bill} key={bill._id} deleted />
                ))}
              </div>
              <h4 className="dflex-sb">
                <div>Total:</div>
                <NumberFormat
                  value={student.deleted.reduce((a, b) => a + b.amount, 0)}
                  thousandSeparator
                  displayType="text"
                />
              </h4>
            </div>
          ) : null}
          {i < students.length - 1 ? <hr /> : null}
        </div>
      ))}
    </div>
  );
}

function DrawBill({ bill, deleted }) {
  return (
    <div>
      <div style={{ fontSize: 12, color: deleted ? "red" : "green"}}>
        {deleted
          ? "Deleted by " +
            !bill.billType==="ClassRefund" ? bill.deletedBy.name : "Automatic" +
            " at " +
            format(new Date(bill.deletedAt), "h:mm:ss aaa")
          : "Added by " +
           !bill.billType==="ClassRefund" ? bill.createdBy.name : "Automatic" +
            " at " +
            format(new Date(bill.createdAt), "h:mm:ss aaa")}
      </div>
      <div className="dflex-sb">
        <div>{bill.description}</div>
        <NumberFormat
          value={bill.amount}
          thousandSeparator
          displayType="text"
        />
      </div>
    </div>
  );
}
