import React, { useContext } from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { format } from "date-fns";
import TeacherChoice from "../../components/TeacherChoice";
import "./makeupclasses.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SelectFromList from "../../components/SelectFromList";
import { CenterContext } from "../../../../Context";

export default function ScheduledClasses(props) {
 
  const {center} = useContext(CenterContext)
  const [classes, setClasses] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/makeup-classes").then((res) => setClasses(res));
  }, []);

  if (!classes) return <Loading />;

  return (
    <div>
      <h3>Scheduled classes</h3>
      {classes.map((c) => (
        <DrawClass
          key={c._id}
          muClass={c}
          center={center}
          setClasses={setClasses}
          classes={classes}
        />
      ))}
    </div>
  );
}

function DrawClass(props) {
  let { muClass, center, setClasses, classes } = props;
  let location = center.locations.find((l) => l._id === muClass.location);
  let navigate = useNavigate();

  const updateTeacher = (e) => {
    net
      .post(`/update-makeup-class/${muClass._id}`, { teacher: e.target.value })
      .then((res) => {
        muClass.teacher = res.teacher;
        setClasses(classes.map((c) => (c._id === muClass._id ? muClass : c)));
      });
  };

  const updateRoom = (room) => {
    net
      .post(`/update-makeup-class/${muClass._id}`, {room})
      .then((res) => {
        muClass.room = res.room;
        setClasses(classes.map((c) => (c._id === muClass._id ? muClass : c)));
      });
  };

  return (
    <div
      className="class-schedule"
      style={{
        background: muClass.open
          ? "white"
          : "linear-gradient(to right, #ddd6f3, #faaca8)",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: 200 }}>
          {format(new Date(muClass.date), "EEEE dd/MM/yyyy")}
          <div>{muClass.level}</div>
        </div>

        <div style={{ width: 120 }}>
          {muClass.startTime} - {muClass.endTime}
        </div>
        <div style={{ width: 120 }}>{location.name}</div>

        <TeacherChoice
          style={{ width: 180 }}
          size="small"
          value={muClass.teacher ? muClass.teacher : ""}
          {...(!muClass.teacher && {
            error: true,
            label: "No teacher",
          })}
          onChange={updateTeacher}
        />
        <SelectFromList choices={location.rooms} value={muClass.room || ""} setValue={(room)=>updateRoom(room)} label="Room" style={{width:100,marginLeft: 5}} size="small" />
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 1 }}
          style={{ height: 40 }}
          onClick={() =>
            navigate(`/manage/makeupclasses/detail/${muClass._id}`)
          }
        >
          View class
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 1 }}
          style={{ height: 40 }}
          color={muClass.open ? "error" : "success"}
          onClick={() => {
            net
              .post(`/makeupclasses/openclose/${muClass._id}`, {
                open: !muClass.open,
              })
              .then((res) =>
                setClasses((prev) =>
                  prev.map((p) => (p._id === res._id ? res : p))
                )
              );
          }}
        >
          {muClass.open ? "Close" : "Open"}
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <div style={{ fontSize: 11 }}>Students</div>
          <div style={{ fontSize: 24, textAlign: "center" }}>
            {muClass.students.length}
          </div>
          <div> {muClass.open ? "" : "Closed"}</div>
        </div>
      </div>
    </div>
  );
}
