import {Button,Dialog,DialogActions,DialogContent,DialogTitle, TextField} from "@mui/material";
import React, { useContext } from "react";
import ChooseDate from "../../components/ChooseDate";
import TimeChooser from "../../components/TimeChooser";
import RoomChoice from "../../components/RoomChoice";
import TeacherChoice from "../../components/TeacherChoice";
import { net } from "../../../../database";
import { addHours } from "date-fns";
import StudentsChooser from "../../components/StudentsChooser";
import LocationChoiceChip from "../../components/LocationChoiceChip";
import { CenterContext } from "../../../../Context";

export default function NewExtraClassDialog(props) {
  const { open, setOpen, setClasses, edit, setEdit } = props;
  const { center } = useContext(CenterContext);
  let { locations } = center;
  const [newClass,setNewClass] = React.useState({date: new Date(),students:[],studentNotes:[],location: locations[0]._id})
  React.useEffect(() => {
    if (edit) setNewClass({ ...edit, date: new Date(edit.date) })
  },[open]);
    const addClass = () => {
      let date = addHours(newClass.date, 7)
      if (edit) {
        net.post("/extra-classes/"+edit._id, { ...newClass, date: date.toISOString().substring(0, 10)}).then(res => { setOpen(false); setNewClass({date: new Date(),students:[],studentNotes:[],location: locations[0]._id});setClasses((prev)=>[...prev,res]);setEdit(prev=>undefined) })
        
      }
      else {
         net.put("/extra-classes", { ...newClass, date: date.toISOString().substring(0, 10)}).then(res => { setOpen(false); setNewClass({date: new Date(),students:[],studentNotes:[],location: locations[0]._id});setClasses((prev)=>[...prev,res]) })
      }
       
    }
    
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add new extra class</DialogTitle>
          <DialogContent style={{display:"flex", flexDirection:"column",gap:10,paddingTop:5}}>
              <ChooseDate value={newClass.date} setValue={(date) => setNewClass((prev) => ({ ...prev, date }))} label="Date" noPast />
              <TimeChooser value={newClass.startTime} setValue={(startTime) => setNewClass((prev) => ({ ...prev, startTime }))} times={["17:15","17:45","18:00","19:30"]} label="Start time" />
              <TimeChooser value={newClass.endTime} setValue={(endTime) => setNewClass((prev) => ({ ...prev, endTime }))} times={["18:45","19:15","19:30","21:00"]} label="Finishing time"/>
              <LocationChoiceChip value={newClass.location} setValue={(location) => setNewClass((prev) => ({ ...prev, location }))} fullWidth/>
              <RoomChoice location={newClass.location || ""} value={newClass.room || ""} setValue={(room) => setNewClass((prev) => ({ ...prev, room }))} fullWidth />
        <TeacherChoice value={newClass.teacher} onChange={(teacher) => setNewClass((prev) => ({ ...prev, teacher: teacher.target.value }))} fullWidth />
        <StudentsChooser value={newClass.students} setValue={(students)=>setNewClass(prev=>({...prev, students}))} />
      <TextField value={newClass.notes || ""} onChange={(e) => setNewClass((prev) => ({ ...prev, notes: e.target.value }))} multiline rows={3} label="Notes" fullWidth/>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { setOpen(false); setNewClass({ date: new Date(), students: [], studentNotes: [], location: locations[0]._id });setEdit(prev=>undefined) }}>Close</Button>
        <Button color="success" {...((!newClass.startTime || !newClass.endTime || !newClass.location) && {
        disabled : true 
        })} onClick={()=>addClass()}>{edit ? "Update" : "Add class"}</Button>
      </DialogActions>
    </Dialog>
  );
}