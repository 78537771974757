import React from "react";
import StudentLink from "../../components/StudentLink";
import HtClass from "../../components/HtClass";
import HtDate from "../../components/HtDate";
import Notes from "../../components/Notes";
import { Button } from "@mui/material";
import MakeUpChoiceDialog from "./MakeUpChoiceDialog";
import Info from "../../components/Info";
import { FixedSizeList as List } from 'react-window';
import { useWindowSize } from "@react-hook/window-size/throttled";
import FormatDate from "../../components/FormatDate";
import Location from "../../components/Location";
import Staff from "../../components/Staff";



export default function NoClass(props) {
  const { attendance, setAttendance, timetable } = props;
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState(undefined)
  const height = useWindowSize({ fps: 60 })[1];
  
  
const Row = ({ index, style }) => (
  <div style={style}>
    <DrawAttendance
      key={attendance[index]._id}
      attendance={attendance[index]}
      setAttendance={setAttendance}
      setOpen={setOpen}
      setSelected={setSelected}
    />
  </div>
);

  return (<div>
 
    <List height={height-210}
    itemCount={attendance.length}
      itemSize={200}
      style={{flexGrow: 1}}
     >
      {Row}
    </List>
    <MakeUpChoiceDialog open={open} setOpen={setOpen} selected={selected} timetable={timetable} />
  </div>)
}




function DrawAttendance(props) {
  let { attendance, setAttendance, setOpen, setSelected } = props;
    return (
      <div
        style={{
          display: "flex",
          marginBottom: 10,
          alignItems: "center",
          border: "1px solid #ccc",
          padding: 5,
        }}
      >
        <Basic {...props} />
        <Notes
          notes={attendance.absentNotes}
          route={`/absent2/${attendance._id}/notes`}
          success={(res) =>
            setAttendance((prev) =>
              prev.map((p) => (p._id === res._id ? res : p))
            )
          }
          style={{ minHeight: 185, maxHeight: 185 }}
        />
        <div style={{ marginLeft: 20 }}>
          {attendance.makeUpClass ? (
            <MakeUpClassDetails
              attendance={attendance}
              onClick={() => {
                setSelected((prev) => attendance);
                setOpen(true);
              }}
            />
          ) : 
             
            <AddClass attendance={attendance} setSelected={setSelected} setOpen={setOpen} />
          }
        </div>
        </div>)
}




function Basic(props) {
  let { attendance } = props;
    return (
      <div style={{ width: 300 }}>
        <Info
          title="Student"
          body={<StudentLink student={attendance.student} showClasses />}
        />
        <Info
          title="Missed class"
          body={<HtClass htClass={attendance.htClass} />}
        />
        <Info title="Date" body={<HtDate value={attendance.createdAt} />} />
        <Info title="Reason" body={attendance.absentReason} />
      </div>
    );
}


function AddClass(props) {
  const {attendance,setSelected,setOpen} = props

  return (
    <div style={{ width: 300, display: "flex" }}>
      <div style={{ width: 150, display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setSelected((prev) => attendance);
            setOpen(true);
          }}
        >
          Add class
        </Button>
      </div>
      <div>{attendance.missedMakeupClasses && attendance.missedMakeupClasses.length>0 ? <MMUClass missedMakeupClasses={attendance.missedMakeupClasses} /> : null}</div>
    </div>
  );
    
       
}

function MMUClass(props) {
  return <div>Missed</div>
}


function MakeUpClassDetails(props) {
  let { makeUpClass } = props.attendance
  let {onClick} = props
 
   if (!makeUpClass) return <div>Unknown class</div>;
  return (
    <div onClick={onClick} style={{width: 300, border:"1px solid #ccc",borderRadius: 5, padding: 10}} className="mu-class-details">
      <Info
        title="Date"
        body={<FormatDate value={makeUpClass.date} />}
      />
      <Info
        title="Time"
        body={`${makeUpClass.startTime} - ${makeUpClass.endTime}`}
      />
      <Info
        title="Location"
        body={<Location id={makeUpClass.location} />}
      />
      {makeUpClass.teacher ? (
        <Info
          title="Teacher"
          body={<Staff id={makeUpClass.teacher} />}
        />
      ) : null}
    </div>
  );
}