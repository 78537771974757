import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Students from "../students/Students";
import SLink from "../../components/SLink";


export default function ManagePickup(props) {


const [students, setStudents, error, loading] = useDB(`/students-pickup`)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(students);


    return (<div>
        <h3>Students with pickup service</h3>
        {students.map(s => <SLink id={s._id} showClasses showPhone status />)}</div>)
}