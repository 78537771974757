import React from "react";
import {
  TextField,
  Stack,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { net } from "../../../../database";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../Loading";

export default function NewStudent(props) {
  let { customerId } = useParams();
  const { l, language } = props;
  let navigate = useNavigate();
  const [newStudent, setNewStudent] = React.useState({
    name: "",
    status: "New",
    dateOfBirth: "",
    selfStudy: "No",
    strengthsAndWeaknesses: "",
    otherClasses: "",
    school: "",
    learningHistory: "",
    desiredTimetable: "",
    hobbies: [],
    customer: customerId, facebook:""
  });
  const [errors, setErrors] = React.useState([]);

  const [localSchools, setLocalSchools] = React.useState(undefined);
  const [status, setStatus] = React.useState(undefined);
  const [hobbies, setHobbies] = React.useState(undefined);
  const [newHobbyDialog, setNewHobbyDialog] = React.useState(false);
  const [newHobby, setNewHobby] = React.useState("");

  React.useEffect(() => {
    net.get("/center").then((r) => {
      setLocalSchools(r.localSchools);
      setStatus(r.status);
      setHobbies(r.hobbies);
    });
  }, []);

  /* const closeSchoolDialog = () => {
    setNewLocalSchool("");
    setSchoolDialog(false);
  }; */

  /* const addNewLocalSchool = () => {
    net
      .post("/center/localschools/add", { newSchool: newLocalSchool })
      .then((r) => {
        if (r.modifiedCount === 1) {
          setLocalSchools([...localSchools, newLocalSchool]);
        }
        setNewLocalSchool("");
        setSchoolDialog(false);
      });
  }; */

  const closeHobbyDialog = () => {
    setNewHobby("");
    setNewHobbyDialog(false);
  };

  const addNewHobby = () => {
    net.post("/center/hobbies/add", { newHobby }).then((r) => {
      if (r.modifiedCount === 1) {
        setHobbies([...hobbies, newHobby]);
      }
      setNewHobby("");
      setNewHobbyDialog(false);
    });
  };

  const addNewStudent = () => {
    console.log(newStudent);
    let newErrors = [];
    if (newStudent.name === "") {
      newErrors.push("name");
    }

    if (newStudent.dateOfBirth === "") {
      newErrors.push("dateOfBirth");
    }

    if (newStudent.strengthsAndWeaknesses === "") {
      newErrors.push("strengthsAndWeaknesses");
    }

    if (newStudent.otherClasses === "") {
      newErrors.push("otherClasses");
    }

    if (newStudent.school === "") {
      newErrors.push("school");
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    net.post("/student", newStudent).then((r) => {
      console.log(r);
      if (!r.err) {
        navigate("/student/" + r._id);
      } else {
        alert(r.err);
      }
    });
  };

  if (!hobbies) return <Loading />;

  return (
    <div>
      <h3>{l.addANewStudent[language]}</h3>
      <div style={{ fontSize: 11 }}>{l.basicInformation[language]}</div>
      <Stack
        spacing={2}
        sx={{ mt: 1, mb: 1 }}
      >
        <TextField
          value={newStudent.name}
          label={l.name[language]}
          onChange={(e) =>
            setNewStudent({ ...newStudent, name: e.target.value })
          }
          {...(errors.includes("name") && {
            error: true,
            helperText: l.pleaseTypeInTheStudentsName[language],
          })}
        />
        <div style={{ display: "flex" }}>
          <TextField
            style={{ marginRight: 10 }}
            value={newStudent.dateOfBirth}
            type="Date"
            label={l.dateOfBirth[language]}
            fullWidth
            onChange={(e) =>
              setNewStudent({
                ...newStudent,
                dateOfBirth: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
            {...(errors.includes("dateOfBirth") && {
              error: true,
              helperText: l.pleaseTypeInTheStudentsDateOfBirth[language],
            })}
          />
          <TextField
            value={newStudent.status}
            label={l.status[language]}
            onChange={(e) =>
              setNewStudent({ ...newStudent, status: e.target.value })
            }
            select
            fullWidth
          >
            {status.map((status) => (
              <MenuItem
                key={"st" + status}
                value={status}
              >
                {status}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ display: "flex" }}>
          <Autocomplete
            value={newStudent.school}
            freeSolo
            options={localSchools}
            onChange={(e, newValue) => {
              setNewStudent({ ...newStudent, school: newValue });
            }}
            inputValue={newStudent.school}
            onInputChange={(event, newInputValue) => {
              setNewStudent({ ...newStudent, school: newInputValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={l.studentsSchool[language]}
              />
            )}
            /*   {...(errors.includes("school") && {
              error: true,
               helperText: l.pleaseChooseTheStudentsSchool[language],
            })} */
            fullWidth
          ></Autocomplete>

          {/*    <Dialog open={schoolDialog} onClose={closeSchoolDialog}>
            <DialogTitle>Add new local school</DialogTitle>
            <DialogContent>
              <TextField
                value={newLocalSchool}
                onChange={(e) => setNewLocalSchool(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeSchoolDialog}>Cancel</Button>
              <Button onClick={addNewLocalSchool}>Add</Button>
            </DialogActions>
          </Dialog> */}
        </div>
        <TextField
          label="Facebook"
          value={newStudent.facebook}
          onChange={(e) =>
            setNewStudent((prev) => ({ ...prev, facebook: e.target.value }))
          }
        />
      </Stack>
      <div style={{ fontSize: 11 }}>{l.learning[language]}</div>
      <Stack
        spacing={2}
        sx={{ mt: 1, mb: 1 }}
      >
        <TextField
          value={newStudent.selfStudy}
          label={l.canSelfStudyAtHome[language]}
          onChange={(e) =>
            setNewStudent({ ...newStudent, selfStudy: e.target.value })
          }
          select
          fullWidth
        >
          {["No", "Yes"].map((selfStudy, i) => (
            <MenuItem
              key={"ls" + selfStudy}
              value={selfStudy}
            >
              {l[selfStudy.toLowerCase()][language]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          value={newStudent.desiredTimetable}
          label={l.desiredTimetableOfTheStudent[language]}
          onChange={(e) =>
            setNewStudent({
              ...newStudent,
              desiredTimetable: e.target.value,
            })
          }
          {...(errors.includes("desiredTimetable") && {
            error: true,
            helperText: l.pleaseEnterTheDesiredTimetableOfTheStudent[language],
          })}
        />
        <TextField
          value={newStudent.learningHistory}
          label={l.learningHistoryOfTheStudent[language]}
          onChange={(e) =>
            setNewStudent({
              ...newStudent,
              learningHistory: e.target.value,
            })
          }
          {...(errors.includes("learningHistory") && {
            error: true,
            helperText: l.pleaseTypeInTheLearningHistoryOfTheStudent[language],
          })}
        />
        <TextField
          value={newStudent.strengthsAndWeaknesses}
          label={l.studentsStrengthsAndWeaknesses[language]}
          onChange={(e) =>
            setNewStudent({
              ...newStudent,
              strengthsAndWeaknesses: e.target.value,
            })
          }
          {...(errors.includes("strengthsAndWeaknesses") && {
            error: true,
            helperText:
              l.pleaseTypeInTheStudentsStrengthsAndWeaknesses[language],
          })}
        />
        <TextField
          value={newStudent.otherClasses}
          label={
            l.otherClassesThatTheStudentStudiesAndTimetablesForThoseClasses[
              language
            ]
          }
          onChange={(e) =>
            setNewStudent({
              ...newStudent,
              otherClasses: e.target.value,
            })
          }
          {...(errors.includes("otherClasses") && {
            error: true,
            helperText:
              l
                .pleaseTypeInTheOtherClassesThatTheStudentStudiesAndTimetablesIfKnown[
                language
              ],
          })}
        />
      </Stack>
      <div style={{ fontSize: 11 }}>{l.otherInformation[language]}</div>
      <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
        <Autocomplete
          multiple
          fullWidth
          options={hobbies}
          onChange={(e, value) =>
            setNewStudent({ ...newStudent, hobbies: value })
          }
          value={newStudent.hobbies}
          renderInput={(params) => (
            <TextField
              {...params}
              label={l.hobbies[language]}
              placeholder={l.hobbies[language]}
            />
          )}
        />
        <div style={{ width: 50, padding: 10 }}>
          <IconButton onClick={() => setNewHobbyDialog(true)}>
            <AddIcon color="success" />
          </IconButton>
        </div>

        <Dialog
          open={newHobbyDialog}
          onClose={closeHobbyDialog}
        >
          <DialogTitle>{l.addNewHobby[language]}</DialogTitle>
          <DialogContent>
            <TextField
              value={newHobby}
              onChange={(e) => setNewHobby(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={closeHobbyDialog}
            >
              {l.cancel[language]}
            </Button>
            <Button
              color="success"
              onClick={addNewHobby}
            >
              {l.add[language]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={addNewStudent}>{l.addStudent[language]}</Button>
      </div>
    </div>
  );
}
