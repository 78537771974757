import React, { useContext } from "react";
import { useTheme } from "@mui/styles";
import "./classview.css";
import { Box, LinearProgress } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { StaffContext } from "../../../../../Context";
import { ht } from "date-fns/locale";
//
export default function ClassView(props) {
  let { htClass, l, language } = props;
  const theme = useTheme();
  //let teacher = htClass.teacher;
  //if (htClass.lesson) teacher = htClass.lesson.teacher;
  let room = htClass.makeUpClass || htClass.extraClass ? htClass.room : htClass.classDay.room;
  if (htClass.lesson) room = htClass.lesson.room;
  const { findStaff } = useContext(StaffContext)
  
  const teacher = htClass.lesson ? findStaff(htClass.lesson.teacher) : findStaff(htClass.teacher)

  let attendanceColor = checkAttendance(htClass);
  let name = ""
  let className = ""
  if (htClass.makeUpClass) { name = "Make up"; className="makeup-frame" }
  if (htClass.extraClass) { name = "Extra"; className="extra-frame" }
 
  if (!htClass.makeUpClass && !htClass.extraClass) {
      name = htClass.name
      if (htClass.coming) {
        className="coming-frame"
      } else {
        className = "attendance-frame"
      }
    
  }
  let progress = 0
  if (htClass.lesson) {
    const {lesson} = htClass
    progress = (100 / htClass.students.length) * (lesson.attended.length+lesson.late.length+lesson.absent.length+lesson.noShow.length)
  }

  return (
    <div
      onClick={() =>
        htClass.makeUpClass
          ? props.showMakeUp(htClass._id)
          : props.showAttendance(props.htClass._id)
      }
      className={className}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 0,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: 300,
            fontFamily: "Inter",
            fontStyle: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            paddingLeft: 10,
            paddingTop: 5,
            width: "50%",
            color: "white",
          }}
        >
          {name}
          {teacher ? (
            <div style={{ fontSize: 12, color: "#CCC" }}>{teacher.name}</div>
          ) : (
            <div
              style={{
                color: theme.palette.error.light,
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              No teacher
            </div>
          )}
        </div>

        <div
          style={{
            fontSize: 12,
            fontWeight: 300,
            fontFamily: "Inter",
            textAlign: "right",
            color: "#dddddd",
            fontStyle: "normal",
            paddingTop: 5,
            paddingRight: 10,
          }}
        >
          <div>{htClass.makeUpClass ? `R ${room}` : `R ${room}`}</div>
        </div>
      </div>
      <LinearProgress
        value={progress}
        variant="determinate"
        color="success"
        sx={{ ml: 1, mr: 1,mt:.5,mb:.5 }}
        {...(progress<100 && {
        color: "error" 
        })}
      />
      <div className="attendance-students-frame">
        <div
          style={{
            color: "#FFFFFF",
            backdropFilter: "blur(4px)",
          }}
        >
          {l.students[language]}:{" "}
          {htClass.makeUpClass || htClass.extraClass
            ? htClass.students.length
            : htClass.students.filter(
                (s) => !s.doesntStudy.includes(htClass.classDay._id)
              ).length}
          <div style={{ marginTop: 7 }}>
          {/*   <AssignmentIcon
              color={attendanceColor}
              sx={{ mr: 1, fontSize: 14 }}
            /> */}
            {/*  <ChatIcon color={feedbackColor} sx={{ fontSize: 14 }} /> */}
          </div>
        </div>
        <div>
          {htClass.makeUpClass ? (
            <DrawAttendanceMakeUpClass htClass={htClass} />
          ) : (
            <DrawAttendanceHtClass htClass={htClass} />
          )}
        </div>
      </div>
    </div>
  );
}

function DrawAttendanceMakeUpClass(props) {
  let { htClass } = props;

  let attended = 0;
  let late = 0;
  let absent = 0;
  let noShow = 0;

  for (let student of htClass.students) {
    if (student.attendance === 1) attended++;
    if (student.attendance === -1) late++;
    if (student.attendance === 2) absent++;
    if (student.attendance === 3) noShow++;
  }

  return (
    <div>
      {" "}
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "success.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Attended:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 35,
            textAlign: "right",
            width: 20,
          }}
        >
          {attended}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "yellow" }}
      >
        <div style={{ position: "relative", left: 40 }}>Late:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 61,
            textAlign: "right",
            width: 20,
          }}
        >
          {late}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "warning.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Absent:</div>
        <div
          style={{
            position: "relative",
            left: 46,
            textAlign: "right",
            width: 20,
          }}
        >
          {absent}
        </div>
      </Box>
      <Box style={{ display: "flex", lineHeight: 1.2, color: "#fa8989" }}>
        <div style={{ position: "relative", left: 40, width: 60 }}>
          No Show:
        </div>
        <div
          style={{
            position: "relative",
            left: 28,
            textAlign: "right",
            width: 20,
          }}
        >
          {noShow}
        </div>
      </Box>
    </div>
  );
}

function DrawAttendanceHtClass(props) {
  let { htClass } = props;
  let attended =
    htClass.lesson && htClass.lesson.attended
      ? htClass.lesson.attended.length
      : 0;
  let late =
    htClass.lesson && htClass.lesson.late ? htClass.lesson.late.length : 0;
  let absent =
    htClass.lesson && htClass.lesson.absent ? htClass.lesson.absent.length : 0;
  let noShow =
    htClass.lesson && htClass.lesson.noShow
      ? htClass.lesson.noShow.length
      : "0";

  return (
    <div>
      {" "}
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "success.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Attended:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 35,
            textAlign: "right",
            width: 20,
          }}
        >
          {attended}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "yellow" }}
      >
        <div style={{ position: "relative", left: 40 }}>Late:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 61,
            textAlign: "right",
            width: 20,
          }}
        >
          {late}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "warning.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Absent:</div>
        <div
          style={{
            position: "relative",
            left: 46,
            textAlign: "right",
            width: 20,
          }}
        >
          {absent}
        </div>
      </Box>
      <Box style={{ display: "flex", lineHeight: 1.2, color: "#fa8989" }}>
        <div style={{ position: "relative", left: 40, width: 60 }}>
          No Show:
        </div>
        <div
          style={{
            position: "relative",
            left: 28,
            textAlign: "right",
            width: 20,
          }}
        >
          {noShow}
        </div>
      </Box>
    </div>
  );
}

function checkAttendance(htClass) {
  let color = "error";

  if (!htClass.lesson) return color;

  let students = htClass.students.length;
  let attended =
    htClass.lesson.attended?.length + htClass.lesson.late?.length || 0;
  let absent = htClass.lesson.absent?.length || 0;
  let noShow = htClass.lesson.noShow?.length || 0;

  if (attended + absent + noShow === students) return "success";

  return "error";
}
