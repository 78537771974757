import React, { useContext } from "react";
import { CenterContext } from "../../../../../Context";
import Text from "../../../components/Text";
import { useState } from "react";
import mut from "../../../hooks/mut";
/* import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error"; */


export default function SurveyTags(props) {
const {center} = useContext(CenterContext)
const [newTag,setNewTag] = useState("")
const [surveyTags,setSurveyTags] = useState(center.surveyTags || [])


/* if (loading) return <Loading />;
if (error) return <Error />; */


/* console.log(locations); */

    
    const addCategory = async (e) => {
      
        if (e.keyCode === 13) {
            let result = await mut("post", "/center", { surveyTags: [...surveyTags, newTag] })
            console.log(result)
         
            setSurveyTags(result.surveyTags)
         setNewTag("");
        }
    }
    


    return (<div>{surveyTags.map(l => <div>{l}</div>)}
    <Text value={newTag} setValue={setNewTag} label="Add survey tag" onKeyDown={addCategory}></Text>
    </div>)
}