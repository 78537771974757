import {Button,Dialog,DialogActions,DialogContent,DialogTitle, Stack} from "@mui/material";
import React, { useState } from "react";
import Text from "../../components/Text";
import mut from "../../hooks/mut";

export default function NewTargetDialog(props) {
    const { open, setOpen } = props;
    const [newTarget, setNewTarget] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        baseRevenue: 0,
        newStudents: 0
    })

  return (
    <Dialog open={open}>
      <DialogTitle>New Target</DialogTitle>
      <DialogContent>
        <Stack spacing={2} style={{marginTop: 5}}>
          {fields.map((field) => (
            <Text
              value={newTarget[field.field]}
              setValue={(v) =>
                setNewTarget((prev) => ({ ...prev, [field.field]: v }))
              }
                  label={field.label}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
              <Button color="success" onClick={() => {
                  mut("put","/targets",newTarget,()=>setOpen(false))
        }}>Go</Button>
      </DialogActions>
    </Dialog>
  );
}

const fields = [
  { field: "year", type: "number", label: "Year" },
  { field: "month", type: "number", label: "Month" },
  { field: "baseRevenue", type: "number", label: "Base revenue for bonus" },
  { field: "newStudents", type: "number", label: "New students" },
];