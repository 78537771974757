import React from "react";

import HtTabs from "../../components/HtTabs";
import Overview from "./Overview";
import Bonus from "./Bonus";


export default function ManageMarketing(props) {

  const labels = ["Overview","Bonus"]
  const components = [<Overview />,<Bonus />]
  return <div>
    <HtTabs labels={labels} components={components} />
  </div>
}
