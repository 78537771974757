import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { addHours } from "date-fns";

export default function ChooseDateInline(props) {
const {style,enable,value,setValue} = props;


    return (
      <div style={style}>
        <Flatpickr
          options={{ inline: true, enable }}
          className="flatp"
          value={value}
          onChange={(dates) => setValue((prev) => addHours(dates[0], 7))}
          style={{width:600}}
        />
      </div>
    );
}