import React, { useContext, useState } from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { format } from "date-fns";
import { Button, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Search from "../../components/Search";
import { exportFile } from "table-xlsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SLink from "../../components/SLink";
import useDB from "../../hooks/useDB";
import { CenterContext, UserContext } from "../../../../Context";
import StoppedReason from "./StoppedReason";
import SelectFromList from "../../components/SelectFromList";


import replaceById from "../../hooks/replaceById";
import mut from "../../hooks/mut";
import Pagination from "../../components/Pagination";

export default function SalesStudents(props) {
  const { user } = useContext(UserContext);
  const {center} = useContext(CenterContext)
  const [status, setStatus] = React.useState("Lead");
  const [search, setSearch] = React.useState("");
  const [filter,setFilter] = useState("")
  const [students, setStudents, error, loading] = useDB(
    `/students-status-data/${status}`
  );
  const [page,setPage] = useState(0)
  
  if (loading) return <Loading />;
    
  const handleStatus = (event, newAlignment) => {
    setStatus(newAlignment);
  };

  const filterFunction = (student) => {
    if (search === "" && filter ===""
    ) return true;
    if (filter !== "" && search==="") {
      if(student.stoppedReasons.includes(filter)) return true
    }
    if (
      search !== "" && filter==="" &&
      student.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
      return true;
     if (
       search !== "" && filter!=="" && 
       student.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
       student.stoppedReasons.includes(filter)
     )
       return true;
    return false;
  };

  const list = center.stoppedReasons.map(sr=>({_id: sr._id, name:`${sr.name} (${students.filter(s=>s.stoppedReasons.includes(sr._id)).length})`}))

  const onExportFileClick = () => {
    let cols = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      { title: "Phone", dataIndex: "phone", key: "phone" },
      {
        title: "Date of birth",
        dataIndex: "dateOfBirth",
        key: "dateOfBirth",
      },
      {
        title: "Stopped",
        dataIndex: "statusChanged",
        key: "statusChanged",
      },
      {
        title: "Reason",
        dataIndex: "leaveReason",
        key: "leaveReason",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
      },
    ];

    let expStudents = students.map((student) => ({
      ...student,
      dateOfBirth: student.dateOfBirth
        ? format(new Date(student.dateOfBirth), "dd/MM/yyy")
        : "",
      statusChanged: student.statusChanged
        ? format(new Date(student.statusChanged), "dd/MM/yyy")
        : "",
      notes: student.notes
        .map((n) => `${format(new Date(n.date), "dd/MM/yyyy")} ${n.note}`)
        .toString(),
      leaveReason: student.leaveReason
        ? student.leaveReason
        : student.statusHistory &&
          student.statusHistory[student.statusHistory.length - 1] &&
          student.statusHistory[student.statusHistory.length - 1].reason
        ? student.statusHistory[student.statusHistory.length - 1].reason
        : "",
    }));

    exportFile({
      columns: cols,
      dataSource: expStudents,
      fileName: `${status}.xlsx`,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <ToggleButtonGroup
          value={status}
          onChange={handleStatus}
          exclusive
          size="small"
        >
          <ToggleButton value="Lead">Lead</ToggleButton>
          <ToggleButton value="Stopped">Stopped</ToggleButton>
          <ToggleButton value="HotLead">Potential</ToggleButton>
        </ToggleButtonGroup>
        <Search
          search={search}
          setSearch={setSearch}
          size="small"
          sx={{ ml: 3 }}
        />
        <SelectFromList
          choices={list}
          label="Filter"
          size="small"
          style={{ width: 200 }}
          value={filter}
          setValue={setFilter}
          none
        />
        <div style={{flexGrow:1}}></div>
        <Pagination
          page={page}
          setPage={setPage}
          end={students.length}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/*  <Button
            style={{ marginRight: 10 }}
            onClick={() => onExportFileClick()}
          >
            Export
          </Button> */}
        
        </div>
      </div>
      <hr />
      {students
        .filter((s) => filterFunction(s))
        .slice(page * 10, page * 10 + 10)
        .map((student, i) => (
          <DrawStudent
            student={student}
            setStudents={setStudents}
            user={user}
            page={page}
            index={i}
          />
        ))}
    </div>
  );
}

function DrawStudent(props) {
  let { student, setStudents, user, page, index } = props;
  let leaveReason = ""
  if (student.leaveReason) leaveReason = student.leaveReason
  if (student.statusHistory && student.statusHistory.length > 1) leaveReason = student.statusHistory[student.statusHistory.length - 1].reason
  /*   console.log(student.statusHistory[student.statusHistory.length - 1].reason); */

  const toPotential = () => {
    net
      .post(`/leads/${student._id}`, {
        stage: 1,
        status: "HotLead",
        marketingPerson: user._id,
        placementTests: [],
      })
      .then((res) => {
        setStudents((prev) => prev.filter((l) => l._id !== student._id));
      });
  };
  return (
    <div key={student._id}>
      <div
        style={{
          display: "flex",
          /*  justifyContent: "space-between", */
          /*  alignItems: "center", */
        }}
      >
        <div style={{ width: 30, minWidth: 30 }}>{(page*10)+index+1}</div>
        <div style={{ width: 200, minWidth: 200 }}>
          <SLink
            id={student._id}
            showPhone
            showClasses
          />
        </div>
        <div style={{ flexGrow: 1, fontSize: 12 }}>
          <StoppedReason id={student.stoppedReasons} />

          {leaveReason}
        </div>
        <TextField
          value={student.leadNotes}
          multiline
          inputProps={{ style: { fontSize: 11 } }}
          onChange={(e) =>
            replaceById(setStudents, { ...student, leadNotes: e.target.value })
          }
          onBlur={() =>
            mut("post", `/students/${student._id}`, {
              leadNotes: student.leadNotes,
            })
          }
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 250,
            minWidth: 250,
            marginRight: 5,
          }}
        >
          <div>{format(new Date(student.statusChanged), "dd/MM/yyyy")}</div>
          <Button
            size="small"
            color="success"
            endIcon={<ArrowForwardIcon />}
            onClick={() => toPotential()}
          >
            To potential
          </Button>
        </div>
      </div>
      {/*  {student.notes
        ? student.notes.map((note) => (
            <div key={note._id}>
              {format(new Date(note.date), "dd/MM/yyyy")} {note.note}
            </div>
          ))
        : ""} */}

      <hr />
    </div>
  );
}
