import React from "react";
import AddButton from "../../components/AddButton";
import { Stack, TextField } from "@mui/material";
import { useFloating, offset, flip, shift } from "@floating-ui/react";
import { ClickAwayListener } from "@mui/base";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import mut from "../../hooks/mut";

/**
 * 
 * @param {string} label text label 
 * @param {string} color
 * @returns 
 */

export default function ResourceContainer(props) {
    const { label, background,url,setLesson } = props
    const [open, setOpen] = React.useState(false)   
    const { refs, floatingStyles, context } = useFloating({
        placement:'bottom-end',
      middleware: [
        offset(10),
        flip({ fallbackAxisSideDirection: "end" }),
        shift(),
      ], 
    });

    return (
      <div
        className="resource"
        style={{ background }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{label}</div>
          <div ref={refs.setReference}>
            <AddButton
              style={{
                background: "green",
                color: "white",
                width: 30,
                height: 30,
              }}
              action={() => setOpen(true)}
            />
          </div>
        </div>
        {open ? (
          <AddResource
            refs={refs}
            floatingStyles={floatingStyles}
            context={context}
            label={label}
            setOpen={setOpen}
            url={url}
            setLesson={setLesson}        
          />
        ) : null}
        <div>
          <Resources />
        </div>
      </div>
    );
}

function Resources(props){


const {} = props


return <div>Resources</div>
}

function AddResource(props){


const {refs,floatingStyles,context,label, setOpen,url,setLesson} = props

    const [name, setName] = React.useState("")
    const [link, setLink] = React.useState("")

    const add = () => {
          if (name !== "" && link !== "") {
              mut("post", url, { resource: { name, link }, setLesson });
              setOpen(false)
          }
    }

    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            width: 500,
            background: "white",
            borderRadius: 10,
            zIndex: 100,
            border: "1px solid #ccc",
            padding: 10,
            color: "black",
          }}
        >
          <div style={{ padding: 5, marginBottom: 5 }}>Add new {label}</div>
          <Stack spacing={2}>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              {name === "" ? (
                <CancelIcon color="error" />
              ) : (
                <CheckCircleIcon color="success" />
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <TextField
                label="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                            fullWidth
                            onBlur={add}
              />
              {link === "" ? (
                <CancelIcon color="error" />
              ) : (
                <CheckCircleIcon color="success" />
              )}
            </div>
          </Stack>
        </div>
      </ClickAwayListener>
    );
}