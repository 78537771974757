import React from "react";
import Chat from "./Chat";
export default function Chats(props) {
  const { chats, user, message } = props;

  return (
    <div className="chats">
      <div>
        {" "}
        {message.result && message.result !== "" ? (
          <div className="old-chat-description">{message.description}</div>
        ) : (
          ""
        )}
        {chats.sort().map((chat) => (
          <Chat chat={chat} user={user} key={chat._id} />
        ))}
      </div>
    </div>
  );
}
