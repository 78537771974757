import React from "react";
import FormatDate from "../../components/FormatDate"
import { net } from "../../../../database"
import { Button, TextField } from "@mui/material";
import Loading from "../../Loading";
import { useContext } from "react";
import { StudentsContext, UserContext } from "../../../../Context";
import StudentLink from "../../components/StudentLink";
import DateOfBirth from "../../components/DateOfBirth";
import Location from "../../components/Location";
import Time from "../../components/Time";
import Info from "../../components/Info";


export default function PlacementTests(props) {
  const [tests, setTests] = React.useState(undefined)
  const {user} = useContext(UserContext)


  React.useEffect(() => {
    net.get("/placement-tests/" + user._id).then(res=>setTests(res))
  },[]);

  if(!tests) return <Loading />
  console.log(tests);
  return (
    <div>
      <h3>Placement tests coming up</h3>
      {tests.map((test) => (
        <Test
          test={test}
          setTests={setTests}
          key={test._id}
        />
      ))}
    </div>
  );
}

function Test(props) {
  let { test, setTests } = props;
  return (
    <div style={{ marginBottom: 20 }}>
      <div style={{display:"flex", color: "white", backgroundColor:"#888", padding:5, borderRadius:5, marginBottom:10}}>
        <FormatDate
          value={test.date}
          style={{ marginRight: 7 }}
        />{" "}
        at{" "}
        <div style={{ marginLeft: 7, marginRight: 7 }}>
          {" "}
          <Time time={test.startTime} />
        </div>{" "}
        {test.location ? <React.Fragment>at{" "}
          <Location
            id={test.location}
        
          /></React.Fragment> : null}
      </div>
      {test.students.map((student) => (
        <TestStudent
          student={student}
          test={test}
          setTests={setTests}
          key={student._id}
        />
      ))}
     
    </div>
  );
}

function TestStudent(props) {
  let { student, test } = props;

  const [speaking, setSpeaking] = React.useState(student.speaking || "");
  const [writing, setWriting] = React.useState(student.writing || "");
  const [conclusion, setConclusion] = React.useState(student.conclusion || "");
    const { findStudent } = useContext(StudentsContext);

  if (!student) return <div></div>;


  const st = findStudent(student.student)
  const update = (field) => {
    net
      .post(`/placement-test-student2/${student.student}/${test._id}`, {
        ...field,
      })
      .then((res) => console.log(res));
  };
  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ display: "flex", marginBottom: 10, alignItems:"center" }}>
        <div style={{ width: 300 }}>
          <StudentLink student={student.student} />
        </div>
        <div style={{ fontSize: 14,flexGrow:1 }}><Info title="Notes" body={student.notes} /></div>
        <DateOfBirth dateOfBirth={st.dateOfBirth} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          value={speaking}
          label="Speaking"
          type="number"
          onChange={(e) => setSpeaking(e.target.value)}
          onBlur={(e) => update({ speaking: e.target.value })}
          sx={{ width: 80 }}
        />
        <TextField
          value={writing}
          label="Writing"
          type="number"
          onChange={(e) => setWriting(e.target.value)}
          onBlur={(e) => update({ writing: e.target.value })}
          sx={{ width: 80, ml: 1 }}
        />

        <TextField
          value={conclusion}
          label="Conclusion"
          onChange={(e) => setConclusion(e.target.value)}
          onBlur={(e) => update({ conclusion: e.target.value })}
          InputProps={{ style: { fontSize: 14 } }}
          sx={{ flexGrow: 1, ml: 1 }}
        />
        <div
          style={{
            width: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={() => update({ status: "Completed" })}>
            Finish
          </Button>
        </div>
      </div>
      <hr />
    </div>
  );
}