import React, { useEffect, useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { format } from "date-fns";
import { net } from "../../../../database";
import MonthChoice from "../../components/MonthChoice";
import AddButton from "../../components/AddButton";
import NewTestDialog from "./NewTestDialog";
import DrawTest from "./DrawTest";
import SelectFromList from "../../components/SelectFromList";

export default function Tests(props) {
  const [contacts, setContacts, error, loading] = useDB(`/marketing-contacts`);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [openNewTest, setOpenNewTest] = useState(false)
  const [testDate,setTestDate] = useState(new Date().toISOString().substring(0,10))

  useEffect(() => {
    let int = setInterval(async () => {
      const results = await net.get(`/marketing-contacts`);
      setContacts(results);
    }, 60000);
    return () => clearInterval(int);
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!contacts) return <div></div>;

  let dates = [
    ...new Set(
      contacts
        .filter(
          (c) =>
            new Date(c.testDate).getFullYear() === year &&
            new Date(c.testDate).getMonth() === month
        )
        .map((c) => c.testDate.substring(0,10))
    ),
  ];

  dates = dates.sort().reverse()
    
  const labels = dates.map((d) => ({ _id: d , name: `${format(new Date(d), "dd/MM")} (${contacts.filter(c => c.testDate && c.testDate.substring(0, 10) === d).length})` }));

  return (
    <div >
      <div style={{ display: "flex", gap:20, paddingBottom:20,marginBottom:20, borderBottom:"1px solid #ccc" }}>
      
        <MonthChoice
          year={year}
          setYear={setYear}
          month={month}
          setMonth={setMonth}
        />
        <SelectFromList choices={labels} value={testDate} setValue={setTestDate} size="small" style={{width:150}} />
        <AddButton
          tooltip="Add new test"
          action={() => setOpenNewTest(true)}
        />
        <NewTestDialog
          open={openNewTest}
          setOpen={setOpenNewTest}
          setContacts={setContacts}
        />
      </div>
      <DrawTab testDate={testDate} contacts={contacts} />
    </div>
  );
}

function DrawTab(props) {
  const { contacts, testDate } = props;
  return (
    <div>
  
      {contacts
        .filter((c) => c.testDate && c.testDate.substring(0,10) === testDate)
        .map((c) => (
          <DrawTest
            contact={c}
            key={c._id}
          />
        ))}
    </div>
  );
}
