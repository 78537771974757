import { MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import Total from "./Total";
import { CenterContext } from "../../../../../../Context";
import Lang from "../../../../components/Lang";

export default function DrawTotalsAndPaymentType({
  payment,
  setPayment }) {
  const {center} = useContext(CenterContext)
  return (
    <div className="dflex-sb">
      <TextField
        select
        fullWidth
        sx={{ m: 1 }}
        label={<Lang w="paymentType" />}
        value={payment.type}
        onChange={(e) => setPayment({ ...payment, type: e.target.value })}
      >
        {center.paymentTypes.map((ptype, i) => (
          <MenuItem key={`paymentType${i}`} value={i}>
            {ptype}
          </MenuItem>
        ))}
      </TextField>
      <Total
        payment={payment}
        setPayment={setPayment}
    
      />
    </div>
  );
}
