import React from "react";
//import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

/* const useStyles = makeStyles((theme) => ({
  root: {},
})); */

export default function Loading(props) {
  //const classes = useStyles();
  if (props.size === "small")
    return <CircularProgress color="primary" size={20} />;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
