import React, { useContext } from "react";
import { net } from "../../../../database";
import { TextField } from "@mui/material";
import ShowTags from "./ShowTags";

import { StaffContext, UserContext } from "../../../../Context";
export default function ChatInput(props) {
  //const {l,language} = props;

  const { chatId, setChat } = props;
  const { staff } = useContext(StaffContext);
  const { user } = useContext(UserContext);
  const [newChat, setNewChat] = React.useState("");
  const [showAt, setShowAt] = React.useState(false);
  const [staffFilter, setStaffFilter] = React.useState("");
  const [selected, setSelected] = React.useState(0);

  const sendChat = (e) => {
    if (e.keyCode === 13 && newChat !== "") {
      net
        .post(`/chat/${chatId}`, {
          user: user._id,
          message: newChat,
        })
        .then((result) => setChat(result));
      setChat("");
    } else {
      let words = newChat.split(" ");
      let lastWord = words[words.length - 1];

      if (lastWord.length > 0 && lastWord.substring(0, 1) === "@") {
        setStaffFilter(lastWord.replace("@", ""));
        setShowAt(true);
      }
    }
  };
  let st = staff.filter(
    (s) =>
      staffFilter.length > 0 &&
      s.name.toLowerCase().includes(staffFilter.toLowerCase())
  );
  const doTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      net
        .post(`/chat/${chatId}/add-user`, {
          newPerson: st[selected]._id,
        })
        .then((res) => addUser(res, st[selected]));
    }
    if (e.keyCode === 38) {
      e.preventDefault();
      if (selected > 0) setSelected(selected - 1);
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      setSelected(selected + 1);
    }
  };

  const addUser = (res, user) => {
    let position = newChat.lastIndexOf("@");
    setShowAt(false);
    setNewChat(newChat.substring(0, position + 1) + user.name);
  };

  return (
    <div>
      {showAt ? (
        <ShowTags
          addUser={addUser}
          selected={selected}
          staff={st}
          chatId={chatId}
        />
      ) : (
        ""
      )}
      <TextField
        sx={{ background: "White" }}
        size="small"
        className="chat-input"
        fullWidth
        value={newChat}
        onKeyUp={(e) => sendChat(e)}
        onKeyDown={(e) => doTab(e)}
        onChange={(e) => setNewChat(e.target.value)}
        placeholder="@, write a message..."
      />
    </div>
  );
}
