import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { MenuItem, TextField } from "@mui/material";

export default function Payments(props) {
  let { payments, center, language, l } = props;
  payments.forEach((payment) => {
    payment.id = payment.payments._id;
    payment.amount = payment.payments.amount;
    payment.date = new Date(payment.payments.date);
    payment.name = payment.name ? payment.name : "No name";
  });
  const [paymentType, setPaymentType] = React.useState(
    props.center.paymentTypes[0]
  );
  /*  payments.filter((payment) =>payment.) */
  const rows = payments;
  const columns = [
    { field: "date", headerName: "Date", width: 120, type: "date" },
    { field: "name", headerName: "Name", width: 300 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "ref", headerName: "Ref", width: 100 },
    { field: "amount", headerName: "Amount", type: "number", flex: 1 },
  ];
  return (
    <div style={{ height: 5000, width: "100%" }}>
      <TextField
        select
        value={paymentType}
        onChange={(e) => setPaymentType(e.target.value)}
        label={l.typeOfPayment[language]}
        sx={{ width: 200 }}
      >
        {center.paymentTypes.map((pt) => (
          <MenuItem value={pt}>{pt}</MenuItem>
        ))}
      </TextField>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}
