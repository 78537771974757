import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Headings from "../../components/Headings";
import Rating from "../../components/Rating";
import { StaffContext } from "../../../../Context";
import { useContext } from "react";
import { format } from "date-fns";
import { Button } from "@mui/material";

export default function Overview(props) {
  const [weeks,setWeeks] = useState(1)
  const [numbers, setNumbers, error, loading] = useDB(`/marketing-results/${weeks}`);
  const { getStaffRole } = useContext(StaffContext);
  const staff = getStaffRole("Sales");
  if (loading) return <Loading />;
  if (error) return <Error />;

  const { results, locations, sales } = build(numbers, staff);
  console.log(numbers);
  const labels = [
    {
      label: "Name",
      width: 200,
    },
    {
      label: "Today",
      width: 100,
    },
    {
      label: "Tests Today",
      width: 100,
    },
    {
      label: "Numbers",
      width: 100,
    },
    {
      label: "Tests",
      width: 100,
    },
    { label: "Conversion Rate", width: 100 },
    {
      label: (
        <Rating
          value={5}
          height={15}
          readOnly
        />
      ),
      width: 100,
    },
    {
      label: (
        <Rating
          value={4}
          height={15}
          readOnly
        />
      ),
      width: 100,
    },
    ,
    {
      label: (
        <Rating
          value={3}
          height={15}
          readOnly
        />
      ),
      width: 100,
    },
    ,
    {
      label: (
        <Rating
          value={2}
          height={15}
          readOnly
        />
      ),
      width: 100,
    },
    ,
    {
      label: (
        <Rating
          value={1}
          height={15}
          readOnly
        />
      ),
      width: 100,
    },
  ];

  return (
    <div>
      <div>
        {buttons.map(b => <Button onClick={()=>setWeeks(b.value)} {...(weeks===b.value && {
        color: "success" 
        })}>{b.label}</Button>)}
      </div>
      <h3>Staff</h3>
      <Headings labels={labels} />
      {results.map((r) => (
        <User
          result={r}
          key={r.name}
        />
      ))}
      <h3>Locations</h3>
      <Headings labels={labels} />
      {locations.map((r) => (
        <User
          result={r}
          key={r.name}
        />
      ))}
      <h3>Sales staff</h3>
      <Headings labels={labels} />
      {sales.map((r) => (
        <User
          result={r}
          key={r.name}
        />
      ))}
    </div>
  );
}

function User({ result }) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 200 }}>{result.name}</div>{" "}
      <div style={{ width: 100, textAlign: "center" }}>{result.today}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.todayGood}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.numbers}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.good}</div>
      <div style={{ width: 100, textAlign: "center" }}>
        {result.conversionRate}
      </div>
      <div style={{ width: 100, textAlign: "center" }}>{result.five}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.four}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.three}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.two}</div>
      <div style={{ width: 100, textAlign: "center" }}>{result.one}</div>
      {/* {result.score && <div>{result.score}</div>} */}
    </div>
  );
}

function build(numbers, staff) {
  let results = [];
  let locations = [];
  let sales = [];
  let today = format(new Date(), "yyyy-MM-dd");
  console.log(numbers)
  for (let n of numbers) {
    console.log(n)
    let user = results.find((r) => n.marketingPerson && r._id === n.marketingPerson._id);
    if (user) {
      user.numbers++;
      if (n.testDate) user.good++;
      if (n.localDate && n.localDate === today) user.today++;
      if (n.localDate && n.localDate === today && n.testDate) user.todayGood++;
    } else {
      if (n.marketingPerson) {
        results.push({
          _id: n.marketingPerson._id,
          name: n.marketingPerson.name,
          numbers: 1,
          good: n.testDate ? 1 : 0,
          today: n.localDate && n.localDate === today ? 1 : 0,
          todayGood: n.localDate && n.localDate === today && n.testDate ? 1 : 0,
        });
      }
    }

    let location = locations.find((r) => r.name === n.location);
    if (location) {
      location.numbers++;
      if (n.testDate) location.good++;
      if (n.localDate && n.localDate === today) location.today++;
      if (n.localDate && n.localDate === today && n.testDate)
        location.todayGood++;
    } else {
      locations.push({
        name: n.location,
        numbers: 1,
        good: n.testDate ? 1 : 0,
        today: n.localDate && n.localDate === today ? 1 : 0,
        todayGood: n.localDate && n.localDate === today && n.testDate ? 1 : 0,
      });
    }

    let person = sales.find((s) => s._id === n.consultant);
    if (person) {
      person.numbers++;
      if (n.testDate) person.good++;
      if (n.localDate && n.localDate === today) person.today++;
      if (n.localDate && n.localDate === today && n.testDate)
        person.todayGood++;
    } else {
      let staffMember = staff.find((s) => s._id === n.consultant);
      sales.push({
        _id: n.consultant,
        numbers: 1,
        name: staffMember ? staffMember.name : "Unallocated",
        good: n.testDate ? 1 : 0,
        today: n.localDate && n.localDate === today ? 1 : 0,
        todayGood: n.localDate && n.localDate === today && n.testDate ? 1 : 0,
      });
    }
  }

  for (let r of results) {
    r.conversionRate = conversionRate(
      numbers.filter((n) => n.marketingPerson && n.marketingPerson._id === r._id)
    );
    r.score = (r.numbers * r.average).toFixed(1);
    r.one = numbers.filter(
      (n) =>
        n.marketingPerson && n.marketingPerson._id === r._id && n.score === 1
    ).length;
    r.two = numbers.filter(
      (n) =>
        n.marketingPerson && n.marketingPerson._id === r._id && n.score === 2
    ).length;
    r.three = numbers.filter(
      (n) =>
        n.marketingPerson && n.marketingPerson._id === r._id && n.score === 3
    ).length;
    r.four = numbers.filter(
      (n) =>
        n.marketingPerson && n.marketingPerson._id === r._id && n.score === 4
    ).length;
    r.five = numbers.filter(
      (n) =>
        n.marketingPerson && n.marketingPerson._id === r._id && n.score === 5
    ).length;
  }
  for (let l of locations) {
    l.conversionRate = conversionRate(
      numbers.filter((n) => n.location === l.name)
    );
    l.one = numbers.filter(
      (n) => n.location === l.name && n.score === 1
    ).length;
    l.two = numbers.filter(
      (n) => n.location === l.name && n.score === 2
    ).length;
    l.three = numbers.filter(
      (n) => n.location === l.name && n.score === 3
    ).length;
    l.four = numbers.filter(
      (n) => n.location === l.name && n.score === 4
    ).length;
    l.five = numbers.filter(
      (n) => n.location === l.name && n.score === 5
    ).length;
  }

  for (let s of sales) {
    s.conversionRate = conversionRate(
      numbers.filter((n) => n.consultant === s._id)
    );
    s.score = (s.numbers * s.average).toFixed(1);
    s.one = numbers.filter(
      (n) => n.consultant === s._id && n.score === 1
    ).length;
    s.two = numbers.filter(
      (n) => n.consultant === s._id && n.score === 2
    ).length;
    s.three = numbers.filter(
      (n) => n.consultant === s._id && n.score === 3
    ).length;
    s.four = numbers.filter(
      (n) => n.consultant === s._id && n.score === 4
    ).length;
    s.five = numbers.filter(
      (n) => n.consultant === s._id && n.score === 5
    ).length;
  }

  return { results, locations, sales };
}

function average(numbers) {
  const sum = numbers.reduce((a, b) => a + b.score, 0);
  return (sum / numbers.length).toFixed(1);
}

function conversionRate(numbers) {
  const count = numbers.filter((n) => n.testDate).length;
  return (100 * (count / numbers.length)).toFixed(1) + "%";
}


const buttons = [{
  label: "1 week",
  value: 1
}, {
  label: "2 weeks",
  value: 2
  }, { label: "3 weeks", value: 3 },{label:"4 weeks", value: 4},{label: "All", value: 52}]