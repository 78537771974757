import { Box, TextField, IconButton, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { net } from "../../../../../database";
import DeleteIcon from "@mui/icons-material/Delete";
export default function LocationDetails(props) {
  //const [location, setLocation] = React.useState(props.location);

  const updateName = () => {
    net
      .post("/center/", { locations: props.center.locations })
      .then((r) => console.log(r));
  };

  const deleteRoom = (r, i) => {
    props.location.rooms.splice(i, 1);
    let locations = props.center.locations.map((l) =>
      l._id === props.location._id ? props.location : l
    );

    net.post("/center/", { locations }).then((center) => {
      console.log(center);
      props.setCenter(center);
    });
  };

  return (
    <Box
      padding={1}
      border={1}
      borderRadius={5}
      marginLeft={2}
      borderColor={"#CCCCCC"}
    >
      <Stack spacing={2}>
        <TextField
          label="Name"
          variant="standard"
          value={props.location.name}
          onChange={(e) =>
            props.setLocation({ ...props.location, name: e.target.value })
          }
          onBlur={updateName}
        />
        <div style={{ fontSize: 13 }}>Rooms</div>
        {props.location.rooms.map((r, i) => (
          <div>
            <TextField
              value={r}
              key={`room${i}`}
              variant="standard"
              onBlur={updateName}
              onChange={(e) => {
                props.location.rooms[i] = e.target.value;
                let locations = props.center.locations.map((l) =>
                  l._id === props.location._id ? props.location : l
                );

                props.setCenter({ ...props.center, locations });
              }}
            />
            <IconButton onClick={() => deleteRoom(r, i)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Add new room">
            <IconButton
              onClick={(e) => {
                props.location.rooms.push("");
                let locations = props.center.locations.map((l) =>
                  l._id === props.location._id ? props.location : l
                );
                net.post("/center/", locations).then((center) => {
                  props.setCenter(center);
                });
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Stack>
    </Box>
  );
}

/*  const [locations, setLocations] = React.useState(props.locations);

  const [render, setRender] = React.useState(0);

  React.useEffect(() => {
    setLocations(props.locations);
  }, [props.locations]);

  const updateRoom = (e, i) => {
    console.log(e.target.name);
    let newlocations = locations;

    newlocations[props.location].rooms.find(
      (r) => r._id === e.target.name
    ).name = e.target.value;
    setLocations(newlocations);
    setRender(render + 1);
  };

  const addRoom = (e, i) => {
    props.net
      .post(`/center/location/${locations[props.location].name}/rooms/add`, {
        name: "new room from app",
      })
      .then((result) => {
        console.log(result);

        setLocations(result);
        setRender(render + 1);
      });
  };

  const updateRoomServer = (e) => {
    props.net.post(
      `/center/location/${locations[props.location].name}/rooms/${
        e.target.name
      }/update`,
      { name: e.target.value }
    );
  };

  if (location.length < 1) return <div>No Locations</div>;
  return (
    <Box border={1} padding={2} borderRadius={5} ml={2} borderColor={"#CCCCCC"}>
      <Stack spacing={2}>
        <TextField
          label="Name"
          placeholder="Name"
          variant="standard"
          value={locations[props.location].name}
        ></TextField>
        <div>
          Rooms:
          <div>
            {locations[props.location].rooms.map((room, index) => (
              <div key={room + index}>
                <TextField
                  name={room._id}
                  variant="standard"
                  value={room.name}
                  onChange={updateRoom}
                  onBlur={updateRoomServer}
                ></TextField>
              </div>
            ))}
          </div>
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" onClick={addRoom} mt={5}>
              Add room
            </Button>
          </div>
        </div>
      </Stack>
    </Box>
  ); */
