import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationDetails from "./components/LocationDetails";
import NewLocationDialog from "./components/NewLocationDialog";
import SimpleDialog from "../../components/SimpleDialog";

import Locations from "./components/Locations";
import Teaching from "./components/Teaching";
import Admin from "./components/Admin";
import Staff from "./components/Staff";
import Holidays from "./components/Holidays";
import Loading from "../../Loading";

import Departments from "./components/Departments";
import StoppedReasons from "./components/StoppedReasons";
import MarketingLocations from "./components/MarketingLocations";
import CostCategories from "./components/CostCategories";
import SurveyTags from "./components/SurveyTags";

export default function Settings(props) {
  const { l, language } = props;
  /*  const [settings, setSettings] = React.useState(undefined); */

  // const [openNewLocation, setOpenNewLocation] = React.useState(false);

  /*  React.useEffect(() => {
    net.get("/settings").then((r) => setSettings(r));
  }, []); */

  if (props.center === undefined) return <Loading />;
  console.log(props.center);
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{l.locations[language]}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Locations
            /*  settings={settings}
            setSettings={setSettings} */
            center={props.center}
            setCenter={props.setCenter}
            {...props}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{l.departments[language]}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Departments {...props} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Teaching</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Teaching
            center={props.center}
            setCenter={props.setCenter}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Admin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Admin />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Staff</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Staff />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Holidays</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Holidays
            center={props.center}
            setCenter={props.setCenter}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Reasons for students stopping</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StoppedReasons />
        </AccordionDetails>
      </Accordion>
      <Acc
        title="Marketing Locations"
        component={<MarketingLocations />}
      />
      <Acc
        title="Cost categories"
        component={<CostCategories />}
      />
      <Acc
        title="Customer survey tags"
        component={<SurveyTags />}
      />
    </div>
  );
}


function Acc({ title, component }){
  
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {component}
      </AccordionDetails>
    </Accordion>
  );
}

const fields = [
  {
    name: "Name",
    type: "text",
    label: "Name",
    value: "",
  },
];

const billTypeFields = [
  { name: "name", type: "text", label: "Bill Type", value: "" },
];

// const { setting } = useParams();
// const [selectedLocation, setSelectedLocation] = React.useState(0);
//const [locations, setLocations] = React.useState(props.init.center.locations);
/*  const [costTypes, setCostTypes] = React.useState(
    props.init.center.costType || []
  ); */
/*  const [billTypes, setBillTypes] = React.useState(
    props.init.center.billTypes || []
  ); */
/*   const [openCostTypeDialog, setOpenCostTypeDialog] = React.useState(false);
  const [openBillTypeDialog, setOpenBillTypeDialog] = React.useState(false);
  const settings = ["Locations", "Teaching", "Admin", "Roles", "Holidays"];
  const closeNewLocation = (location) => {
    if (location.name === "") {
      setOpenNewLocation(false);
      return;
    }
    props.net.post("/center/locations/add", location).then((response) => {
      //setLocations(response);

      setOpenNewLocation(false);
    });
  };
 */
