import React from "react";


export default function InfoBox({title,body}) {


    return (
      <div style={{ border: "1px solid #ccc", borderRadius: 5, padding: 5 }}>
        <div style={{ fontSize: 16,fontWeight: "bold" }}>{title}</div>
        <div style={{ fontSize: 14 }}>{body}</div>
      </div>
    );
}