import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { net } from "../../../../../database";
export default function Departments(props) {
  const { l, language, center, setCenter } = props;
  let { departments } = center;
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  if (!center) return <div></div>;

  const newDepartment = () => {
    net.post(`/center/departments`, { name }).then((res) => {
      setCenter(res);
      setName("");
      setOpen(false);
    });
  };

  return (
    <div>
      {departments.map((department) => (
        <div>{department.name}</div>
      ))}
      <IconButton onClick={() => setOpen(true)}>
        <AddIcon />{" "}
      </IconButton>
      <Dialog open={open}>
        <DialogTitle>Add Department</DialogTitle>
        <DialogContent>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="success" onClick={newDepartment}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
