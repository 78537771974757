import "./Topbar.css";
import React, { } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import SearchStudents from "./SearchStudents";
import {ClickAwayListener,Tooltip,Chip,Avatar,
} from "@mui/material/";
/* import DarkModeSwitch from "./DarkModeSwitch"; */
import Notifications from "./Notifications";
import { deleteApiKey } from "../../App";
import { Link, useNavigate } from "react-router-dom";

import NewMessageDialog from "./components/NewMessageDialog";
import Connected from "./Connected";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  // console.log(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [NanchorEl, setNAnchorEl] = React.useState(null);
  const [searchEl, setSearchEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const {user,setUser} = props
  const [notificationsCount, setNotificationsCount] = React.useState(
    props.user.unreadNotifications.length
  );

  const isMenuOpen = Boolean(anchorEl);
  const isNMenuOpen = Boolean(NanchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isSearchOpen = Boolean(searchEl);
  const navigate = useNavigate();

  const changeLanguage = () => {
    if (props.language === 0) {
      props.setLanguage(1);
    } else {
      props.setLanguage(0);
    }
  };

  const showNotifications = (event) => {
    setNAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/profile");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.setItem("apiKey", undefined);

    deleteApiKey();
    console.log(typeof apiKey);
    props.setUser(undefined);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [search, setSearch] = React.useState("");

  /* React.useEffect(() => {
    console.log("search ue");
    net.get("/search").then((res) => {
      setStudents((prev) => res.students);
      setCustomers((prev) => res.customers);
    });
  }, []); */

  /*  React.useEffect(() => {
    props.net.get("/search").then((r) => {
      //  console.log(r);
      setStudents(r);
    });
  }, []); */

  const doSearch = (e) => {
    setSearchEl(e.currentTarget);
    e.target.value !== "" ? setSearch(e.target.value) : setSearch("");
  };
  const clearSearch = (e) => {
    setSearch("");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderNotifications = (
    <Notifications
      notifications={props.user.unreadNotifications}
      NanchorEl={NanchorEl}
      isNMenuOpen={isNMenuOpen}
      handleNotificationsClose={handleNotificationsClose}
      user={props.user}
      setUser={props.setUser}
      setNotificationsCount={setNotificationsCount}
      l={props.l}
      language={props.language}
    />
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 4 new mails"
          color="inherit"
          onClick={changeLanguage}
        >
          {props.language === 0 ? (
            <img src="/uk.png" alt="uk" />
          ) : (
            <img src="/vn.png" alt="vn" />
          )}
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            {/*    <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
            {/*   <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  fontSize: 14,
                  fontWeight: "normal",
                  textAlign: "center",
                  width: 150,
                },
              }}
            >
              {props.center !== undefined ? props.center.name : "Loading"}
            </Typography> */}
            <Box sx={{ flexGrow: 1 }} />
            <ClickAwayListener onClickAway={clearSearch}>
              <Search sx={{ border: 1, borderColor: "#bbb" }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={doSearch}
                  value={search}
                />
              </Search>
            </ClickAwayListener>

            <Tooltip title="New Customer">
              <Link to={`/newcustomer`}>
                <IconButton variant="contained" /* sx={{ color: "#FFFFFF" }} */>
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="New Message">
              <IconButton
                variant="contained"
                onClick={() => setMessageDialogOpen(true)}
              >
                <MessageIcon />
              </IconButton>
            </Tooltip>
            <Box sx={{ flexGrow: 1 }} />
            {/*   <DarkModeSwitch
              colorMode={props.colorMode}
              setColorMode={props.setColorMode}
            /> */}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Connected />
              <IconButton
                size="large"
                aria-label="Change Language"
                color="inherit"
                onClick={changeLanguage}
              >
                {props.language === 0 ? (
                  <img src="/uk.png" alt="uk" />
                ) : (
                  <img src="/vn.png" alt="vn" />
                )}
              </IconButton>
              <IconButton
                size="large"
                aria-label="Show notifications"
                color="inherit"
                onClick={showNotifications}
                aria-controls={menuId}
              >
                <Badge badgeContent={user.unreadNotifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Chip
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                label={props.user.name}
                avatar={
                  <Avatar
                    alt={props.user.name}
                    src={"./images/" + props.user.image}
                  />
                }
                sx={{
                  mt: 0.9,
                }}
              ></Chip>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {renderNotifications}
      </Box>
      <div>
        {search !== "" ? (
          <SearchStudents
            search={search}
            setSearch={setSearch}
            open={isSearchOpen}
            close={handleMenuClose}
            anchorEl={searchEl}
          />
        ) : null}
      </div>
      {messageDialogOpen ? (
        <NewMessageDialog
          {...props}
          messageDialogOpen={messageDialogOpen}
          setMessageDialogOpen={setMessageDialogOpen}
        />
      ) : null}
    </div>
  );
}
