
import { apiKey } from "../../../App";
import axios from "axios";

/**
 * 
 * @param {string} method http method, put,post,delete 
 * @param {string} url the url to send the request 
 * @param {object} data the data to send 
 * @param {function} updateFunction the function to call when the data is returned 
 * @returns {object} the data from the response
 */

export default async function mut(method = "post", url, data, updateFunction) {
    try {
        const response = await axios({
            method,
            url: `/api${url}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + apiKey,
            },
            data
        });
        if (updateFunction) updateFunction(response.data);
        return response.data
    } catch (err) {
        console.log(err)
    }
}