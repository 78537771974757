import React from "react";
import {
  Button,
  LinearProgress,
} from "@mui/material";
import { net } from "../../../../database";
import AttendanceButtons from "../attendance/components/AttendanceButtons";
import Loading from "../../Loading";
import { useParams, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

export default function MobileAttendance(props) {
  const [classInfo, setClassInfo] = React.useState(undefined);
  const { classdayid } = useParams();
  let navigate = useNavigate();
  /*  const [teacher, setTeacher] = React.useState(props.currentClass.teacher); */

  /*  const [room, setRoom] = React.useState(props.currentClass.room); */
  // console.log(props.currentClass);
  React.useEffect(() => {
    net.get("/studentsinclass/" + classdayid).then((r) => {
      console.log(r);
      setClassInfo(r);
    });
  }, [classdayid]);

  // console.log(teacher);

  const updateStudent = (student) => {
    console.log(classInfo);
    setClassInfo({
      ...classInfo,
      students: classInfo.students.map((s) =>
        s._id === student._id ? student : s
      ),
    });
  };

  if (classInfo === undefined) return <Loading />;
  let progress = 0;
  if (classInfo !== undefined) {
    if (
      classInfo.students &&
      classInfo.students.length > 0 &&
      classInfo.students.filter((s) => s.attendance !== undefined).length > 0
    ) {
      progress =
        (classInfo.students.filter((s) => s.attendance !== undefined).length /
          classInfo.students.length) *
        100;
    }
  }

  return (
    <div>
      <h1>{classInfo.name}</h1>

      <div>
        <LinearProgress
          variant="determinate"
          value={progress}
          {...(progress === 100 && { color: "success" })}
          sx={{ marginBottom: 2 }}
        />
        {classInfo.students.length === 0 ? (
          <div>No Students</div>
        ) : (
          classInfo.students.map((s) => (
            <div key={s._id}>
              <AttendanceButtons
                student={s}
                updateStudent={updateStudent}
                currentClass={{ _id: classdayid }}
              />

              <hr></hr>
            </div>
          ))
        )}
      </div>
      <Button
        onClick={() => navigate(`/`)}
        variant="outlined"
        startIcon={<HomeIcon />}
        style={{ float: "right" }}
      >
        Home
      </Button>
    </div>
  );
}
