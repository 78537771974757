import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * 
 * @param {[string]} labels labels for the tabs 
 * @param {[function]} components array of components for each tab 
 * @param {function} nextToTabs component to be placed to the right of the tabs 
 * @param {string} localStorage name of variable to be used to store this in local
 * @param {function} updateValue function to call when the value changes 
 *
 * @returns 
 */

export default function HtTabs(props) {
   const { labels, components, nextToTabs,localStorage,style={},variant,scrollButtons,updateValue } = props;
    const [value, setValue] = React.useState(() => {
    if (!localStorage) return 0
    let ls = getLocalStorage(localStorage)
    if (ls) return ls
    return 0
  }
   
  );
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (localStorage) setLocalStorage(localStorage, newValue)
    if(updateValue) updateValue(newValue)
  };

  return (
    <Box style={{ padding: 0, ...style }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant={variant}
          scrollButtons={scrollButtons}
        
        >
          {labels.map((l, i) => (
            <Tab label={l} key={"tablabel"+i} {...a11yProps(i)} />
          ))}
        </Tabs>
        {nextToTabs ? nextToTabs : null}
      </Box>
      {components.map((c, i) => (
        <TabPanel value={value} index={i} key={"tab" + i}>
          {c}
        </TabPanel>
      ))}
    </Box>
  );
}


function getLocalStorage(name) {
   return parseInt(localStorage.getItem(name)) || undefined
}

function setLocalStorage(name,value) {
  localStorage.setItem(name,value)
}