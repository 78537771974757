import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export default function Bcs(props) {
const {syllabus, unit,cLesson} = props

    return (
      <div style={{marginBottom:15}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/curriculum"
            className="cur-breadcrumbs"
          >
            Curriculum
          </Link>
          {syllabus ? (
            <Link
              to={`/curriculum/${syllabus._id}`}
              className="cur-breadcrumbs"
            >
              {syllabus.name}{" "}
            </Link>
          ) : null}
          {unit ? (
            <Link
              to={`/curriculum/${syllabus._id}/unit/${unit._id}`}
              className="cur-breadcrumbs"
            >
              Unit {unit.unitNumber} - {unit.name}{" "}
            </Link>
          ) : null}
          {cLesson ? (
            <div>
              Lesson {cLesson.lessonNumber} - {cLesson.name}{" "}
            </div>
          ) : null}
        </Breadcrumbs>
      </div>
    );
}