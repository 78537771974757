import { Checkbox } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import VND from "../../../../components/VND";



export default function DrawStudentsNameAndTotal(props) {
  let { student, payment, setPayment } = props;
  return (
    <div>
      {" "}
      <div
        key={student._id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: !payment.excludedStudents.includes(student._id)
            ? "black"
            : "grey",
          alignItems: "center",
        }}
      >
        <div className="dflex-sb" style={{ alignItems: "center" }}>
          <Checkbox
            checked={!payment.excludedStudents.includes(student._id)}
            sx={{ pl: 0 }}
            onChange={(e) => studentCheck(e, student, payment, setPayment)}
          />
          <Link
            to={`/student/${student._id}`}
            className="neat"
            style={{
              color: !payment.excludedStudents.includes(student._id)
                ? "black"
                : "grey",
            }}
          >
            {" "}
            {student.name}
          </Link>
        </div>
        <div>
          {/*  <NumberFormat
            displayType="text"
            value={student.unpaidBills.reduce((a, b) => a + b.amount, 0)}
            thousandSeparator
            style={{ marginRight: 14 }}
          /> */}
          <VND
            value={student.unpaidBills.reduce((a, b) => a + b.amount, 0)}
            style={{ marginRight: 14 }}
          />
        </div>
      </div>
    </div>
  );
}

function studentCheck(e, student, payment, setPayment) {
 
  if (e.target.checked) {
    let excludedStudents = payment.excludedStudents.filter(
      (exs) => exs !== student._id
    );

    let amount = recalcBills({ ...payment, excludedStudents });
    setPayment(prev => ({ ...prev, amount, excludedStudents }));

    return;
  }
  let excludedStudents = [...payment.excludedStudents,student._id];
  let amount = recalcBills({ ...payment, excludedStudents });
  setPayment(prev => ({ ...prev, amount, excludedStudents }));

}

function recalcBills(payment) {
  let amount = 0;
  payment.students
    .filter((student) => !payment.excludedStudents.includes(student._id))
    .forEach((student) => {
      amount += student.unpaidBills
        .filter((bill) => !payment.excludedBills.includes(bill._id))
        .reduce((a, b) => a + b.amount, 0);
    });
  return amount;
}