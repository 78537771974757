import React from "react";

import { Paper, TextField, Stack, Button } from "@mui/material";
import { net } from "../../../../database";
import TestsTabs from "./TestsTabs";
import ChooseClassDialog from "./ChooseClassDialog";
import BasicDetails from "./BasicDetails";
import Notes from "./Notes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChooseTestTime from "./ChooseTestTime";



export default function StageTwo(props) {
  let { leads, setLeads } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(undefined);
  const [openTT, setOpenTT] = React.useState(false);
  const [selectedTest, setSelectedTest] = React.useState(undefined);

  const chooseTestTime = (id) => {
    setSelected((prev) => id);
  };

  return (
    <div>
      {leads.map((lead) => (
        <div
          className="stage-two-box"
          key={lead._id}
        >
          <BasicDetails lead={lead} />
          <Notes
            lead={lead}
            setLeads={setLeads}
            style={{ width: 400 }}
          />
          <TestsTabs
            lead={lead}
            setLeads={setLeads}
            chooseTestTime={chooseTestTime}
          />
          <div style={{ flexGrow: 1 }}></div>
          <Decision
            setOpen={setOpen}
            lead={lead}
            setSelected={setSelected}
            {...props}
          />
        </div>
      ))}
      {open ? (
        <ChooseClassDialog
          open={open}
          setOpen={setOpen}
          lead={selected}
          setLeads={setLeads}
        />
      ) : null}
      {selected ? (
        <ChooseTestTime
          open={selected}
          setOpen={setSelected}
          setLeads={setLeads}
          
        />
      ) : null}
    </div>
  );
}

function Decision({ setOpen, lead, setLeads, setSelected, waiting }) {
  const backToLead = () => {
    net.post(`/leads/${lead._id}`, { stage: 1 }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };
  const backToData = () => {
    net.post(`/leads/${lead._id}`, { stage: 1, status: "Lead" }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };
  const addToWaiting = () => {
    net.post(`/leads/${lead._id}`, { status: "Waiting" }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };
  return (
    <div className="leads-decision">
      <Stack>
        <Button
          color="success"
          onClick={() => {
            setOpen(true);
            setSelected(lead);
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Add Student to class
        </Button>
        {!waiting ? (
          <Button
            color="primary"
            onClick={() => addToWaiting()}
            endIcon={<ArrowForwardIcon />}
          >
            Add Student to waiting list
          </Button>
        ) : null}
        <Button
          color="error"
          onClick={() => backToLead()}
          startIcon={<ArrowBackIcon />}
        >
          Back to potential
        </Button>
        <Button
          color="info"
          onClick={() => backToData()}
          startIcon={<ArrowBackIcon />}
        >
          Back to data
        </Button>
      </Stack>
    </div>
  );
}
