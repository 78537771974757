import React from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { Button,  IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NewExtraClassDialog from "./NewExtraClassDialog";
import FormatDate from "../../components/FormatDate";
import Loca from "../../components/Loca";
import RoomChoice from "../../components/RoomChoice";
import TeacherChoice from "../../components/TeacherChoice";
import SLink from "../../components/SLink";
import Info from "../../components/Info";
import DeleteButton from "../../components/DeleteButton";
import EditButton from "../../components/EditButton";


export default function ExtraClass(props) {

    const [classes, setClasses] = React.useState(undefined) 
    const [open, setOpen] = React.useState(false)
    const [edit, setEdit] = React.useState(undefined)
    
    React.useEffect(() => {
        net.get("/extraclasses").then(res=>setClasses(res))
    },[]);
    
if(!classes) return <Loading />
    console.log(classes)
    return (<div>
        <Button onClick={()=>setOpen(true)}>Add extra class</Button>
        <NewExtraClassDialog open={open} setOpen={setOpen} setClasses={setClasses} edit={edit} setEdit={setEdit} />
        {classes.map(c => <DrawExtraClass xc={c} setClasses={setClasses} setEdit={setEdit} setOpen={setOpen} />)}
    </div>)
}



function DrawExtraClass(props) {
    const { xc, setClasses,setEdit,setOpen } = props
    const [show, setShow] = React.useState(false)
    const update = (exc) => {
    net.post("/extra-classes/" + xc._id, exc).then(res => setClasses(prev=> prev.map(p => p._id===res._id ? res : p)))
}
    return  <div>
            <div style={{display:"flex", gap:10, alignItems:"center",marginTop:10}}>
                <FormatDate value={xc.date} style={{marginRight:10}} />{xc.startTime} - {xc.endTime}
                <div style={{width:75}}><Loca id={xc.location} /></div>
                <RoomChoice value={xc.room} location={xc.location} size="small" style={{width: 100}} setValue={e=> update({room: e})} {...(!xc.room && {
                error:true 
                })}/>
                <TeacherChoice value={xc.teacher} size="small" style={{width: 150}} onChange={e=> update({teacher: e.target.value})} {...(!xc.teacher && {
                error:true 
                })}/>
                <Info title={`Student${xc.students.length === 1 ? "" : "s"}`} body={xc.students.length} />
                <Info title="Notes" body={xc.notes || "None"} style={{flexGrow:1}} />
                <div>
                <EditButton action={() => { setEdit(prev=>xc); setOpen(prev=>true) }} />
                <DeleteButton route={`/extra-classes/${xc._id}`} success={() => setClasses(prev => prev.filter(c => c._id !== xc._id))} />
                <IconButton onClick={() => setShow(!show)}>{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                </div>
        </div>
        {show ?        
                xc.students.map(student => <div style={{ display: "flex" }}>
                    <SLink id={student.student} showClasses style={{ width: 200 }} />
                    <Info title="Notes" body={student.notes || "None"} style={{ flexGrow: 1 }} />
                    <Info title="Link" body={student.link || "No link"} style={{ width: 200 }} />
               
                </div>)
             : null}
       </div>
}