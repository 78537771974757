import React from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddColdDataDialog from "./AddColdDataDialog";
import { Table } from "antd";
import { format } from "date-fns";
import ContactCustomerDialog from "./ContactCustomerDialog";
import EditColdDataDialog from "./EditColdDataDialog";
import { formatPhone } from "../../../../functions";
export default function ColdData(props) {
  let { search } = props;
  const [data, setData] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [openCC, setOpenCC] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(undefined);

  const [contactId, setContactId] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/sales/cold-data").then((res) => {
      setData(res);
    });
  }, [open, openCC, selectedRow]);

  const cols = [
    { title: "TÊN HỌC SINH", dataIndex: "name" },
    {
      title: "SỐ ĐIỆN THOẠI",
      dataIndex: "phone",
      render: (text, record) => {
        return <div>{text ? formatPhone(text) : ""}</div>;
      },
    },
    {
      title: "NĂM SINH",
      dataIndex: "dateOfBirth",
      render: (text, record) => {
        return <div>{text ? format(new Date(text), "dd/MM/yyyy") : ""}</div>;
      },
    },
    { title: "TRƯỜNG", dataIndex: "school" },

    {
      title: "CON Ở NHÀ CÓ THÍCH HỌC TIẾNG ANH KHÔNG?",
      dataIndex: "likeEnglishAtHome",
    },
    {
      title: "MONG MUỐN CỦA PHỤ HUYNH KHI CHO CON ĐI HỌC TIẾNG ANH?",
      dataIndex: "parentsWishes",
    },
    {
      title:
        "ANH/CHỊ CÓ BIẾT ĐẾN TRUNG TÂM TIẾNG ANH HT EXPLORE Ở ĐÔNG NGẠC KHÔNG?",
      dataIndex: "knowAboutHTExplore",
    },
    /* {
      title: "Person",
      dataIndex: "user",
      render: (text, record) => {
        return <div>{text ? text.name : ""}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text, record) => {
        return <div>{text ? format(new Date(text), "dd/MM/yyyy") : ""}</div>;
      },
    },
    {
      title: "Contacted",

      render: (text, record) => {
        if (record.contacts.length === 0)
          return (
            <div
              onClick={() => {
                setContactId(record._id);
                setOpenCC(true);
              }}
              style={{ cursor: "pointer" }}
            >
              Never
            </div>
          );
        return (
          <div onClick={() => setOpenCC(true)} style={{ cursor: "pointer" }}>
            {`${format(
              new Date(record.contacts[record.contacts.length - 1].createdAt),
              "dd/MM/yyyy"
            )} by ${record.contacts[record.contacts.length - 1].user?.name}`}
          </div>
        );
      },
    },
    {
      title: "Notes",
      render: (text, record) => {
        if (record.contacts.length === 0) return <div></div>;
        return <div>{record.contacts[record.contacts.length - 1].notes}</div>;
      },
    },
    {
      title: "Interested",
      dataIndex: "interest",
      render: (text, record) => {
        return (
          <Rating
            value={text}
            onChange={(e, newValue) => {
              net
                .post(`/sales/cold-data/${record._id}`, {
                  interest: newValue,
                })
                .then((res) => {
                  setData(data.map((d) => (d._id === res._id ? res : d)));
                });
              setData(data.map((d) => (d._id === record._id ? record : d)));
            }}
          />
        );
      },
    }, */
  ];

  if (!data) return <Loading />;
  return (
    <div>
      <div className="dflex-sb" style={{ alignItems: "center" }}>
        <h1>Cold Data</h1>
        <IconButton onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </div>
      <Table
        columns={cols}
        onRow={(record, index) => {
          return {
            onClick: (event) => setSelectedRow(record),
          };
        }}
        dataSource={
          search === ""
            ? data
            : data.filter(
                (d) =>
                  (d.name &&
                    d.name
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase())) ||
                  (d.phone &&
                    d.phone
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase())) ||
                  (d.school &&
                    d.school
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase())) ||
                  (d.likeEnglishAtHome &&
                    d.likeEnglishAtHome
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase())) ||
                  (d.parentsWishes &&
                    d.parentsWishes
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase())) ||
                  (d.knowAboutHTExplore &&
                    d.knowAboutHTExplore
                      .toLocaleUpperCase()
                      .includes(search.toLocaleUpperCase()))
              )
        }
      />
      <AddColdDataDialog open={open} setOpen={setOpen} user={props.user} />
      {selectedRow ? (
        <EditColdDataDialog
          open={selectedRow}
          setOpen={setSelectedRow}
          user={props.user}
        />
      ) : null}
      <ContactCustomerDialog
        open={openCC}
        setOpen={setOpenCC}
        user={props.user}
        id={contactId}
        setId={setContactId}
      />
    </div>
  );
}
