import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import SLink from "../../components/SLink";
import { Tooltip } from "@mui/material";
import FormatDate from "../../components/FormatDate";



export default function StudentData(props) {


const [students, setStudents, error, loading] = useDB(`/student-data`)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(students);


    return (
      <div>
        <h1>Students with low recent attendance</h1>
        {students.map((s) => (
          <DrawStudent
            student={s}
            key={s._id}
          />
        ))}
      </div>
    );
}

function DrawStudent(props){


const {student} = props


    return (
      <div style={{ display: "flex", alignItems:"center" }}>
        <SLink
          id={student._id}
          showClasses
          style={{ width: 300 }}
        />{" "}
        <div style={{display:"flex",gap: 3, width: 120}}>
          {student.attendance.map((at) => (
              <DrawAttendance attendance={at} key={at._id} />
          ))}
            </div>
            <div>{student.attendance.filter(at=>at.absentReason).map(at=> at.absentReason).toString()}</div>
      </div>
    );
}

function DrawAttendance(props){


    const { attendance } = props
    const colors = ["limegreen","green", "yellow", "red"]
    let at = attendance.attendance
    if(at===-1) at = 0


return (
    <Tooltip title={<FormatDate value={attendance.tDate} />}>
    <div
      style={{
        background: colors[at],
        width: 20,
        height: 20,
        border: "1px solid",
      }}
    ></div>
  </Tooltip>
);
}