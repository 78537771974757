import { React } from "react";

import { Link } from "react-router-dom";
import { formatPhone } from "../../../../../functions";
import { useTheme } from "@mui/styles";
import { useContext } from "react";
import { StudentsContext } from "../../../../../Context";
import UploadAvatar from "../../../components/UploadAvatar";

/**
 * @param  {student} student the student to show
 */
export default function StudentNamePhone(props) {
  const theme = useTheme();
  let { student, width } = props;
  if (!width) width = 300;
  const { findStudent } = useContext(StudentsContext);
  if (typeof student === "string") {
    
    let Cstudent = findStudent(student)
     return (
       <div style={{ width,display:"flex", alignItems:"center", gap: 10 }}>
         <UploadAvatar id={student} />
         <div>
           <Link             
             className="neat"
             style={{         
               fontSize: 18,
             }}

             to={`/student/${Cstudent._id}`}
           >
             {Cstudent.name}
           </Link>
        
         <div style={{ fontSize: 14, color: theme.palette.text.secondary }}>
           {formatPhone(Cstudent.phone)}
           </div>
            </div>
       </div>
     );
  }
 
  return (
    <div style={{ width }}>
      <div>
        <Link
          style={{
            textDecoration: "none",
            color: theme.palette.text.primary,
            fontSize: 18,
          }}
          to={`/student/${student._id}`}
          //onClick={() => clearSearch()}
        >
          {student.name}
        </Link>
      </div>
      <div style={{ fontSize: 14, color: theme.palette.text.secondary }}>
        {formatPhone(student.phone)}
      </div>
    </div>
  );
}
