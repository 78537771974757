import React from "react";
import FormatDate from "../../components/FormatDate";
import PhoneNumber from "../../components/PhoneNumber";
import DateOfBirth from "../../components/DateOfBirth";
import Info from "../../components/Info";
import Staff from "../../components/Staff";
import StudentLink from "../../components/StudentLink";

export default function BasicDetails({ lead }) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="leads-basic-details">
      <Info
        title="Họ tên"
        body={<StudentLink student={lead} />}
        important
      />

      <Info
        title="Điện thoại"
        body={<PhoneNumber value={lead.phone} />}
      />

      <DateOfBirth dateOfBirth={lead.dateOfBirth} />

      <Info
        title="Ngày tiếp nhận"
        body={<FormatDate value={lead.createdAt} />}
      />

      <Info
        title="Nhân viên tiếp nhận"
        body={<Staff id={lead.marketingPerson} />}
      />

      <Info
        title="Parents name"
        body={lead.parentsName}
      />
      <Info
        title="Facebook"
        body={lead.facebook}
      />
      {lead.consultant ? (
        <Info
          title="Consultant"
          body={<Staff id={lead.consultant} />}
        />
      ) : null}
    </div>
  );
}
