import React, { useContext, useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { DataGrid } from "@mui/x-data-grid";
import HtTabs from "../../components/HtTabs";
import { CenterContext, StaffContext } from "../../../../Context";
import Info from "../../components/Info";
import SelectFromList from "../../components/SelectFromList";
import SetTargetDialog from "./SetTargetDialog";
import Search from "../../components/Search";



export default function ClassList(props) {


    const [classes, setClasses, error, loading] = useDB(`/htclasses?status=Active&status=Tutoring`)
  const { center } = useContext(CenterContext)
  
   


if (loading) return <Loading />;
if (error) return <Error />;

console.log(classes)

    const labels = center.locations.filter(l => l.name !== "All locations").map(l => `${l.name} (${ classes.filter(c => c.location === l._id).length })`)
    const components = center.locations.filter(l => l.name !== "All locations").map(l => <DrawTable classes={classes.filter(c=>c.location===l._id)} setClasses={setClasses}/>)

    return (<div>
        <HtTabs components={components} labels={labels} localStorage/>
</div>)
}

function DrawTable({ classes,setClasses }) {
    const {findStaffName} = useContext(StaffContext)
  const [teacher, setTeacher] = useState("")
  const [selectedClass, setSelectedClass] = useState(undefined)
  const [search,setSearch] = useState("")
    let rows = classes.filter(c => {
        if (!teacher) return true
        if (teacher === c.teacher) return true
        return false
    }).filter(c=> search==="" || c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map(c => ({ id: c._id, name: c.name,teacher: findStaffName(c.teacher),students: c.students.length, target: c.maxStudents, space: c.maxStudents ? c.maxStudents-c.students.length : "" }))
    //let teachers = [...new Set(classes.map(c=> ({_id: c.teacher._id, name: findStaffName(c.teacher._id)})))]
    let teachers = []
    for (let c of classes) {
        let t = teachers.find(t => t._id === c.teacher)
        if(!t) teachers.push({_id: c.teacher, name: `${findStaffName(c.teacher)} (${classes.filter(cl=>cl.teacher===c.teacher).length})`})
    }
    

    const columns = [
  { field: 'id', width: 0 },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,

        },
        {
    field: 'teacher',
    headerName: 'Teacher',
    width: 150,

        },
    {
    field: 'students',
    headerName: 'Students',
    width: 150,

  },{
    field: 'target',
    headerName: 'Target',
    width: 150,

        },
    {
    field: 'space',
    headerName: 'Space',
    width: 150,

  }]


  return <div /* style={{height:"100vh"}} */><Totals classes={classes} setTeacher={setTeacher} teachers={teachers} search={search} setSearch={setSearch}/>
    <DataGrid rows={rows} columns={columns} autoHeight initialState={{
    columns: {
      columnVisibilityModel: {
        id: false,
        },
     
    },
    }}
      onRowClick={r => setSelectedClass(classes.find(c=>c._id===r.id))}
    />
      {selectedClass ?
        <SetTargetDialog open={selectedClass} setOpen={setSelectedClass} update={htClass => {
        setClasses(prev=> [...prev.map(p=>p._id===htClass._id ? htClass : p)])
      }} />
        : null}
    </div>
}

function Totals({ classes, teachers, setTeacher, teacher,search,setSearch }) {
    let total = classes.reduce((a,b)=> a + b.students.length,0)
    let target = classes.reduce((a,b)=> b.maxStudents ? a + b.maxStudents : a+0,0)
    return <div style={{ display: "flex", gap: 20, marginBottom: 5 }}>
        <Info title="Students" body={total} style={{ width: 100 }} />
        <Info title="Target" body={target} style={{ width: 100 }}/>
        <Info title="Space" body={target - total} style={{ width: 100 }} />
      <SelectFromList choices={teachers} value={teacher} setValue={setTeacher} label="Teachers" size="small" style={{ width: 200 }} none />
      <Search search={search} setSearch={setSearch} size="small" />
    </div>
}