import React from "react";
import { Box, List, MenuItem, Button, TextField } from "@mui/material";
import LocationDetails from "./LocationDetails";
import NewLocationDialog from "./NewLocationDialog";

export default function Locations(props) {
  // const [locations, setLocations] = React.useState(props.init.center.locations);
  const { l, language } = props;
  const [selectedLocation, setSelectedLocation] = React.useState(
    props.center.locations[0]
  );
  const [openNewLocation, setOpenNewLocation] = React.useState(false);
  /*   const [render, setRender] = React.useState(0); */

  const closeNewLocation = () => {
    setOpenNewLocation(false);
  };

  const select = (location) => {
    console.log(location);
    setSelectedLocation(location);
  };

  return (
    <div>
      <SetLocationOfComputer {...props} />
      <div style={{ display: "flex" }}>
        <Box border={1} padding={2} borderRadius={5} borderColor={"#CCCCCC"}>
          <List>
            {props.center.locations.map((location, i) => (
              <MenuItem key={location._id} onClick={() => select(location)}>
                {location.name}
              </MenuItem>
            ))}
          </List>
          <Button variant="contained" onClick={() => setOpenNewLocation(true)}>
            Add Location
          </Button>
        </Box>
        <LocationDetails
          location={selectedLocation}
          setLocation={setSelectedLocation}
          center={props.center}
          setCenter={props.setCenter}
        />
        <NewLocationDialog
          open={openNewLocation}
          handleClose={closeNewLocation}
        />
      </div>
    </div>
  );
}

function SetLocationOfComputer(props) {
  const { l, language, center } = props;
  const [location, setLocation] = React.useState(
    localStorage.getItem("systemLocation") || ""
  );

  const updateLocation = (location) => {
    localStorage.setItem("systemLocation", location);
    setLocation(location);
  };

  return (
    <div style={{ display: "flex" }}>
      <h3>{l.locationOfThisComputer[language]}</h3>
      <TextField
        select
        value={location}
        onChange={(e) => updateLocation(e.target.value)}
      >
        {center.locations.map((location) => (
          <MenuItem key={location._id} value={location._id}>
            {location.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
