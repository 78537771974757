import React, { useContext } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import HtTabs from "../../components/HtTabs";
import { Button } from "@mui/material";
import SelectFromList from "../../components/SelectFromList";
import { CenterContext } from "../../../../Context";
import mut from "../../hooks/mut";
import replaceById from "../../hooks/replaceById";
import { format } from "date-fns";


export default function Humanresources(props) {

  const [status, setStatus] = React.useState(0)
  const [filter, setFilter] = React.useState("")
const [data, setData, error, loading] = useDB(`/user/status/${status}`);
const {center} = useContext(CenterContext)

if (loading) return <Loading />;
if (error) return <Error />;


  console.log(data);
  


  return (
    <div>
      <div>
        <Button onClick={() => setStatus(0)}>Current</Button>
        <Button onClick={() => setStatus(1)}>Stopped</Button>
        <SelectFromList
          choices={center.roles}
          value={filter}
          setValue={setFilter}
          style={{ width: 200 }}
          label="Filter by role"
          size="small"
          none
        />
      </div>
      <br></br>
      <table>
        {data
          .filter((s) => (filter === "" ? true : s.roles.includes(filter)))
          .map((s) => (
            <tr>
              <td> {s.name}</td>
              <td> {format(new Date(s.createdAt), "dd/MM/yyyy")}</td>
              <td>{s.roles.toString()}</td>
              <td>
                {status === 1 ? (
                  "Stopped"
                ) : (
                  <Button
                    onClick={async () => {
                      await mut("post", `/user/stop/${s._id}`);
                      replaceById(data, s.id, { ...s, status: 1 });
                    }}
                  >
                    Stop
                  </Button>
                )}
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
}
