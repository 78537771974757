import { net } from "../../../../../../database";

export default function makePayment(
  customerId,
  payment,
  setPayment,
  setReceipt,
  setDisabled
) {
  let error = false;
  payment.students.forEach((student) => {
    if (student.nextBillDate === "") {
      student.billDateError = true;
      error = true;
    } else {
      student.billDateError = undefined;
    }
  });

  if (error) {
    setPayment(payment);
    return;
  }
  net.post(`/customers/${customerId}/payments/add`, payment).then((result) => {
    setReceipt((prev) => result.receipt);
    setDisabled((prev) => false);
  });
  setDisabled(true);
}
