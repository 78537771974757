import React from "react";
import { Tab, Tabs, Box, Typography, Button, IconButton } from "@mui/material";
import { net } from "../../../../database";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Test from "./Test";
import HtTabs from "../../components/HtTabs";

export default function TestsTabs({ lead, setLeads, chooseTestTime }) {
  let { placementTests } = lead;
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (lead.placementTests && lead.placementTests.length > 0) {
      setValue(lead.placementTests.length - 1);
    } else {
      setValue(0);
    }
  }, [lead.placementTests.length]);

  const labels = placementTests.map((test, i) => `Test ${i + 1}`)
  const components = placementTests.filter(t=>t.students).map((test, i) => <Test
            lead={lead}
            setLeads={setLeads}    
            i={i}
            test={test}
    chooseTestTime={chooseTestTime} />)
  
 /*  if(components.length===0) components = [<div>Click + to add a test</div>] */


  return (
    <div className="leads-test-container">
      <HtTabs labels={labels} components={components} nextToTabs={<ButtonInTabs onClick={() => chooseTestTime(lead._id)} />} />
      {components.length===0 ? <div style={{padding:10}}>No tests scheduled, Click + to add a test</div>: null}
    </div>
  );
}

const ButtonInTabs = ({
 onClick

}) => {
  return (
    <IconButton
      color="success"
      variant="contained"
      sx={{ p: 0, ml: 1 }}
      onClick={onClick}>
      <AddCircleIcon />
    </IconButton>
  );
};
