import { format } from "date-fns";
import Info from "../../components/Info";
import SLink from "../../components/SLink";

export default function DrawAttendance(props) {
  const { at } = props;

  let cats = [];
  for (let c in at) {
    cats.push({ name: c, value: at[c] });
  }
  let color = "goldenrod";
  if (at.attendance === 3) color = "red";

  if (at.attendance > 1)
    return (
      <div style={{ color }}>
        <SLink id={at.student} /> {at.absentReason}
        <hr />
      </div>
    );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SLink id={at.student} />{" "}
        <div style={{ display: "flex", gap: 15 }}>
          <Info
            title="Started at"
            body={
              at.feedbackStarted ? (
                <div style={{ color: "green" }}>
                  {format(new Date(at.feedbackStarted), "dd/MM/yyyy h:mm aaa")}
                </div>
              ) : (
                <div style={{ color: "red" }}>Not started</div>
              )
            }
            style={{ width: 150 }}
          />
          <Info
            title="Completed at"
            body={
              at.feedbackComplete ? (
                <div style={{ color: "green" }}>
                  {format(new Date(at.feedbackComplete), "dd/MM/yyyy h:mm aaa")}
                </div>
              ) : (
                <div style={{ color: "red" }}>Not completed</div>
              )
            }
            style={{ width: 150 }}
          />
          <Info
            title="Sent at"
            body={
              at.feedbackSent ? (
                <div style={{ color: "green" }}>
                  {format(new Date(at.feedbackSent), "dd/MM/yyyy h:mm aaa")}
                </div>
              ) : (
                <div style={{ color: "red" }}>Not sent</div>
              )
            }
            style={{ width: 150 }}
          />
        </div>
      </div>
      <Info
        title="Homework"
        body={at.homeWorkNotes}
        empty="None"
      />
      <Info
        title="Feedback"
        body={at.notes}
        empty="None"
      />
      <hr />
    </div>
  );
}
