import { addDays } from "date-fns";

export function formatTime(intime) {
  var hours = parseInt(intime.substring(0, 2));
  var minutes = parseInt(intime.substring(3, 5));
  var ampm = "am";
  if (hours > 11) {
    ampm = "pm";
  }
  if (hours > 12) {
    hours = hours - 12;
  }
  if (minutes === 0) {
    minutes = "00";
  }
  return hours + ":" + minutes + " " + ampm;
}

export function currentTS() {
  const d = new Date();
  var a = "";
  a +=
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getDate() +
    " " +
    d.getHours() +
    ":" +
    d.getUTCMinutes() +
    ":" +
    d.getSeconds();
  return a;
}

export function currentDate() {
  const d = new Date();
  var a = "";
  a += d.getFullYear();
  a += `-`;
  if (d.getMonth() < 9) {
    a += `0${d.getMonth() + 1}`;
  } else {
    a += d.getMonth() + 1;
  }
  a += `-`;
  if (d.getDate() < 10) {
    a += `0${d.getDate()}`;
  } else {
    a += d.getDate();
  }

  return a;
}

export function formatPhone(phone) {
  if (phone) {
    var phonenumber =
      phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6, 4);
    return phonenumber;
  } else {
    return "None";
  }
}

export function formatDate(datein) {
  var tdate = datein.split("-");
  var gdate = tdate[2] + "/" + tdate[1] + "/" + tdate[0].substr(2, 2);
  return gdate;
}

export function formatDateTS(datein) {
  var adate = datein.substr(0, 10);
  var tdate = adate.split("-");
  var gdate = tdate[2] + "/" + tdate[1] + "/" + tdate[0].substr(2, 2);
  return gdate;
}

export function formatDateTime(datein) {
  var ret = formatDate(datein.substring(0, 10));
  ret += " " + formatTime(datein.substring(11, 18));
  return ret;
}

export function formatVND(numberin) {
  return Intl.NumberFormat().format(numberin);
}

export function updateClassNames(data) {
  Object.values(data.students).forEach(s => {
    s.classnames = "";
    if (s.classes) {
      Object.values(s.classes).forEach(cl => {
        const htclass = data.classes[cl.id];
        s.classnames += htclass.name;
      });
    }
  });
  return data;
}

export function getStudentImages(data) {
  var a = [];
  if (data.students.length > 0) {
    data.students.forEach(s => {
      if (s.image !== null) {
        a.push({ id: s.id, image: s.image });
      }
    });
  }
  return a;
}

export function uniqueSet(data,field) {
  if (typeof data === "array") return [...new Set(data)];
  if (typeof data === "object") return [...new Set(data.map(a => a[field]))]
  return false
}

export async function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

/**
 * 
 * @param {number | [number]} weekdays 
 * @param {number} month 
 * @param {number} year 
 */

export function weekdaysInMonth(days, month,year) {
  
  const d1 = new Date(year, month)
  const d2 = new Date(year, month + 1)
  let count = 0
  while (d1 < d2) {
    if (d1.getDay() === days) count++
    addDays(d1,1)
  }
  return count

}

/**
 * @description calculate the number of hours between 2 times with one decimal point
 * @param {string} startTime 
 * @param {string} endTime 
 */

export function calcHours(startTime, endTime) {
                let st = new Date("2022-01-01 " + startTime + ":00");
                let et = new Date("2022-01-01 " + endTime + ":00");
                let hours = et.getTime() - st.getTime();
                hours = hours / (1000 * 3600);

  hours = Math.round((hours + Number.EPSILON) * 100) / 100;
  return hours
}