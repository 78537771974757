import { Button, Rating } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { net } from "../../../../../database";
import "./message.css";
import ChatBox from "../../../components/ChatBox";
import { socket } from "../../../../../App";
import Staff from "../../../components/Staff";
import StudentLink from "../../../components/StudentLink";
import HtClass from "../../../components/HtClass";

import FormatDateTime from "../../../components/FormatDateTime";
import Info from "../../../components/Info";
var { format } = require("date-fns");

export default function Message(props) {
  const [actions, setActions] = React.useState(props.message.actions || "");

  let {
    _id,
    type,

    studentsTagged,
    customersTagged,
    classesTagged,
    importance,
    description,
    recordedBy,
    createdAt,
   
  } = props.message;
  let { messages, setMessages, user, style } = props;
  let who = props.message.who || undefined;

  const closeMessage = () => {
    net.post(`/messages/${_id}/close`, { actions }).then((result) => {
      console.log(result);
      if (result.sent) {
        props.removeMessage(_id);
      }
    });
  };

  const updateActions = (actions) => {
    if (actions === "") {
      return;
    }

    net.post(`/messages/${_id}/update`, { actions }).then((result) => {
      console.log(result);
      setMessages(messages.map((m) => (m._id === result._id ? result : m)));
    });
  };

  React.useEffect(() => {
    socket.on("message" + props.message._id, (newMessage) => {

      setMessages(
        messages.map((m) => (m._id === newMessage._id ? newMessage : m))
      );
      return () => {
        socket.off("message" + props.message._id);
      };
    });
  });

  return (
    <div className="message" style={style}>
      <div
        className="message-heading"
        {...(type === "Complaint" && {
          style: {
            background:
              "linear-gradient(80deg, rgba(2,0,36,1) 0%, rgba(93,0,30,1) 0%, rgba(251,0,77,1) 100%)",
          },
        })}
      >
        <div>
          {format(new Date(createdAt), "EEEE dd/MM/yyyy h:mm aaa")} {type}{" "}
          recorded by {<Staff id={recordedBy} />}{" "}
          {who ? <ShowWho who={who} /> : ""}
        </div>
        <Rating value={importance} className="rating" readOnly />
      </div>
      <div className="information">
        <div style={{ minWidth: 200, maxWidth: 200, marginRight: 10 }}>
          <ShowTags tags={customersTagged} tagName="Customer" link="customer" />
          {studentsTagged.map((student, i) => (
            <div key={student + i}>
              <StudentLink
                student={student}
                style={{ fontSize: 14 }}
                showClasses={true}
              />
            </div>
          ))}
          {/*  <ShowTags tags={classesTagged} tagName="Class" link="classes" /> */}
          {classesTagged.map((htClass, i) => (
            <div key={htClass + i}>
              <HtClass htClass={htClass} style={{ fontSize: 14 }} />
            </div>
          ))}
        </div>
        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          <div className="smText">Description</div>
          <div className="message-description">{description}</div>
        </div>
        <div style={{ marginRight: 10 }}>
          <ChatBox
            message={props.message}
            user={user}
            setMessages={setMessages}
            messages={messages}
            /* staff={staff} */
          />
        </div>
        <div className="close-message-button">
            <CloseButton closeMessage={closeMessage} message={props.message} />
        </div>
      </div>
    </div>
  );
}


function CloseButton(props) {
  const { closeMessage, message } = props
  
  if (message.status === 1) return (
    <div style={{ fontSize: 12, width: 150 }}>
      <Info title="Closed by" body={<Staff id={message.closedBy} />} />
      <div>
        <FormatDateTime value={message.closedTimeStamp} />
      </div>
    </div>
  );

  return    <Button
            sx={{ width: 100 }}
            onClick={() => closeMessage()}
            disabled
            {...(message.chats.length > 0 && {
              disabled: false,
            })}
          >
            Close {message.type}
          </Button>
}


function ShowWho(props) {
  let { who } = props;
  return (
    <React.Fragment>
      allocated to{" "}
      {who.map((w, i) => (
        <React.Fragment key={w}>
          <Staff id={w} />
          {i < who.length - 1 ? ", " : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function ShowTags(props) {
  let { tags, tagName, link } = props;

  if (tags.length < 1) return <div></div>;
  return (
    <div>
      <div className="smText">{tagName}</div>
      {tags.map((tag) => (
        <div key={tag._id}>
          <Link className="neat" to={`/${link}/${tag._id}`}>
            {tag.name ? tag.name : tag.phone}
          </Link>
        </div>
      ))}
    </div>
  );
}
