import React, { useContext, useEffect, useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { format } from "date-fns";
import { net } from "../../../../database";
import HtTabs from "../../components/HtTabs";
import MonthChoice from "../../components/MonthChoice";
import DrawTitles from "./DrawTitles";
import DrawContact from "./DrawContact";
import SelectFromList from "../../components/SelectFromList";


export default function MonthView(props) {
  const [contacts, setContacts, error, loading] = useDB(`/marketing-contacts`);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [showDate,setShowDate] = useState(new Date().toISOString().substring(0,10))

 /*  useEffect(() => {
    let int = setInterval(async () => {
      const results = await net.get(`/marketing-contacts`);
      setContacts(results);
    }, 60000);
    return () => clearInterval(int);
  }, []); */

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!contacts) return <div></div>;

  const dates = [
    ...new Set(
      contacts
        .filter(
          (c) =>
            new Date(c.localDate).getFullYear() === year &&
            new Date(c.localDate).getMonth() === month
        )
        .sort()
        .reverse()
        .map((c) => c.localDate)
    ),
  ];

const labels = dates.map((d) => ({
  _id: d,
  name: `${format(new Date(d), "do")}, ${
    contacts.filter((c) => c.localDate && c.localDate === d).length
  } numbers, ${
    contacts.filter((c) => c.localDate && c.localDate === d && c.testDate).length
  } tests`,
}));

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: 20,
          paddingBottom: 20,
          marginBottom: 20,
          borderBottom: "1px solid #ccc",
        }}
      >
        <MonthChoice
          year={year}
          setYear={setYear}
          month={month}
          setMonth={setMonth}
        />
        <SelectFromList
          choices={labels}
          value={showDate}
          setValue={setShowDate}
          size="small"
          style={{ width: 300 }}
        />
      </div>
      <DrawTab localDate={showDate} contacts={contacts} setContacts={setContacts}/>
    </div>
  );
}

function DrawTab(props) {
  const { contacts, localDate, setContacts } = props;
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  return (
    <div>
      <h2>
        {new Date(localDate).toLocaleDateString(undefined, options)},{" "}
        {contacts.filter((c) => c.localDate === localDate).length} numbers,{" "}
        {
          contacts.filter(
            (c) => c.localDate && c.localDate === localDate && c.testDate
          ).length
        }{" "}
        tests,{" "}
        {
          contacts.filter(
            (c) =>
              c.localDate &&
              c.localDate === localDate &&
              c.testDate &&
              c.result &&
              c.result === "New student"
          ).length
        }{" "}
        new customer
        {
          contacts.filter(
            (c) =>
              c.localDate &&
              c.localDate === localDate &&
              c.testDate &&
              c.result &&
              c.result === "New student"
          ).length===1 ? "" : "s"}
      </h2>
      <DrawTitles />
      {contacts
        .filter((c) => c.localDate === localDate)
        .map((c) => (
          <DrawContact
            contact={c}
            setContacts={setContacts}
            key={c._id}
          />
        ))}
    </div>
  );
}
