import React from "react";

import PaymentsCosts from "./components/PaymentsCosts";
//import GenerateBills from "./components/GenerateBills";

export default function Money(props) {
  return (
    <div>
      <PaymentsCosts
        /*  center={props.center}
        l={props.l}
        language={props.language} */
        {...props}
      />
      {/*  <GenerateBills /> */}
    </div>
  );
}
