import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { net } from "../../../../database";
import Message from "../home/components/Message";
import Loading from "../../Loading";

export default function ManageMessages(props) {
  const { l, language } = props;
  const [showClosed, setShowClosed] = React.useState("open");
  const [showAllStaff, setShowAllStaff] = React.useState("mystaff");
  const [messages, setMessages] = React.useState(undefined);

  const [staff, setStaff] = React.useState(undefined);

  React.useEffect(() => {
    net
      .get(`/manage/messages/${showClosed}/${showAllStaff}`)
      .then((messages) => {
        setMessages(messages);
      });
    net.get(`/users`).then((res) => setStaff(res));
  }, [showClosed, showAllStaff]);

  const removeMessage = (message) => {
    setMessages(messages.filter((m) => m._id !== message));
  };
  if (!staff || !messages) return <Loading />;
  console.log(staff);
  return (
    <div>
      <h1>{l.messages[language]}</h1>
      <div style={{ display: "flex", marginBottom: 5 }}>
        <ShowToggleButtons
          value={showClosed}
          setValue={setShowClosed}
          buttons={[
            { label: l.open[language], value: "open" },
            { label: l.closed[language], value: "closed" },
          ]}
        />
        <ShowToggleButtons
          value={showAllStaff}
          setValue={setShowAllStaff}
          buttons={[
            { label: l.myStaff[language], value: "mystaff" },
            { label: l.allStaff[language], value: "allstaff" },
          ]}
        />
      </div>

      <DrawMessages
        messages={messages}
        removeMessage={removeMessage}
        {...props}
        staff={staff}
      />
    </div>
  );
}

function ShowToggleButtons(props) {
  const { value, setValue, buttons } = props;
  return (
    <div style={{ marginRight: 10 }}>
      <ToggleButtonGroup
        value={value}
        onChange={(e) => setValue(e.target.value)}
        size="small"
      >
        {buttons.map((button) => (
          <ToggleButton key={button.label} value={button.value}>
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

function DrawMessages(props) {
  let { messages, l, language, staff } = props;
  console.log(staff);
  if (messages.length === 0) return <div>{l.noMessagesToShow[language]}</div>;

  messages.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));

  return (
    <div>
      {messages.map((message) => (
        <Message message={message} key={message._id} {...props} staff={staff} />
      ))}
    </div>
  );
}
