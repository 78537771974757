import React from "react";
import DayOfWeek from "../../../components/DayOfWeek";
import Delete from "../../../components/Delete";
import { pullAt } from "lodash";


export default function DrawTimeTable(props) {
 const { classDay, setNewClass, index,allowDelete } = props;
 const { day, startTime, endTime, room } = classDay;

 return (
   <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
     <div style={{ width: 100 }}>
       <DayOfWeek day={day} />
     </div>
     <div>
       {startTime} - {endTime}
     </div>
     <div>{room}</div>
     <div style={{ flexGrow: 1 }}></div>
         {allowDelete ? <Delete
             action={() => {
                 setNewClass((prev) => ({
                     ...prev,
                     classDays: pullAt(prev.classDays, index),
                 }));
             }}
         /> : null}
   </div>
 );
}