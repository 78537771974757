import React from "react";
import ClassButtons from "./ClassButtons";
import { net } from "../../../database";
import Loading from "../../content/Loading";
import Stats from "./Stats";
import useDB from "../../content/hooks/useDB";

export default function Home(props) {
  const {  user } = props;
  const [classes, setClasses, error, loading] = useDB("/todays-classes")

  /* React.useEffect(() => {
    net.get("/myclassestoday").then((res) => {
      console.log(res)
      setMakeUpClasses(res.makeUpClasses);
      setClasses(res.classes);
    });
  }, []); */
 
  if (loading) return <Loading />;
  return (
    <div>
      {" "}
      <div style={{ paddingLeft: 10, paddingRight: 10, textAlign: "center" }}>
        <h3>Hello {user.name}</h3>
        <ClassButtons classes={classes} {...props} />
     
        {user.roles.includes("Owner") ? <Stats /> : ""}
       {/*   {user.roles.includes("Owner") ? <Upload /> : ""} */}
      </div>
    </div>
  );
}
