import React from "react";
import { Link } from "react-router-dom";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { format } from "date-fns";

export default function Care(props) {
  const [care, setCare] = React.useState(undefined);
  /*  const [studentsWithNoClass, setStudentsWithNoClass] = React.useState([]);
  const [changedStudents, setChangedStudents] = React.useState([]);
  const [followUpStudents, setChangedStudents] = React.useState([]);
 */
  React.useEffect(() => {
    net.get("/care").then((care) => {
   
      setCare(care);
    });
  }, []);

  if (!care) return <Loading />;
  let {
    newStudents,
    changedStudents,
    followUpStudents,
    studentsWithNoClass,
    pendingStudents,
  } = care;
  return (
    <div>
      <h1>Customer Care</h1>
      <h3>New Students Today</h3>
      {newStudents.map((student) => (
        <div key={student._id}>
          <Link to={`/student/${student._id}`} className="neat">
            {student.name} {student.classes.map((c) => c.name).toString()}
          </Link>
        </div>
      ))}
      <h3>Students needing follow up</h3>
      {followUpStudents.map((student) => (
        <div key={student._id}>
          <Link to={`/student/${student._id}`} className="neat">
            {student.name} {student.classes.map((c) => c.name).toString()}
          </Link>
        </div>
      ))}
      <h3>Students moved to new class today</h3>
      {changedStudents.map((student) => (
        <div key={student._id}>
          <Link to={`/student/${student._id}`} className="neat">
            {student.name} {student.classes.map((c) => c.name).toString()}
          </Link>
        </div>
      ))}
      <h3>Pending students due to return</h3>
      {pendingStudents.map((student) => (
        <div key={student._id} className="dflex-sb">
          <Link to={`/student/${student._id}`} className="neat">
            {student.name}{" "}
          </Link>
          <div>{student.classes.map((c) => c.name).toString()}</div>
          <div>
            {format(
              new Date(student.statusHistory[0].returnDate),
              "dd/MM/yyyy"
            )}
          </div>
        </div>
      ))}
      <h3>Students not in a class</h3>
      {studentsWithNoClass.map((student) => (
        <div key={student._id}>
          <Link to={`/student/${student._id}`} className="neat">
            {student.name}
          </Link>
        </div>
      ))}
    </div>
  );
}
