import React from "react";

export default function Info({ title, body, important,style={}, empty="Unknown" }) {
 
  return (
    <div style={style}>
      <div className="small-text">{title}</div>
      <div className="leads-basic-subinfo" {...(important && {
     style : {fontWeight: 600} 
      })}>
        {body===undefined ? empty : body}
      </div>
    </div>
  );
}
