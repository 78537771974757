import React, { useContext, useState } from "react";

import { Delete, Edit, SwapHoriz } from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Attendance from "./Attendance";

import HtClass from "../../../components/HtClass";
import { ClassesContext } from "../../../../../Context";

import Weekday from "./Weekday";
import Time from "../../../components/Time";
import DDialog from "../../../components/DDialog";

export default function ShowClasses(props) {

  const {student,setStudent,open,setOpen} = props
  const [remove, setRemove] = useState(false);
  const [classToRemove,setClassToRemove] = useState(undefined)
  const { findClasses,findClassName } = useContext(ClassesContext)
  const classes = student.classes ? findClasses(student.classes) : []

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <IconButton
          onClick={() => setOpen(true)}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </div>
      {classes.map((c) => (
        <div key={c._id} style={{marginTop:10, border:"1px solid #ccc", borderRadius:5, padding:10}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
              <HtClass htClass={c._id} link style={{ fontSize: 18}} />
            <div style={{display:"flex", gap:5}}>
              <Tooltip title="Change student to another class">
                <IconButton
                  color="info"
                  onClick={() => props.setChangeOpen(c)}
                  sx={{ padding: 0.5 }}
                >
                  <SwapHoriz />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Days of Study">
                <IconButton
                  color="warning"
                  onClick={() => props.setEditOpen(c)}
                  sx={{ padding: 0.5 }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove student from class">
                <IconButton
                  color="error"
                  onClick={() => { setRemove(true); setClassToRemove(c) }}
                  sx={{ padding: 0.5 }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {c.classDays
            .filter((cd) => !student.doesntStudy.includes(cd._id))
            .map((cd) => (
              <div
                style={{
                  display: "flex",
                  paddingRight: 5,
                  paddingLeft: 5,
                  fontSize: 14,
                }}
                key={cd._id}
              >
                <div>
                  <Weekday id={cd.day} />
                </div>
                <div style={{ flexGrow: 1 }}></div>
                <Time time={cd.startTime} /> - <Time time={cd.endTime} />
              </div>
            ))}
        </div>
      ))}
      <Attendance
        student={student}
        classes={classes}
      />
      {classToRemove ?
        <DDialog title={`Remove ${student.name} from ${classToRemove.name}`}
          required={["reason"]} fields={[{ name: "reason", label: "Reason for leaving class" }, { name: "_id", value: classToRemove._id, type: "hidden" }]}
          open={remove}
          setOpen={setRemove}
          ok="Remove"
          route={`/student/${student._id}/classes/remove`}
          req="post"
          newData={(classes) => { setStudent(prev => ({ ...prev, classes })); setClassToRemove(undefined); setRemove(false) }}
        
        /> : null}
    </div>
  );
}
