
/**
 * 
 * @param {Array} labels labels and widths in px for the headings [{label,width}]
 * @param {Number} fontSize optional, the size in px for these headers
 * @param {String} color optional, the color of the text
 * @returns 
 */

export default function Headings({ labels, fontSize=11, color = "#888" }) {
  return (
    <div style={{ display: "flex", fontSize, color }}>
      {labels.map((l) => (
        <div style={{ width: l.width,display:"flex",justifyContent:"center" }} key={l.label}>{l.label}</div>
      ))}
    </div>
  );
}
