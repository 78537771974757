import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";

export default function NewLocationDialog(props) {
  const [location, setLocation] = React.useState({ name: "" });

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Add a new location to your center</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name of new location"
          type="text"
          fullWidth
          variant="standard"
          value={location.name}
          onChange={(e) => setLocation({ ...location, name: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(location)}>Cancel</Button>
        <Button onClick={() => props.handleClose(location)}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
