import React from "react";
import BasicDetails from "./BasicDetails";
import Notes from "./Notes";
import LeadClass from "./LeadClass";
import TuitionCalculator from "../student/components/TuitionCalculator";
import CalculateTuitionForClasses from "../../components/CalculateTuitionForClasses";
import { net } from "../../../../database";
import { Stack, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function StageThree({ leads, setLeads }) {
  //const {l,language} = props;

  return (
    <div>
      {leads.map((lead) => (
        <div className="stage-two-box">
          <BasicDetails lead={lead} />
          <Notes lead={lead} setLeads={setLeads} style={{ width: 400 }} />
          <LeadClass lead={lead} />
          {lead.firstTuition ? (
            "Tuition added"
          ) : (
            <CalculateTuitionForClasses lead={lead} />
          )}
          <div style={{ flexGrow: 1 }}></div>
          <Decision lead={lead} setLeads={setLeads} />
        </div>
      ))}
    </div>
  );
}

function Decision({ setOpen, lead, setLeads, setSelected }) {
  const backToTesting = () => {
    net.post(`/leads/${lead._id}`, { stage: 2 }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };

  const backToLead = () => {
    net.post(`/leads/${lead._id}`, { stage: 1 }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };
  const backToData = () => {
    net
      .post(`/leads/${lead._id}`, {
        stage: 1,
        status: "Lead",
        statusChanged: new Date(),
      })
      .then((res) => {
        setLeads((prev) => prev.filter((l) => l._id !== lead._id));
      });
  };
  return (
    <div className="leads-decision">
      <Stack>
        <Button
          color="error"
          onClick={() => {
            backToTesting();
          }}
          startIcon={<ArrowBackIcon />}
        >
          Back to testing
        </Button>
        <Button
          color="error"
          onClick={() => backToLead()}
          startIcon={<ArrowBackIcon />}
        >
          Back to potential
        </Button>
        <Button
          color="info"
          onClick={() => backToData()}
          startIcon={<ArrowBackIcon />}
        >
          Back to data
        </Button>
      </Stack>
    </div>
  );
}
