import React from "react";

/**
 * 
 * @param {number} value the value of the box
 * @param {number} width default 30
 * @param {number} height default 30  
 * @returns 
 */

export default function AttendanceBox(props) {
    const { width = 30, height = 30, value } = props
  const backgrounds = [
    "linear-gradient(229deg, #A9F803 0%, #84C200 100%)",
    "linear-gradient(229deg, #04FB58 0%, #00AE1C 100%)",
    "linear-gradient(229deg, #E4E901 0%, #D3B202 100%)",
    "linear-gradient(229deg, #F2002C 0%, #A9030D 100%)",
    "linear-gradient(229deg, #eee 0%, #fff 100%)",
  ];
  let background = ""
  if (value === -1) {
    background=backgrounds[0]
  } else {
    background=backgrounds[value]
  }

return (
  <div
    style={{
      width,
      height,
      background,
      border: "1px solid rgba(147, 147, 147, 0.47)",
      boxShadow: "2px 2px 3px -2px #939393",
    }}
  >
  </div>
);
}