import React, { useContext } from "react";
import { MenuItem, TextField } from "@mui/material";
import { StaffContext } from "../../../Context";

export default function TeacherChoice(props) {
  const { staff } = useContext(StaffContext)
  return (
    <div>
      <TextField
        select
        label="Teacher"
        {...props}
        {...(props.width && { width: props.width })}
      >
        {staff.map((staffm) => (
          <MenuItem key={staffm._id} value={staffm._id}>
            {staffm.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
