import React from "react";
import { net } from "../../../../../database";
import { format } from "date-fns";
import ChooseDate from "../../../components/ChooseDate";

export default function PendingReturnDate(props) {
  const { value, setValue, studentId } = props;

  const updatePendingDate = (value) => {
    net.post(`/students/${studentId}/update-pending-date`, {
      pendingDate: format(value, "yyyy-MM-dd"),
    });
    setValue((prev) => value);
  };

  return <ChooseDate value={value} setValue={updatePendingDate} noPast />;
}
