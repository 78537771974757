import React, { useContext } from "react";
import { UserContext } from "../../../../Context";
import { net } from "../../../../database";
import Loading from "../../Loading";
import StudentLink from "../../components/StudentLink";
import { Button } from "@mui/material";
import VND from "../../components/VND";
import FormatDate from "../../components/FormatDate";


export default function ReadyForClass(props) {
    let { user } = useContext(UserContext)
 
    const [rfc,setRfc] = React.useState(undefined)
    React.useEffect(() => {
        net.get(`/ready-for-class/${user._id}`).then(res => setRfc(res))
    },[user]);
    if (!rfc) return <Loading />
    console.log(rfc)
    const finish = (id) => {
        net.post(`/ready-for-class/${id}`, { readyForClass: 2 }).then(res => {
            setRfc(prev=>prev.filter(p=>p._id!==res._id))
        })
    }
    
    return (
      <div>
        <h3>Students requiring bills and care</h3>
        {rfc.map((student) => (
          <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center", marginBottom:5 }}>
                <div style={{ display: "flex" }}>
                    
                    <StudentLink
              student={student}
                        showClasses
                        style={{width:300}}
                    />
                    <div style={{width:250,display: "flex"}}>
                        <div style={{marginRight:10}}>First class:</div>  <FormatDate value={student.firstClassDate} />
                        </div>
                    <Billed bills={student.bills} />
                    <Attendance attendance={student.attendance} />
                    </div>
            <Button
              color="success"
                    variant="contained"
                    size="small"
                    onClick={()=> finish(student._id)}
            >
              Complete
            </Button>
          </div>
        ))}
      </div>
    );
}

function Billed({ bills }) {
    
    if(!bills || bills.length===0) return <div style={{color:"red",width:250}}>No bills</div>

    return <div style={{color:"green",width: 250}}>Billed: <VND value={bills.reduce((a, b) => a + b.amount, 0)} /></div>
}

function Attendance({ attendance }) {
    
    let attended = attendance.filter(a => a.attendance < 2).length
    let missed = attendance.filter((a) => a.attendance > 1).length;
    if (!attendance || attendance.length === 0) return <div style={{ color: "red" }}>No attendance</div>
    
    return <div>Attended: {attended}, Missed: {missed}</div>

}