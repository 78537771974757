import React from "react";
import AttendanceWidget from "./AttendanceWidget";
import { net } from "../../../../../database";
import Loading from "../../../Loading";
import MonthChoice from "../../../components/MonthChoice";
import HtClass from "../../../components/HtClass";
import Lang from "../../../components/Lang";


export default function Attendance(props) {
  let { student } = props;
  let [year, setYear] = React.useState(new Date().getFullYear());
  let [month, setMonth] = React.useState(new Date().getMonth());
  const [attendance, setAttendance] = React.useState(undefined);

  React.useEffect(() => {
    net
      .get(`/students/${student._id}/attendance`)
      .then((res) => setAttendance((prev) => res));
  }, [student]);

  if (!attendance) return <Loading />;

  if (attendance.length < 1) return <div></div>;
  console.log(attendance)
  let atThisMonth = attendance.filter((a) => new Date(a.classDate).getFullYear() === year && new Date(a.classDate).getMonth() === month);

  let htClassIds = [...new Set(atThisMonth.map((at) => at.htClass))];

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", margin: 10 }}
      >
        <div className="attendance-title">
          <h3><Lang w="attendance"/></h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MonthChoice month={month} setMonth={setMonth} year={year} setYear={setYear} />
        </div>
      </div>

      {htClassIds.map((htClass) => (
        <div key={htClass} className="attendance-class">
          <div className="attendance-class-title"><HtClass htClass={htClass}/></div>
          <div className="attendance-widgets-layout">
            {atThisMonth.filter(a=>a.htClass===htClass).map((attendance) => (
              <AttendanceWidget
                key={`attendance${attendance._id}`}
                attendance={attendance}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
