import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";

export default function AddColdDataDialog(props) {
  const { open, setOpen } = props;
  const [data, setData] = React.useState({ user: props.user._id });

  const addColdData = () => {
    net.post("/sales/cold-data", data).then((res) => {
      setOpen(false);
      setData({});
    });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add new cold data</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {fields.map((field) => (
            <TextField
              label={field.label}
              type={field.type}
              value={data[field.name] ? data[field.name] : ""}
              onChange={(e) =>
                setData({ ...data, [field.name]: e.target.value })
              }
              {...(field.type === "date" && {
                InputLabelProps: { shrink: true },
              })}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={addColdData}>
          Add student
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const fields = [
  { name: "name", type: "text", label: "TÊN HỌC SINH" },
  { name: "phone", type: "text", label: "SỐ ĐIỆN THOẠI" },
  { name: "dateOfBirth", type: "date", label: "NĂM SINH" },
  { name: "school", type: "text", label: "TRƯỜNG" },
  {
    name: "likeEnglishAtHome",
    type: "text",
    label: "CON Ở NHÀ CÓ THÍCH HỌC TIẾNG ANH KHÔNG?",
  },
  {
    name: "parentsWishes",
    type: "text",
    label: "MONG MUỐN CỦA PHỤ HUYNH KHI CHO CON ĐI HỌC TIẾNG ANH?",
  },
  {
    name: "knowAboutHTExplore",
    type: "text",
    label:
      "ANH/CHỊ CÓ BIẾT ĐẾN TRUNG TÂM TIẾNG ANH HT EXPLORE Ở ĐÔNG NGẠC KHÔNG?",
  },
];
