import React from "react";
import DrawNewStudent from "./DrawNewStudent";
import VND from "../../components/VND";
import Lang from "../../components/Lang";
import Loading from "../../Loading";
import StudentsAtLocation from "./StudentsAtLocation";
import Error from "../../components/Error";
import useDB from "../../hooks/useDB";
import Month from "../../components/Month";


export default function NewStudents(props) {
  let { year, month } = props

  const [newStudents, setNewStudents, error, loading] = useDB(
    `/new-students/${year}/${month}`
  );

  if (loading) return <Loading />
  if (error) return <Error />

  console.log(newStudents)

     let revenue = 0;
  let payments = 0;
  let noAttendance = 0
  let noBills = 0
     for (let student of newStudents) {
       revenue += student.bills.reduce((a, b) => a + b.amount, 0);
       payments += student.bills
         .filter((b) => b.paymentId)
         .reduce((a, b) => a + b.amount, 0);
       if(!student.firstAttendance) noAttendance++
        if ((!student.bills || student.bills.length === 0) && student.firstAttendance) noBills++
     }


   return (
     <div>
       <h3 className="dflex-sb">
         <div>
           New students in <Month value={month + 1} /> ({newStudents.length}){" "}
         </div>
         <StudentsAtLocation students={newStudents} />
         <div style={{ fontSize: 14, fontWeight: "normal", textAlign:"right" }}>
           <div>
             Revenue: <VND value={revenue} />, Payments:{" "}
             <VND value={payments} />
           </div>
           <div>
             No attendance: {noAttendance}, No bills {noBills}
           </div>
         </div>
       </h3>

       <div className="new-lost-students-table-title">
         <div style={{ width: 300 }}>
           <Lang w="name" />
         </div>
         <div style={{ width: 150 }}>Customer</div>
         <div style={{ width: 100 }}>
           <Lang w="status" />
         </div>

         <div style={{ width: 150 }}>Enrolled</div>
         <div style={{ width: 150 }}>First Attendance</div>
         <div style={{ width: 150 }}>Bills</div>
         <div style={{ width: 150 }}>Consultant</div>
         <div style={{ width: 150 }}>Ready for class</div>
       </div>

       {newStudents.map((student) => (
         <DrawNewStudent
           student={student}
           key={student._id}
         />
       ))}
     </div>
   );
}