import React from "react";
import NumberFormat from "react-number-format";
import { net } from "../../../../database";
import Loading from "../../Loading";
import MonthPicker from "./MonthPicker";
import SalaryDetailDialog from "./SalaryDetailDialog";
import "./salaries.css";
import Location from "../../components/Location";
import FormatDate from "../../components/FormatDate";
import Time from "../../components/Time";
import StaffChooser from "../../components/StaffChooser";
import StaffChooserContext from "../../components/StaffChooserContext";
import { Button } from "@mui/material";

export default function Salaries(props) {
  const { center } = props;
  const [shifts, setShifts] = React.useState(undefined);
  const [managersO, setManagersO] = React.useState(undefined);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [render, setRender] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [update,setUpdate] = React.useState(0)
  

  React.useEffect(() => {
    net.get(`/salaries/${year}/${month}`).then((res) => {
      setManagersO(res.managers);
      setShifts(res.shifts);
     
      //  setRender(render + 1);
    });
  }, [month, year, update]);

  if (!shifts) return <Loading />;
  console.log(shifts)
  let noManager = [];

  let managers = [...managersO];

  for (let manager of managers) {
    manager.staff = [];
  }

  let noUser = []

  for (let shift of shifts) {
    if (shift.user === null) {
      noUser.push(shift)
    } else {
      if (shift.user.reportsTo) {
        let manager = managers.find((m) => m._id === shift.user.reportsTo);
        if (!manager.staff) manager.staff = [];
        let staff = manager.staff.find((s) => s._id === shift.user._id);
        if (staff) {
          if (!staff.shifts) staff.shifts = [];
          staff.shifts.push(shift);
        } else {
          manager.staff.push({
            _id: shift.user._id,
            name: shift.user.name,
            salary: shift.user.salary,
            shifts: [shift],
          });
        }
      } else {
        let staff = noManager.find((s) => s._id === shift.user._id);
        if (staff) {
          if (!staff.shifts) staff.shifts = [];
          staff.shifts.push(shift);
        } else {
          noManager.push({
            _id: shift.user._id,
            name: shift.user.name,
            salary: shift.user.salary,
            shifts: [shift],
          });
        }
      }
    }
  }

  managers.push({
    name: "No manager",
    staff: noManager,
  });

console.log(noUser)
  return (
    <div>
      <DrawTitle
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        {...props}
      />
      <div style={{color:"red"}}>Shifts with no staff member</div>
       {noUser.map(shift => 
        <DrawNoUser shift={shift} setUpdate={setUpdate} />)
      }
      {managers.map((manager) => (
        <DrawManager
          manager={manager}
          setOpen={setOpen}
          setSelectedUser={setSelectedUser}
        />
      ))}
      {/*   <DrawManager manager={noManager} noManager /> */}
      <SalaryDetailDialog
        open={open}
        setOpen={setOpen}
        shifts={shifts.filter((shift) => shift.user && shift.user._id === selectedUser)}
        center={center}
      />
    </div>
  );
}

function DrawTitle(props) {
  return (
    <div className="dflex-sb">
      <h1>Part Time Salaries</h1>
      <MonthPicker {...props} />
    </div>
  );
}
function DrawManager(props) {
  let { manager, noManager } = props;

  if (!manager.staff) return <div></div>;
  if (manager.staff && manager.staff.length < 1) return <div></div>;
  let total = 0;
  manager.staff.forEach((s) => {
    s.shifts.forEach((shift) => {
      total += shift.hours * s.salary;
    });
  });
  return (
    <div>
      <h2>{noManager ? "No manager" : manager.name} </h2>
      <hr />
      <div>
        <div style={{ display: "flex", fontSize: 11 }}>
          <div style={{ width: 200 }}>Name</div>
          <div style={{ width: 200 }}>Salary</div>
          <div style={{ width: 200 }}>Shifts</div>
          <div style={{ width: 200 }}>Hours</div>
          <div style={{ flexGrow: 1, textAlign: "right" }}>Total Amount</div>
        </div>
        {manager.staff.map((staffMember) => (
          <div>
            {" "}
            <DrawStaff member={staffMember} {...props} />
            <hr />
          </div>
        ))}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Total</h3>
        <h3>
          <NumberFormat value={total} thousandSeparator displayType="text" />
        </h3>
      </div>
      <hr></hr>
    </div>
  );
}

function DrawStaff(props) {
  let { member, setOpen, setSelectedUser } = props;
  let totalHours = member.shifts.reduce((a, b) => a + b.hours, 0);
  let totalSalary = totalHours * member.salary;
  return (
    <div
      style={{ display: "flex" }}
      className="staff"
      onClick={() => {
        setSelectedUser(member._id);
        setOpen(true);
      }}
    >
      <div style={{ width: 200 }}>{member.name}</div>
      <div style={{ width: 200 }}>
        <NumberFormat
          value={member.salary}
          displayType="text"
          thousandSeparator
        />
      </div>
      <div style={{ width: 200 }}>{member.shifts.length}</div>
      <div style={{ width: 200 }}>{totalHours}</div>
      <div style={{ flexGrow: 1, textAlign: "right" }}>
        <NumberFormat
          value={parseInt(totalSalary)}
          displayType="text"
          thousandSeparator
        />
      </div>
    </div>
  );
}

function DrawNoUser({ shift,setUpdate }) {
  const [user,setUser] = React.useState("")
 console.log("nouser")
  return (
    <div style={{ display: "flex", alignItems:"center",gap: 20 }}>
      <div>
        <Location id={shift.location} />
      </div>
      <div>
        <FormatDate value={shift.date} />
      </div>
      <div>
        Start: <Time time={shift.startTime} />
      </div>
      <div>
        End: <Time time={shift.endTime} />
      </div>
      <StaffChooserContext
        label="Person"
        style={{ width: 150 }}
        value={user}
        setValue={setUser}
        size="small"
      />
      <Button
        onClick={() => {
          net
            .post(`/shifts/${shift._id}`, { user })
            .then((res) => setUpdate((prev) => prev + 1));
        }}
      >
        Choose
      </Button>
    </div>
  );
}
