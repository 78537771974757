import { MenuItem, TextField } from "@mui/material";
import React from "react";

export default function Select(props) {
  const { value, setValue, items } = props;
  if (typeof items[0]) {
    return (
      <TextField
        select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      >
        {items.map((item, i) => (
          <MenuItem value={item} key={item + i}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  return (
    <TextField
      select
      value={value}
      onChange={(e) => setValue(e.target.value)}
      {...props}
    >
      {items.map((item) => (
        <MenuItem value={item._id} key={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
