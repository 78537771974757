import React, { useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CenterContext } from "../../../../Context";

export default function StudentsLostAtLocation({ students }) {
    let locs = {}
  let total = 0
  const { center } = useContext(CenterContext);
    for (let student of students) {
        for (let htc of student.classes) {
            if (locs[htc.location]) {
                locs[htc.location]++
                total++
            } else {
                locs[htc.location] = 1
                total++
            }
        }
    }
    
    /*   return (<div>{Object.keys(locs).map(l => <>  <LocationF id={l} /> ({locs[l]}, {getPercent(total, locs[l])}%)</>)}{" "}</div>) */
  return (
    <div style={{width:810}}>
      <DataGrid
        style={{
          height: 148,
          marginBottom: 20,
        }}
        rows={buildRows(students, center)}
        columns={cols}
        hideFooter
        rowHeight={30}
        /*  slots={{ toolbar: GridToolbar }} */
      />
    </div>
  );
}

function getPercent(total, amount) {
    return Math.round((amount / total)*100)
}

const cols = [
  {
    field: "location",
    headerName: "Location",
    width: 200,
  },
  {
    field: "active",
    headerName: "Active",
    width: 150,
  },
  {
    field: "new",
    headerName: "New",
    width: 150,
  },
  {
    field: "pending",
    headerName: "Pending",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total",
    width: 150,
  },
];

function buildRows(students, center) {
  
  const rows = center.locations.map(l=>({id: l._id, location: l.name, active:0, new:0, pending:0, total: 0}))
  for (let student of students) {
    console.log(student)
    for (let htc of student.classes) {
      let location = rows.find(r => r.id === htc.location)
      let all = rows.find((r) => r.location === "All locations");
      if (student.statusHistory.length > 1 && student.statusHistory[student.statusHistory.length - 2].status === "Active") {
        location.active++;
        location.total++
        all.active++;
        all.total++
      }
      if (
        student.statusHistory.length > 1 &&
        student.statusHistory[student.statusHistory.length - 2].status ===
          "New"
      ) {
        location.new++;
         location.total++;
        all.new++;
         all.total++;
      }
      if (
        student.statusHistory.length > 1 &&
        student.statusHistory[student.statusHistory.length - 2].status === "Pending"
      ) {
        location.pending++;
         location.total++;
        all.pending++;
         all.total++;
      }
    
     }
  }
  return rows
}
