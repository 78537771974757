import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { net } from "../../../../../database";
import format from "date-format";

export default function Holidays(props) {
  const [holiday, setHoliday] = React.useState("");
  const [holidayType, setHolidayType] = React.useState("date");
  const [name, setName] = React.useState("");
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const [error, setError] = React.useState({
    name: false,
    date: false,
    from: false,
    to: false,
  });

  const [openHoliday, setOpenHoliday] = React.useState(false);
  let { holidays } = props.center;

  const addHoliday = () => {
    if (name === "") {
      setError({ ...error, name: true });

      return;
    }

    if (holidayType === "date" && holiday === "") {
      setError({ ...error, date: true });

      return;
    }

    if (holidayType === "range" && from === "") {
      setError({ ...error, from: true });

      return;
    }

    if (holidayType === "range" && to === "") {
      setError({ ...error, to: true });

      return;
    }

    let newHoliday = {
      type: holidayType,
      name,
    };
    if (holidayType === "date") {
      newHoliday.date = holiday;
    }
    if (holidayType === "range") {
      newHoliday.range = [];
      newHoliday.range.push(from);
      newHoliday.range.push(to);
    }

    holidays.push(newHoliday);

    net.post("/center/", { holidays }).then((center) => {
      setOpenHoliday(false);
      props.setCenter(center);
      setError({ name: false, date: false, from: false, to: false });
      setName("");
      setHoliday("");
      setFrom("");
      setTo("");
    });
  };

  const removeHoliday = (_id) => {
    let index = holidays.findIndex((h) => h._id === _id);
    holidays.splice(index, 1);
    net.post("/center/", { holidays }).then((center) => {
      props.setCenter(center);
    });
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>Dates that centers will be closed</div>
        <div>
          <Tooltip title="Add new holiday">
            <IconButton onClick={() => setOpenHoliday(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {holidays.map((holiday) => (
        <div>
          {holiday.type === "date" ? (
            <div
              key={holiday._id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton>
                <DeleteIcon
                  style={{ fontSize: 14 }}
                  color="error"
                  onClick={() => removeHoliday(holiday._id)}
                />
              </IconButton>
              <div style={{ width: 200, marginLeft: 10 }}>
                {format("dd/MM/yyyy", new Date(holiday.date))}
              </div>
              <div style={{ marginLeft: 20 }}>{holiday.name}</div>
            </div>
          ) : (
            <div
              key={holiday._id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton>
                <DeleteIcon
                  style={{ fontSize: 14 }}
                  color="error"
                  onClick={() => removeHoliday(holiday._id)}
                />
              </IconButton>
              <div style={{ width: 200, marginLeft: 10 }}>
                {format("dd/MM/yyyy", new Date(holiday.range[0]))} -{" "}
                {format("dd/MM/yyyy", new Date(holiday.range[1]))}
              </div>
              <div style={{ marginLeft: 20 }}>{holiday.name}</div>
            </div>
          )}
        </div>
      ))}
      <Dialog open={openHoliday} fullWidth>
        <DialogTitle>Add new holiday</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Name of holiday"
              fullWidth
              sx={{ mt: 1 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              {...(error.name && { error: true })}
            ></TextField>
            <FormControl style={{ display: "flex", justifyContent: "center" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={holidayType}
                name="radio-buttons-group"
                onChange={(e) => setHolidayType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="One day"
                />
                <FormControlLabel
                  value="range"
                  control={<Radio />}
                  label="Range"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {holidayType === "date" ? (
                <TextField
                  label="Date of holiday"
                  value={holiday}
                  onChange={(e) => setHoliday(e.target.value)}
                  type="Date"
                  InputLabelProps={{ shrink: true }}
                  {...(error.date && { error: true })}
                />
              ) : (
                <div>
                  <TextField
                    label="From"
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                      setTo(e.target.value);
                    }}
                    type="Date"
                    InputLabelProps={{ shrink: true }}
                    {...(error.from && { error: true })}
                  />
                  <TextField
                    label="To"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    type="Date"
                    InputLabelProps={{ shrink: true }}
                    {...(error.to && { error: true })}
                  />
                </div>
              )}
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHoliday(false)}>Close</Button>
          <Button onClick={addHoliday} color="success">
            Add Holiday
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
