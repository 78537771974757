import { IconButton, Button, Stack, TextField, Tooltip } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { net } from "../../../../../database";

export default function Teaching(props) {
  const [render, setRender] = React.useState(0);

  const updateFeedback = () => {
    net
      .post("/center/", { feedbackCategories: props.center.feedbackCategories })
      .then((center) => {
        props.setCenter(center);
      });
  };

  const newFbc = () => {
    let { feedbackCategories } = props.center;
    feedbackCategories.push("");
    net.post("/center/", { feedbackCategories }).then((center) => {
      props.setCenter(center);
    });
  };

  const deleteFbc = (index) => {
    let { feedbackCategories } = props.center;
    feedbackCategories.splice(index, 1);
    net.post("/center/", { feedbackCategories }).then((center) => {
      props.setCenter(center);
    });
  };

  return (
    <div>
      <h3>Feedback Categories</h3>
      <Tooltip title="Add new feedback category">
        <IconButton onClick={newFbc} style={{ float: "right" }}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Stack spacing={2}>
        {props.center.feedbackCategories.map((fb, i) => (
          <div
            key={"fbc" + i}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              value={fb}
              onChange={(e) => {
                props.center.feedbackCategories[i] = e.target.value;
                props.setCenter(props.center);
                setRender(render + 1);
              }}
              onBlur={updateFeedback}
            />
            <IconButton onClick={() => deleteFbc(i)} style={{ marginLeft: 20 }}>
              <DeleteIcon color="error" />
            </IconButton>
          </div>
        ))}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: 80 }}></div>
        </div>
      </Stack>
    </div>
  );
}
