import React from "react";
import { net } from "../../../../database";
import { DataGrid } from "@mui/x-data-grid";
import {
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import "./owed.css";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CallIcon from "@mui/icons-material/Call";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";



import NumberFormat from "react-number-format";

import { differenceInDays } from "date-fns";

import { format } from "date-fns";
import PaymentDialog from "./components/PaymentDialog";
import ContactDialog from "./components/ContactDialog";
import { exportFile } from "table-xlsx";
import { formatPhone } from "../billCustomers/functions";
import StudentLink from "../../components/StudentLink";
import PhoneNumber from "../../components/PhoneNumber";

import {copyBillsToClipboard} from "../../components/copyToClipboard.js"


/* var format = require("date-format"); */

export default function Owed(props) {
  const { l, language, center } = props;
  const [rows, setRows] = React.useState([]);
  const [render, setRender] = React.useState(0);
  const [contact, setContact] = React.useState({
    notes: "",
    followUp: "",
    customer: undefined,
  });
  const [openContactDialog, setOpenContactDialog] = React.useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  /* const [payment, setPayment] = React.useState({
    type: 1,
    amount: 0,
  }); */

  const [search, setSearch] = React.useState("");
  const [customerId, setCustomerId] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/customers/owed").then((owed) => {
      let newRows = [];
      owed.customers.forEach((customer) => {
        newRows.push(makeNewRow(customer));
      });
      console.log(owed);
      setRows(newRows);
    });
  }, [customerId]);

  const closePaymentDialog = (customer) => {
    setCustomerId(undefined);
    setOpenContactDialog(false);
  };

  const getColumns = () => {
    return [
      {
        field: "name",
        key: "name",
        title: "name",
        headerName: l.name[language],
        flex: 0.1,
        renderCell: (props) => (
          <Link className="neat" to={"/customer/" + props.id}>
            {props.value}
          </Link>
        ),
        headerClassName: "lastcolumnSeparator",
      },
      {
        field: "phone",
        title: "Phone",
        key: "phone",
        headerName: l.phone[language],
        width: 120,
        renderCell: (props) => (
          <div>
            <a
              href={"https://zalo.me/" + props.value.replace("0", "84")}
              target={"_blank"}
              rel="noopener noreferrer"
              className="neat"
            >
              <PhoneNumber value={props.value} />
            </a>
          </div>
        ),
        headerClassName: "lastcolumnSeparator",
      },
      {
        field: "phone2",
        title: "Zalo",
        key: "phone2",
        headerName: "Zalo",
        width: 120,
        renderCell: (props) => (
          <div>
            {props.value ? (
              <a
                href={"https://zalo.me/" + props.value.replace("0", "84")}
                target={"_blank"}
                rel="noopener noreferrer"
                className="neat"
              >
                <PhoneNumber value={props.value} />
              </a>
            ) : null}
          </div>
        ),
        headerClassName: "lastcolumnSeparator",
      },
      /*  {
        field: "ref",
        key: "ref",
        title: "Reference",
        headerName: l.reference[language],
        headerClassName: "lastcolumnSeparator",
      }, */
      {
        field: "owed",
        key: "owed",
        title: "Owed",
        headerName: l.amount[language],
        type: "number",
        headerClassName: "lastcolumnSeparator",
      },

      {
        field: "students",
        key: "students",
        title: "students",
        headerName: l.students[language],
        flex: 0.4,
        renderCell: (props) => (
          <div>
            {props.value
              .filter((student) => student.unpaidBills.length > 0)
              .map((student, i) => (
                <div key={student._id} style={{ marginRight: 2 }}>
                  <StudentLink student={student} showClasses />
                </div>
              ))}{" "}
          </div>
        ),
        headerClassName: "lastcolumnSeparator",
      },
      {
        field: "lastPayment",
        key: "lastPayment",
        title: "Last Payment",
        headerName: l.lastPayment[language],
        type: "date",
        headerClassName: "lastcolumnSeparator",
      },
      /* {
        field: "oldestBill",
        key: "oldestBill",
        title: "Oldest Bill",
        headerName: l.oldestBill[language],
        type: "date",
        headerClassName: "lastcolumnSeparator",
        cellClassName: (params) => {
          if (differenceInDays(new Date(), new Date(params.value)) < 30)
            return "good";
          if (differenceInDays(new Date(), new Date(params.value)) < 60)
            return "worse";
          return "bad";
        },
      }, */
      {
        field: "lastContact",
        key: "lastContact",
        title: "Last contacted",
        type: "date",
        headerName: l.lastContacted[language],

        cellClassName: (params) => {
          if (differenceInDays(new Date(), new Date(params.value)) < 20)
            return "good";
          if (differenceInDays(new Date(), new Date(params.value)) < 30)
            return "worse";
          return "bad";
        },
        headerClassName: "lastcolumnSeparator",
      },
      {
        field: "lastContactNotes",
        key: "lastContactNotes",
        title: "Notes",
        headerName: l.notes[language],
        width: 200,
        headerClassName: "lastcolumnSeparator",
      },
      {
        field: "followUp",
        key: "followUp",
        title: "Follow up",
        headerName: l.followUp[language],
        type: "date",
        headerClassName: "lastcolumnSeparator",
      },

      {
        field: "payment",
        headerName: l.actions[language],
        width: 130,
        headerClassName: "lastcolumnSeparator",

        renderCell: (props) => (
          <div>
            <Tooltip title="Copy">
              <IconButton
                onClick={() => {
                 /*  setContact({ ...contact, customer: props.id });
                  setOpenCopy(true);
                  setCustomerId(props.id); */
               
                   copyBillsToClipboard(props.row);
            
                }}
              >
                <ContentCopyIcon sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Payment">
              <IconButton
                component="button"
                onClick={(e) => {
                  setCustomerId(props.id);
                  setOpenPaymentDialog(true);
                  setContact({ ...contact, customer: props.id });
                }}
              >
                <AttachMoneyIcon sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Contact">
              <IconButton
                component="button"
                onClick={(e) => {
                  setCustomerId(props.id);
                  setContact({ ...contact, customer: props.id });
                  setOpenContactDialog(true);
                }}
              >
                <CallIcon sx={{ fontSize: "0.9rem" }} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  const columns = getColumns();

  const onExportFileClick = () => {
    let out = rows.map((row) => ({
      ...row,
      phone: formatPhone(row.phone),
      zalo: formatPhone(row.phone2),
      students: row.students.map((s) => s.name).toString(),
      lastPayment: row.lastPayment
        ? format(new Date(row.lastPayment), "dd/MM/yyyy")
        : "None",
      oldestBill: row.oldestBill
        ? format(new Date(row.oldestBill), "dd/MM/yyyy")
        : "None",
      lastContact: row.lastContact
        ? format(new Date(row.lastContact), "dd/MM/yyyy")
        : "None",
      followUp: row.followUp
        ? format(new Date(row.followUp), "dd/MM/yyyy")
        : "None",
    }));
    console.log(out);
    exportFile({
      columns: getColumns(),
      dataSource: out,
      fileName: `owed.xlsx`,
    });
  };

  const contactCustomer = () => {
    let a = {
      notes: contact.notes,
      followUp: contact.followUp,
    };
    console.log(contact);
    net
      .post(`/customers/${contact.customer}/contacts/add`, a)
      .then((customer) => {
        console.log(customer);
        let newRow = makeNewRow(customer);
        setRows(rows.map((r) => (r.id === newRow.id ? newRow : r)));
        setRender(render + 1);
        setOpenContactDialog(false);
      });
  };

  let customer = rows.find((r) => r.id === customerId);

  const filter = (row) => {
    if (row.name.toLowerCase().includes(search.toLowerCase())) return true;
    if (row.ref.includes(search.toUpperCase())) return true;
    if (row.phone.includes(search)) return true;
    if (row.studentsNames.includes(search.toLowerCase())) return true;
  };
 
 
  return (
    <div style={{ height: "100%" }} id="owed">
      <div className="dflex-sb" style={{ alignItems: "center" }}>
        <h1>{l.owed[language]}</h1>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label={l.search[language]}
          sx={{ width: "15rem" }}
          {...(search.length > 0 && {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="reset select box"
                    onClick={() => setSearch("")}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          })}
        />
        <div style={{ display: "flex" }}>
          <h1>
            Total:{" "}
            <NumberFormat
              value={rows.reduce((a, b) => a + b.owed, 0)}
              displayType="text"
              thousandSeparator={true}
            />
            đ
          </h1>
          <Button onClick={() => onExportFileClick()} sx={{ ml: 1 }}>
            Export
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rows.filter((r) => filter(r))}
        columns={columns}
        disableSelectionOnClick
        autoPageSize
        sx={{ fontSize: "0.8rem" }}
        rowHeight={40}
      />

      {customer ? (
        <ContactDialog
          open={openContactDialog}
          setOpen={setOpenContactDialog}
          customer={customer}
         /*  copyBillsToClipboard={copyBillsToClipboard} */
          contact={contact}
          setContact={setContact}
          contactCustomer={contactCustomer}
          l={l}
          language={language}
        />
      ) : (
        ""
      )}
      {customer ? (
        <PaymentDialog
          customerId={customer.id}
          open={openPaymentDialog}
          setOpen={closePaymentDialog}
          l={l}
          language={language}
          user={props.user}
          center={center}
          onSuccess={() => setCustomerId(undefined)}
        />
      ) : (
        ""
      )}
     {/*  {customer ? <ScreenBills open={openCopy} setOpen={setOpenCopy} customer={customer} /> : null} */}

     {/*  <div id="secretDiv" style={{ display: "none" }}></div> */}
      {/* <div id="secretDiv2" style={{ width:400, height: 500,display: "none" }}>
       
        {customer ? (
          <div>
            {customer.students
              .filter((student) => student.unpaidBills.length > 0)
              .map((student) => (
                <div>
                  <div
                    key={student._id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Link to={`/student/${student._id}`} className="neat">
                        {" "}
                        {student.name}
                      </Link>
                    </div>
                    <div>
                      <NumberFormat
                        displayType="text"
                        value={student.unpaidBills.reduce(
                          (a, b) => a + b.amount,
                          0
                        )}
                        thousandSeparator
                        style={{ marginRight: 14 }}
                      />
                    </div>
                  </div>
                  {[...new Set(student.unpaidBills.map((b) => b.date))].map(
                    (dates) => (
                      <div key={dates}>
                        <div style={{ fontSize: 11, fontWeight: "bold" }}>
                          {format(new Date(dates), "dd/MM/yyyy")}
                        </div>
                        {student.unpaidBills
                          .filter((b) => b.date === dates)
                          .map((bill) => (
                            <div
                              key={bill._id}
                              className="dflex-sb"
                              style={{ fontSize: 12, marginRight: 14 }}
                            >
                              <div>{bill.description}</div>
                              <div>
                                <NumberFormat
                                  value={bill.amount}
                                  displayType="text"
                                  thousandSeparator
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )
                  )}
                </div>
              ))}
            <br></br>
            <hr />
            <div className="dflex-sb">
              <div>Total: </div>
              <NumberFormat
                value={customer.owed - customer.credit}
                thousandSeparator
                displayType="text"
                style={{ marginRight: 14 }}
              ></NumberFormat>
            </div>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
}

function makeNewRow(customer) {
  let newRow = {
    id: customer._id,
    name: customer.name ? customer.name : "No name",
    phone: customer.phone,
    ref: customer.ref,
    owed: customer.owed - customer.credit,
    students: customer.students,
    studentsNames: customer.students
      .map((s) => s.name.toLowerCase())
      .toString(),
    lastPayment:
      customer.payments.length > 0
        ? new Date(customer.payments[customer.payments.length - 1].date)
        : "",
    payments: customer.payments,
    credit: customer.credit,
    lastContact:
      customer.contactsAboutBills.length > 0
        ? new Date(
            customer.contactsAboutBills[
              customer.contactsAboutBills.length - 1
            ].date
          )
        : "",
    lastContactNotes:
      customer.contactsAboutBills.length > 0
        ? customer.contactsAboutBills[customer.contactsAboutBills.length - 1]
            .notes
        : "",
    followUp:
      customer.contactsAboutBills.length > 0 &&
      customer.contactsAboutBills[customer.contactsAboutBills.length - 1]
        .followUp
        ? new Date(
            customer.contactsAboutBills[
              customer.contactsAboutBills.length - 1
            ].followUp
          )
        : "",
  };
  let billDate = undefined;
  customer.students.forEach((student) => {
    //student.nextBillDate = new Date(student.nextBillDate);
    student.unpaidBills.forEach((bill) => {
      if (new Date(bill.date) < billDate || billDate === undefined)
        billDate = new Date(bill.date);
    });
  });
  newRow.oldestBill = billDate;
  /*  newRow.nextBillDate = customer.nextBillDate
    ? new Date(customer.nextBillDate)
    : new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`);

  newRow.paymentFrequency = customer.paymentFrequency || 1; */
  return newRow;
}
