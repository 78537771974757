import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import Payments from "./components/Payments";
import Costs from "./components/Costs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Accounting(props) {
  const { l, language, center } = props;
  const [accounting, setAccounting] = React.useState(undefined);
  const [tabValue, setTabValue] = React.useState(0);

  React.useEffect(() => {
    net.get("/accounting").then((res) => {
      setAccounting(res);
    });
  }, []);

  if (!accounting) return <Loading />;

  return (
    <div>
      <h1>{l.accounting[language]}</h1>
      <Tabs
        value={tabValue}
        aria-label="basic tabs example"
        onChange={(e, val) => setTabValue(val)}
      >
        <Tab label={l.payments[language]} {...a11yProps(0)} />
        <Tab label={l.costs[language]} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Payments
          payments={accounting.payments}
          center={center}
          language={language}
          l={l}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Costs costs={accounting.costs} center={center} />
      </TabPanel>
    </div>
  );
}
