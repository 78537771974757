import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

import "./students.css";
import StudentPill from "../../../components/StudentPill";

export default function Students(props) {
  let { students } = props.customer;
  let navigate = useNavigate();
  if (students.length === 0)
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>No Students</div>{" "}
        <Tooltip title="Add a new student">
          <IconButton
            onClick={() => navigate("/newstudent/" + props.customer._id)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );

  return (
    <div className="customer-students">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontSize: 12 }}>
          {students.length} Student{students.length > 1 ? "s" : ""}
        </div>
        <div>
          <IconButton
            onClick={() => navigate("/newstudent/" + props.customer._id)}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {students.map((s) => (
          <StudentPill student={s._id} />
          /*   <MenuItem key={s._id} onClick={() => navigate(`/student/${s._id}`)}>
          <div style={{ display: "flex" }}>
            <div style={{ width: 300 }}>{s.name}</div>
            <div style={{ width: 200 }}>
              {s.classes.length === 0 ? (
                <div>No Classes</div>
              ) : (
                <div>{s.classes.map((c) => c.name).toString()} </div>
              )}
            </div>
            <div style={{ width: 150 }}>
              {format("dd/MM/yyyy", new Date(s.startDate))}
            </div>
            <div>{s.status}</div>
          </div>
        </MenuItem> */
        ))}
      </div>
    </div>
  );
}
