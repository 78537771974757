import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
} from "@mui/material";

import React from "react";
import { net } from "../../../../../database";
import NumberFormat from "react-number-format";
import Lang from "../../../components/Lang";

export default function EditNameDialog(props) {
  let { l, language, open } = props;
  const [name, setName] = React.useState(props.student.name);
  const [dateOfBirth, setDateOfBirth] = React.useState(
    props.student.dateOfBirth || ""
  );
  const [phone, setPhone] = React.useState(props.student.phone);
  const [phone2, setPhone2] = React.useState(props.student.phone2 || "");
  const [facebook,setFacebook] = React.useState(props.student.facebook || "")

  const updateStudent = () => {
    let update = { name };
    if (dateOfBirth !== "") update.dateOfBirth = dateOfBirth;
    if (phone) update.phone = phone;
    if (phone2 !== "") update.phone2 = phone2;
    if(facebook) update.facebook = facebook
    net.post(`/student/${props.student._id}`, update).then((result) => {
      props.setStudent({ ...props.student, ...result });
      props.setOpen(false);
    });
  };

  return (
    <div>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Lang w="editNameAndDateOfBirth" />
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            sx={{ mt: 1 }}
          >
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label={<Lang w="name" />}
            ></TextField>
            <TextField
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              label={<Lang w="dateOfBirth" />}
              type="date"
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <NumberFormat
              label="Primary phone"
              customInput={TextField}
              format="(###) ###-####"
              value={phone}
              onValueChange={(value) => {
                setPhone(value.value);
              }}
            ></NumberFormat>
            <NumberFormat
              label="Zalo phone"
              customInput={TextField}
              format="(###) ###-####"
              value={phone2}
              onValueChange={(value) => {
                setPhone2(value.value);
              }}
            ></NumberFormat>
            <TextField
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              label="Facebook"
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => props.setOpen(false)}
          >
            {<Lang w="close" />}
          </Button>
          <Button
            color="success"
            onClick={updateStudent}
          >
            {<Lang w="update" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
