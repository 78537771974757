import React, { useContext } from "react";
import { CenterContext } from "../../../../../Context";
import Text from "../../../components/Text";
import { useState } from "react";
import mut from "../../../hooks/mut";
/* import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error"; */


export default function CostCategories(props) {
const {center} = useContext(CenterContext)
const [newCategory,setNewCategory] = useState("")
const [categories,setCategories] = useState(center.costCategory || [])


/* if (loading) return <Loading />;
if (error) return <Error />; */


/* console.log(locations); */

    
    const addCategory = async (e) => {
      
        if (e.keyCode === 13) {
            let result = await mut("post", "/center", { costCategory: [...categories, newCategory] })
            console.log(result)
         
            setCategories(result.costCategory)
         setNewCategory("");
        }
    }
    


    return (<div>{categories.map(l => <div>{l}</div>)}
    <Text value={newCategory} setValue={setNewCategory} label="Add new category" onKeyDown={addCategory}></Text>
    </div>)
}