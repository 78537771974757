import {Button,Dialog,DialogActions,DialogContent,DialogTitle, TextField} from "@mui/material";
import React, { useState } from "react";
import { net } from "../../../../../database";

export default function StoppedReasonDialog(props) {
  const { open, setOpen,setReasons } = props;
    const [name, setName] = useState("")
    
    const add = () => {
        net.post("/center/stopped-reasons", { name }).then(res => { setReasons(res); setName(""); setOpen(false) })
    }

  return (
    <Dialog open={open}>
      <DialogTitle>Add new reason for students to stop</DialogTitle>
          <DialogContent>
              <TextField value={name} onChange={(e)=>setName(e.target.value)} fullWidth/>
      </DialogContent>
      <DialogActions>
              <Button color="error" onClick={() => { setOpen(false); setName("") }}>Close</Button>
        <Button color="success" onClick={add} {...(name==="" && {
        disabled: true 
        })}>Go</Button>
      </DialogActions>
    </Dialog>
  );
}