import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ClassesContext, StudentsContext } from "../../../Context";
import PhoneNumber from "./PhoneNumber";
import "./slink.css"
import Loca from "./Loca";
import Status from "./Status";
import Staff from "./Staff";

/**
 * A link to a student's page, with optional extra information.
 *
 * @param {string} id The id of the student
 * @param {boolean} showClasses Show the classes of the Student
 * @param {React.CSSProperties} style The style of the component
 * @param {boolean} showPhone Show the phone number of the students
 * @param {React.CSSProperties} smallStyle The style of the small text
 * @param {boolean} noLink Disable the link
 * @param {string} status The status of the student
 * @param {boolean} showTeacher Show the teacher of the student
 * @returns {JSX.Element} The component
 */

export default function SLink(props) {
  let { id, showClasses, style, showPhone, smallStyle={fontSize:11, color:"grey"},noLink,status,showTeacher } = props;
  let { findStudent } = useContext(StudentsContext);
  let student = findStudent(id)
  const { findClasses } = useContext(ClassesContext);
  let classes = student && student.classes ? findClasses(student.classes) : []

  if (!student) return <div>No Student</div>;

  if(noLink)return <div style={style}>
      {student.name}
      {showClasses && classes ? <div style={smallStyle}>{classes && classes.length > 0 ? classes.map(c => <>{c.name}  <Loca id={c.location} /></>) : "Not in a class"}</div> : null}
    {showPhone ? <div style={smallStyle}><PhoneNumber value={student.phone} /></div> : null}
   
      </div>

  return (
    <div
      style={style}
      key={id}
    >
      <Link
        to={`/student/${id}`}
        className="neat"
      >
        {student.name}
      </Link>
      {showClasses && classes ? (
        <div style={smallStyle}>
          {classes && classes.length > 0
            ? classes.map((c) => (
                <>
                  <Link
                    to={`/class/${c._id}`}
                    className="small-class-link"
                    key={c._id}
                    {...(c.status === "Coming" && {
                      style: { color: "goldenrod" },
                    })}
                  >
                    <div>
                      {c.name} <Loca id={c.location} />{" "}
                    </div>
                  </Link>{" "}
                  {showTeacher && classes ? (
                    <Staff id={classes[classes.length - 1].teacher} />
                  ) : null}
                </>
              ))
            : "Not in a class"}
        </div>
      ) : null}
      <div style={{ display: "flex", gap: 10 }}>
        {showPhone ? (
          <div style={smallStyle}>
            <PhoneNumber value={student.phone} />
          </div>
        ) : null}{" "}
        {status &&
        ["Lead", "Stopped", "Pending", "New"].includes(student.status) ? (
          <div style={smallStyle}>
            <Status status={student.status} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
