import React from "react";
import NumberFormat from "react-number-format";
import BarChart from "./BarChart";
import MonthChoice from "./common/MonthChoice";
import LineChart from "./LineChart";
import { addMonths } from "date-fns";
import format from "date-fns/format";
import StatChange from "./common/StatChange";
export default function Money(props) {
  let { stats, title, stat, color, chartFormat,year,month } = props;
  const [months, setMonths] = React.useState(1);

  let monthdata = getMonthsStats(stats,year,month)

  if(months===1 && monthdata.length===0) return <div style={{ height: 310, width:450,display:"flex", justifyContent:"center", alignItems:"center", color:"#ccc"}}>No data</div>

  return (
    <div style={chartFormat}>
      <div className="dflex-sb">
        <h3 style={{ marginLeft: 5, marginBottom: 0 }}>
          {title}:{" "}
          <NumberFormat
            displayType="text"
            value={monthdata[monthdata.length - 1][stat]}
            thousandSeparator
          />
        </h3>
        <MonthChoice months={months} setMonths={setMonths} />
      </div>
      {year === new Date().getFullYear() && month === new Date().getMonth() ? <StatChange stats={stats} stat={stat} /> : <div style={{height:24}}></div>}
      <div>
        {months === 1 ? (
          <LineChart
            labels={monthdata.map((s) => s.day)}
            lineData={monthdata.map((s) => s[stat])}
            color={color}
          />
        ) : (
          <BarChart
            labels={getMonthlyData(stats, months, stat).labels}
            barData={getMonthlyData(stats, months, stat).data}
            color={color}
          />
        )}
      </div>
    </div>
  );
}

const getMonthlyData = (stats, months, statName) => {
  let labels = [];
  let data = [];

  let dt = addMonths(new Date(), 1 - months);
  stats.sort((a, b) =>
    new Date(a.date) < new Date(b.date)
      ? 1
      : new Date(b.date) < new Date(a.date)
      ? -1
      : 0
  );

  for (let i = 0; i < months; i++) {
    let stat = stats.find(
      (s) => s.year === dt.getFullYear() && s.month === dt.getMonth()
    );
    labels.push(format(dt, "MMM"));
    data.push(stat[statName]);
    dt = addMonths(dt, 1);
    /*  console.log(stat); */
  }
  console.log(labels, data);
  return { labels, data };
};

const getMonthsStats = (stats,year,month) => {
  let monthStats = [];
  let dt = new Date();
  stats.sort((a, b) =>
    new Date(a.date) > new Date(b.date)
      ? 1
      : new Date(b.date) > new Date(a.date)
      ? -1
      : 0
  );
  monthStats = stats.filter(
    (s) =>
      s.year===year &&
      s.month===month+1
  );
  return monthStats;
};
