import React, { useContext } from "react";
import "./classview.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Lang from "../../../components/Lang";
import Staff from "../../../components/Staff";
import { StudentsContext } from "../../../../../Context";
import Time from "../../../components/Time";

export default function ClassView(props) {
  const { htclass, teacherFilter, search } = props;
  let { students } = useContext(StudentsContext);

  let classDay = props.htclass.classDays.find((cd) => cd.day === props.day);
  let searchHit = false;
  let filter = props.search;
  if (teacherFilter !== "") filter = teacherFilter;
  let numberOfStudents = getNumberOfStudents(htclass, classDay, students);
  if (search !== "") {
    if (htclass.name.toUpperCase().includes(search.toUpperCase()))
      searchHit = true;
  }
  if (teacherFilter !== "") {
    if (htclass.teacher === teacherFilter) searchHit = true;
  
      for (let otherTeacher of classDay.otherTeachers) {
        if(teacherFilter===otherTeacher.teacher) searchHit=true
  
    }
  }

  let frameHeight = 74;
  if (classDay.otherTeachers.length > 1) {
    frameHeight += (classDay.otherTeachers.length - 1) * 24;
  }

  let color = "#7aff7d";
  if (Math.abs(htclass.maxStudents - numberOfStudents) > 1 && Math.abs(htclass.maxStudents - numberOfStudents)<4) {
    color = "#fffbad";
  }
  if (Math.abs(htclass.maxStudents - numberOfStudents) >= 4) {
    color = "#ffadad";
  }



  return (
    <Link
      to={`/class/${props.htclass._id}`}
      className="class-link"
    >
      <div
        className={
          !searchHit && filter !== ""
            ? "frameDisabled"
            : props.htclass.status === "Coming"
            ? "class-coming"
            : htclass.level && htclass.level.toLowerCase() || "frame"
        }
        style={{ height: frameHeight }}
      >
        <div className={"classtitle"}>{htclass.name}</div>
        <div
          className={"teachersName"}
          variant="subtitle2"
        >
          <Staff id={htclass.teacher} />
        </div>
        <div
          className={"classRoom"}
          variant="subtitle2"
          sx={{ color: "#5df4fc", fontSize: 12, fontWeight: "normal" }}
        >
          {classDay.room ? classDay.room : "No room"}
        </div>
        <div
         className={"classRoom"}>
          U{htclass.currentUnit || ""} L{htclass.currentLesson || ""}
        </div>
        <Box
          className={"activeStudents"}
          sx={{ color }}
        >
          <NumberOfStudents numberOfStudents={numberOfStudents} htClass={htclass} />
        </Box>

        {classDay.otherTeachers && classDay.otherTeachers.length > 0 ? (
          <div className="other-teacher">
            {classDay.otherTeachers.map((teacher) => (
              <div>
                <div>
                  <Staff id={teacher.teacher} />
                  {" "}
                  <Time time={teacher.startTime} short/> -{" "}
                  <Time time={teacher.endTime} short/>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </Link>
  );
}

function getNumberOfStudents(htClass, classDay, students) {
  let number = 0;
  for (let student of htClass.students) {
    let st = students.find((s) => s.id === student);
    if (st && st.doesntStudy && st.doesntStudy.includes(classDay._id)) {
    } else {
      number++;
    }
  }
  return number;
}

function NumberOfStudents(props) {
  const { numberOfStudents, htClass } = props
 /*  if (htClass.pendingStudents.length > 0) return (<div style={{ display: "flex",gap:3 }}><div>{numberOfStudents}/{htClass.targetStudents} <Lang w="students" /></div><div style={{color:"yellow"}}>(+{htClass.pendingStudents.length}) </div>  </div>) */
        
  return <div>{numberOfStudents}/{htClass.maxStudents}</div>
}