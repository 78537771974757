import { Box, Chip, ClickAwayListener } from "@mui/material";
import React, { useContext, useRef } from "react";
import "./htComplete.css"
import { StudentsContext } from "../../../Context";
import StudentCard from "./StudentCard";


export default function HtComplete(props) {
  const {tagged,setTagged} = props
  const [search, setSearch] = React.useState("")
  const { studentsByStatus: students } = useContext(StudentsContext);
  const [selected, setSelected] = React.useState(0);
 


return (
  <div>
    <Tags
      search={search}
      setSearch={setSearch}
      tagged={tagged}
      alreadyTagged={tagged.map((t) => t._id)}
      setTagged={setTagged}
      selected={selected}
      setSelected={setSelected}
      students={students}
    />
    {search !== "" ? (
      <StudentsList
        search={search}
        students={students}
        alreadyTagged={tagged.map((t) => t._id)}
        tagged={tagged}
        setTagged={setTagged}
        setSearch={setSearch}
        selected={selected}
        setSelected={setSelected}
      />
    ) : null}
  </div>
);
}

function Tags(props) {
  const { search, students, setSearch, tagged, setTagged, selected, setSelected, alreadyTagged } = props
   let background = {
     New: "linear-gradient(80deg, rgba(4,122,0,1) 0%, rgba(22,180,7,1) 100%)",
     Active: "linear-gradient(to left, #00b4db, #0083b0)",
     Pending: "linear-gradient(to right, #fc4a1a, #f7b733)",
     Lead: "linear-gradient(to right, #ff416c, #ff4b2b)",
     Stopped: "linear-gradient(to right, #ff416c, #ff4b2b)",
   };
  const keyDown = (e) => {
       let sts = students
         .filter(
           (s) =>
             !alreadyTagged.includes(s._id) &&
             s.name.toUpperCase().includes(search.toUpperCase())
         )
         .slice(0, 20);
      if (e.keyCode === 13) {
        e.preventDefault();
       
        setTagged([...tagged, sts[selected]])
        setSearch(prev => "")
        setSelected(prev=>0)
 
    
        e.target.innerText = "";
      }
       if (e.keyCode === 38) {
         e.preventDefault();
         if (selected > 0) {
           if (selected > 0) setSelected(selected - 1);
           const section = document.getElementById("" + sts[selected - 1]._id);
           section.scrollIntoView({ behavior: "smooth", block: "start" });
         }
       }
       if (e.keyCode === 40) {
         e.preventDefault();
         if (selected < 19) {
           setSelected(selected + 1);
           const section = document.getElementById("" + sts[selected + 1]._id);
           section.scrollIntoView({ behavior: "smooth", block: "start" });
         }
       }
    };
    return (
      <Box
        border="1px solid #ccc"
        borderRadius={1}
        /* sx={{ pt:1,pb:1}} */
        style={{ display: "flex", paddingTop: 20, paddingBottom: 20 }}
      >
        <div
          className="tagged"
          {...(tagged.length > 0 && {
            style: { padding: 10 },
          })}
        >
          {tagged.map((t) => (
            <Chip
              key={t._id}
              label={t.name}
              value={t._id}
              sx={{ m: 0.3, background: background[t.status], color: "white" }}
              onDelete={() => setTagged(tagged.filter((f) => f._id !== t._id))}
            />
          ))}
        </div>
        <ClickAwayListener onClickAway={()=>setSearch(prev=>"")}>
          <input
            value={search}
            tabIndex={0}
            className="input-box"
            style={{ width: "100%" }}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              keyDown(e);
            }}
            {...(search === "" && {
              placeholder: "Tag students...",
            })}
            /*  placeholder="Tag students" */
            /* ref={(input) => {
              input && input.focus();
            }} */
        
          ></input>
        </ClickAwayListener>
      </Box>
    );
}

function StudentsList(props) {
  const { search, students, alreadyTagged,setTagged, setSearch, selected,setSelected,tagged } = props
  
  let sts = students.filter(s=> !alreadyTagged.includes(s._id) && s.name.toUpperCase().includes(search.toUpperCase())).slice(0,20)
  return (
    <div className="students-list">
      {sts.map((student, i) => (
        <StudentCard
          student={student}
          key={student._id}
          setTagged={setTagged}
          setSearch={setSearch}
          tagged={tagged}
          {...(i===selected && {
            selected: true,
          })}
          index={i}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
}