import React from "react";
import StudentLink from "../../../components/StudentLink"

export default function CustomersWithNoNextBillDate(props) {
  let { student } = props;
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 300 }}>
        <StudentLink student={student._id} showClasses />
      </div>
     
    </div>
  );
}
