import React, { useContext } from "react";
import useDB from "../../hooks/useDB"
import SelectFromList from "../../components/SelectFromList";
import { ClassesContext } from "../../../../Context";
import { format } from "date-fns";
import Loading from "../../Loading";
import Error from "../../components/Error";
export default function Test(props) {


    const [value, setValue] = React.useState(""); 
    const [months,setMonths] = React.useState(3)
    const { active } = useContext(ClassesContext)
    const [tuition, setTuition, error, loading] =useDB(
    `/tuition/${value}/${months}`
  )

if (loading) return <Loading />;


/* if(loading) return <div>Loading</div>   */
    
console.log(error, value);


    
    return (
      <div>
        <SelectFromList
          choices={active}
          label="Classes"
          value={value}
          setValue={setValue}
          style={{ width: 300, marginRight: 10 }}
        />
        <SelectFromList
          choices={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          label="Months"
          value={months}
          setValue={setMonths}
          style={{ width: 100, marginRight: 10 }}
        />
        {tuition && <Months tuition={tuition} />}
        {tuition && <div>Total Fees: {tuition.total.toLocaleString()}</div>}
      </div>
    );
}


function Months({ tuition }) {
  const { classMonths,fee } = tuition
  return <div>{classMonths.map(cm => <div>
    <div style={{fontSize:12, color:"gray",marginTop:10}}>{format(new Date(cm.year, cm.month, 1), "MM/yyyy")}</div>
    <Days classes={cm.classes} fees={cm.fees} fee={fee}/>
  </div>)}</div>
}


function Days({ classes, fees,fee }) {
  const {dates} = classes
  return (
    <div>
      {dates.map((c) => (
        <div style={{ display: "flex" }}>
          <div style={{ width: 200 }}>
            {format(new Date(c.date), "dd/MM/yyyy")}
          </div>
          <div style={{ width: 200 }}>{c.type}</div>
          <div style={{ textAlign: "right", width: 200 }}>
            {c.type !== "holiday" ? fee.toLocaleString() : 0}
          </div>
        </div>
      ))}
      <div style={{ fontWeight: "bold" }}>Total: {fees.toLocaleString()}</div>
    </div>
  );
}