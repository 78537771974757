import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useContext } from "react";
import { format } from "date-fns";
import NumberFormat from "react-number-format";
import { net } from "../../../../../database";
import { CenterContext, ClassesContext } from "../../../../../Context";
import Lang from "../../../components/Lang";

export default function AddNewBill(props) {
  let { open, setOpen, student, setStudent } = props;
  const {center} = useContext(CenterContext)
  let {className} = useContext(ClassesContext)
  let emptyBill = {
    date: format(new Date(), "yyyy-MM-dd"),
    classId:
      student.classes && student.classes.length > 0
        ? student.classes[0]
        : "",
    location: student.location[0] || "",
    billType: "",
    amount: 0,
    description: "",
  };
  const [bill, setBill] = React.useState(emptyBill);
  const [errors, setErrors] = React.useState([]);

  const addBill = () => {
    let errors = [];
    if (bill.amount === 0) errors.push("amount");
    if (bill.location === "") errors.push("location");
    if (bill.billType === "") errors.push("billType");
    if (bill.description === "") errors.push("description");

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    net.post(`/student/${student._id}/bills/add`, bill).then((unpaidBills) => {
      setStudent({ ...student, unpaidBills });
      setBill(emptyBill);
      setOpen(false);
      setErrors([]);
    });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{<Lang w="addANewBill" />}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            value={bill.date}
            label={<Lang w="dateOfBill" />}
            onChange={(e) => setBill({ ...bill, date: e.target.value })}
          />
          <TextField
            value={bill.classId}
            label={<Lang w="class" />}
            onChange={(e) => setBill({ ...bill, classId: e.target.value })}
            select
          >
            {student.classes.map((htClass) => (
              <MenuItem key={htClass} value={htClass}>
                {className(htClass)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={bill.location}
            label={<Lang w="location" />}
            onChange={(e) => setBill({ ...bill, location: e.target.value })}
            select
            {...(errors.includes("location") && { error: true })}
          >
            {center.locations.filter(l=>l.name!=="All locations").map((location) => (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={bill.billType}
            label={<Lang w="typeOfBill"/>}
            onChange={(e) => setBill({ ...bill, billType: e.target.value })}
            select
            {...(errors.includes("billType") && { error: true })}
          >
            {center.billTypes.map((billType) => (
              <MenuItem key={billType} value={billType}>
                {billType}
              </MenuItem>
            ))}
          </TextField>

          <NumberFormat
            customInput={TextField}
            InputProps={{
              sx: {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            thousandSeparator={true}
            onValueChange={(value) => {
              console.log(value);
              setBill({ ...bill, amount: value.value });
            }}
            autoComplete="off"
            value={bill.amount}
            label={<Lang w="amount" />}
            {...(errors.includes("amount") && { error: true })}
          />
          <TextField
            value={bill.description}
            multiline
            rows={3}
            label={<Lang w="description" />}
            onChange={(e) => setBill({ ...bill, description: e.target.value })}
            {...(errors.includes("description") && { error: true })}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          {<Lang w="close" />}
        </Button>
        <Button color="success" onClick={addBill}>
          {<Lang w="addBill" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
