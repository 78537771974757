import React from "react";
import Feedback from "./Feedback";
import MobileAttendance from "./MobileAttendance";

export default function AttendanceFeedback(props) {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  )
    return (
      <div>
        <MobileAttendance />
      </div>
    );

  return (
    <div>
      <Feedback
        user={props.user}
        center={props.center}
        language={props.language}
        l={props.l}
      />
    </div>
  );
}
