import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { net } from "../../../../../database";

import Receipt from "../../../components/Receipt";
import ScreenReceipt from "../../student/components/ScreenReceipt";
import buildPayment from "./paymentDialogComponents/buildpayment";
import makePayment from "./paymentDialogComponents/makepayment";
import DrawBills from "./paymentDialogComponents/DrawBills";
import DrawCustomerCredit from "./paymentDialogComponents/DrawCustomerCredit";
import DrawTotalsAndPaymentType from "./paymentDialogComponents/DrawTotalsAndPaymentType";

export default function PaymentDialog(props) {
  let { open, setOpen, customerId, onSuccess } = props;
  const [payment, setPayment] = React.useState(undefined);
  const [receipt, setReceipt] = React.useState(undefined);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      net.get(`/customers-bills/${customerId}`).then((customer) => {
        setPayment((prev) => buildPayment(customer));
      });
    }
  }, [open]);

  if (!payment) return <div></div>;
  console.log(payment);
  if (receipt)
    return <ScreenReceipt receipt={receipt} open={open} setOpen={setOpen} />;

  const printReceipt = (result) => {
    /*  setReceipt(result.receipt);

    window.frames["Receipt"].focus();
    window.frames["Receipt"].print();
    setTimeout(() => {
      onSuccess(result.customer);
      setDisabled(false);
    }, 4000); */
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"lg"}>
      <DialogTitle>Make payment for {payment.customerName}</DialogTitle>
      <DialogContent>
        <DrawBills payment={payment} setPayment={setPayment} />
        <DrawCustomerCredit customerCredit={payment.customerCredit} />
        <DrawTotalsAndPaymentType
          payment={payment}
          setPayment={setPayment}
          {...props}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpen(false)}>Close</Button>
        <Button
          color="success"
          onClick={(e) =>
            makePayment(
              customerId,
              payment,
              setPayment,
              setReceipt,
              setDisabled,
              onSuccess
            )
          }
          disabled={disabled}
        >
          Payment
        </Button>
      </DialogActions>
      {receipt ? <Receipt receipt={receipt} user={props.user} /> : null}
    </Dialog>
  );
}
