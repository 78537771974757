import React, { useContext } from "react";
import { StaffContext } from "../../../Context";
import { MenuItem, TextField } from "@mui/material";

/**
 *
 *
 * @export
 * @param {string} id the id of the staff member
 * @param {function} setValue change to a select
 * @return {*} 
 */
export default function Staff(props) {
  const { findStaff,staff } = useContext(StaffContext);
  const { id,setValue } = props;
  if (!id) return <>No staff</>;
  let member = findStaff(id)
  if(!member) return <>No staff</>
  if (!setValue) return <>{member.name}</>;
  return <TextField {...props} value={id} onChange={(e) => setValue(e.target.value)} select>{staff.map(s => <MenuItem value={s._id}>{s.name}</MenuItem>)}</TextField>
}
