import React, { useContext } from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import HtTabs from "../../components/HtTabs";
import NoClass from "./NoClass";
import Search from "../../components/Search";
import { StudentsContext } from "../../../../Context";



export default function Absent2(props) {
    const [noClass,setNoClass] = React.useState(undefined)
    const [waiting, setWaiting] = React.useState(undefined); 
    const [timetable, setTimetable] = React.useState(undefined)  
    const [search, setSearch] = React.useState("")
  const [loading, setLoading] = React.useState(true)
  const {studentsName} = useContext(StudentsContext)
    
    React.useEffect(() => {
      net.get("/absent2").then(res => {     
        console.log(res)
       
            setNoClass(prev => res.noClass)
            setWaiting((prev) => res.waiting);
            setTimetable((prev) => res.timetable)
            setLoading(prev=> false)
    })
    }, []);
        if (loading) return <Loading />;
    let labels = [`No class (${noClass.length})`, `Waiting (${waiting.length})`]
    let components = [
      <NoClass
        attendance={search==="" ? noClass : noClass.filter(n=> studentsName(n.student).toLocaleLowerCase().includes(search.toLocaleLowerCase()))}
        setAttendance={setNoClass}
        timetable={timetable}
      />,
      <NoClass
        attendance={waiting}
        setAttendance={setWaiting}
        timetable={timetable}
      />,
    ];


  
    
  return (<div><HtTabs labels={labels} components={components} nextToTabs={<Search search={search} setSearch={setSearch} size="small" />} /></div>)
}
