import React, { useContext } from "react";
import { CenterContext } from "../../../Context";
import { MenuItem, TextField } from "@mui/material";
import mut from "../hooks/mut";
import Lang from "./Lang";

/**
 * Description placeholder
 * @date 6/15/2023 - 6:49:25 PM
 *
 * @export
 * @param {string} value The id of the location
 * @param {function} setValue If provided the component will be a select of locations
 * @param {boolean} showAll optional, offers choice of "All locations"
 * @returns {*}
 */
export default function Locations(props) {
  let { value,setValue, showAll, url, field, size="normal" } = props;
  const { center } = useContext(CenterContext);
  if (!center) return <div></div>;

   const change = (val) => {
     if (url && field) {
       mut("post", url, { [field]: val });
     }
     setValue(val);
   };

  if(setValue)  return (
    <div>
      <TextField
        select
        label={<Lang w="location" />}
        value={value}
        onChange={(e) => change(e.target.value)}
        size={size}
        style={{width:150}}
      >
        {center.locations
          .filter((l) => (showAll ? true : l.name !== "All locations"))
          .map((location) => (
            <MenuItem
              key={location._id}
              value={location._id}
            >
              {location.name}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );

  const location = center.locations.find((l) => l._id === value);
  if (!location) return <div>Unknown</div>;
  return <div {...props}>{location.name}</div>;
}
