import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import Bcs from "./Bcs";
import AddButton from "../../components/AddButton";
import DDialog from "../../components/DDialog";

import Error from "../../components/Error";
import useDB from "../../hooks/useDB";


export default function Unit(props) {
    const { id, uid } = useParams()
    const [unit, setUnit, error, loading] = useDB(`/unit/${uid}`);
    const [open,setOpen] = useState(false)
  
    if (loading) return <Loading />
    if (error) return <Error />
    console.log(unit)

    const fields = [{
        name: "name", label: "Name"
    }, {
      name:"lessonNumber", label:"Number", type:"number",value: unit.lessons.length+1  
    },{
        name: "curriculum", value: unit.curriculum._id, type:"hidden"
        },
        {
        name: "unit", value: unit._id, type:"hidden"
    }]

    return (
      <div>
        <Bcs
          syllabus={unit.curriculum}
          unit={unit}
        />
        <div className="cur-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2> {unit.name}</h2>
            <AddButton action={() => setOpen(true)} />
          </div>
          <table className="cur-table">
            <tr>
              <th>Lesson</th>
              <th>Name</th>
            </tr>
            {unit.lessons.map((l) => (
              <tr>
                <td>{l.lessonNumber}</td>
                <td>
                  <Link to={`/curriculum/${id}/unit/${uid}/lesson/${l._id}`} className="cur-link">
                    {l.name}
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <DDialog
          fields={fields}
          ok="Add lesson"
          title="Add new lesson"
          required={["name", "lessonNumber"]}
          route="/clesson"
          open={open}
          setOpen={setOpen}
          newData={(newData) =>
            setUnit((prev) => {
              let lessons = [...prev.lessons, newData];
              return { ...prev, lessons };
            })
          }
        />

      
      </div>
    );
}