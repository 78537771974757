import React, { useContext } from "react";
import { CenterContext } from "../../../../../Context";
import Text from "../../../components/Text";
import { useState } from "react";
import mut from "../../../hooks/mut";
/* import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error"; */


export default function MarketingLocations(props) {
const {center} = useContext(CenterContext)
const [newLocation,setNewLocation] = useState("")
const [locations,setLocations] = useState(center.marketingLocations)


/* if (loading) return <Loading />;
if (error) return <Error />; */


/* console.log(locations); */

    
    const addLocation = async (e) => {
      
        if (e.keyCode === 13) {
            let result = await mut("post", "/center", { marketingLocations: [...locations, newLocation] })
            console.log(result)
         
            setLocations(result.marketingLocations)
             setNewLocation("");
        }
    }
    


    return (<div>{locations.map(l => <div>{l}</div>)}
    <Text value={newLocation} setValue={setNewLocation} label="Add new location" onKeyDown={addLocation}></Text>
    </div>)
}