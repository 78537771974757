import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../../database";

export default function NewBookDialog(props) {
  const { l, language, open, setOpen, setBooks } = props;
  const [name, setName] = React.useState("");

  const newBook = () => {
    net.post("/books", { name }).then((res) => {
      setBooks(res);
      setOpen(false);
    });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add new book</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></TextField>{" "}
      </DialogContent>{" "}
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button onClick={() => newBook()}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
