import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../content/Loading";
import { net } from "../../../database";

import { AppBar, LinearProgress, Box, Typography, Button } from "@mui/material";
import AttendanceButtons2 from "../../content/pages/attendance/components/AttendanceButtons2";
import HtClass from "../../content/components/HtClass";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Box sx={{ width: "100%", mr: 1, mt: 0.05 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 0, p: 0 }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Attendance(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const [htClass, setHtClass] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  React.useEffect(() => {
    let d = new Date()
    net.get(`/class-attendance/${id}/${d.getFullYear()}/${d.getMonth()}/${d.getDate()}`).then((htClassIn) => {
      setHtClass(htClassIn);
    });
  }, []);

  const updateLesson = (lesson) => {
    setHtClass({ ...htClass, lesson }, () => setRender(render + 1));
  };

  if (!htClass) return <Loading />;
  console.log(htClass)

/*   let progress = calculateProgress(htClass);
  let color = "error";
  if (progress > 50) color = "warning";
  if (progress > 99) color = "success"; */

  let d = new Date()
  let showDate = new Date(d.getFullYear(),d.getMonth(),d.getDate(),0)

  return (
    <div style={{ padding: 10, overflowY: "scroll" }}>
    
      <AppBar sx={{ alignItems: "center" }}>
        <div style={{ width: "80vw", textAlign: "center" }}>
          <h3 style={{ marginBottom: 0 }}>Attendance for <HtClass htClass={htClass._id} /></h3>
      {/*     <LinearProgressWithLabel
            value={progress}
            color={color}
              style={{ marginTop: 0, marginBottom: 10 }}
            />
           */}
        </div>
      </AppBar>
      <div style={{ height: 80 }}></div>
      {htClass.students.map((student) => (
        <AttendanceButtons2
          student={student}
          htClass={htClass}
          setHtClass={setHtClass}
          updateLesson={updateLesson}
          key={student._id}
          render={render}
          setRender={setRender}
          showDate={showDate}
        />
      ))}
      <div style={{display:"flex", flexDirection:"row-reverse", marginTop: 10}}>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

function calculateProgress(htClass) {
  if (!htClass.lesson) return 0;
  if (htClass.students.length === 0) return 0;
  let st = htClass.students.filter(
    (student) => !student.doesntStudy.includes(htClass.classDay._id)
  );

  let students = st.length;
  let attended = htClass.lesson.attended.length;
  let absent = htClass.lesson.absent.length;
  let noShow = htClass.lesson.noShow.length;

  let total = attended + absent + noShow;
  if (total === 0) return 0;

  return (100 / students) * total;
}
