import { useEffect, useState } from "react";
import { apiKey } from "../../../App";
import axios from "axios";

/**
 * 
 * @param {string} url 
 * @returns {array} state, setState, error, loading
 */

export default function useDB(url) {
    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [loading,setLoading] = useState(true)
 useEffect(() => {
        (
            async function(){
                try{
                    setLoading(true)
                    const response = await axios({
                        method: "get",
                        url: `/api${url}`,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + apiKey,
                    },
                    })
                    setData(response.data)
                }catch(err){
                    setError(err)
                }finally{
                    setLoading(false)
                }
            }
        )()
    }, [url])

    return [data, setData , error, loading]
}