import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { InputAdornment } from "@mui/material";
import { net } from "../../../../../database";
import SelectFromList from "../../../components/SelectFromList";
import VND from "../../../components/VND";

export default function NewStaffDialog(props) {
  const [staff, setStaff] = React.useState({
    name: "",
    login: "",
    password: "",
    salaryType: "Monthly",
    salary:0
  });
  const [errors,setErrors] = React.useState({})

  const handleClose = () => {
    props.setOpen(false);
    setTimeout(() => {
      setStaff({
        name: "",
        login: "",
        password: "",
        salaryType: "Monthly",
        salary:0
      });
    }, 1000);
  };

  const enterStaff = () => {
    let nstaff = staff;
    nstaff.reportsTo = props.user._id;
    nstaff.centerId = props.user.centerId;
    props.net.post("/users/new", nstaff).then((res) => {
      handleClose();
    });
  };

  

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <DialogTitle>Add a new staff member</DialogTitle>
      <DialogContent>
        <Stack spacing={2} style={{paddingTop:5}}>
           <TextField
            value={staff.name}
            onChange={(e) => {
              setStaff({ ...staff, name: e.target.value }); debounce(checkUserLogin({ login: staff.login, name: e.target.value },setErrors),20000)
            }
            }
            {...(errors.name && {
              error: true
            })}
            label="Name"
            helperText={errors.name ? "There is already a staff member with the same name" : ""}
          />
          <TextField
            value={staff.login}
            onChange={(e) => { setStaff(prev => ({ ...prev, login: e.target.value })); checkUserLogin({ login: e.target.value, name: staff.name }) }}
            
            label="Login"
            type="text"
            InputProps={{
              endAdornment: <InputAdornment position="end">@htexplore.vn</InputAdornment>,
            }}
            {...(errors.login && {
              error: true
            })}
            helperText={errors.login ? "There is already a staff member with this login" : ""}
          />
          <TextField
            value={staff.password}
            onChange={(e) => setStaff({ ...staff, password: e.target.value })}       
            label="Password"
            type="text"
          />
          <SelectFromList choices={["Monthly", "Hourly"]} value={staff.salaryType} setValue={(salaryType) => setStaff({ ...staff, salaryType })} label="Type of salary"/>
          <VND value={staff.salary} setValue={(salary)=>setStaff({...staff, salary})} label="Salary" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={enterStaff} {...((staff.name === "" || staff.login === "" || staff.password==="" || errors.name || errors.login) && {
        disabled: true 
        })}>Add staff</Button>
      </DialogActions>
    </Dialog>
  );
}




  const checkUserLogin = (check,setErrors) => {
      net.post("/user/check-user", check).then(res => {
      console.log(res)
      let er = {}
      if (res.name) er.name = true
      if (res.login) er.login = true
      setErrors(er)
    })

  }


  function debounce(func, timeout = 300){
    let timer;
    console.log("debounce",timeout)
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
  }