import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { net } from "../../../database";
import VND from "./VND";

/**
 * A standard dialog for entering data into the database
 * @param {array} fields - Object {name, label, type, value}
 * @param {function} newData - function to handle the new data created
 * @param {string} route - place to send the new data
 * @param {boolean} open - show or hide the dialog
 * @param {function} setOpen - show or hide the dialog
 * @param {string} title - title of the dialogTitle
 * @param {string} ok - label for the success button
 * @param {string | function} content - message to the user about the dialog, can be text or component
 * @param {[string]} required array of field names that must be filled out
 * @param {string} req - request type, default=put, change to post if required
 */

export default function DDialog(props) {
  const {route,open,setOpen,title="",ok="Add",content="",required=[],req} = props
  const [fields, setFields] = React.useState(props.fields);

  const handleClose = () => {
    setFields(props.fields);
    setOpen(false);
  };
  const handleNewData = () => {
    if (checkerrors() === true) return;

    let newData = {};
    fields.forEach((f) => {
        newData[f.name] = f.value;
    });
    if (req === "post") {
      net.post(route, newData).then((r) => {
        if (props.newData) props.newData(r);
        setFields(props.fields)
        setOpen(false);
      });
    } else {
      net.put(route, newData).then((r) => {
        if (props.newData) props.newData(r);
        setFields(props.fields)
        setOpen(false);
      });
    }
  };

  const checkerrors = () => {
    let errors = false;
    let newFields = fields;
    newFields.forEach((f) => {
      if (f.value === "" && required.includes(f.name)) {
        f.error = true;
        errors = true;
      
      } else {
        delete f.error;
      }
    });
    setFields(newFields);
    return errors;
  };

  const empty = checkEmpty(fields,required)

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="dialog-box"
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content ? content : ""}
       
          {fields
            .filter((f) => f.type !== "hidden")
            .map((f, i) => (
              <div
                key={f.name + i}
                style={{ padding: "10px" }}
              >
                {f.type && f.type === "vnd" ? (
                  <VND
                    thousandSeparator={true}
                    setValue={(val) =>
                      setFields((prev) => ({ ...prev, [f.name]: val }))
                    }
                    value={f.value}
                    label={f.label}
                    {...(f.error && { error: true })}
                  />
                ) : (
                  <TextField
                    id={f.name}
                    label={f.label}
                    type={f.type || "text"}
                    value={f.value}
                    fullWidth
                    autoFocus
                    onChange={(e) =>
                      setFields((prev) =>
                        prev.map((p) =>
                          p.name === f.name
                            ? { ...f, value: e.target.value }
                            : p
                        )
                      )
                    }
                    {...(f.type &&
                      f.type.includes("select") && {
                        select: true,
                      })}
                    {...(f.error && { error: true })}
                  >
                    {f.type && f.type === "select"
                      ? f.items.map((item) => (
                          <MenuItem
                            key={"mi" + item}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))
                      : f.type === "selectNameId"
                      ? f.items.map((item) => (
                          <MenuItem
                            key={"mi" + item._id}
                            value={item._id}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                      : ""}
                  </TextField>
                )}
              </div>
            ))}
     
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          tabIndex={100}
          color="error"
        >
          Close
        </Button>
        <Button
          onClick={handleNewData}
          color="success"
          {...(empty && {
            disabled: true,
          })}
        >
          {ok}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function checkEmpty(fields,required) {
  let empty = false
  for (let field of fields) {
    if(required.includes(field.name) && (!field.value || field.value==="")) empty=true
  }
  return empty
}
