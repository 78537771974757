import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import DrawContact from "./DrawContact";
import DrawTitles from "./DrawTitles";


export default function NewContacts(props) {


const [contacts, setContacts, error, loading] = useDB(`/marketing-contacts/new`)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(contacts);


    return (<div>
        <DrawTitles />
        {contacts.map(c => <DrawContact contact={c} key={c._id} />)}</div>)
}