import React, { useState } from "react";
import DayOfWeekChoice from "../../../components/DayOfWeekChoice";
import TimeChooser from "../../../components/TimeChooser";
import { Stack,Button } from "@mui/material";
import RoomChoice from "../../../components/RoomChoice";
import { addMinutes, format } from "date-fns";
import DayOfWeek from "../../../components/DayOfWeek";
import DeleteButton from "../../../components/DeleteButton";
import Delete from "../../../components/Delete";
import pullAt from "lodash.pullat";
import DrawTimeTable from "./DrawTimeTable";



export default function StepTwo(props) {
    const { newClass, setNewClass } = props;
    const [classDay, setClassDay] = useState({})
    console.log(classDay)
  console.log(props);

    return (
        <Stack spacing={2}>
            <div>
               <DrawTimeTableBox newClass={newClass} setNewClass={setNewClass} />
            </div>
        <DayOfWeekChoice
          value={classDay.day || 0}
          setValue={(day) => setClassDay((prev) => ({ ...prev, day: day.target.value }))}
          label="Day of the week"
        />
        <TimeChooser
          times={["17:15", "17:45", "18:00", "18:15", "19:00", "19:30"]}
          label="Start Time"
          value={classDay.startTime}
                setValue={(startTime) => {
                const endTime = format(addMinutes(new Date("2023-01-01 " + startTime), 90),"H:mm")
                setClassDay((prev) => ({ ...prev, startTime,endTime }));
          }
         
          }
        />
        <TimeChooser
          times={["18:45", "19:15", "19:30", "19:45", "20:30", "21:00"]}
          label="Finish Time"
          value={classDay.endTime}
          setValue={(endTime) =>
            setClassDay((prev) => ({ ...prev, endTime }))
          }
        />
            <RoomChoice location={newClass.location} value={classDay.room || ""} setValue={(room)=>setClassDay(prev=>({...prev, room}))} />
            <Button onClick={() => {
                const classDays = newClass.classDays ? [...newClass.classDays,classDay] : [classDay]            
                setNewClass(prev => ({ ...prev, classDays}))
                setClassDay({startTime: classDay.startTime, endTime: classDay.endTime, room: classDay.room})
            }}
            {...(classDayCheck(classDay) && {
            disabled: true
            })}>Add class to timetable</Button>
      </Stack>
    );
}

function classDayCheck(cd) {
    if(!cd.day || !cd.startTime || !cd.endTime || !cd.room) return true
    return false
}

function DrawTimeTableBox({ newClass, setNewClass }) {
    if(!newClass.classDays) return <div></div>
    return <div style={{border:"1px solid #ccc", borderRadius: 5, padding: 10, marginTop:10}}>
        <div style={{fontSize: 11}}>TimeTable</div>
        {newClass.classDays.sort((a, b) => (a.day > b.day) ? 1 : ((b.day > a.day) ? -1 : 0)).map((cd, i) => <DrawTimeTable classDay={cd} setNewClass={setNewClass} index={i} allowDelete/>)}</div>
}
