import React, { useContext } from "react";
import { IconButton, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { net } from "../../../../../database";
import { UserContext } from "../../../../../Context";

export default function Buttons({student,htClass,setHtClass,showDate}) {
   
    const [loading,setLoading] = React.useState(false)
    const update = (attendance) => {
        let dt = new Date(showDate)
         let at = {
          attendance,
          classDayId: htClass.classDay,
          classId: htClass._id,
          teacher: htClass.teacher,
           room: htClass.room,
           year: dt.getFullYear(),
           month: dt.getMonth() + 1,
           day: dt.getDate(),
           classDate: showDate.toISOString().substring(0,10),
           date: showDate.toISOString().substring(0,10)
        };

        net.post(`/students/${student._id}/attendance/add`, at).then(res => {
            setHtClass(prev => {
              let newStudent = { _id: student._id, attendance: res.attendance.attendance, attendanceId: res.attendance._id }
              if (res.attendance.absentReason) newStudent.absentReason = res.attendance.absentReason
              if (res.attendance.refundAmount) newStudent.refundAmount = res.attendance.refundAmount
              if(res.attendance.cantRefund) newStudent.cantRefund=res.attendance.cantRefund
              //  if(res.attendance.absentReason) newStudent.absentReason = res.attendance.absentReason
                let students = prev.students.map(s => s._id === student._id ? newStudent : s)
                return {...htClass, students}
            })
            setLoading(prev=>false)
        }).then(res => setLoading(false));
     
        setLoading(true)
    }

  return <div style={{ display: "flex" }}>{buttons.map(b => <Button key={b.attendanceType} button={b} selected={student.attendance === b.value ? true : false} update={update} loading={loading} htClass={htClass} />)}</div>;
}

function Button({ selected, button, update, loading, htClass }) {
  const [time, setTime] = React.useState(Date.now());
  const {user} = useContext(UserContext)
  React.useEffect(() => {
  const interval = setInterval(() => setTime(Date.now()), 10000);
  return () => {
    clearInterval(interval);
  };
}, []);
  let classDay = htClass.classDays.find(cd => cd._id === htClass.classDay)
  let afterStartTime = false
  if (new Date().toTimeString().substring(0, 5) > classDay.startTime.substring(0, 5)) afterStartTime = true

    let style = selected ? { backgroundColor: button.color, color: "white" } : { color: button.color, backgroundColor: "white" }
    style.width = 45
  style.height = 45
  
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    style.width = 30
    style.height = 30
    style.fontSize=16
    
  }
    
    if (selected && loading) return (
      <div style={{padding:2}}>
        <div style={{ padding: 10, ...style, borderRadius: 30 }}>
          <CircularProgress style={{ color: "white", width: 25, height: 25 }} />
        </div>
      </div>
    );

    return (
      <div style={{ padding: 2 }}>
        <IconButton
          size="large"
                style={style}
          onClick={() => update(button.value)}
          {...(afterStartTime && button.value===2 && !user.roles.includes("Manager") && {
            disabled: true, 
          })}
         >
          {button.letter || <CheckIcon />}
        </IconButton>
      </div>
    );
}

let buttons = [
  { attendanceType: "attended", color: "#00b020", value: 1 },
  { attendanceType: "late", color: "#93bd22", letter: "L", value: -1 },
  { attendanceType: "absent", color: "#fcba03", letter: "P", value: 2 },
  { attendanceType: "noShow", color: "#fc0341", letter: "O", value: 3 },
];