import React, { useContext } from "react";
import { UserContext } from "../../../../Context";
import useDB from "../../hooks/useDB";
import Loading from "../../Loading";
import StudentCare from "./StudentCare";
import CLink from "../../components/CLink";
import useCenter from "../../hooks/center";


export default function SalesCare(props) {

    const { user } = useContext(UserContext)
    const [data, setData, error, loading] = useDB(
      `/customer-care/641ada19e462ac6ff63982c6`
    );
  

  if (loading) return <Loading />
   const { customer, survey } = data;
   

 
    return (
      <div>
            <CLink customer={customer} phone />
        <h4>Students:</h4>
        {customer.students.map((s) => (
          <StudentCare
            student={s}
            key={s._id}
            survey={survey}
            setSurvey={(survey) => setData(prev => ({ ...prev, survey }))}
            chatId={survey.chatId}
          />
        ))}
      </div>
    );
}