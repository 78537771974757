import React from "react";
import Loading from "../../../Loading";
import { net } from "../../../../../database";
import FormatDate from "../../../components/FormatDate";
import InfoBox from "../../../components/InfoBox";
import HtClass from "../../../components/HtClass";
import Staff from "../../../components/Staff";


export default function Feedback(props) {
    let {id} = props

    const [feedback, setFeedback] = React.useState(undefined)
    React.useEffect(() => {
    net.get(`/students/${id}/attendance`).then(res=>setFeedback(res.filter(r=>new Date(r.createdAt).getTime()>new Date("2023-03-30").getTime()).sort((a,b) =>  (a.classDate < b.classDate) ? 1 : ((b.classDate < a.classDate) ? -1 : 0))))
},[]);



    if (!feedback) return <Loading />
    console.log(feedback)
    return (<div>{feedback.map(f => <DrawFeedback fb={f} key={f._id} />)}</div>)
}



function DrawFeedback({ fb }) {

    return (
      <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
        <div style={{width: 200, minWidth:200}}>
          <HtClass htClass={fb.htClass} />
          <div style={{fontSize: 12, color: "grey"}}>
            <FormatDate value={fb.createdAt} />
            <div>
              <Staff id={fb.teacher} />
            </div>
          </div>
        </div>
        <div>
          {fb.notes ? (
            <InfoBox
              title="Notes"
              body={fb.notes}
            />
          ) : null}
          {fb.homeWorkNotes ? (
            <InfoBox
              title="Homework"
              body={fb.homeWorkNotes}
            />
          ) : null}
        </div>
      </div>
    );
}