import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { CustomersContext } from "../../../Context";
import PhoneNumber from "./PhoneNumber";

/**
 * Description placeholder
 * @date 6/14/2023 - 6:04:46 PM
 *
 * @export
 * @param {string|object} customer string of id or object with _id, name
 * @returns {*}
 */
export default function CLink(props) {
  let { customer,phone } = props;
  let {findCustomer} = useContext(CustomersContext)
  if (typeof customer === "string") {
    let cust = findCustomer(customer)
    if (!cust) return <div>No customer</div>;
    if (!cust.name) cust.name = "Unknown Name";
    return (
      <div>
        <Link
          to={`/customer/${cust._id}`}
          className="neat"
        >
          {cust.name}
        </Link>
        <div>fdsfs{phone ? <PhoneNumber value={cust.phone} /> : null}</div>
      </div>
    );
  }
  if (!customer) return <div>No customer</div>;
  if (!customer.name) customer.name = "Unknown Name";
  return (
    <Link to={`/customer/${customer._id}`} className="neat">
      {customer.name}
    </Link>
  );
}
