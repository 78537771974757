import React, { useContext } from "react";
import { net } from "../../../../../database";
import { useParams } from "react-router-dom";
import {
  TextField,
  MenuItem,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Autocomplete,
  Chip,
} from "@mui/material";
import Loading from "../../../Loading";
import NumberFormat from "react-number-format";
import { weekdays } from "../../../../../enums";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { formatDate } from "../../../../../functions";
import "./editClass.css";
import NewBookDialog from "./NewBookDialog";
import { add, format } from "date-fns";
import CopyClassDialog from "./CopyClassDialog";
import { CenterContext, StaffContext } from "../../../../../Context";

export default function EditClass(props) {
  let { id } = useParams();
  const [htClass, setHtClass] = React.useState(undefined);
 const [render, setRender] = React.useState(0);

  const [newClassDayDialog, setNewClassDayDialog] = React.useState(false);
  const [newClassDay, setNewClassDay] = React.useState({});
  const [feeDialog, setFeeDialog] = React.useState(false);
  const [newFee, setNewFee] = React.useState({
    feeChangeDate: "",
    fee: 0,
  });

  const [feeError, setFeeError] = React.useState(false);
  const [feeAmountError, setFeeAmountError] = React.useState(false);
  const [feeErrorReason, setFeeErrorReason] = React.useState("");
  const [books, setBooks] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [ccOpen, ccSetOpen] = React.useState(false);

  const { center } = useContext(CenterContext)
  const {getStaffRole} = useContext(StaffContext)
  React.useEffect(() => {
    net.get(`/class/${id}/getedit`).then((r) => {
      setHtClass(r.htClass);
     // setManagers(r.managers);
     // setTeachers(r.teachers);
      setBooks(r.books);
    });
  }, [id]);

  if (!htClass) return <Loading />;

  let location = "";
  let rooms = [];
  if (htClass.location) {
    location = htClass.location;
    rooms = center.locations.find((l) => l._id === htClass.location).rooms;
  }

  const updateClass = (field) => {
    console.log(field);
    net.post(`/classes/${id}/update`, field).then((htClass) => {
      setHtClass(htClass);
    });
    //setChange(false);
  };

  const copyClassDay = (classDay) => {
    delete classDay._id;
    delete classDay.createdAt;
    delete classDay.updatedAt;
    net
      .post(`/classes/${id}/copyClassDay`, classDay)
      .then((r) => setHtClass(r));
  };

  const deleteClassDay = (classDay) => {
    net
      .post(`/classes/${id}/deleteclassday`, classDay)
      .then((r) => setHtClass(r));
  };

  const addNewClassFee = () => {
    if (newFee.feeChangeDate === "") {
      setFeeError(true);
      setFeeErrorReason("Please choose a date");
      return;
    }

    if (newFee.fee === "") {
      setFeeAmountError(true);
      return;
    }
    htClass.fee.push(newFee);
    net.post(`/classes/${id}/update`, { fee: htClass.fee }).then((htClass) => {
      setHtClass(htClass);
      setFeeDialog(false);
      setNewFee({
        feeChangeDate: "",
        fee: "",
      });
      setFeeError(false);
      setFeeErrorReason("");
    });
  };

  const deleteFee = (fee) => {
    try {
      let index = htClass.fee.findIndex((f) => f._id === fee._id);
      htClass.fee.splice(index, 1);
      net
        .post(`/classes/${id}/update`, { fee: htClass.fee })
        .then((htClass) => {
          setHtClass(htClass);
          setNewFee({
            feeChangeDate: "",
            fee: 0,
          });
          setFeeError(false);
          setFeeErrorReason("");
        });
    } catch (err) {
      console.log(err);
    }
  };

  let newestFee = htClass.fee.filter(
    (f) => new Date(f.feeChangeDate) < new Date()
  );

  if (newestFee && newestFee.length > 0)
    htClass.currentFee = newestFee[newestFee.length - 1].fee;

  const updateNumberOfLessons = (numberOfLessons) => {
    if (htClass.startDate && htClass.startDate !== "") {
      let d = new Date(htClass.startDate);
      let classDays = htClass.classDays.map((cd) => cd.day);
      let hit = 0;
      do {
        d = add(d, { days: 1 });
        if (
          !checkHolidays(center.holidays, d) &&
          classDays.includes(d.getDay())
        )
          hit++;
      } while (hit < numberOfLessons && hit < 1000);

      let endDate = d;
      setHtClass({
        ...htClass,

        endDate,
        numberOfLessons,
      });
    } else {
      setHtClass({ ...htClass, numberOfLessons });
    }
  };

  function checkHolidays(holidays, checkdate) {
    let check = false;
    holidays.forEach((holiday) => {
      let start = new Date(`${holiday.range[0]} 00:00:00`);
      let end = new Date(`${holiday.range[1]} 00:00:00`);
      if (checkdate >= start && checkdate <= end) {
        check = true;
      }
    });
    return check;
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContenr: "space-between" }}>
        <div className="edit-class-top">
          <Stack spacing={2}>
            <TextField
              value={htClass.name}
              label="Name"
              onChange={(e) => {
                setHtClass({ ...htClass, name: e.target.value });
              }}
              onBlur={() => updateClass({ name: htClass.name })}
            />
            <TextField
              value={htClass.location}
              label="Location"
              select
              onChange={(e) =>
                setHtClass({ ...htClass, location: e.target.value })
              }
              onBlur={() => updateClass({ location: htClass.location })}
            >
              {center.locations.map((l) => (
                <MenuItem key={l._id} value={l._id}>
                  {l.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              value={htClass.teacher}
              label="Teacher"
              select
              onChange={(e) => {
                setHtClass({ ...htClass, teacher: e.target.value });
              }}
              onBlur={() => updateClass({ teacher: htClass.teacher })}
            >
              {getStaffRole("Teacher").map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              value={htClass.manager}
              label="Manager"
              select
              onChange={(e) => {
                setHtClass({ ...htClass, manager: e.target.value });
              }}
              onBlur={() => updateClass({ manager: htClass.manager })}
            >
              {getStaffRole("Manager").map((manager) => (
                <MenuItem key={manager._id} value={manager._id}>
                  {manager.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              value={htClass.status}
              label="Status"
              select
              onChange={(e) => {
                setHtClass({ ...htClass, status: e.target.value });
              }}
              onBlur={() => updateClass({ status: htClass.status })}
            >
              {center.classTypes.map((classStatus) => (
                <MenuItem key={classStatus} value={classStatus}>
                  {classStatus}
                </MenuItem>
              ))}
            </TextField>
            <div style={{ display: "flex" }}>
              <NumberFormat
                customInput={TextField}
                thousandSeparator={true}
                autoComplete="off"
                value={htClass.currentFee}
                label="Fee"
                fullWidth
              />
              <IconButton
                onClick={() => setFeeDialog(true)}
                sx={{ fontSize: 16, marginTop: 1 }}
              >
                <EditIcon />
              </IconButton>
            </div>
            <NumberFormat
              customInput={TextField}
              thousandSeparator={true}
              autoComplete="off"
              value={htClass.photocopyFee}
              label="Photocopy Fee"
              fullWidth
              onValueChange={(value) => {
                setHtClass({ ...htClass, photocopyFee: value.value });
              }}
              onBlur={() => updateClass({ photocopyFee: htClass.photocopyFee })}
            />
          </Stack>
        </div>
        <div className="edit-class-top">
          <Stack spacing={2}>
            {/*  <TextField
              value={htClass.color}
              label="Color"
              onChange={(e) => {
                setHtClass({ ...htClass, color: e.target.value });
              }}
              onBlur={(e) => updateClass({ color: htClass.color })}
              select
            >
              {classColors.map((color) => (
                <MenuItem key={htClass.name + color.name} value={color.name}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: color.color,
                        width: 20,
                        height: 20,
                        border: "2px solid",
                        marginRight: 10,
                      }}
                    ></div>
                    <div>{color.name}</div>
                  </div>
                </MenuItem>
              ))}
            </TextField> */}

            <TextField
              value={htClass.minStudents}
              type="number"
              label="Minimum number of students"
              onChange={(e) => {
                setHtClass({ ...htClass, minStudents: e.target.value });
              }}
              onBlur={(e) => updateClass({ minStudents: htClass.minStudents })}
            />
            <TextField
              value={htClass.maxStudents}
              type="number"
              label="Maximum number of students"
              onChange={(e) => {
                setHtClass({ ...htClass, maxStudents: e.target.value });
              }}
              onBlur={(e) => updateClass({ maxStudents: htClass.maxStudents })}
            />

            <TextField
              type="date"
              InputLabelProps={{ shrink: true }}
              value={
                htClass.startDate && htClass.startDate !== ""
                  ? htClass.startDate.substring(0, 10)
                  : ""
              }
              onChange={(e) => {
                setHtClass({ ...htClass, startDate: e.target.value });
              }}
              onBlur={() => updateClass({ startDate: htClass.startDate })}
              label="Start date"
            />
            <TextField
              value={htClass.numberOfLessons}
              type="number"
              label="Number of lessons in this course"
              onChange={(e) => {
                updateNumberOfLessons(parseInt(e.target.value));
              }}
              onBlur={() =>
                updateClass({
                  numberOfLessons: htClass.numberOfLessons,
                  endDate: htClass.endDate,
                })
              }
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              value={
                htClass.endDate
                  ? format(new Date(htClass.endDate), "dd/MM/yyyy")
                  : ""
              }
              readOnly
            />
            <div style={{ display: "flex" }}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={books}
                getOptionLabel={(option) => option.name}
                value={htClass.books}
                /*   onChange={(e, value) => setHtClass({ ...htClass, books: value })} */
                onChange={(e, value) => updateClass({ books: value })}
                renderInput={(params) => (
                  <TextField {...params} label={"Teaching materials"} />
                )}
                style={{ flexGrow: 1 }}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      style={{
                        background:
                          "linear-gradient(to right, #616161, #9bc5c3)",

                        color: "white",
                      }}
                      variant="outlined"
                      label={`${option.name}`}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
              />
              <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
              </IconButton>
            </div>
          </Stack>
        </div>
      </div>
      <hr />
      <div className="dflex-sb">
        <h3>
          Classes Per Week: {htClass.classDays ? htClass.classDays.length : 0}
        </h3>
        <Button
          variant="contained"
          color="error"
          onClick={() => ccSetOpen(true)}
        >
          Create new class from this class
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "",
          marginTop: 10,
        }}
      >
        {htClass.classDays
          .sort((a, b) => (a.day > b.day ? 1 : -1))
          .map((cd, i) => (
            <div className="class-day">
              <Stack
                key={cd._id}
                spacing={2}
                sx={{ padding: 2, borderRadius: 5, margin: 2 }}
              >
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  {i>0 && <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => deleteClassDay(cd)}
                  >
                    <DeleteIcon sx={{ fontSize: 16, color: "red" }} />
                  </IconButton>}
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => copyClassDay(cd)}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </div>
                <TextField
                  value={cd.day}
                  label="Day of the week"
                  select
                  onChange={(e) => {
                    htClass.classDays[i].day = e.target.value;

                    setHtClass(htClass);
                    setRender(render + 1);
                    // setChange(true);
                  }}
                  onBlur={() => {
                    net
                      .post(
                        `/classes/${htClass._id}/updateclassday/${cd._id}`,
                        cd
                      )
                      .then((r) => {
                        setHtClass(r);
                      });
                  }}
                >
                  {weekdays.map((w, i) => (
                    <MenuItem key={"wd" + i} value={i}>
                      {w[props.language]}
                    </MenuItem>
                  ))}
                </TextField>
                <div style={{ display: "flex" }}>
                  <TextField
                    value={cd.startTime}
                    label="Start Time"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      cd.startTime = e.target.value;

                      setHtClass(htClass);
                      setRender(render + 1);
                      // setChange(true);
                    }}
                    onBlur={() => {
                      net
                        .post(
                          `/classes/${htClass._id}/updateclassday/${cd._id}`,
                          cd
                        )
                        .then((r) => {
                          setHtClass(r);
                        });
                    }}
                  />

                  <TextField
                    value={cd.endTime}
                    label="Finish Time"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      cd.endTime = e.target.value;

                      setHtClass(htClass);
                      setRender(render + 1);
                      // setChange(true);
                    }}
                    onBlur={() => {
                      net
                        .post(
                          `/classes/${htClass._id}/updateclassday/${cd._id}`,
                          cd
                        )
                        .then((r) => {
                          setHtClass(r);
                        });
                    }}
                  />
                </div>
                <TextField
                  value={cd.room ? cd.room : ""}
                  label="Room"
                  select
                  onChange={(e) => {
                    let tclass = htClass;
                    tclass.classDays[i].room = e.target.value;

                    setHtClass(tclass);
                    setRender(render + 1);
                    // setChange(true);
                  }}
                  onBlur={() => {
                    net
                      .post(
                        `/classes/${htClass._id}/updateclassday/${cd._id}`,
                        cd
                      )
                      .then((r) => {
                        setHtClass(r);
                      });
                  }}
                >
                  {rooms.map((u) => (
                    <MenuItem key={"room" + u} value={u}>
                      {u}
                    </MenuItem>
                  ))}
                </TextField>
                {!cd.otherTeachers
                  ? " "
                  : cd.otherTeachers.map((teacher, oti) => (
                      <Stack spacing={2}>
                        <div>Extra Teachers</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            value={teacher.teacher}
                            label="Teacher"
                            select
                            size="small"
                            style={{ flexGrow: 1 }}
                            onChange={(e) => {
                              cd.otherTeachers[oti].teacher = e.target.value;

                              net
                                .post(
                                  `/classes/${htClass._id}/updateclassday/${cd._id}`,
                                  cd
                                )
                                .then((r) => {
                                  setHtClass(r);
                                });
                            }}
                          >
                            {getStaffRole("Teacher").map((teacher) => (
                              <MenuItem key={teacher._id} value={teacher._id}>
                                {teacher.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ marginLeft: 1 }}
                          onClick={() => {
                              console.log(teacher)
                              cd.otherTeachers = cd.otherTeachers.filter(ot=>ot._id!==teacher._id)
                              net
                                .post(
                                  `/classes/${htClass._id}/updateclassday/${cd._id}`,
                                  cd
                                )
                                .then((r) => {
                                  setHtClass(r);
                                });
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: 16,
                                color: "red",
                                width: 30,
                              }}
                            />
                          </IconButton>
                        </div>
                        <div style={{ display: "flex" }}>
                          <TextField
                            value={teacher.startTime ? teacher.startTime : ""}
                            label="Start Time"
                            type="time"
                            size="small"
                            onChange={(e) => {
                              htClass.classDays[i].otherTeachers[
                                oti
                              ].startTime = e.target.value;
                              setHtClass(htClass);
                              setRender(render + 1);
                            }}
                            onBlur={(e) => {
                              net
                                .post(
                                  `/classes/${htClass._id}/updateclassday/${cd._id}`,
                                  cd
                                )
                                .then((r) => {
                                  setHtClass(r);
                                });
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            value={teacher.endTime ? teacher.endTime : ""}
                            label="Finish Time"
                            type="time"
                            size="small"
                            onChange={(e) => {
                              htClass.classDays[i].otherTeachers[oti].endTime =
                                e.target.value;
                              setHtClass(htClass);
                              setRender(render + 1);
                            }}
                            onBlur={(e) => {
                              net
                                .post(
                                  `/classes/${htClass._id}/updateclassday/${cd._id}`,
                                  cd
                                )
                                .then((r) => {
                                  setHtClass(r);
                                });
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </Stack>
                    ))}
                <Button
                  onClick={() => {
                    if (!htClass.classDays[i].otherTeachers)
                      htClass.classDays[i].otherTeachers = [];
                    htClass.classDays[i].otherTeachers.push({
                      teacher: "",
                      startTime: "",
                      endTime: "",
                    });
                    setHtClass(htClass);
                    setRender(render + 1);
                  }}
                >
                  Add Extra Teacher
                </Button>
              </Stack>
            </div>
          ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <IconButton
              aria-label="delete"
              onClick={() => setNewClassDayDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <Dialog open={newClassDayDialog}>
        <DialogTitle>New day for {htClass.name}</DialogTitle>
        <DialogContent>
          <Stack
            key={"newClassDay"}
            spacing={2}
            /* sx={{ padding: 2, border: "1px solid", borderRadius: 5, margin: 2 }} */
          >
            <TextField
              value={newClassDay.day ? newClassDay.day : ""}
              label="Day of the week"
              select
              onChange={(e) => {
                setNewClassDay({ ...newClassDay, day: e.target.value });
              }}
            >
              {weekdays.map((w, i) => (
                <MenuItem key={"wd" + i} value={i}>
                  {w[props.language]}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              value={newClassDay.startTime ? newClassDay.startTime : ""}
              label="Start Time"
              type="time"
              onChange={(e) => {
                setNewClassDay({ ...newClassDay, startTime: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              value={newClassDay.endTime ? newClassDay.endTime : ""}
              label="Finish Time"
              type="time"
              onChange={(e) => {
                setNewClassDay({ ...newClassDay, endTime: e.target.value });
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              value={newClassDay.room ? newClassDay.room : ""}
              label="Room"
              select
              onChange={(e) => {
                setNewClassDay({ ...newClassDay, room: e.target.value });
              }}
            >
              {rooms.map((u) => (
                <MenuItem key={"room" + u} value={u}>
                  {u}
                </MenuItem>
              ))}
            </TextField>
            {!newClassDay.otherTeachers
              ? " "
              : newClassDay.otherTeachers.map((teacher, i) => (
                  <Stack spacing={2}>
                    <TextField
                      value={teacher.teacher}
                      label="Teacher"
                      select
                      onChange={(e) => {
                        newClassDay.otherTeachers[i].teacher = e.target.value;

                        setNewClassDay(newClassDay);
                      }}
                    >
                      {getStaffRole("Teacher").map((teacher) => (
                        <MenuItem key={teacher._id} value={teacher._id}>
                          {teacher.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      value={teacher.startTime ? teacher.startTime : ""}
                      label="Start Time"
                      type="time"
                      onChange={(e) => {
                        newClassDay.otherTeachers[i].startTime = e.target.value;

                        setNewClassDay(newClassDay);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      value={teacher.endTime ? teacher.endTime : ""}
                      label="Finish Time"
                      type="time"
                      onChange={(e) => {
                        newClassDay.otherTeachers[i].endTime = e.target.value;
                        setNewClassDay(newClassDay);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                ))}
            <Button
              onClick={() => {
                let ncd = newClassDay;
                if (!ncd.otherTeachers) ncd.otherTeachers = [];
                ncd.otherTeachers.push({
                  teacher: "",
                  startTime: "",
                  endTime: "",
                  hours: 0,
                });

                setNewClassDay(ncd);
                setRender(render + 1);
              }}
            >
              Add teacher
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setNewClassDay({});
              setNewClassDayDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              net.post(`/classes/${id}/newclassday`, newClassDay).then((r) => {
                setHtClass(r);
                setNewClassDayDialog(false);
                setNewClassDay({});
              });
            }}
          >
            Add Day
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={feeDialog}>
        <DialogTitle>Change fee for {htClass.name}</DialogTitle>
        <DialogContent>
          <div style={{ fontSize: 11 }}>Past fees</div>
          {htClass.fee
            .filter((f) => new Date(f.feeChangeDate) < new Date())
            .map((fee) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>{formatDate(fee.feeChangeDate.substring(0, 10))}</div>
                <div style={{ width: 50 }}></div>
                <div>{fee.fee}</div>
              </div>
            ))}
          <hr />
          {htClass.fee.filter((f) => new Date(f.feeChangeDate) >= new Date())
            .length > 0 ? (
            <div>
              <div style={{ fontSize: 11 }}>Future fees</div>
              {htClass.fee
                .filter((f) => new Date(f.feeChangeDate) > new Date())
                .map((fee, index) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{formatDate(fee.feeChangeDate.substring(0, 10))}</div>
                    <div style={{ width: 50 }}></div>
                    <div>{fee.fee}</div>
                    <Tooltip title="Delete future fee">
                      <IconButton onClick={() => deleteFee(fee, index)}>
                        <DeleteIcon color="error" style={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))}
              <hr />
            </div>
          ) : (
            ""
          )}
          <Stack spacing={2}>
            <TextField
              type="date"
              variant="standard"
              label="Date of new fee"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newFee.feeChangeDate}
              onChange={(e) =>
                setNewFee({ ...newFee, feeChangeDate: e.target.value })
              }
              {...(feeError && { error: true, helperText: feeErrorReason })}
            ></TextField>
            <NumberFormat
              customInput={TextField}
              variant="standard"
              thousandSeparator={true}
              autoComplete="off"
              fullWidth
              label="New Fee"
              value={newFee.fee}
              onValueChange={(value) => {
                setNewFee({ ...newFee, fee: value.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setFeeDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              addNewClassFee();
            }}
          >
            Add New Fee
          </Button>
        </DialogActions>
      </Dialog>
      <NewBookDialog open={open} setOpen={setOpen} setBooks={setBooks} />
      <CopyClassDialog open={ccOpen} setOpen={ccSetOpen} htClass={htClass} />
    </div>
  );
}
