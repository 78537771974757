import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, Switch, TextField } from "@mui/material";
import format from "date-format";
import React, { useContext } from "react";
import { net } from "../../../../../database";
import { weekdays } from "../../../../../enums";
import { ClassesContext } from "../../../../../Context";
import Weekday from "./Weekday";
import Time from "../../../components/Time";

export default function EditClassDialog(props) {
  const [reason, setReason] = React.useState("");
  const [reasonErr, setReasonErr] = React.useState(false);
  const {student,classToChange} = props

  const toggleClassDay = (e, classDayId) => {
    if (reason === "") {
      setReasonErr(true);
      return;
    }
    net
      .post(`/student/${props.student._id}/classes/changeclassday`, {
        action: e.target.checked,
        classDayId,
        reason,
      })
      .then((doesntStudy) => {
        props.setStudent({ ...props.student, doesntStudy });
        setReasonErr(false);
        setReason("");
      });
  };

  if (!props.classToChange) return <div></div>;
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>
        Edit days {props.student.name} attends{" "}
        {props.classToChange ? props.classToChange.name : ""}
      </DialogTitle>
      <DialogContent>
        <TextField
          label={"Reason for changing"}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          fullWidth
          {...(reasonErr && { error: true })}
          sx={{ mt: 1, mb: 1 }}
        />
        <ClassDays student={student} classToChange={classToChange} toggleClassDay={toggleClassDay} />
       {/*  {props.student.classes.find((c) => c._id === props.classToChange._id)
          ? props.student.classes
              .find((c) => c._id === props.classToChange._id)
              .classDays.map((cd) => (
                <div
                  style={{
                    display: "flex",
                  }}
                  key={cd._id}
                >
                  <div
                    style={{
                      paddingTop: 7,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      color: "black",
                    }}
                    {...(props.student.doesntStudy.includes(cd._id) && {
                      style: {
                        paddingTop: 7,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "grey",
                      },
                    })}
                  >
                    <div>{weekdays[cd.day][props.language]}</div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div>
                      {format("hh:mm", new Date("2022-01-01 " + cd.startTime))}{" "}
                      -{" "}
                    </div>
                    <div>
                      {format("hh:mm", new Date("2022-01-01 " + cd.endTime))}
                    </div>
                  </div>
                  <Switch
                    checked={!props.student.doesntStudy.includes(cd._id)}
                    onChange={(e) => toggleClassDay(e, cd._id)}
                  />
                </div>
              ))
          : ""} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function ClassDays(props) {
  const {student,classToChange,toggleClassDay}= props
  console.log(classToChange)
  return (
    <div>
      {classToChange.classDays.map((cd) => (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center" }} key={cd._id}>
          <div>
            <div style={{display:"flex"}}>
              
              <div style={{width:120}}><Weekday id={cd.day} /></div>
              <div> <Time time={cd.startTime} /> - <Time time={cd.endTime} /></div>
              </div>
          </div>
          <Switch
            checked={!student.doesntStudy.includes(cd._id)}
            onChange={(e) => toggleClassDay(e, cd._id)}
          />
        </div>
      ))}
    </div>
  );
}