import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";


/**
 * Description placeholder
 * @date 6/17/2023 - 3:56:45 PM
 * Generic cancel button
 * @export
 * @param action: function - What to do when the button is clicked
 * @param size: number - how wide and high the button is
 * @param tooltip: string - tooltip text
 * @returns {*}
 */
export default function AddButton({ action, size,style,tooltip="" }) {
  if (!size) size = 40;
  return (
    <Tooltip title={tooltip}>
      <IconButton
        color="success"
        onClick={action}
        style={{ height: size, width: size, ...style }}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}
