import React, { useState } from "react";
import AddButton from "../../components/AddButton";
import DDialog from "../../components/DDialog";
import Loading from "../../Loading";

import { Link } from "react-router-dom";
import "./curriculum.css"
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";
import Bcs from "./Bcs";


export default function Curriculum(props) {
    const [addNew, setAddNew] = useState(false)
    const [curriculum,setCurriculum,error,loading] = useDB("/curriculum")
 
    if (loading) return <Loading />
    if (error) return <Error />
    
    console.log(curriculum)
    return (
      <div>
        <Bcs />

        <DDialog
          fields={fields}
          open={addNew}
          setOpen={setAddNew}
          route="/curriculum"
          ok="Add new"
          required={["name"]}
          title="Add new curriculum"
          newData={(newCurriculum) =>
            setCurriculum((prev) => [...prev, newCurriculum])
          }
        />
        <div className="cur-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Curriculum</h2>
            <AddButton
              action={() => setAddNew(true)}
              tooltip="Add new curriculum"
            />
          </div>
          <table className="cur-table">
            <tr>
              <th>Name</th>
              <th>Units</th>
              <th>Lessons</th>
            </tr>
            {curriculum.map((c) => (
              <tr>
                <td>
                  <Link
                    to={`/curriculum/${c._id}`}
                    className="cur-link"
                  >
                    {c.name}{" "}
                  </Link>
                </td>
                <td width="100px">{c.nunits}</td>
                <td width="100px">{c.nlessons}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    );
}
const fields = [{
    name: "name",label:"Name"
}]