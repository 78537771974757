import React from "react";
import Lang from "../../components/Lang";
import DrawStudent from "./DrawStudent";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { subDays } from "date-fns";
import Title from "./Title";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";


export default function Changes(props) {
  const {year,month} = props
const [students, setStudents, error, loading] = useDB(
  `/newlost/${year}/${month}`
);

  if (loading) return <Loading />
  if(error) return <Error />

return (
  <div>
 
    <h3>
      <Lang w="statusChangesToday" />
    </h3>
  <Title reason returnDate/>
    {students.filter(s=>new Date(s.statusChanged)>=new Date(year,month, new Date().getDate(), 7)).map((student) => (
      <DrawStudent student={student} key={student._id}/>
    ))}
     <h3>
     Status changes this month
    </h3>
    <Title reason returnDate/>
    {students.map((student) => (
      <DrawStudent student={student} key={student._id}/>
    ))}
  </div>
);
}
