import React from "react";
import StudentLink from "../../components/StudentLink";
import FormatDate from "../../components/FormatDate";


export default function DrawStudent(props) {
    const { student } = props
    

    return (
      <div style={{ display: "flex" }}>
        <StudentLink
          student={student._id}
         /*  showClasses */
          style={{ width: 400 }}
        />
        <div style={{ width: 150 }}>
          <FormatDate
            value={
              student.statusHistory[student.statusHistory.length - 1].startDate
            }
          />
        </div>
        {student.status === "Pending" ? (
          <div style={{ width: 150 }}>
            <FormatDate
              value={
                student.statusHistory[student.statusHistory.length - 1]
                  .returnDate
              }
            />
          </div>
        ) : null}
      </div>
    );
}