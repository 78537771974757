import React, { useContext } from "react";
import { ClassesContext } from "../../../Context";
import { Link } from "react-router-dom";


/**
 * 
 * @param {string} htClass the id of the class  
 * @returns 
 */


export default function HtClass(props) {
  const { htClass, link,style } = props;
  const { findClass } =
    useContext(ClassesContext);
  const cl = findClass(htClass);
  if (!htClass || !cl) return <>No HT Class</>;

  if (!link) return <>{cl.name}</>;
  return <Link to={`/class/${htClass}`} style={style} className="neat">{cl.name}</Link>
}
