import React from "react";
import ClassView from "./ClassView";
import Time from "../../../components/Time";
import PrintTimetable from "./PrintTimetable";

export default function ListClasses(props) {
  let { classes } = props;

  let startTimes = [
    ...new Set(
      props.classes.map((c) =>
        c.makeUpClass || c.extraClass ? c.startTime : c.classDay.startTime.substring(0,5)
      )
    ),
  ];
  startTimes.sort();

  return (
    <div>
      {startTimes.map((st) => (
        <div key={st}>
          <div
            style={{
              fontSize: 12,
              padding: 1,
              textAlign: "center",
              background:
                "linear-gradient(49deg, rgba(2,0,36,1) 0%, rgba(191,191,191,1) 0%, rgba(237,237,237,1) 100%)",
              borderRadius: 5,
            }}
          >
            <Time time={st} />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {classes
              .filter((c) =>
                c.makeUpClass || c.extraClass ? c.startTime === st : c.classDay.startTime.substring(0,5) === st
              )
              .map((c) => (
                <div key={c._id} style={{ marginRight: 3 }}>
                  <ClassView
                    htClass={c}
                    /* teachers={props.todaysClasses.teachers} */
                    {...props}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
       <div id="printDiv" style={{ display: "none" }}>
        <PrintTimetable classes={classes} startTimes={startTimes}  />

      </div>
    </div>
  );
}
