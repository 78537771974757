import { FormControl, TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import L from "../../../../components/Lang";
import Lang from "../../../../components/Lang";

export default function Total(props) {
  let { payment, setPayment } = props;

  return (
    <FormControl fullWidth sx={{ m: 1 }}>
      <NumberFormat
        key={"total"}
        customInput={TextField}
        InputProps={{
          sx: {
            "& input": {
              textAlign: "right",
            },
          },
        }}
        thousandSeparator={true}
        onValueChange={(value) => {
          setPayment({ ...payment, amount: value.value });
        }}
        /*   onBlur={() => setPayment({ ...payment, amount })} */

        value={payment.amount}
        label={<Lang w="amount" />}
      />
    </FormControl>
  );
}
