import React from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberFormat from "react-number-format";
export default function StatChange(props) {
  //const {l,language} = props;
  const { stats, stat } = props;
  let difference =
    parseInt(stats[stats.length - 1][stat]) -
      parseInt(stats[stats.length - 2][stat]) || 0;

  return (
    <div style={{ marginLeft: 5, display: "flex", alignItems: "center" }}>
      {difference < 0 ? (
        <ExpandMoreIcon style={{ color: "red" }} />
      ) : (
        <ExpandLessIcon style={{ color: "green" }} />
      )}
      <div>
        {" "}
        <NumberFormat
          displayType="text"
          value={Math.abs(difference)}
          thousandSeparator
        />{" "}
        from yesterday
      </div>
    </div>
  );
}
