import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { net } from "../../../database";

import VND from "./VND";

export default function CalculateTuitionForClasses(props) {
  const { lead } = props;
  const [value, setValue] = React.useState(4);
  const [tuition, setTuition] = React.useState(undefined);

  React.useEffect(() => {
    if (lead.firstClassDate) {
      net
        .get(
          `/student/${lead._id}/calculatebills/${lead.firstClassDate.substring(
            0,
            10
          )}`
        )
        .then((res) => setTuition(res));
    }
  }, [lead]);

  if (!tuition) return <div></div>;
  if (!lead.firstClassDate) return <div>No Class</div>;

  return (
    <div style={{ marginLeft: 10, display: "flex", flexDirection: "column" }}>
      <FormControl>
        <div className="small-text">Tuition</div>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {/*  <FormControlLabel value={4} control={<Radio />} label="Full course" /> */}
          <div className="small-text">6 months</div>
          <FormControlLabel
            value={6}
            control={<Radio />}
            label={<Tuition tuition={tuition.sixMonths} />}
          />
          <div className="small-text">3 months</div>
          <FormControlLabel
            value={3}
            control={<Radio />}
            label={<Tuition tuition={tuition.threeMonths} />}
          />
          <div className="small-text">1 month</div>
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={<Tuition tuition={tuition.oneMonth} />}
          />
        </RadioGroup>
      </FormControl>
      <Button>Add Tuition</Button>
    </div>
  );
}

function Tuition({ tuition }) {
 
  let numberOfLessons = 0;
  tuition.forEach((t) => {
    if (t.numberOfLessons) numberOfLessons += t.numberOfLessons;
  });
  return (
    <div>
      {numberOfLessons} classes,{" "}
      <VND value={tuition.reduce((a, b) => a + b.amount, 0)} />
    </div>
  );
}
