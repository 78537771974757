import React, { useContext } from "react";
import { LanguageContext } from "../../../../../Context";
import { weekdays } from "../../../../../enums";


export default function Weekday(props) {
    const {id} = props
    const { language } = useContext(LanguageContext)
    if(!id && id!==0) return <React.Fragment>Invalid id</React.Fragment>
    
    return(<React.Fragment>{weekdays[id][language]}</React.Fragment>)
}