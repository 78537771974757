import React from "react";
import { IconButton, TextField} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import StudentNamePhone from "../../student/components/StudentNamePhone";
import { net } from "../../../../../database";
import StudentLink from "../../../components/StudentLink";

export default function AttendanceButtons(props) {
  let { htClass, setHtClass, student, updateLesson } = props;
  const [loading, setLoading] = React.useState(false);
  let [absentReason, setAbsentReason] = React.useState(
    student.attendance?.absentReason || ""
  );

  let lesson = {
    attended: [],
    late: [],
    absent: [],
    noShow: [],
  };
  if (htClass.lesson) lesson = htClass.lesson;

  const updateAttendance = (student, attendanceType) => {
    let attendance = getAttendanceCode(attendanceType);

    let at = {
      attendance,
      classDayId: htClass.classDay._id,
      classId: htClass._id,
      teacher: htClass.teacher._id,
      room: htClass.classDay.room,
    };

    net
      .post(`/students/${student._id}/attendance/add`, at)
      .then(({ lesson, attendance }) => {
        htClass.lesson = lesson;

        student.attendance = attendance;
        student.loading = false;
        setLoading(false);
         updateLesson(lesson);

        setHtClass(htClass);

      });
   
  };

  const updateAbsentReason = (e, student) => {
    net
      .post(`/students/absentReason/${student.attendance._id}`, {
        absentReason: e.target.value,
      })
      .then((attendance) => {
        let students = htClass.students;
        let st = students.find((s) => s._id === attendance.student);
        st.attendance = attendance;
        setHtClass({ ...htClass, students });
      });
  };

  let buttons = [
    { attendanceType: "attended", color: "#00b020", number: 1 },
    { attendanceType: "late", color: "#93bd22", letter: "L", number: -1 },
    { attendanceType: "absent", color: "#fcba03", letter: "P", number: 2 },
    { attendanceType: "noShow", color: "#fc0341", letter: "O", number: 3 },
  ];
  if (student.doesntStudy.includes(htClass.classDay._id)) return <div></div>;
  if (student.firstClassDate && new Date(student.firstClassDate) > Date.now()) return <div></div>;
  // Mobile
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  )
    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 width={300}><StudentLink student={student._id} /></h3>

            <div style={{ display: "flex", width: 200, marginRight: 15 }}>
              {buttons.map((button) => (
                <AttendanceButton
                  key={"AttendanceButton" + button.attendanceType + student._id}
                  student={student}
                  lesson={lesson}
                  updateAttendance={updateAttendance}
                  attendanceType={button.attendanceType}
                  buttonColor={button.color}
                  size="large"
                  letter={button.letter}
                />
              ))}
            </div>
          </div>

          <AbsentReason
            lesson={lesson}
            absentReason={absentReason}
            setAbsentReason={setAbsentReason}
            updateAbsentReason={updateAbsentReason}
            student={student}
          />
        </div>
        <hr />
      </div>
    );
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StudentNamePhone student={student._id} width={300} />

        <div style={{ display: "flex", width: 250 }}>
          {buttons.map((button) => (
            <AttendanceButton
              key={"AttendanceButton" + button.attendanceType}
              student={student}
              lesson={lesson}
              updateAttendance={updateAttendance}
              attendanceType={button.attendanceType}
              buttonColor={button.color}
              size="medium"
              loading={loading}
              letter={button.letter}
            />
          ))}
        </div>

        <AbsentReason
          lesson={lesson}
          absentReason={absentReason}
          setAbsentReason={setAbsentReason}
          updateAbsentReason={updateAbsentReason}
          student={student}
        />
      </div>
      <hr />
    </div>
  );
}

const AttendanceButton = (props) => {
  let {
    lesson,
    student,
    updateAttendance,
    attendanceType,
    buttonColor,
    size,
    letter,
  } = props;

  let backgroundColor = "white";
  let color = buttonColor;

  if (lesson && lesson[attendanceType].includes(student._id)) {
    backgroundColor = buttonColor;
    color = "white";
  }

  return (
    <div style={{ padding: 5 }}>
      <IconButton
        onClick={() => updateAttendance(student, attendanceType)}
        style={{ backgroundColor, color, width: 45, height: 45 }}
        size={size}
      >
        {attendanceType === "attended" ? <CheckIcon /> : <div>{letter}</div>}
      </IconButton>
    </div>
  );
};

const AbsentReason = (props) => {
  let { lesson, setAbsentReason, updateAbsentReason, absentReason, student } =
    props;
  return (
    <div>
      {lesson &&
      (lesson.absent.includes(student._id) ||
        lesson.noShow.includes(student._id)) ? (
        <div style={{ display: "flex", width: 400, justifyContent: "center" }}>
          {" "}
          <TextField
            label="Reason for absence"
            size="small"
            sx={{ width: 400 }}
            value={absentReason}
            onChange={(e) => setAbsentReason(e.target.value)}
            onBlur={(e) => updateAbsentReason(e, student)}
            fullWidth
          />
        </div>
      ) : (
        <div style={{ width: 400 }}></div>
      )}
    </div>
  );
};

const getAttendanceCode = (attendanceType) => {
  let attendance = 1;
  switch (attendanceType) {
    case "late":
      attendance = -1;
      break;
    case "absent":
      attendance = 2;
      break;
    case "noShow":
      attendance = 3;
      break;
    default:
      attendance = 1;
      break;
  }
  return attendance;
};
