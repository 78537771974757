import React from "react";
import "./projection.css";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import useDB from "../../hooks/useDB";
import VND from "../../components/VND";

import UpDownChevron from "../../components/UpDownChevron";


export default function Projection(props) {

const [stats, setStats, error, loading] = useDB(`/projection`);




if (loading) return <Loading />;
if (error) return <Error />;




    return (
      <div>
        <h2>Current projection</h2>
        <table className="main-table">
          <tr>
            <td>Salaries</td>
            <td className="figure">
              <VND value={stats[0].salaryProjection} />
            </td>
            <td className="figure difference">
              <Difference
                stats={stats}
                field="salaryProjection"
              />
            </td>
          </tr>
          <tr>
            <td>Wages</td>
            <td className="figure">
              <VND value={stats[0].wageProjection} />
            </td>
            <td className="figure difference">
              <Difference
                stats={stats}
                field="wageProjection"
              />
            </td>
          </tr>
          <tr>
            <td>Estimated costs</td>
            <td className="figure">
              <VND value={stats[0].estimatedCosts} />
            </td>
            <td className="figure difference">
              <Difference
                stats={stats}
                field="estimatedCosts"
              />
            </td>
          </tr>
          {/*  <tr>
            <td>Costs projections</td>
            <td className="figure">
              <VND value={stats[0].booksCost + stats[0].nonBooksCosts} />
            </td>
          </tr> */}
          <tr>
            <td className="top-border">Total Costs projected</td>
            <td className="figure top-border">
              <VND value={stats[0].projectedCosts} />
            </td>
            <td className="figure top-border difference">
              <Difference
                stats={stats}
                field="projectedCosts"
              />
            </td>
          </tr>
          <tr>
            <td>Revenue</td>
            <td className="figure">
              <VND value={stats[0].revenue} />
            </td>
            <td className="figure difference">
              <Difference
                stats={stats}
                field="revenue"
              />
            </td>
          </tr>
          <tr className="profit top-border">
            <td className="top-border">Projected profit</td>
            <td className="figure top-border">
              <VND value={stats[0].projectedProfit} />
            </td>
            <td className="figure difference">
              <Difference
                stats={stats}
                field="projectedProfit"
              />
            </td>
          </tr>
        </table>
      </div>
    );
}

function Difference({ stats, field }) {
  
  let value = stats[0][field] - stats[1][field]

  return <><UpDownChevron value={value} /><VND value={value} /></>
}