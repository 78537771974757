import React from "react";

import { Button, Stack } from "@mui/material";
import { net } from "../../../../database";

import BasicDetails from "./BasicDetails";
import Notes from "./Notes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddConsultantDialog from "./AddConsultantDialog";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditLeadDialog from "./EditLeadDialog";

export default function StageOne(props) {
  let { leads, setLeads, staff } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(undefined);
  const [openEdit, setOpenEdit] = React.useState(false);

  return (
    <div>
      {leads.map((lead) => (
        <div className="stage-two-box" key={lead._id}>
          <BasicDetails lead={lead} />
          <div>
            <IconButton
              style={{ float: "right" }}
              onClick={() => {
                setSelected((prev) => lead);
                setOpenEdit(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          <Notes lead={lead} setLeads={setLeads} style={{ flexGrow:1 }} />
          
          <ActionButtons
            lead={lead}
            setSelected={setSelected}
            setOpen={setOpen}
            setLeads={setLeads}
          />
        </div>
      ))}
      {selected ? (
        <AddConsultantDialog
          open={open}
          setOpen={setOpen}
          lead={selected}
          staff={staff}
          setLeads={setLeads}
        />
      ) : null}
      {selected ? (
        <EditLeadDialog
          selected={selected}
          open={openEdit}
          setOpen={setOpenEdit}
          setLeads={setLeads}
        />
      ) : null}
    </div>
  );
}

function ActionButtons({ setSelected, setOpen, lead, setLeads }) {
  const backToLead = () => {
    net.post(`/leads/${lead._id}`, { stage: 1, status: "Lead" }).then((res) => {
      setLeads((prev) => prev.filter((l) => l._id !== lead._id));
    });
  };
  return (
    <div className="leads-decision">
      <Stack spacing={2}>
        <Button
          color="success"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            setSelected(lead);
            setOpen(true);
          }}
        >
          Assign Consultant
        </Button>
        <Button
          style={{ alignItems: "center" }}
          onClick={() => backToLead()}
          startIcon={<ArrowBackIcon />}
          color="info"
        >
          Back to Data
        </Button>
      </Stack>
    </div>
  );
}
