import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import DrawStudent from "./DrawStudent";
import Title from "./Title";
import Month from "../../components/Month";
import useDB from "../../hooks/useDB";


export default function ActiveToPending(props) {
    const {year,month} = props
    const [students, setStudents,error,loading] = useDB(
      `/active-to-pending/${year}/${month}`
    );

    if (loading) return <Loading />
   
    return (<div><h3>Students who changed from active to pending in <Month value={month+1} /> ({students.length})</h3><Title reason returnDate />{students.map(s=> <DrawStudent student={s} />)}</div>)
}