import React, { useContext } from "react";
import { StudentsContext } from "../../../Context";
import { Avatar, Box } from "@mui/material";
import HtClass from "./HtClass";
import "./studentpill.css";
import { useNavigate } from "react-router-dom";

export default function StudentPill(props) {
  const { findStudent } = useContext(StudentsContext);
  let navigate = useNavigate();
  let student = findStudent(props.student);
  let background = {
    New: "linear-gradient(80deg, rgba(4,122,0,1) 0%, rgba(22,180,7,1) 100%)",
    Active: "linear-gradient(to left, #00b4db, #0083b0)",
    Pending: "linear-gradient(to right, #fc4a1a, #f7b733)",
    Lead: "linear-gradient(to right, #ff416c, #ff4b2b)",
    Stopped: "linear-gradient(to right, #ff416c, #ff4b2b)",
    HotLead: "linear-gradient(to right, #616161, #9bc5c3)",
  };
  return (
    <Box
      className="pill"
      style={{ display: "flex", alignItems: "center" }}
      sx={{
        p: 1,
        m: 1,
        width: 250,
        borderRadius: 10,
        background: background[student.status],
      }}
      onClick={() => navigate(`/student/${student._id}`)}
    >
      {" "}
      <Avatar>{student.name.substring(0, 1)}</Avatar>
      <div style={{ marginLeft: 10 }}>
        <div style={{ color: "white", fontSize: 14 }}>{student.name}</div>
        <div style={{ fontSize: 11, color: "#ccc" }}>
          {student.classes.map((c) => (
            <HtClass htClass={c} />
          ))}
        </div>
      </div>
    </Box>
  );
}
