import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function LightenColor(color, percent) {
  var num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
}

const plugin = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;

    ctx.save();

    ctx.globalCompositeOperation = "destination-over";
    var my_gradient = ctx.createLinearGradient(0, 0, chart.width, chart.height);

    my_gradient.addColorStop(0, options.color);
    my_gradient.addColorStop(1, LightenColor(options.color, 30));
    ctx.fillStyle = my_gradient;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};
export default function BarChart(props) {
  //const {l,language} = props;
  const { labels, barData, color } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },

      customCanvasBackgroundColor: {
        color,
      },
    },
    layout: {
      padding: 15,
    },
    scales: {
      x: {
        ticks: { color: "white", font: { weight: 100 } },
      },
      y: {
        ticks: { color: "white" },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: barData,
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointRadius: 0,
        tension: 0.2,
        fill: "origin",
      },
    ],
  };

  return (
    <div style={{ height: 300, width: 430 }}>
      <Bar
        options={options}
        data={data}
        plugins={[plugin]}
        style={{ borderRadius: 10, padding: 5 }}
      />
    </div>
  );
}
