import React from "react";
import { weekdays } from "../../../../enums";
import DayOfWeek from "../../components/DayOfWeek";
import Time from "../../components/Time";
import Info from "../../components/Info";

export default function ClassTimes({htClass}) {


  return (
    <Info title="Timetable" body={
      <div style={{ fontSize: 14 }}>
        {htClass.classDays.map(classDay => <DayInfo classDay={classDay} />)}
      </div>} />
);
}

function DayInfo({classDay}){
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 100 }}>
        <DayOfWeek day={classDay.day} />
      </div>
      <div style={{ width: 150 }}>
        <Time time={classDay.startTime} /> - <Time time={classDay.endTime} />
      </div>
      <div>{classDay.room}</div>
    </div>
  );
}