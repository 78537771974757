import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import Flatpickr from "react-flatpickr";
import { net } from "../../../../database";

import Location from "../../components/Location";
import Time from "../../components/Time";
import Lang from "../../components/Lang";
import Info from "../../components/Info";

export default function ChooseTestTime(props) {
  const { open, setOpen, /* lead, test,*/ setLeads } = props;
  let lead = open
  const [value, setValue] = React.useState(new Date());
  const [timetable, setTimetable] = React.useState(undefined);
  const [notes,setNotes] = React.useState("")

  React.useEffect(() => {
    if (open) {
      net
        .get("/placement-test-timetable")
        .then((res) => setTimetable(res.timetable));
    }
  }, []);
  if (!open) return <React.Fragment></React.Fragment>;
  if (!timetable)
    return (
      <div></div>
    );
  console.log(timetable);
  let days = [...new Set(timetable.map((time) => time.dayOfWeek))];

  let enable = [
    function (date) {
      return days.includes(date.getDay());
    },
  ];

  const chooseTest = (time) => {
    
    console.log(lead)
    let body = { student: lead,testDate: value,notes };
    net
      .post(
        `/add-student-to-placement-test/${time._id}`,
        body
      )
      .then((res) => {
       // console.log(res);
        setLeads((prev) => {
          let plead = prev.find(p => p._id === open)
          console.log(plead)
          if (plead.placementTests || plead.placementTests.length === 0) {
            plead.placementTests.push(res)
             return prev.map((p) => (p._id === plead._id ? plead : p));
          }
          plead.placementTests = prev.placementTests.map(t => t._id === res._id ? res : t)
          return prev.map(p=>p._id===plead._id ? plead : p)
        })
        setOpen(undefined);
      });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Choose a time for {lead.name} to come for a test
      </DialogTitle>
      <DialogContent style={{display: "flex", flexDirection:"column", alignItems:"center"}}>
        <TextField value={notes} onChange={(e) => setNotes(e.target.value)} multiline fullWidth rows={2} label={<Lang w="notes" />} style={{ marginBottom: 10,marginTop: 5}} />
        <Flatpickr
          value={value}
          options={{ inline: true, enable, minDate: new Date() }}
          style={{ display: "none" }}
          onChange={([date]) => setValue((prev) => date)}
        />
        <Box
          border="1px solid #ccc"
          fullWidth
          borderRadius={1}
          sx={{ mt: 2,p:1 }}
          style={{width: 550}}
        >
          {timetable
            .filter((t) => t.dayOfWeek === value.getDay())
            .map((time) => (
              <div
                style={{display:"flex", justifyContent: "space-between"}}
                onClick={() => chooseTest(time)}
                className="choose-timetable-div"
              >
                <Info title={<Lang w="location" />} body={<Location id={time.location} />} />
                <Info title={<Lang w="time" />} body={<Time time={time.startTime} />} />
              </div>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        {/*  <Button color="success">Go</Button> */}
      </DialogActions>
    </Dialog>
  );
}
