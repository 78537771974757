export function formatPhone(phone) {
  if (phone) {
    var phonenumber =
      phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6, 4);
    return phonenumber;
  } else {
    return "None";
  }
}

export function formatVND(numberin) {
  return Intl.NumberFormat().format(numberin);
}
