import React from "react";
import StudentLink from "../../components/StudentLink";
import FormatDate from "../../components/FormatDate";


import Notes from "../../components/Notes";
import SLink from "../../components/SLink";
import Info from "../../components/Info";
import { formatDuration,intervalToDuration } from "date-fns";
import StoppedReason from "../../components/StoppedReason";
import StatusChange from "./StatusChange";


export default function DrawStoppedStudent(props) {
  let { student, setStopped } = props;

  let history = student.statusHistory.at(-1)
 let stoppedReason = history ? history.reason : student.leaveReason;

  const updateNewLost = (stoppedChat) => {
    setStopped(prev => prev.map(s => s._id === student._id ? { ...student, stoppedChat } : s))
  }


  let firstReason = { note: stoppedReason, user:history && history.changedBy || undefined, createdAt: student.statusChanged}

 
  let stoppedChat = [firstReason, ...student.stoppedChat]

  return (
    <div style={{ display: "flex",marginBottom: 5 }}>
      <div style={{ width: 300,minWidth:300 }}>
       <SLink id={student._id} showClasses showPhone showTeacher/>
      </div>
      <div style={{ width: 200, minWidth: 200 }}>
        <div>
          <StatusChange statusHistory={student.statusHistory} />
        </div>
        <Info title="Studied for" body={formatDuration(intervalToDuration({ start: new Date(student.startDate), end: new Date() }), { format: ["years", "months"] }) + " " +student.attendedClasses + " lessons"} />
        {student.stoppedReasons.length>0 ? <Info title="Reasons" body={<StoppedReasons reasons={student.stoppedReasons} />} /> : null}
        </div>
      <FormatDate value={student.statusChanged} style={{ width: 150 }} />
        <Notes notes={stoppedChat} route={`/student/${student._id}/stopped-notes`} success={(stoppedChat) => updateNewLost(stoppedChat)} style={{ width: 300 }} />
    </div>)
}

function StoppedReasons({ reasons }) {
  console.log(reasons)
  return <div>{reasons.map(r => <div><StoppedReason id={r} /></div>)}</div>
}