import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import FormatDate from "../../../components/FormatDate";

let scoreColours = [
  "#bdbdbd",
  "#fc0d5d",
  "#fc6d0d",
  "#fcc80d",
  "#69ad02",
  "#02ad05",
];

let fbCategories = [
  "",
  "",
  "Bài tập về nhà",
  "Có đầy đủ sách vở",
  "Thái độ, hành vi",
  "Điểm",
];

function DrawFeedback(props) {

  let fbDisplay = {};

  let { feedback } = props.attendance;

  if (!feedback) return <div></div>;

  if (feedback.length > 0 && feedback[0].id) {
    feedback.forEach((fb) => {
      for (const field in fb) {
        if (field === "id" || field === "score") {
        } else {
          fbDisplay[field] = fb[field];
        }
      }
    });
  } else {
    fbDisplay = feedback;
  }

  return (
    <div>
      {Object.keys(fbDisplay).map((f) => (
        <div
          key={props.attendance._id + [f]}
          className="feedback-scores"
          style={{
            backgroundColor: scoreColours[fbDisplay[f]],

            height: 27,
          }}
        >
          {[f]}
        </div>
      ))}
    </div>
  );
}

export default function AttendanceWidget(props) {
  let { attendance } = props;

  let colors = ["#007874", "#038722", "#ffae00", "#ff0055"];
  let notes = "";
  if (attendance.attendance > 1) notes = attendance.absentReason;
  if (attendance.attendance < 2)
    notes = !attendance.feedbackNotes
      ? attendance.notes
      : attendance.feedbackNotes;
  return (
    <Tooltip title={notes}>
      <div
        className="attendance-widget"
        style={{
          background:
            attendance.attendance > 0
              ? colors[attendance.attendance]
              : "limegreen",
        }}
      >
        <div className="attendance-date">
          {format(new Date(attendance.createdAt), "dd/MM")}
        </div>
        <div style={{ color: "white" }}>
          {attendance.attendance > 1 ? (
           <Absent attendance={attendance} />
          ) : (
            <DrawFeedback attendance={attendance} />
          )}
        </div>
      
      </div>
    </Tooltip>
  );
}


function Absent(props) {
  let { attendance } = props
  let {makeUpClass} = attendance
  return <div>
    <div>{attendance.absentReason}</div>
    {/* {makeUpClass ? <MUClass makeUpClass={makeUpClass} student={attendance.student} />: <div>No makeup class</div>} */}
  </div>
}

/* function MUClass({makeUpClass,student}) {
  let attendance = makeUpClass.students.find(s => s.student === student)
  let att = "No info"
  if (attendance) {
    att="Attended"
    if (attendance.attendance === -1) att = "Late"
    if (attendance.attendance === 2) att = "Absent"
    if (attendance.attendance === 3) att = "No Show";
  }
    return (
      <div
        style={{
       
          color: "white",
          borderRadius: 10,
          padding:10,
          marginTop: 5,
          backdropFilter: "brightness(0.8)"
        }}
      >
     
          Make up class:
          <FormatDate value={makeUpClass.date} />
          <div>{att}</div>
   
      </div>
    );
} */