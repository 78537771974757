import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { net } from "../../../database";

/**
 * Description placeholder
 * @date 6/17/2023 - 3:56:45 PM
 *
 * @export
 * @param {string} route the route to use
 * @param {number} id optional, the id number of the record (include if not in the route)
 * @param {function} success function to call if successful
 * @param {number} size The size of the icon button (px)
 * @param {string} tooltip overide the default tooltip text
 * @returns {*}
 */
export default function DeleteButton({ route, id, success, size,tooltip }) {
  const dItem = () => {
    let c = window.confirm("Are you sure you want to delete this?")
    if (c) {
      if (id) {
        net.del(route + "/" + id).then((res) => {
          if (success) success(res);
        });
      } else {
        net.del(route).then((res) => {
          if (success) success(res);
        });
      }
    };
  }
  if (!size) size = 40;
  return (
    <Tooltip title={tooltip || "Delete"}>
    <IconButton
      color="error"
      onClick={dItem}
      style={{ height: size, width: size }}
    >
      <CancelIcon />
      </IconButton>
      </Tooltip>
  );
}
