
import SLink from "../../components/SLink";

import Staff from "../../components/Staff";
import LineChart from "../overview/dailyStats/LineChart";

import HtClass from "../../components/HtClass";
import FormatDate from "../../components/FormatDate";

import Notes from "../../components/Notes";
import { Autocomplete, Chip, TextField } from "@mui/material";
import Rating from "../../components/Rating";
import Info from "../../components/Info";
import AddButton from "../../components/AddButton";
import { useContext, useState } from "react";
import HtComplete from "../../../topbar/components/HtComplete";
import HtAutoComplete from "../../components/HtAutocomplete";
import { CenterContext } from "../../../../Context";
import ChatBox from "../../components/ChatBox";
import ChatBoxR from "../../components/ChatBoxR";
import useCenter from "../../hooks/center";
import Wiki from "./Wiki";


export default function StudentCare({student,survey,setSurvey,chatId}) {

    return (
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{width:200, minWidth:200}}>
          <SLink
            id={student._id}
            showClasses
            showPhone
            showTeacher
            status
          />
        </div>
        {["New", "Active", "Pending"].includes(student.status) ? (
          <DrawFeedback student={student} survey={survey} setSurvey={setSurvey} chatId={chatId}/>
        ) : (
          <DrawStopped student={student} />
        )}
      </div>
    );
}

function DrawStopped({ student }) {

    let reason = ""
    if (student.leaveReason) reason = student.leaveReason
    if (
        student.statusChanged &&
      student.statusChanged.length > 0 &&
      student.getLast() &&
      student.getLast().reason
    ) reason = student.statusChanged.getLast().reason;
      return (
        <div style={{display:"flex", gap:10}}>
         
          <div style={{fontSize:12}}>{reason}</div>
        </div>
      );
}

function DrawFeedback({ student, survey, setSurvey,chatId }) {
/*   const { center } = useContext(CenterContext)
  const {surveyTags,setSurveyTags} = center */
  const surveyTags = useCenter("surveyTags")
  const setSurveyTags = useCenter("setSurveyTags")
  const {tags} = survey
  return (
    <div style={{ display: "flex", gap: 10 }}>
      {/*    <LineChart lineData={[1, 5, 3, 2, 3]} color="#EEEEEE" labels={["one", "two", "three", "four", "five"]} /> */}

      <div style={{ width: "50%" }}>
       {/*  <ChatBoxR
          chatId={chatId}
          style={{ height: 300, width: "100%" }}
        /> */}
        <Wiki />
        <HtAutoComplete
          tags={tags}
          setTags={(tags) => setSurvey({ ...survey, tags })}
          choices={surveyTags}
          setChoices={setSurveyTags}
          label="Concerns"
          placeholder="Add a concern"
        />
        <Info
          style={{ marginTop: 10 }}
          title="Customer feeling"
          body={
            <Rating
              value={survey.score}
              setValue={(score) => setSurvey({ ...survey, score })}
              field="score"
            />
          }
        />
      </div>
      <div style={{ width: "50%" }}>
        Recent feedback:
        {student.attendance.map((at) => (
          <DrawAttendance
            attendance={at}
            key={at._id}
          />
        ))}
      </div>
    </div>
  );
}

function DrawAttendance({ attendance }) {
    

    return (
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: 5,
          padding: 5,
          fontSize: 12,
          marginBottom: 5,
        }}
      >
        <div style={{display:"flex", justifyContent: "space-between"}}>
        <div>Notes</div>
        <div><Staff id={attendance.teacher} /> <HtClass htClass={attendance.htClass} /> <FormatDate value={attendance.classDate} /></div>
        </div>
        <div>{attendance.notes}</div>
      </div>
    );
}

function DrawTags({ tags, setTags }) {
 
  return (
    <div style={{}}>
    
    </div>
  );
}