import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Staff from "../../components/Staff";
import Loca from "../../components/Loca";
import MonthChoice from "../../components/MonthChoice";


export default function ManageSales(props) {

   
    const [year, setYear] = useState(new Date().getFullYear())
    const [month,setMonth] = useState(new Date().getMonth())
const [contacts, setContacts, error, loading] = useDB(`/marketing-tests/${year}/${month}`)


if (loading) return <Loading />;
if (error) return <Error />;

  
    const people = [...new Set(contacts.filter(c => c.consultantForTest).map((c) => c.consultantForTest))];
    const locations = [
      ...new Set(
        contacts
          .filter((c) => c.testLocation)
          .map((c) => c.testLocation)
      ),
    ];
     const marketing = [
       ...new Set(
         contacts
           .filter((c) => c.marketingPerson)
           .map((c) => c.marketingPerson)
       ),
     ];
    const results = ["New student", "Potential", "Not interested","Cannot contact", undefined];


    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MonthChoice
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
          />
        </div>
        <hr />
        <div>Total tests {contacts.length}</div>
        <div>
          Completed tests {contacts.filter((c) => c.consultantForTest).length}
        </div>
        <div>
          Incomplete tests {contacts.filter((c) => !c.consultantForTest).length}
        </div>
        <div>
          New customers {contacts.filter((c) => c.result==="New student").length}
        </div>
        <h3>Staff Calling</h3>
        <Headers results={results} />
        {people.map((p) => (
          <Person
            contacts={contacts.filter((c) => c.consultant === p)}
            person={p}
            results={results}
          />
        ))}
        <h3>Staff Testing</h3>
        <Headers results={results} />
        {people.map((p) => (
          <Person
            contacts={contacts.filter((c) => c.consultantForTest === p)}
            person={p}
            results={results}
          />
        ))}
        <h3>Locations</h3>
        <Headers results={results} />
        {locations.map((l) => (
          <Person
            contacts={contacts.filter((c) => c.testLocation === l)}
            results={results}
            location={l}
          />
        ))}
        <h3>
          Marketing Staff ({contacts.filter((c) => c.marketingPerson).length}{" "}
          tests)
        </h3>
        <Headers results={results} />
        {marketing.map((p) => (
          <Person
            contacts={contacts.filter((c) => c.marketingPerson === p)}
            person={p}
            results={results}
          />
        ))}
      </div>
    );
}   

function Person({ contacts,person,results,location }) {
    const success = contacts.filter(c => c.result === "New student").length
    let rate = 0
    if (success > 0) {
        rate = success/contacts.length * 100
    }

    return (
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ width: 200 }}>
          {" "}
          {location ? <Loca id={location} /> : <Staff id={person} />}
        </div>

        <div style={{ width: 100, textAlign: "center" }}>{contacts.length}</div>
        {results.map((r) => (
          <div style={{ width: 100, textAlign: "center" }}>
            {contacts.filter((c) => c.result === r).length}
          </div>
        ))}
        <div style={{ width: 100, textAlign: "center" }}>
          {rate.toFixed(0)}%
        </div>
      </div>
    );
}

function Headers({ results }) {
    
    return (
      <div style={{ display: "flex", fontSize: 11, gap: 10 }}>
        <div style={{ width: 200 }}>Name</div>
        <div style={{ width: 100, textAlign: "center" }}>Total</div>
        {results.map((r) =>
          r ? (
            <div style={{ width: 100, textAlign: "center" }}>{r}</div>
          ) : (
            <div style={{ width: 100, textAlign: "center" }}>No result</div>
          )
        )}
        <div style={{ width: 100, textAlign: "center" }}>Conversion Rate</div>
      </div>
    );
}