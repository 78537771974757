import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * 
 * @param {[string]} labels labels for the tabs 
 * @param {[function]} components array of components for each tab 
 * @param {function} nextToTabs component to be placed to the right of the tabs 

 * @returns 
 */

export default function HtTabsSm(props) {
   const { labels, components, nextToTabs } = props;
    const [value, setValue] = React.useState(0)
   
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
      
          
        >
          {labels.map((l, i) => (
            <Tab label={l} key={"tablabel"+i} {...a11yProps(i)} />
          ))}
        </Tabs>
        {nextToTabs ? nextToTabs : null}
      </Box>
      {components.map((c, i) => (
        <TabPanel value={value} index={i} key={"tab" + i}>
          {c}
        </TabPanel>
      ))}
    </Box>
  );
}
