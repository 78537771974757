import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import "./htclass.css";

import useDB from "../../hooks/useDB";
import BasicInfo from "./BasicInfo";
import Error from "../../components/Error";
import StudentData from "./StudentData";



export default function HtClass(props) {
  let { id } = useParams();
  const [htClass, setHtClass, error, loading] = useDB(`/class/${id}`);

  if (loading) return <Loading />;
  if (error) return <Error />
  console.log(htClass)
  return (
    <div>
      <BasicInfo
        htClass={htClass}
        setHtClass={setHtClass}
      />
      <StudentData students={htClass.students} pendingStudents={htClass.pendingStudents} attendance={htClass.attendance} />
    </div>
  );
}
