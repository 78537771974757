import React from "react";
import format from "date-format";
import numeral from "numeral";
import "./receipt.css";

export default function Receipt(props) {
  let { receipt, user } = props;
 
  if (!receipt) return "";
  var total = numeral(parseInt(receipt.paymentAmount));

  /*   var totalToPay = numeral(parseInt(receipt.totalBill - receipt.credit)); */
  let credit = numeral(0);
  if (receipt && receipt.credit) credit = numeral(receipt.credit);
  let owed = numeral(receipt.owed - receipt.credit);
  let totalToPay = numeral(receipt.totalToPay);

  let text = `<html><head></head><body style="font-family: Roboto, Arial, Helvetica, sans-serif;font-size:9pt">`;

  text += `<div style="display:flex;align-items:center"><img src="/htlogo.png" width="50%" alt="No Logo" />`;
  text += `<div><div style="text-align: center;">Địa chỉ: HT Explore Leaders - 51B Kẻ Vẽ</div><br>`;
  text += `<div style="text-align: center;font-weight:bold">HÓA ĐƠN HỌC PHÍ </div></div></div>`;
  if (props.receipt !== undefined) {
    text += `<br><div>${format(
      "dd-MM-yyyy hh:mm",
      new Date(props.receipt.date)
    )}</div>`;
    text += `<div>Thu ngân: ${user.name}</div>`;
    text += props.receipt.customersName
      ? `<div>khách hàng: ${props.receipt.customersName}</div>`
      : "";
    text += `<div>Ref: ${props.receipt.customersRef}</div>`;

    receipt.students
      .filter((student) => student.paidBills)
      .forEach((s) => {
        text += `<br><div style="font-weight: bold">${s.name}</div>`;
        s.paidBills.forEach((b) => {
          let amount = numeral(b.amount);
          text += `<div style="display:flex;width: 100%;justify-content: space-between">
            <div>${b.description}</div>
            <div>${amount.format("0,0")}đ</div>
          </div>`;
        });
        if (receipt.students.length > 1) {
          let subTotal = numeral(s.paidBills.reduce((a, b) => a + b.amount, 0));
          text += `<hr /><div style="display:flex;width: 100%;justify-content: space-between"><div>Học phí đã thanh toán:</div><div>${subTotal.format(
            "0,0"
          )}đ</div></div>`;
        }
      });

    /*   text +=
      credit && !receipt.hasUnpaidBills
        ? `<br><div style="display:flex;width: 100%;justify-content: space-between"><div>Tín dụng:</div><div>${credit.format(
            "0,0"
          )}đ</div></div>`
        : ""; */
    text += `<br><hr /><div style="display:flex;width: 100%;justify-content: space-between"><div><b>Tổng học phí cần thanh toán:</b></div><div><b>${totalToPay.format(
      "0,0"
    )}đ</b></div></div>`;

    /*  text += `<div style="display:flex;width: 100%;justify-content: space-between"><div><b>Total Amount to Pay:</b></div><div><b>${totalToPay.format(
      "0,0"
    )}đ</b></div></div>`; */
    text += `<div style="display:flex;width: 100%;justify-content: space-between"><div><b>Học phí đã thanh toán:</b></div><div><b>${total.format(
      "0,0"
    )}đ</b></div></div>`;
    if (receipt.credit > 0) {
      text += `<div style="display:flex;width: 100%;justify-content: space-between"><div><b>Số tiền trả lại:</b></div><div><b>${
        receipt.credit > 0 ? credit.format("0,0") : 0
      }đ</b></div></div>`;
    }
    if (receipt.owed > 0) {
      text += `<div style="display:flex;width: 100%;justify-content: space-between"><div><b>Học phí chưa hoàn thành:</b></div><div><b>${owed.format(
        "0,0"
      )}đ</b></div></div>`;
    }
  }
  /* if (receipt.hasUnpaidBills) {
    text += `<br><br><div><b>Hóa đơn không thể thanh toán do không đủ tiền:</b></div>`;
    receipt.students
      .filter((student) => student.unpaidBills)
      .forEach((student) => {
        text += `<br><div><b>${student.name}</b></div>`;
        student.unpaidBills.forEach((bill) => {
          let amount = numeral(bill.amount);
          text += `<div style="display:flex;width: 100%;justify-content: space-between">`;

          text += `<div>${bill.description}</div>`;
          text += ` <div>${amount.format("0,0")}đ</div>`;
          text += `</div>`;
        });
      });

    if (receipt.credit && receipt.credit > 0) {
      let credit = numeral(receipt.credit);
      text += `<br><div style="display:flex;width: 100%;justify-content: space-between">`;

      text += `<div>Tín dụng::</div>`;
      text += ` <div>${credit.format("0,0")}đ</div>`;
      text += `</div>`;
    }

    text += `<br><hr /><div style="display:flex;width: 100%;justify-content: space-between">`;

    text += `<div><b>Nợ:</b></div>`;
    text += ` <div><b>${owed.format("0,0")}đ</b></div>`;
    text += `</div>`;
  } */

  text += `<br><br><div style="text-align: center;">Trân trọng cảm ơn quý phụ huynh!</div>`;
  text += `</body></html>`;

  return (
    <div>
      <iframe
        className="receipt"
        title="Receipt"
        width="400"
        id="Receipt"
        name="Receipt"
        style={{ display: "none" }}
        srcDoc={text}
      ></iframe>{" "}
    </div>
  );
}
