import {
  Chip,
  IconButton,
  MenuItem,
  Rating,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { net } from "../../../../../database";
export default function SetupNewCustomer(props) {
  let { l, language } = props;
  const [customers, setCustomers] = React.useState([]);
  const [search, setSearch] = React.useState("");
  console.log(props.customerErrors);
  React.useEffect(() => {
    net.get("/customers").then((r) => setCustomers(r));
  }, []);

  const deleteFriend = () => {};
  const addFriend = (e, _id, name, phone) => {
    props.newCustomer.friends.push({ _id, name, phone });
    props.setNewCustomer(props.newCustomer);
    setSearch("");
  };

  return (
    <div>
      <div>
        <h3>{l.customerNotFoundSetupANewCustomer[language]}</h3>
      </div>
      <Stack spacing={2}>
        <div style={{ fontSize: 11 }}>{l.basicInformation[language]}</div>
        <div style={{ display: "flex" }}>
          <TextField
            style={{ width: 100, marginRight: 10 }}
            value={props.newCustomer.title}
            label={l.title[language]}
            onChange={(e) =>
              props.setNewCustomer({
                ...props.newCustomer,
                title: e.target.value,
              })
            }
            {...(props.customerErrors.includes("title") && {
              error: true,
              helperText: "",
            })}
            select
          >
            <MenuItem value="Anh">Anh</MenuItem>
            <MenuItem value="Chi">Chi</MenuItem>
          </TextField>
          <TextField
            value={props.newCustomer.name}
            label={l.customersName[language]}
            fullWidth
            onChange={(e) =>
              props.setNewCustomer({
                ...props.newCustomer,
                name: e.target.value,
              })
            }
            {...(props.customerErrors.includes("name") && {
              error: true,
              helperText: l.pleaseTypeInTheCustomersName[language],
            })}
          />
        </div>
        <TextField
          value={props.newCustomer.address}
          fullWidth
          label={l.customersAddress[language]}
          onChange={(e) =>
            props.setNewCustomer({
              ...props.newCustomer,
              address: e.target.value,
            })
          }
          {...(props.customerErrors.includes("address") && {
            error: true,
            helperText: l.pleaseTypeInTheCustomersAddress[language],
          })}
        />
        <TextField
          value={props.newCustomer.dob}
          type="Date"
          label={l.customersDateOfBirth[language]}
          fullWidth
          onChange={(e) =>
            props.setNewCustomer({
              ...props.newCustomer,
              dob: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
          {...(props.customerErrors.includes("dob") && {
            error: true,
            helperText: l.pleaseChooseTheCustomersDateOfBirth[language],
          })}
        />
        <TextField
          value={props.newCustomer.marketingSource}
          type="text"
          label={"How do they know about HT Explore"}
          fullWidth
          onChange={(e) =>
            props.setNewCustomer({
              ...props.newCustomer,
              marketingSource: e.target.value,
            })
          }
          InputLabelProps={{ shrink: true }}
          {...(props.customerErrors.includes("marketingSource") && {
            error: true,
            helperText: "Please put how they know about our center",
          })}
        />

        <div style={{ fontSize: 11 }}>{l.learning[language]}</div>
        <Stack spacing={2}>
          <TextField
            value={props.newCustomer.reasonForLearning}
            label={l.reasonForLearning[language]}
            onChange={(e) =>
              props.setNewCustomer({
                ...props.newCustomer,
                reasonForLearning: e.target.value,
              })
            }
            {...(props.customerErrors.includes("reasonForLearning") && {
              error: true,
              helperText: l.pleaseTypeInTheCustomersReasonForLearning[language],
            })}
          />
          <TextField
            value={props.newCustomer.problemsWithChildren}
            label={l.problemsWithTheCustomersChildren[language]}
            onChange={(e) =>
              props.setNewCustomer({
                ...props.newCustomer,
                problemsWithChildren: e.target.value,
              })
            }
            {...(props.customerErrors.includes("problemsWithChildren") && {
              error: true,
              helperText: l.pleaseDescribeAnyProblemsTheChildrenHave[language],
            })}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              {...(props.customerErrors.includes("englishKnowledge") && {
                style: { color: "red", textDecoration: "underline" },
              })}
            >
              {l.customersEnglishKnowledge[language]}:
            </div>
            <Rating
              value={props.newCustomer.englishKnowledge}
              onChange={(e) =>
                props.setNewCustomer({
                  ...props.newCustomer,
                  englishKnowledge: e.target.value,
                })
              }
            />
          </div>
        </Stack>

        <div style={{ fontSize: 11 }}>{l.financial[language]}</div>
        <Stack spacing={2}>
          <TextField
            value={props.newCustomer.paymentFrequency}
            fullWidth
            label={l.howOftenWouldTheCustomerLikeToPay[language]}
            onChange={(e) =>
              props.setNewCustomer({
                ...props.newCustomer,
                paymentFrequency: e.target.value,
              })
            }
            {...(props.customerErrors.includes("howTheyLikeToPay") && {
              error: true,
              helperText:
                l.pleaseChooseHowOftenTheCustomerWouldLikeToPay[language],
            })}
            select
          >
            <MenuItem value={1}>{l.oneMonth[language]}</MenuItem>
            <MenuItem value={3}>{l.threeMonths[language]}</MenuItem>
            <MenuItem value={6}>{l.sixMonths[language]}</MenuItem>
          </TextField>
          <div style={{ display: "flex" }}>
            <TextField
              value={props.newCustomer.jobs[0]}
              label={l.fathersJob[language]}
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                let jobs = props.newCustomer.jobs;
                jobs[0] = e.target.value;
                props.setNewCustomer({
                  ...props.newCustomer,
                  jobs,
                });
              }}
              {...(props.customerErrors.includes("jobs0") && {
                error: true,
                helperText: "",
              })}
            />
            <TextField
              value={props.newCustomer.jobs[1]}
              label={l.mothersJob[language]}
              onChange={(e) => {
                let jobs = props.newCustomer.jobs;
                jobs[1] = e.target.value;
                props.setNewCustomer({
                  ...props.newCustomer,
                  jobs,
                });
              }}
              {...(props.customerErrors.includes("jobs1") && {
                error: true,
                helperText: "",
              })}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ marginRight: 20 }}
              {...(props.customerErrors.includes("income") && {
                style: { color: "red", textDecoration: "underline" },
              })}
            >
              {l.householdIncome[language]}:
            </div>
            <Rating
              value={props.newCustomer.income}
              onChange={(e) =>
                props.setNewCustomer({
                  ...props.newCustomer,
                  income: parseInt(e.target.value),
                })
              }
            />
          </div>
        </Stack>

        <div style={{ fontSize: 11 }}>
          {l.friendsAtTheCenter[language]}
          <div style={{ display: "flex" }}>
            <TextField
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search !== "" ? (
              <div>
                {customers
                  .filter(
                    (c) =>
                      (c.name &&
                        c.name
                          .toLowerCase()
                          .includes(search.toLocaleLowerCase())) ||
                      (c.phone && c.phone.includes(search))
                  )
                  .slice(0, 10)
                  .map((c) => (
                    <div style={{ fontSize: 14, marginLeft: 10 }}>
                      {c.name ? c.name : c.phone}
                      <IconButton
                        onClick={(e) => addFriend(e, c._id, c.name, c.phone)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {props.newCustomer.friends.map((f) => (
              <Chip
                label={f.name ? f.name : f.phone}
                onDelete={deleteFriend}
                sx={{ marginRight: 1 }}
              />
            ))}
          </div>
        </div>
      </Stack>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={props.addCustomer}>{l.addCustomer[language]}</Button>
      </div>
    </div>
  );
}
