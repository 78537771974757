import React from "react";
import FormatDate from "../../components/FormatDate";
import VND from "../../components/VND";
import Status from "../../components/Status";
import StaffChooserContext from "../../components/StaffChooserContext";
import SelectFromList from "../../components/SelectFromList";
import { net } from "../../../../database";
import SLink from "../../components/SLink";
import { useFloating, useHover, useInteractions } from "@floating-ui/react";
import { useState } from "react";
import Staff from "../../components/Staff";
import { Link } from "react-router-dom";


export default function DrawNewStudent(props) {
  let { student } = props;
  const [consultant, setConsultant] = React.useState(student.consultant || "")
  const [readyForClass, setReadyForClass] = React.useState(student.readyForClass || 0)
  console.log(student)
  
  const updateConsultant = (c) => {
   
    net.post(`/student/${student._id}`, { consultant:c })
     setConsultant(c);
  }

  const updateReadyForClass = (c) => {
    net.post(`/student/${student._id}`, { readyForClass:c });
    setReadyForClass(c)
  }

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
return (
  <div style={{ display: "flex", marginBottom: 5 }}>
    <div style={{ width: 300, minWidth: 300 }}>
      <SLink
        id={student._id}
        showClasses
      />
    </div>
    <Customer customer={student.customer} />
    <div style={{ width: 100, minWidth: 100 }}>
      <Status status={student.status} />
    </div>

    <div style={{ width: 150, minWidth: 150 }}>
      <FormatDate value={student.statusChanged} />
    </div>
    <div
      style={{
        color: student.firstAttendance ? "black" : "red",
        width: 150,
        minWidth: 150,
        cursor:"pointer"
      }}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {student.firstAttendance && student.firstAttendance.attendance < 2 ? (
        <FormatDate value={student.firstAttendance.createdAt} />
      ) : (
        "No Attendance"
      )}
    </div>
    <Bills bills={student.bills} scholarship={student.scholarship} />
    <StaffChooserContext
      value={consultant}
      setValue={updateConsultant}
      size="small"
      style={{ width: 150 }}
      filter="Sales"
    />
    <SelectFromList
      value={readyForClass}
      setValue={(e) => updateReadyForClass(e)}
      choices={[
        { _id: 0, name: "Not Ready" },
        { _id: 1, name: "Ready" },
      ]}
      size="small"
      style={{ width: 150 }}
    />
    {isOpen && (
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}

      >
        <Attendance attendance={student.firstAttendance} />
      </div>
    )}
  </div>
);
}

function Attendance({ attendance }) {
  if(!attendance) return <></>
  let colors = ["#007874", "#038722", "#ffae00", "#ff0055"];
  const backgroundColor = attendance.attendance>0 ? colors[attendance.attendance] : "Limegreen"
  return <div style={{ backgroundColor, color: "white", width: 200, minHeight: 100, borderRadius: "0.5rem", padding: 10, fontSize: 12 }}>
    <Staff id={attendance.teacher} />
    <hr />
    {attendance.notes}
  </div>
}


function Bills({ bills,scholarship }) {
  
  if(scholarship) return <div style={{ color:"green", width: 150 }}>Scolarship</div>;

  if (!bills || bills.length === 0) return <div style={{ color: "red",width:150 }}>No bills</div>
  let color = "green"
  for (let bill of bills) {
    if(!bill.paymentId) color="black"
  }
  return (
    <div style={{ color, width: 150 }}>
      {" "}
      <VND value={bills.reduce((a, b) => a + b.amount, 0)} />
    </div>
  );
}

function Customer({ customer }) {
  
  return (
    <div style={{ width: 150 }}>
      <div>{<Link to={`/customer/${customer._id}`} className="neat">{customer.name}</Link> || "Unknown"}</div>
      <div style={{ fontSize: 9, color: "#888", margin: 0 }}>
        {customer.marketingSource || "Unkown source"}
      </div>
    </div>
  );
}