import React from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { IconButton } from "@mui/material";

/**
 * @description control for pagination
 * 
 * @param {number} page the current page state
 * @param {function} setPage setter for the page
 * @param {number} end the number of items in the array 
 * @returns 
 */

export default function Pagination({ page, setPage, end }) {
  const buttons = [
    {
      icon: <KeyboardDoubleArrowLeftIcon />,
      action: () => setPage(0),
      type: "down",
    },
    {
      icon: <KeyboardArrowLeftIcon />,
      action: () => setPage(page - 1),
      type: "down",
    },
    {
      icon: <KeyboardArrowRightIcon />,
      action: () => setPage(page + 1),
      type: "up",
    },
    {
      icon: <KeyboardDoubleArrowRightIcon />,
      action: () => setPage(Math.floor(end / 10)),
      type: "up",
    },
  ];
  return (
    <div style={{ float: "right" }}>
      <div>
        {buttons.map((b) => (
          <IconButton
            onClick={b.action}
            {...(b.type === "down" &&
              page < 1 && {
                disabled: true,
              })}
            {...(b.type === "up" &&
              page >= Math.floor(end / 10) && {
                disabled: true,
              })}
          >
            {b.icon}
          </IconButton>
        ))}
      </div>
      <div style={{ fontSize: 11, float: "right" }}>
        Page {page + 1} of {Math.floor(end / 10) + 1}
      </div>
    </div>
  );
}