import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
//import Costs from "./dailyStats/Costs";
import Money from "./dailyStats/Money";
import Students from "./dailyStats/Students";
//import { ToggleButton, ToggleButtonGroup } from "@mui/material";
//import RevenueByDay from "./RevenueByDay";
import MonthChoice from "../../components/MonthChoice";
import ProfitSalary from "../overview/dailyStats/ProfitSalary";
export default function Overview2(props) {
  const [dailyStats, setDailyStats] = React.useState(undefined);
  const [salaries,setSalaries] = React.useState(undefined)
/*   const [showCenter, setShowCenter] = React.useState("All"); */
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [month,setMonth] = React.useState(new Date().getMonth())
  const { center } = props;
  React.useEffect(() => {
    net.get("/dailystats").then((stats) => {
      let sorted = []
      for (let y = 2020; y < 2024; y++){
        for (let m = 1; m < 13; m++){
          
          let monthStats = stats.dailyStats.filter(s => s.year === y && s.month === m).sort((a,b) => (a.day > b.day) ? 1 : ((b.day > a.day) ? -1 : 0))
          sorted = [...sorted,...monthStats]
        }
      }      
      
      setDailyStats(sorted)
      
        ; setSalaries(stats.salaryStats)
    });
  }, []);
  const chartFormat = {
    width: 450,
    height: 310,
    padding: 10,
    margin: 5,
    border: "1px solid #CCCCCC",
    borderRadius: 5,
  };

  if (!dailyStats || !salaries) return <Loading />;
    const profit=calcProfit(dailyStats,salaries)
  console.log(salaries);
  return (
    <div>
      <div style={{display:"flex",justifyContent:"center"}}>
        <MonthChoice year={year} setYear={setYear} month={month} setMonth={setMonth} />
        </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {/*  <ToggleButtonGroup
        value={showCenter}
        exclusive
        onChange={(e) => setShowCenter(e.target.value)}
      >
        <ToggleButton value={"All"}>All</ToggleButton>
        {center.locations.map((location) => (
          <ToggleButton value={location._id} key={location._id}>
            {location.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup> */}
       
        <Students
          stats={dailyStats}
          center={center}
          title={"Active Students"}
          chartFormat={chartFormat}
          year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"New Students"}
          stat={"newStudents"}
          color="#770091"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"Stopped Students"}
          stat={"stoppedStudentsThisMonth"}
          color="#915a00"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"Revenue"}
          stat={"revenue"}
          color="#1e8a3b"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"Payments"}
          stat={"payments"}
          color="#00918a"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"Costs"}
          stat={"costs"}
          color="#910016"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <Money
          stats={dailyStats}
          title={"Pending Students"}
          stat={"pendingStudents"}
          color="#9e7900"
          chartFormat={chartFormat}
           year={year}
          month={month}
        />
        <ProfitSalary
          stats={salaries}
          title={"Salaries"}
          stat={"amount"}
          color="#910151"
          chartFormat={chartFormat}
          year={year}
          month={month}
        />
         <ProfitSalary
          stats={profit}
          title={"Profit"}
          stat={"amount"}
          color="#069171"
          chartFormat={chartFormat}
          year={year}
          month={month}
        />
      </div>
      {/*   <Costs stats={dailyStats} /> */}
      {/*  <RevenueByDay /> */}
    </div>
  );
}

function calcProfit(dailyStats, salaries) {
 
  let profit = []
  for (let salary of salaries) {
    
    let stats = dailyStats.filter(s => s.year === salary.year && s.month === salary.month)
    let payments = stats[stats.length - 1].payments
    let amount = payments-salary.amount-stats[stats.length - 1].costs

    profit.push({year: salary.year,month: salary.month,amount })

  }
  

return profit

}