import React, { useContext } from "react";
import { LanguageContext } from "../../../Context";

/**
 * 
 * @param {string} w the name of the word to be translated in lang.json 
 * @returns 
 */

export default function Lang(props) {
  const { l, language } = useContext(LanguageContext);
  const { w } = props;
  let word = l[w][language];
  if (!word) return <React.Fragment>Unkown Translation</React.Fragment>;
  return <React.Fragment>{word}</React.Fragment>;
}
