import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../../database";
import { useNavigate } from "react-router-dom";

export default function CopyClassDialog(props) {
  const { open, setOpen, htClass } = props;
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  const updateClass = () => {
    net.post(`/copy-and-retire-class/${htClass._id}`, { name }).then((res) => {
      navigate(`/manage/classes/edit/${res._id}`);
      setOpen(false);
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Create a new class from this class</DialogTitle>
      <DialogContent>
        This will retire this class and make a new class with the same
        information and students
        <TextField
          sx={{ mt: 1 }}
          fullWidth
          label="Name of the new class"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button color="success" onClick={() => updateClass()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
