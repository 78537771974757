import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import NewTargetDialog from "./NewTargetDialog";
import AddButton from "../../components/AddButton";


export default function Target(props) {


    const [target, settarget, error, loading] = useDB(`/targets`)
    const [open,setOpen] = useState(false)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(target);


    return (<div>Target
        <AddButton action={()=>setOpen(true)}/>
        <NewTargetDialog open={open} setOpen={setOpen} />
</div>)
}