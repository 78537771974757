import React, { useContext } from "react";
import { net } from "../../../../database";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import { Autocomplete, TextField } from "@mui/material";
import VND from "../../components/VND";
import SelectFromList from "../../components/SelectFromList";
import { weekdays } from "../../../../enums";
import { StaffContext } from "../../../../Context";

export default function StaffMember(props) {
  let { id } = useParams();
  const [staff, setStaff] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/user/${id}`).then((user) => setStaff(user));
  }, [id]);

  if (!staff) return <Loading />;

  const updateRoles = (e, roles) => {
  
    net.post(`/user/${staff._id}`, { roles }).then((staffIn) => {
      let { roles } = staffIn;
      setStaff({ ...staff, roles });
    });
  };
  

  

  return (
    <div>
      <div style={{ fontSize: 24 }}>{staff.name}</div>
      <div style={{ paddingBottom: 15 }}>{staff.login}</div>
      <Autocomplete
        multiple
        options={props.center.roles.filter(
          (r) => staff.roles.includes(r) === false
        )}
        value={staff.roles}
        onChange={(e, value) => updateRoles(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Roles"
          />
        )}
        sx={{ width: "500px" }}
      />

      <DrawSalary
        staff={staff}
        setStaff={setStaff}
      />

      <DayOff
        staff={staff}
        setStaff={setStaff}
      />

      {staff.reports.length > 0 ? (
        <div>
          <div style={{ fontSize: 11 }}>Reports ({staff.reports.length})</div>
          {staff.reports.map((report) => (
            <div key={report._id}>
              <Link
                key={report._id}
                to={`/manage/staff/${report._id}`}
              >
                {report.name}
              </Link>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}


function DrawSalary(props) {
  let { staff, setStaff } = props;
  const updateSalary = () => {
    net.post(`/user/${staff._id}`, { salary:staff.salary });
    
  };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 300 }}>Salary:</div>
        <VND value={staff.salary || ""} setValue={(salary)=>setStaff(prev=>({...prev, salary}))} onBlur={updateSalary} />
      </div>
    );
}
  

function DayOff(props){


const {staff,setStaff} = props
  const choices = weekdays.map((w, i) => ({ _id: i, name: w[0] }))
  const {updateStaff} = useContext(StaffContext)
console.log(choices)
  
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ width: 300 }}>Day off:</div>
      <SelectFromList
        choices={choices}
        value={staff.dayOff}
        setValue={(dayOff) => {
          setStaff((prev) => ({ ...prev, dayOff }))
          updateStaff(staff._id, "dayOff", dayOff)
        }
        }
        url={`/user/${staff._id}`}
        field="dayOff"
      />
    </div>
  );
}