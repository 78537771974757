import React, { useState } from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
//import Costs from "./dailyStats/Costs";
import Money from "./dailyStats/Money";
import Students from "./dailyStats/Students";
//import { ToggleButton, ToggleButtonGroup } from "@mui/material";
//import RevenueByDay from "./RevenueByDay";
import MonthChoice from "../../components/MonthChoice";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";
export default function Overview(props) {
 /*  const [dailyStats, setDailyStats] = React.useState(undefined); */
/*   const [showCenter, setShowCenter] = React.useState("All"); */
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [month, setMonth] = React.useState(new Date().getMonth()+1)
 /*  const [target, setTarget] = useState(undefined) */
  const { center } = props;
/*   React.useEffect(() => {
    net.get("/dailystats").then((stats) => {
      setDailyStats(stats.dailyStats.sort((a,b) => (a.day > b.day) ? 1 : ((b.day > a.day) ? -1 : 0)))
      setTarget(stats.target)
 
    }); */
  const [data, setData, error, loading] = useDB(`/dailystats/${year}/${month}`)

  if (error) return <Error />
  if( loading) return <Loading />

  const { dailyStats, target } = data
  console.log(data)
/* 
    let refresh = setInterval(() => {
      net.get("/dailystats").then((stats) => {
        setDailyStats(
          stats.dailyStats.sort((a, b) =>
            a.day > b.day ? 1 : b.day > a.day ? -1 : 0
          )
        );
        setTarget(stats.target)
     
      })
      }, 10000)
    
    return ()=> clearInterval(refresh) */

/*   }, []); */
  const chartFormat = {
    width: 450,
    height: 400,
    padding: 10,
    margin: 5,
    border: "1px solid #CCCCCC",
    borderRadius: 5,
  };
  if (!dailyStats || !target) return <Loading />;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MonthChoice
          year={year}
          setYear={setYear}
          month={month}
          setMonth={setMonth}
        />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {/*  <ToggleButtonGroup
        value={showCenter}
        exclusive
        onChange={(e) => setShowCenter(e.target.value)}
      >
        <ToggleButton value={"All"}>All</ToggleButton>
        {center.locations.map((location) => (
          <ToggleButton value={location._id} key={location._id}>
            {location.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup> */}
        <Students
          stats={dailyStats}
          center={center}
          title={"Active Students"}
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/newlost"}
          field="activeStudents"
        />
        <Money
          stats={dailyStats}
          title={"New Students"}
          stat={"newStudents"}
          color="#770091"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/newlost"}
          tab="1"
          target={target.newStudents}
          sites="newStudents"
        />
        <Money
          stats={dailyStats}
          title={"Stopped Students"}
          stat={"stoppedStudentsThisMonth"}
          color="#915a00"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/newlost"}
          tab="2"
          upGood={false}
        />
        <Money
          stats={dailyStats}
          title={"Revenue"}
          stat={"revenue"}
          color="#1e8a3b"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link="/tech/revenue-today"
          level={target.baseRevenue}
          sites="revenue"
          fieldType="money"
        />
        <Money
          stats={dailyStats}
          title={"Payments"}
          stat={"payments"}
          color="#00918a"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/money"}
          fieldType="money"
          sites="payments"
        />
        <Money
          stats={dailyStats}
          title={"Costs (non book)"}
          stat={"nonBooksCosts"}
          color="#910016"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/costs"}
          /* sites="costs" */
          fieldType="money"
          upGood={false}
        />
        <Money
          stats={dailyStats}
          title={"Books cost"}
          stat={"booksCost"}
          color="#910016"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/costs"}
          /* sites="costs" */
          fieldType="money"
          upGood={false}
        />
        <Money
          stats={dailyStats}
          title={"Pending Students"}
          stat={"pendingStudents"}
          color="#b55931"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/newlost"}
          tab="5"
          sites="pendingStudents"
          upGood={false}
        />
        <Money
          stats={dailyStats}
          title={"Owed"}
          stat={"owed"}
          color="#4287f5"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link="/owed/1"
          fieldType="money"
          upGood={false}
        />
        <Money
          stats={dailyStats}
          title={"Potential Payments"}
          stat={"potentialPayments"}
          color="#9042f5"
          chartFormat={chartFormat}
          year={year}
          month={month}
          level={target.baseRevenue}
          fieldType="money"
        />
        <Money
          stats={dailyStats}
          title={"Refunds for absent students"}
          stat={"classRefunds"}
          color="#ee96ff"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link="/absent"
          sites="classRefunds"
          fieldType="money"
          upGood={false}
        />{" "}
        <Money
          stats={dailyStats}
          title={"Potential Students"}
          stat={"totalStudents"}
          color="#1e3a8a"
          chartFormat={chartFormat}
          year={year}
          month={month}
          link={"/manage/newlost"}
          tab="5"
          sites="totalStudents"
        />
      </div>
      {/*   <Costs stats={dailyStats} /> */}
      {/*  <RevenueByDay /> */}
    </div>
  );
}
