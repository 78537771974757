import React from "react";


export default function Wiki(props) {

    let text = ""
    let tags = []

    const update = (e) => {
        if(e.key==="@") console.log("att")
    }

    return (<div
        style={{padding: 10, border:"1px solid #ccc", borderRadius:5}}
        contentEditable
        onKeyDown={update}
        placeholder="Notes"
    ></div>)
}