import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React from "react";
import Text from "../../components/Text";

export default function SetTargetDialog(props) {
  const { open, setOpen, update} = props;
    console.log(open)
  return (
    <Dialog open={open}>
          <DialogTitle>Set target for {open.name}</DialogTitle>
          <DialogContent>
              <Text value={open.maxStudents} setValue={e => {
                  open.maxStudents = parseInt(e)
                  update(open)
              }} type="Number"
                  url={`/htclasses/${open._id}`}
                  field="maxStudents"
              />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(undefined)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}