import React from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import LocationChoice from "../../components/LocationChoice";
import { weekdays } from "../../../../enums";
import CancelIcon from "@mui/icons-material/Cancel";
import ScheduledClasses from "./ScheduledClasses";

export default function TimeTable(props) {
  const { l, language, center } = props;
  const [mutt, setMutt] = React.useState(undefined);
  const [openDialog, setOpenDialog] = React.useState(false);
  console.log(center);
  React.useEffect(() => {
    net.get(`/makeup-timetable`).then((res) => setMutt(res));
  }, [openDialog]);

  if (!mutt) return <Loading />;
  return (
    <div>
      <Button onClick={() => setOpenDialog(true)} style={{ float: "right" }}>
        New timetable
      </Button>
      <DrawMakeUpTimeTable mutt={mutt} setMutt={setMutt} {...props} />

      <NewTimeTableDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        {...props}
      />
    </div>
  );
}

function DrawMakeUpTimeTable(props) {
  let { mutt, center, language, setMutt } = props;

  const deleteTT = (id) => {
    net.del(`/makeup-timetable/${id}`).then((res) => {
      setMutt(res);
    });
  };

  return (
    <div>
      <table style={{ width: "100%", textAlign: "center" }}>
        <thead>
          <tr>
            <th>Location</th>
            <th>Day</th>
            <th>Start</th>
            <th>Finish</th>
            <th>Level</th>
            <th>Max Students</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {mutt.map((mc) => (
            <tr key={mc._id}>
              <td>
                {center.locations.find((l) => l._id === mc.location).name}
              </td>
              <td>{weekdays[mc.dayOfWeek][language]}</td>
              <td>{mc.startTime}</td>
              <td>{mc.endTime}</td>
              <td>{mc.level}</td>
              <td>{mc.maxStudents}</td>
              <td>
                <IconButton color="error" onClick={() => deleteTT(mc._id)}>
                  <CancelIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ScheduledClasses {...props} />
    </div>
  );
}

function NewTimeTableDialog(props) {
  let { open, setOpenDialog, center, language } = props;
  let [newTimetable, setNewTimeTable] = React.useState({
    location: "",
    startTime: "",
    endTime: "",
    maxStudents: 10,
    level: "",
    dayOfWeek: 6,
  });

  const insertTimeTable = () => {
    net.post(`/makeup-timetable`, newTimetable).then((res) => {
      setOpenDialog(false);
    });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add new time</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <LocationChoice
            value={newTimetable.location}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, location: e.target.value })
            }
            locations={center.locations}
            fullWidth
          />
          <TextField
            label="Day of the week"
            select
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, dayOfWeek: e.target.value })
            }
            value={newTimetable.dayOfWeek}
          >
            {weekdays.map((day, i) => (
              <MenuItem value={i} key={day + i}>
                {day[language]}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Start Time"
            type="time"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, startTime: e.target.value })
            }
            value={newTimetable.startTime}
          />
          <TextField
            label="Finish Time"
            type="time"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, endTime: e.target.value })
            }
            value={newTimetable.endTime}
          />
          <TextField
            label="Level"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, level: e.target.value })
            }
            value={newTimetable.level}
          />
          <TextField
            label="Maximum number of students"
            type="number"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setNewTimeTable({ ...newTimetable, maxStudents: e.target.value })
            }
            value={newTimetable.maxStudents}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Close</Button>
        <Button onClick={() => insertTimeTable()}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
