import { React, useState, useEffect } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import StudentNamePhone from "./StudentNamePhone";
import { Avatar } from "@mui/material";

import Loading from "../../../Loading";
/* const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 50,
    height: 50,
  },
})); */
/**
 * @param  {object} students object holding students
 * @param {number} studentid selected students id
 */
export default function StudentAvatar(props) {
  //const classes = useStyles();
  // const [student, setStudent] = useState(props.student || undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    /*   if (student === undefined) {
      read(`student/${props.studentid}`).then((res) => {
        console.log(res);
        setStudent(res);
        setLoading(false);
      }); 
    }*/
  }, [loading]);

  if (props.student === undefined) {
    return (
      <div style={{ display: "flex", width: 300 }}>
        <Avatar name={"No Names"} />

        <div style={{ width: 20 }}></div>
        <Loading />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",

        width: props.width ? props.width : 300,
      }}
    >
      <Avatar src={props.student.image} name={props.student.name} />

      <div style={{ width: 20 }}></div>
      <StudentNamePhone student={props.student} />
      {props.showClass ? (
        <div style={{ marginTop: 10 }}>
          {props.student.classes.map((c) => c.name).toString()}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
