import { Button, TextField, IconButton, MenuItem } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import React from "react";
import NumberFormat from "react-number-format";

import format from "date-format";
import { net } from "../../../../../database";
import Receipt from "../../../components/Receipt";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";
import PaymentDialog from "../../owed/components/PaymentDialog";

import ChangeCredit from "./ChangeCredit";

export default function UnpaidBills(props) {
  let { center, customer, setCustomer, l, language } = props;
  let { students } = props.customer;
  /*   const [amount, setAmount] = React.useState(0);
  const [paymentType, setPaymentType] = React.useState(0); */
  const [open, setOpen] = React.useState(false);
  const [openCC, setOpenCC] = React.useState(false);
  const [receipt, setReceipt] = React.useState(undefined);
  /* const [payment, setPayment] = React.useState({
    type: 1,
    amount: 0,
    nextBillDate: new Date(),
    paymentFrequency: 1,
  }); */
  let navigate = useNavigate();
  let total = 0;

  console.log(customer);

  const openDialog = () => {
    /*  if (!customer.paymentFrequency || !customer.nextBillDate) {
      alert(
        l
          .makeSureTheCustomerHasANextBillDateAndHowOftenTheyPayBeforeMakingAPayment[
          language
        ]
      );
      return;
    } */
    /* setPayment({
      ...payment,
      amount: customer.owed,
      nextBillDate: addMonths(
        new Date(customer.nextBillDate),
        customer.paymentFrequency
      ),
    }); */
    setOpen(true);
  };

  /* const makePayment = () => {
    net
      .post(`/customers/${customer._id}/payments/add`, payment)
      .then((result) => {
        setCustomer(result.customer);
        setReceipt(result.receipt);
        setTimeout(() => {
          window.frames["Receipt"].focus();
          window.frames["Receipt"].print();
        }, 200);
        setOpen(false);
        setPayment({
          type: 1,
          amount: 0,
          nextBillDate: new Date(),
          paymentFrequency: 1,
        });
      });
  }; */

  /* const finishPayment = (result) => {
    setReceipt(result.receipt);
    setTimeout(() => {
      window.frames["Receipt"].focus();
      window.frames["Receipt"].print();
    }, 200);
   
  }; */

  const closePaymentDialog = (retCustomer) => {
    setCustomer({ ...customer, retCustomer });
    setOpen(false);
  };

  const updateCustomer = (update) => {
    net
      .post(`/customers/${props.customer._id}`, update)
      .then((r) => props.setCustomer({ ...props.customer, ...update }));
  };

  students.forEach((s) => {
    if (s.unpaidBills) {
      total += s.unpaidBills.reduce((a, b) => a + parseInt(b.amount), 0);
      s.dates = [...new Set(s.unpaidBills.map((b) => b.date))];
    }
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Unpaid Bills</div>

        <div>
          <TextField
            select
            label="Pays on date"
            sx={{ width: 150 }}
            value={props.customer.paysOnDayOfMonth}
            size="small"
            onChange={(e) =>
              updateCustomer({ paysOnDayOfMonth: e.target.value })
            }
          >
            {Array.apply(0, Array(30)).map((x, i) => (
              <MenuItem key={`payonday${i}`} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <Button
            sx={{ marginRight: 1 }}
            variant="contained"
            onClick={() => openDialog()}
            startIcon={<AttachMoneyIcon />}
          >
            Payment
          </Button>
          <IconButton
            aria-label="delete"
            onClick={() =>
              navigate(`/customer/${props.customer._id}/paymenthistory`)
            }
          >
            <HistoryIcon />
          </IconButton>
        </div>
      </div>
      {students.map((s) => (
        <div key={s._id}>
          {s.unpaidBills === undefined ||
          s.dates.length === 0 ||
          s.unpaidBills.length === 0 ? (
            ""
          ) : (
            <div>
              <hr />
              {s.name}
              {s.dates.map((d) => (
                <div key={`date${d}`}>
                  <div
                    style={{
                      fontSize: 11,
                      paddingLeft: 0,
                      color: "grey",
                    }}
                  >
                    {format("dd/MM/yyyy", new Date(d))}
                  </div>
                  {s.unpaidBills
                    .filter((b) => b.date === d)
                    .map((b) => (
                      <div
                        key={`b_id${b._id}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 0,
                          fontSize: 14,
                        }}
                      >
                        <div style={{ width: 150 }}>{b.billType}</div>
                        <div style={{ flexGrow: 1 }}>{b.description}</div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          <NumberFormat
                            value={b.amount}
                            thousandSeparator={true}
                            displayType="text"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ))}
              <hr style={{ marginRight: 5, marginLeft: 5 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 0,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                <div>Total</div>
                <div>
                  <NumberFormat
                    value={s.unpaidBills.reduce(
                      (a, b) => a + parseInt(b.amount),
                      0
                    )}
                    thousandSeparator={true}
                    displayType="text"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ))}

      {/*  {props.customer.credit > 0 && students.length === 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 5,
          }}
        >
          <div>Credit:</div>
          <div>
            {" "}
            <NumberFormat
              value={props.customer.credit}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        </div>
      ) : (
        ""
      )} */}

      {students.length > 0 ? (
        <div>
          {props.customer.credit > 0 ? (
            <div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                <div>Credit :</div>
                <div>
                  <NumberFormat
                    value={props.customer.credit}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  {props.user.roles.includes("Accountant") ? (
                    <Button color="error" onClick={() => setOpenCC(true)}>
                      {l.change[language]}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            <div>Total to pay:</div>
            <div>
              <NumberFormat
                value={total - props.customer.credit}
                displayType={"text"}
                thousandSeparator={true}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Make a payment for {props.customer.name}</DialogTitle>
        <DialogContent>
          <div>
            <FormControl focused>
              <NumberFormat
                customInput={TextField}
                variant="standard"
                thousandSeparator={true}
                onChange={(e) => setAmount(e.target.value)}
                autoComplete="off"
                value={amount}
                label={"Amount"}
              />
            </FormControl>
          </div>

          <div style={{ marginTop: 15 }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Payment Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={paymentType}
              >
                {center.paymentTypes.map((pt, i) => (
                  <FormControlLabel
                    value={i}
                    control={<Radio />}
                    label={pt}
                    key={i}
                    onChange={(e) => setPaymentType(e.target.value)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={makePayment}>{props.ok ? props.ok : "OK"}</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
      <PaymentDialog
        customerId={customer._id}
        open={open}
        setOpen={setOpen}
        l={l}
        language={language}
        user={props.user}
        center={center}
        onSuccess={closePaymentDialog}
      />
      <Receipt receipt={receipt} user={props.user} setReceipt={setReceipt} />
      <ChangeCredit {...props} open={openCC} setOpen={setOpenCC} />
    </div>
  );
}
