/**
 * @description Remove and object from an array by its id
 * @param {function} setFunction React setter function
 * @param {object} newObject Object to remove from the array
 */


export default function removeById(setFunction, oldObject) {
  if(typeof(oldObject)==="string")  setFunction((prev) => prev.filter((p) => p._id !== oldObject));
  setFunction((prev) =>
      prev.filter(p=> p._id!==oldObject._id)
  );
}
