import React from "react";
import { net } from "../../../../database";
import { Button } from "@mui/material";
import { formatPhone } from "../billCustomers/functions";
import StudentLink from "../../components/StudentLink";
export default function Billing(props) {
  //const {l,language} = props;
  const [customers, setCustomers] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/customers-active-students").then((response) => {
      setCustomers(response);
    });
  }, []);

  const checked = (student, customerid) => {
    net
      .post(`/student/${student._id}/fieldupdate`, {
        billsChecked: true,
      })
      .then((response) => {
        console.log(response);
        student.billsChecked = true;
        let cu = customers.find((c) => c._id === customerid);
        cu.students = cu.students.map((s) =>
          s._id === student._id ? student : s
        );
        setCustomers(customers.map((c) => (c._id === cu._id ? cu : c)));
      });
  };

  if (!customers) return <div>Loading</div>;

  let total = 0;
  let totalChecked = 0;

  for (let customer of customers) {
    for (let student of customer.students) {
      total++;
      if (student.billsChecked) totalChecked++;
    }
  }

  return (
    <div>
      <div className="dflex-sb">
        <h1>Billing</h1>
        <h1>
          {totalChecked} / {total} students
        </h1>
      </div>
      {customers.map((customer) => (
        <DrawCustomer
          customer={customer}
          key={customer._id}
          checked={checked}
        />
      ))}
    </div>
  );
}

function DrawCustomer(props) {
  let { checked, customer } = props;
  let { _id, name, phone, students } = customer;

  return (
    <div>
      <div>{name}</div>
      <div>{formatPhone(phone)}</div>
      {students.map((student) => (
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: 300 }}>
             <StudentLink student={student} showClasses />
            </div>
            {student.billsChecked ? (
              <Button
                color="success"
                variant="contained"
                size="small"
                style={{ width: 120 }}
              >
                Checked
              </Button>
            ) : (
              <Button
                color="error"
                variant="contained"
                size="small"
                style={{ width: 120 }}
                onClick={() => checked(student, _id)}
              >
                Not Checked
              </Button>
            )}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}
