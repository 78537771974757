import React from "react";
import ChangeClassDialog from "./ChangeClassDialog";
import EditClassDialog from "./EditClassDialog";

import NewClassDialog from "./NewClassDialog";
import ShowClasses from "./ShowClasses";


export default function StudentClasses(props) {
  const {student,setStudent} = props
  const [open, setOpen] = React.useState(false);
  const [changeOpen, setChangeOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [classToChange, setClassToChange] = React.useState(undefined);
  

  const openChangeDialog = (htClass) => {
    setClassToChange(htClass);
    setChangeOpen(true);
  };

  const openEditDialog = (htClass) => {
    setClassToChange(htClass);
    setEditOpen(true);
  };

  return (
    <div>
      <ShowClasses
        student={student}        
        setOpen={setOpen}
        setChangeOpen={openChangeDialog}
        setEditOpen={openEditDialog}  
        setStudent={setStudent}
      />

      <NewClassDialog
        open={open}
        setOpen={setOpen}
        student={student}
        setStudent={setStudent}
        title={`Add ${student.name} to a new class`}
      />

      <ChangeClassDialog
        open={changeOpen}
        handleClose={() => setChangeOpen(false)}
        student={props.student}
        classToChange={classToChange}
        setStudent={props.setStudent}
      />
      <EditClassDialog
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        student={props.student}
        classes={props.student.classes}
        setClasses={props.setClasses}
        classToChange={classToChange}
        language={props.language}
        setStudent={props.setStudent}
        center={props.center}
        l={props.l}
      />
    </div>
  );
}
