import React, { useContext } from "react";
import { Chip, MenuItem, TextField } from "@mui/material";

import { CenterContext } from "../../../Context";

export default function LocationChoiceChip(props) {
  const { center } = useContext(CenterContext);
  let { locations } = center;
  const {value,setValue} = props

  if (!center) return <div></div>;
  return (
    <div style={{ border: "1px solid #bbb", padding: 10, borderRadius: 5 }}>
      <div style={{fontSize:12, color: "#666"}}>Location</div>
      {locations.map(l => <Chip label={l.name} style={{margin: 5, width: 100}} variant="outlined" onClick={()=>setValue(l._id)} {...(value===l._id && {
      variant: "filled" 
      })}/>)}
    </div>
  );
}
