import React from "react";
import { Button } from "@mui/material";
import NewStudentDialog from "./components/NewStudentDialog";
import { Link } from "react-router-dom";

export default function Students(props) {
  const [openNewStudents, setOpenNewStudents] = React.useState(false);
  return (
    <div>
      {/*   <Button variant="contained" onClick={() => setOpenNewStudents(true)}>
        New Student
      </Button> */}
      {/*  <NewStudentDialog open={openNewStudents} setOpen={setOpenNewStudents} /> */}
      <Link to={`/newcustomer`}>
        <Button variant="contained">New Customer</Button>
      </Link>
    </div>
  );
}
