import React from "react";
import { net } from "../../../../database"
import Loading from "../../Loading"
import DrawStudent from "./DrawStudent";
import MonthChoice from "../../components/MonthChoice";

export default function StudentChanges(props) {
    const [st, setSt] = React.useState(undefined)
    const [year, setYear] = React.useState(new Date().getFullYear())
    const [month,setMonth] = React.useState(new Date().getMonth())
    
    React.useEffect(() => {
        net.get(`/students/status-changes/${year}/${month}`).then(res=>setSt(prev=>res))
    },[year,month]);

    if (!st) return <Loading />

    let students = st

    for (let student of students) {
         student.statusHistory = student.statusHistory
           .filter(
             (sh) => new Date(sh.startDate) < new Date(year, month + 1, 1)
           )
    }

    let pendingToActive = getPendingToActive(students)
    console.log(pendingToActive)
    
    let activeToPending = getActiveToPending(students)

console.log(students)

    return (
        <div>
            <MonthChoice year={year} setYear={setYear} month={month} setMonth={setMonth} />
        <h3>
          Students changed from Pending to Active ({pendingToActive.length})
        </h3>
        <Headings />
        {pendingToActive.map((student) => (
          <DrawStudent student={student} />
        ))}

        <h3>
          Students changed from Active to Pending ({activeToPending.length})
        </h3>
        <Headings pending/>
        {activeToPending.map((student) => (
          <DrawStudent student={student} />
        ))}
      </div>
    );
}


function Headings({ pending }) {
    
    return (
      <div style={{display:"flex",fontSize:11, color:"#888"}}>
        <div style={{ width: 400 }}>Name</div>
            <div style={{ width: 150 }}>Status Changed</div>
            {pending ? <div style={{ width: 150 }}>Return Date</div> : null}
      </div>
    );
}

function getPendingToActive(students) {
    
    let returnStudents = []
    
    for (let student of students) {
        let history = student.statusHistory.reverse()
        
        if (history.length > 1) {
            
            if (
              ["New", "Active"].includes(history[0].status) &&
              history[1].status === "Pending"
            ) {
                 
                    console.log(student._id);
                 returnStudents.push(student);
            }
             
          }


    }
    return returnStudents
}

function getActiveToPending(students) {
  let returnStudents = [];

  for (let student of students) {
   
 
    if (student.statusHistory.length > 1) {
      if (
        ["New", "Active"].includes(student.statusHistory[1].status) &&
        student.statusHistory[0].status === "Pending"
      )
        returnStudents.push(student);
    }
  }
  return returnStudents;
}