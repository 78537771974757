import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Timetable from "./pages/timetable/TimeTable";
import AttendanceFeedback from "./pages/feedback/AttendanceFeedback";
import Mystudent from "./pages/my_student/MyStudents";
import Campaigns from "./pages/campaigns/Campaigns";
import Overview from "./pages/overview/Overview";
import Staff from "./pages/staff/Staff";
import Attendance from "./pages/attendance/Attendance";
import Money from "./pages/money/Money";
import Profile from "./pages/profile/Profile";
import Settings from "./pages/settings/Settings";
import Student from "./pages/student/Student";
import Students from "./pages/students/Students";
import Owed from "./pages/owed/Owed";
import EditClass from "./pages/manageClasses/components/EditClass";
import Loading from "./Loading";
import NewCustomer from "./pages/newcustomer/NewCustomer";
import Customer from "./pages/customer/Customer";
import NewStudent from "./pages/newstudent/NewStudent";
import StaffShifts from "./pages/shifts/StaffShifts";
import Absent from "./pages/absent/Absent";
import NoShows from "./pages/noshows/NoShows";
import PaymentHistory from "./pages/customer/components/PaymentHistory";
import Care from "./pages/care/Care";
import StaffMember from "./pages/staff/StaffMember";
import HtClass from "./pages/class/HtClass";
import Admin from "./pages/admin/Admin";
import NewLostStudents from "./pages/newLostStudents/NewLostStudents";
import Accounting from "./pages/accounting/Accounting";
import MakeUpClasses from "./pages/makeUpClasses/MakeUpClasses";
import ManageMessages from "./pages/manageMessages/ManageMessages";
/* import LessonPlan from "./pages/lessonplan/LessonPlan"; */
import SalesMessages from "./pages/salesMessages/SalesMessages";
import ClassesReport from "./pages/reports/ClassesReport";
import Salaries from "./pages/salaries/Salaries";
import SalesStudents from "./pages/salesStudents/SalesStudents";
import PaymentFrequency from "./pages/paymentFrequency/PaymentFrequency";
import TimeTable from "./pages/makeUpClasses/TimeTable";
import MakeUpClassDetails from "./pages/makeUpClasses/MakeUpClassDetails";
import SalesFeedback from "./pages/salesFeedback/SalesFeedback";


import Billing from "./pages/accounting/Billing";
import Users from "./pages/users/Users";
/* import FixBills from "./pages/fixbills/FixBills"; */
import NewClasses from "./pages/newClasses/NewClasses";
import StaffList from "./pages/owner/staffList/StaffList";
import OrgTree from "./pages/owner/orgtree/OrgTree";
import ColdData from "./pages/coldData/ColdData";
import ManageFeedback from "./pages/managefeedback/ManageFeedback";
import Pending from "./pages/pending/Pending";
import RouteRecord from "./pages/tech/RouteRecord";
import RevenueToday from "./pages/revenueToday/RevenueToday";
/* import Leads from "./pages/leads/Leads";
import Testing from "./pages/testing/Testing"; */
import Absent2 from "./pages/absent/Absent2";
import NeedMakeUp from "./pages/needMakeUpClasses/NeedMakeUp";
import StudentChanges from "./pages/studentChanges/StudentChanges";
import ReadyForClass from "./pages/readyForClass/ReadyForClass";

import PlacementTests from "./pages/placementTests/PlacementTests";
//import Pricing from "./pages/pricing/Pricing";
/* import NextPayment from "./pages/nextPayment/NextPayment"; */
import Costs from "./pages/costs/Costs";
import Overview2 from "./pages/overview2/Overview2";
import SalaryStat from "./pages/overview2/SalaryStat";
import ExtraClass from "./pages/extraClass/ExtraClass";
import Curriculum from "./pages/curriculum/Curriculum";
import Syllabus from "./pages/curriculum/Syllabus";
import Unit from "./pages/curriculum/Unit";
import CLesson from "./pages/curriculum/CLesson";
import Test from "./pages/test/Test";
import StudentData from "./pages/studentData/StudentData";
import Tests from "./pages/Tests/Tests";
import TestRecord from "./pages/Tests/TestRecord";
import Feedback2 from "./pages/feedback/Feedback2";
import ClassList from "./pages/classList/ClassList";
import CalculateFees from "./components/CalculateFees";
import Target from "./pages/target/Target";
import Marketing from "./pages/marketing/Marketing";
import ManageMarketing from "./pages/manageMarketing/ManageMarketing";
import Pending2 from "./pages/pending2/Pending2";
import Leads from "./pages/leads/Leads";
import Survey from "./pages/survey/Survey";
import ManageSales from "./pages/manageSales/ManageSales";
import SalesCare from "./pages/salesCare/SalesCare";
import ManagePickup from "./pages/managePickup/ManagePickup";
import CheckBills from "./pages/checkBills/CheckBills";
import Observation from "./pages/observation/Observation";


import Financial from "./pages/financial/Financial";

import Projection from "./pages/projection/Projection";

import Humanresources from "./pages/humanResources/Humanresources";

import Notifications from "./pages/notifications/Notifications";

import FtTimetable from "./pages/ftTimetable/FtTimetable";

export default function Content(p) {
  /*  const [users, setUsers] = React.useState([]); */

  /*  React.useEffect(() => {
    p.net.get("/users").then((json) => {
      setUsers(json);
    });
  }, [p.net]); */
  /*  if (users.length === 0 || Object.keys(p.init) === 0)
    return <div>Loading...</div>; */

  /*  if (p.init === undefined) return <Loading />; */
  if (!p.center) return <Loading />;

  return (
    <div
      style={{
        padding: "15px",
        width: "100%",
        height: "92vh",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Home
            /*  user={p.user}
              language={p.language}
              center={p.center}
              l={p.l} */
            />
          }
        />
        <Route
          path="/test"
          element={<Test />}
        />
        <Route
          path="/fee"
          element={<CalculateFees />}
        />
        <Route
          path="/tests"
          element={<Tests />}
        />
        <Route
          path="/tests/:id"
          element={<TestRecord />}
        />
        <Route
          path="/student-data"
          element={<StudentData />}
        />
        <Route
          path="/timetable"
          element={
            <Timetable
              language={p.language}
              center={p.center}
              l={p.l}
            />
          }
        />
        <Route
          path="/admin"
          element={
            <Admin
              language={p.language}
              center={p.center}
              l={p.l}
            />
          }
        />
        <Route
          path="/placement-tests"
          element={<PlacementTests />}
        />
        <Route
          path="/curriculum"
          element={<Curriculum />}
        />
        <Route
          path="/curriculum/:id"
          element={<Syllabus />}
        />
        <Route
          path="/curriculum/:id/unit/:uid"
          element={<Unit />}
        />
        <Route
          path="/curriculum/:id/unit/:uid/lesson/:lid"
          element={<CLesson />}
        />
        <Route
          path="/class/:id"
          element={
            <HtClass
              center={p.center}
              language={p.language}
              user={p.user}
            />
          }
        />
        <Route
          path="/attendance"
          element={
            <Attendance
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/staffshifts"
          element={<StaffShifts center={p.center} />}
        />
        <Route
          path="/students"
          element={<Students />}
        >
          {/*  <Route
            path=":id"
            exact={true}
            element={<Student net={p.net} init={p.init} />}
          /> */}
        </Route>
        <Route
          path="/money"
          element={
            <Money
              /* init={p.init} */ user={p.user}
              center={p.center}
              l={p.l}
              language={p.language}
            />
          }
        />
        {/* <Route
          path="/owed"
          element={<Owed init={p.init} user={p.user} language={p.language} />}
        /> */}
        <Route
          path="/owed/:page"
          element={
            <Owed
              /* init={p.init} */ user={p.user}
              language={p.language}
              l={p.l}
              center={p.center}
            />
          }
        />
        <Route
          path="/absent"
          element={
            <Absent
              user={p.user}
              language={p.language}
              l={p.l}
              center={p.center}
            />
          }
        />
        <Route
          path="/absent2"
          element={<Absent2 />}
        />
        <Route
          path="/need-makeup-classes"
          element={<NeedMakeUp />}
        />
        <Route
          path="/noshows"
          element={
            <NoShows
              user={p.user}
              language={p.language}
            />
          }
        />
        <Route
          path="/care"
          element={
            <Care
              user={p.user}
              language={p.language}
            />
          }
        />
        <Route
          path="/classlist"
          element={<ClassList />}
        />
        <Route
          path="/feedback/:lessonId"
          element={
            <AttendanceFeedback
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/feedback2/:lessonId"
          element={<Feedback2 />}
        />
        {/*    <Route
          path="/lessonplan/:lessonId"
          element={
            <LessonPlan
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        /> */}
        <Route
          path="/mystudents"
          element={<Mystudent />}
        />
        <Route
          path="/marketingcampaigns"
          element={<Campaigns />}
        />
        <Route
          path="/newcustomer"
          element={
            <NewCustomer
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/newstudent/:customerId"
          element={
            <NewStudent
              /*  init={p.init} */
              /*  user={p.user}
              language={p.language}
              l={p.l} */
              {...p}
            />
          }
        />
        <Route
          path="/sales/messages"
          element={
            <SalesMessages
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/sales/students"
          element={
            <SalesStudents
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/sales/pending"
          element={<Pending {...p} />}
        />
        <Route
          path="/sales/care"
          element={<SalesCare />}
        />
        {/*  <Route
          path="/messages/:id"
          element={
            <ShowMessage
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        /> */}
        <Route
          path="/sales/payment-frequency"
          element={
            <PaymentFrequency
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        {/*   <Route
          path="/sales/next-payment"
          element={<NextPayment />}
        /> */}

        <Route
          path="/sales/newclasses"
          element={
            <NewClasses
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/sales/cold-data"
          element={
            <ColdData
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/sales/ready-for-class"
          element={<ReadyForClass />}
        />
        <Route
          path="/customer/:id/paymenthistory"
          element={
            <PaymentHistory
              user={p.user}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/customer/:id"
          element={
            <Customer
              user={p.user}
              language={p.language}
              l={p.l}
              center={p.center}
            />
          }
        />
        <Route
          path="/student/:id"
          element={
            <Student
              net={p.net}
              /* init={p.init} */
              language={p.language}
              user={p.user}
              l={p.l}
              center={p.center}
            />
          }
        />
        <Route
          path="manage/overview"
          element={<Overview center={p.center} />}
        />
        <Route
          path="/manage/staff"
          element={
            <Staff
              user={p.user}
              net={p.net}
            />
          }
        />
        <Route
          path="/manage/staff/:id"
          element={
            <StaffMember
              user={p.user}
              net={p.net}
              center={p.center}
            />
          }
        />
        <Route
          path="/manage/feedback"
          element={
            <ManageFeedback
              user={p.user}
              net={p.net}
              center={p.center}
            />
          }
        />
        <Route
          path="/manage/makeupclasses"
          element={
            <MakeUpClasses
              user={p.user}
              net={p.net}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/manage/makeupclasses/timetable"
          element={
            <TimeTable
              user={p.user}
              net={p.net}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/manage/makeupclasses/detail/:id"
          element={
            <MakeUpClassDetails
              user={p.user}
              net={p.net}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/profile"
          element={
            <Profile
              user={p.user}
              setUser={p.setUser}
              net={p.net}
              center={p.center}
            />
          }
        />
        {/*  <Route
          path="/fixbills"
          element={
            <FixBills
              user={p.user}
              setUser={p.setUser}
              net={p.net}
              center={p.center}
            />
          }
        /> */}
        <Route
          path="/manage/messages"
          element={
            <ManageMessages
              user={p.user}
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/manage/settings"
          element={
            <Settings
              center={p.center}
              setCenter={p.setCenter}
              l={p.l}
              language={p.language}
            />
          }
        ></Route>
        <Route
          path="/manage/settings/:setting"
          element={
            <Settings
              /* init={p.init} */ center={p.center}
              setCenter={p.setCenter}
            />
          }
        />
        <Route
          path="/manage/classes/edit/:id"
          element={
            <EditClass
              center={p.center}
              language={p.language}
              l={p.l}
              user={p.user}
            />
          }
        />
        {/*  <Route
          path="/manage/reports"
          element={
            <Reports
              center={p.center}
              language={p.language}
              l={p.l}
              user={p.user}
            />
          }
        /> */}
        {/*  <Route
          path="/manage/classes"
          element={
            <ManageClasses
              center={p.center}
              net={p.net}
              l={p.l}
              language={p.language}
            />
          }
        /> */}
        <Route
          path="sales/survey"
          element={<Survey />}
        />
        <Route
          path="sales/leads"
          element={<Leads />}
        />
        <Route
          path="/manage/classes/report"
          element={
            <ClassesReport
              center={p.center}
              net={p.net}
            />
          }
        />
        <Route
          path="manage/sales"
          element={<ManageSales />}
        />
        <Route
          path="/manage/newlost"
          element={
            <NewLostStudents
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/manage/salaries"
          element={
            <Salaries
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/manage/users"
          element={
            <Users
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        {/*   <Route
          path="/manage/pricing"
          element={<Pricing />}
        /> */}
        <Route
          path="/manage/costs"
          element={<Costs />}
        />
        <Route
          path="/manage/student-changes"
          element={<StudentChanges />}
        />
        <Route
          path="/manage/pickup-service"
          element={<ManagePickup />}
        />
        <Route
          path="/manage/observations"
          element={<Observation />}
        />
        <Route
          path="/accounting"
          element={
            <Accounting
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/accounting/billing"
          element={
            <Billing
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/owner/stafflist"
          element={
            <StaffList
              center={p.center}
              setCenter={p.setCenter}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/owner/stafftree"
          element={
            <OrgTree
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />

        <Route
          path="/tech/routerecord"
          element={
            <RouteRecord
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/tech/revenue-today"
          element={
            <RevenueToday
              center={p.center}
              language={p.language}
              l={p.l}
            />
          }
        />
        <Route
          path="/manage/overview2"
          element={<Overview2 />}
        />
        <Route
          path="/manage/overview2/addsalarystat"
          element={<SalaryStat />}
        />
        <Route
          path="/extra-classes"
          element={<ExtraClass />}
        />
        <Route
          path="/target"
          element={<Target />}
        />
        <Route
          path="/marketing"
          element={<Marketing />}
        />
        <Route
          path="/manage/marketing"
          element={<ManageMarketing />}
        />
        <Route
          path="/pending"
          element={<Pending2 />}
        />
        <Route
          path="/check-bills"
          element={<CheckBills />}
        />

        <Route
          path="/accounting/financial"
          element={<Financial />}
        />
        <Route path="/tech/projection" element={<Projection />} />
      <Route path="/manage/humanResources" element={<Humanresources />} /><Route path="/tech/notifications" element={<Notifications />} /><Route path="/admin/fttimetable" element={<FtTimetable />} /></Routes>
    </div>
  );
}
