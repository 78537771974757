import { addMonths } from "date-fns";

export default function buildPayment(customer) {
  return {
    customerId: customer._id,
    customerName: customer.name ? customer.name : "No Name",
    customerCredit: customer.credit,
    students: customer.students
      .filter((s) => s.unpaidBills && s.unpaidBills.length > 0)
      .map((student) => ({
        _id: student._id,
        name: student.name,
        nextBillDate: student.nextBillDate,
        newNextBillDate: addMonths(
          new Date(student.nextBillDate),
          student.paymentFrequency
        ),
        paymentFrequency: student.paymentFrequency,
        unpaidBills: student.unpaidBills,
      })),
    amount: customer.owed - customer.credit,
    type: 1,
    excludedStudents: [],
    excludedBills: [],
  };
}
