import { TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import Lang from "./Lang";

export default function PhoneNumber(props) {
  if (props.value === undefined || props.value===null
  ) return <div>No Phone number</div>;
  let displayType = props.setValue ? "input" : "text";
  let setValue = props.setValue ? props.setValue : () => {};

  return (
    <NumberFormat
      {...props}
      onValueChange={(value) => setValue(value.value)}
      customInput={TextField}
      format="(###) ###-####"
      displayType={displayType}
      label={<Lang w="phone" />}
    />
  );
}
