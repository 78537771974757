import React from "react";
import Status from "../../components/Status";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Student from "../student/Student";


export default function StatusChange({statusHistory}) {

    if (!statusHistory || statusHistory.length < 2) return <div><Status status={Student.status} /></div>
    const oldStatus = statusHistory[statusHistory.length - 2].status
    const status = statusHistory[statusHistory.length - 1].status;
    console.log(oldStatus, status)
return <div style={{display:"flex"}}>{oldStatus && <div style={{display:"flex"}}><Status status={oldStatus} /><ArrowRightIcon style={{color:"grey"}} /></div>}<Status status={status} /></div>
}