import { TextField } from "@mui/material";
import React, { useContext } from "react";
import { StudentsContext } from "../../../Context";
import SLink from "./SLink";
import {useFloating,offset,
  flip,
  shift} from '@floating-ui/react';
import { ClickAwayListener } from '@mui/base';
/**
 * 
 * @param {array} value Array of student ID's selected by this component
 * @param {array} setValue Function to call when this component updates its value
 * @returns 
 */

export default function StudentsChooser(props) {

    const {value,setValue} = props
    const [search, setSearch] = React.useState("")
    const { activeStudents } = useContext(StudentsContext)
    const [selected, setSelected] = React.useState(0)
    const { refs, floatingStyles, context } = useFloating({
  
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift()
    ],
   /*  whileElementsMounted: autoUpdate */
  });
    
    const keyHandler = (key) => {
        console.log(value)
        if (key.key === "ArrowDown") { key.preventDefault(); setSelected(prev => prev < 4 ? prev + 1 : 4) }
        if (key.key === "ArrowUp") {
            key.preventDefault(); setSelected(prev => prev > 1 ? prev - 1 : 0)
        }
        if (key.key === "Enter") {
            let student = activeStudents.filter(s => !value.includes(s._id) && s.name.toLowerCase().includes(search.toLowerCase())).splice(0, 5)[selected]._id
            setValue([...value, student])
            setSearch("")
        }
    }
  
    return (<div style={{border:"1px solid #bbb", padding:10,borderRadius:5,display:"flex", flexDirection:"column", gap:5}}>
        <div style={{fontSize:11, color: "#666"}}>Students</div>
        {value.map((student,i) => <div style={{ display: "flex", alignItems:"center" }}>
            <SLink id={student.student} showClasses style={{ width: 150, marginRight: 5 }} />
            <div>
                <TextField value={student.notes} onChange={(e) => {
                    value[i].notes = e.target.value
                    setValue(value)
                }} label="Notes..." size="small" fullWidth InputProps={{ style: { fontSize: 12, padding: 0 } }} InputLabelProps={{ sx: { fontSize: 12 } }}/>
                <TextField label="Link to materials" size="small" fullWidth value={student.link} onChange={(e) => {
                    value[i].link = e.target.value
                    setValue(value)
                }}style={{marginTop:10}} InputProps={{style:{fontSize:12, padding:0}}} InputLabelProps={{ sx: { fontSize: 12 } }}/>
            </div>
            </div>)} 
        <TextField ref={refs.setReference} label="Search..." value={search} onChange={(e) => setSearch(e.target.value)} fullWidth size="small" onKeyDown={keyHandler}/>
        {search ? <StudentsList activeStudents={activeStudents} search={search} setSearch={setSearch} selected={selected} setValue={setValue} value={value} refs={refs} floatingStyles={floatingStyles} context={context} /> : null}
         </div>)
}

function StudentsList({ activeStudents, search, setSearch, selected, setValue,value,refs,floatingStyles,context }) {
    const addStudent = (student) => {
        setValue([...value, { student, notes: "", link: "" }])
        setSearch("")  
    }
    
    return <ClickAwayListener onClickAway={()=>setSearch("")}><div ref={refs.setFloating} style={{ ...floatingStyles, width: 500, background:"white", borderRadius:10,zIndex:100, border:"1px solid #ccc",padding: 5 }}/* style={{ position: "absolute", background: "white", zIndex: 100 }} */>
        {activeStudents.filter(s => !value.includes(s._id) && s.name.toLowerCase().includes(search.toLowerCase())).splice(0, 5).map((student, i) => <div style={{borderRadius:10,padding:5,cursor:"pointer"}} onClick={()=>addStudent(student._id)} {...(selected===i && {
            style: { background: "#ddd",borderRadius:5,padding:5 }
        })}><SLink id={student._id} showClasses noLink /></div>)}
    </div></ClickAwayListener>
}