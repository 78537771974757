import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import { net } from "../../../database";

/**
 * Description placeholder
 * @date 6/17/2023 - 3:56:45 PM
 *
 * @export
 * @param {function} action action to take when clicked
 * @param {number} size The size of the icon button (px)
 * @param {string} tooltip overide the default tooltip text
 * @returns {*}
 */
export default function EditButton({ action, size,tooltip }) {
  
  if (!size) size = 40;
  return (
    <Tooltip title={tooltip || "Edit"}>
    <IconButton
      color="info"
      onClick={action}
      style={{ height: size, width: size }}
      variant="contained"
    >
      <EditIcon />
      </IconButton>
      </Tooltip>
  );
}
