import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import Location from "../../components/Location";
import Staff from "../../components/Staff";
import Info from "../../components/Info";


export default function AvailableClasses(props) {
    const { dateChosen,timetable } = props
    const [classes,setClasses] = React.useState(undefined)
    React.useEffect(() => {
        net.get(`/makeupclasses/available/${dateChosen.toISOString()}`).then(res => { ; setClasses(prev => res) })
        setClasses(prev=>undefined)
},[dateChosen]);

    if(!classes) return <Loading />
    console.log(classes)
   
    return (<div>{timetable.filter(t=>t.dayOfWeek===dateChosen.getDay()).map(time=><DrawClass time={time} classes={classes} />)}</div>)
}


function DrawClass(props) {
    let {time,classes} = props
    let muClass = classes.find(c=>c.timeTable===time._id)
    console.log(time)
    return (
      <div
        className={
          muClass && muClass.open === false ? "muclass-disabled" : "muclass"
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Info title="Location" body={<Location id={time.location} />} />
          {muClass && muClass.teacher ? (
            <Info title="Teacher" body={<Staff id={muClass.teacher} />} />
          ) : (
            <Info title="Teacher" body={"No teacher"} />
          )}
          {muClass && muClass.students ? (
            <Info title="Students" body={muClass.students.length} />
          ) : (
            <Info title="Students" body={"0"} />
          )}
          <Info title="Time" body={`${time.startTime} - ${time.endTime}`} />
          <Info
            title="Status"
            body={
              muClass && muClass.open === false ? (
                <div style={{ color: "red", fontSize: 12 }}>Closed</div>
              ) : (
                <div style={{ color: "green", fontSize: 12 }}>Open</div>
              )
            }
          />
        </div>
      </div>
    );
}