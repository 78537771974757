import React, { useContext } from "react";
import { CenterContext } from "../../../Context";

/**
 * Description placeholder
 * @date 6/15/2023 - 6:49:25 PM
 *
 * @export
 * @param {*} id The id of the location
 * @returns {*}
 */
export default function Location(props) {
  let { id } = props;
  const { center } = useContext(CenterContext);
  if (!center) return <div></div>;
  const location = center.locations.find((l) => l._id === id);
  if (!location) return <div>Unknown</div>;
  return <div {...props}>{location.name}</div>;
}
