import React from "react";

export default function Title(props) {
  //const {l,language} = props;

  return (
    <div
      style={
        {
          display: "flex",
          flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
        }
      }
    >
      <img src="/receiptLogo.png" width={300} alt="No Logo" id="htlogo"/>
      <br />
      <div>
        <div style={{ textAlign: "center", fontSize: 12 }}>
          51B Kẻ Vẽ, 80 Kẻ Vẽ, ph: 036-860-5499
        </div>
        <br />
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          HÓA ĐƠN HỌC PHÍ{" "}
        </div>
      </div>
    </div>
  );
}
