import React from "react";
import { useContext } from "react";
import { ClassesContext, StaffContext } from "../../../../../Context";


export default function FreeTime({day}) {

    const { getStaffRole } = useContext(StaffContext);
    const teachers = getStaffRole("Teacher").filter(s => s.roles.includes("Full time"))
    const { classes } = useContext(ClassesContext)
    const htClasses = classes.filter((c) =>
      ["Active", "Coming", "Tutoring", "Extra"].includes(c.status)
    );
    const times = day>0 ? ["17:45","19:30"] : ["16:00", "17:45"]

    return (<div>{
        times.map(t => <DrawTime time={t} day={day} classes={htClasses} teachers={teachers} />)}
    </div>)
}

function DrawTime({ time, day, classes, teachers }) {
    let busyTeachers = []
    for (let c of classes) {
        if (time === "17:45") {
              let cd = c.classDays.find(
                (cld) =>
                  cld.day === day && ["17:45","18:00"].includes(cld.startTime.substring(0, 5))
              );
              if (cd) busyTeachers.push(c.teacher);
        } else {
              let cd = c.classDays.find(
                (cld) =>
                  cld.day === day && cld.startTime.substring(0, 5) === time
              );
              if (cd) busyTeachers.push(c.teacher);
        }
      
    }
    
    const freeTeachers = teachers.filter(t=>!busyTeachers.includes(t._id) && t.dayOff!==day)
   

    return (
      <div>
        <div
          style={{
            textAlign: "center",
            padding: "0px",
            paddingTop: "5px",
            fontSize: "12px",

            margin: 0,
          }}
        >
          {time}
        </div>
        <div className="frame" style={{color: "white", fontSize:14, padding: 5}}>
          {freeTeachers.map((t) => (
            <div>{t.name}</div>
          ))}
        </div>
      </div>
    );
}