import React from "react";
import { net } from "../../../../../database";
import { useParams } from "react-router-dom";
import Loading from "../../../Loading";
import { format } from "date-fns";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

export default function PaymentHistory() {
  let params = useParams();

  const [customer, setCustomer] = React.useState(undefined);

  React.useEffect(() => {
    net
      .get(`/customer/${params.id}/paymenthistory`)
      .then((customer) => setCustomer(customer));
  }, [params.id]);

  if (!customer) return <Loading />;
  customer.payments.sort((a, b) =>
    new Date(a.date) > new Date(b.date) ? -1 : 1
  );

  return (
    <div>
      {customer.payments.map((payment) => (
        <div key={payment._id}>
          <div style={{ display: "flex" }}>
            <div style={{ width: 400 }}>
              {format(new Date(payment.date), "dd/MM/yyyy")}
            </div>
            <div style={{ width: 100, textAlign: "right" }}>
              {" "}
              <NumberFormat
                value={payment.amount}
                displayType={"text"}
                thousandSeparator={true}
              />
            </div>
          </div>
          {customer.students.map((student) => (
            <DrawStudent
              key={student._id}
              student={student}
              customer={customer}
              payment={payment}
            />
          ))}
          <hr />
        </div>
      ))}
    </div>
  );
}

function DrawStudent(props) {
  let { student, customer, payment } = props;

  let bills = customer.bills.filter(
    (b) => b.paymentId === payment._id && b.student === student._id
  );
  if (bills.length === 0) return <div></div>;

  return (
    <div style={{ fontSize: 14, fontWeight: "bold" }}>
      <Link to={`/student/${student._id}`} className="neat">
        {student.name}
      </Link>
      {bills.map((bill) => (
        <div key={bill._id} style={{ display: "flex", fontWeight: "normal" }}>
          <div style={{ width: 400 }}>{bill.description}</div>
          <div style={{ width: 100, textAlign: "right" }}>
            <NumberFormat
              value={bill.amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
