import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Staff from "../../components/Staff";
import Loca from "../../components/Loca";
import "./ftTimetable.css";

export default function FtTimetable(props) {


const [htClasses, setHtClasses, error, loading] = useDB(`/htclasses?status=Active`)


if (loading) return <Loading />;
if (error) return <Error />;


 
  
  let ftTeachers = []

  for (let c of htClasses) {
    for (let cd of c.classDays) {
      for (let t of cd.otherTeachers) {
        let teacher = ftTeachers.find(f => f._id === t.teacher)
        if (!teacher) {
          ftTeachers.push({ _id: t.teacher, shifts: [{ startTime: t.startTime, endTime: t.endTime, location: c.location, room: cd.room, day: cd.day,name: c.name }] })
        } else {
          teacher.shifts.push({ startTime: t.startTime, endTime: t.endTime, location: c.location, room: cd.room,day: cd.day,name:c.name })
        }
      }
    }
  }


return(<div>{ftTeachers.map(t => <div><DrawTeeacherTimetable teacher={t} /></div>)}</div>)
}

function DrawTeeacherTimetable({ teacher }) {
  
  let times = [...new Set([...teacher.shifts.map((s) => s.startTime)])]
  let days = [{day:10, name:""},{ day: 1,name:"Mon" }, { day: 2,name:"Tue" }, { day: 3,name:"Wed" }, { day: 4,name:"Thu" }, { day: 5,name:"Fri" }, { day: 6,name:"Sat" }, { day: 7,name:"Sun" }]
  times = times.sort()
  console.log(times)
  return (
    <div>
      <div className="teachers-name">
        <Staff id={teacher._id} />
      </div>
      <table className="shift-table">
        <tr>
          {days.map((d) => (
            <th>{d.name}</th>
          ))}
        </tr>
        {times.map((t) => (
          <tr>
            <DrawTime
              time={t}
              days={days}
              shifts={teacher.shifts.filter((s) => s.startTime === t)}
            />
          </tr>
        ))}
      </table>
    </div>
  );
}

function DrawTime({ time, days, shifts }) {
  
  return <><td className="shift-cell">{time}</td>{days.map(d => <td className="shift-cell"><DrawClass shift={shifts.find(s => s.day === d.day)}/></td>)}</>
}


function DrawClass({ shift }) {
  if(!shift) return <div></div>
  return (
    <div>
      <div className="name-of-class">{shift.name}</div>
      <div className="class-details">
        {shift.startTime} - {shift.endTime}
      </div>
      <div className="class-details">
        <Loca id={shift.location} />
      </div>
      <div className="class-details">{shift.room}</div>
    </div>
  );
}