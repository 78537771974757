import React from "react";
import { useParams} from "react-router-dom";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { scoreColours } from "../../../../enums";
import "./feedback.css";

import FeedbackDialog from "./FeedbackDialog";
import StudentAvatar from "../../components/StudentAvatar";


export default function Feedback(props) {
  const { feedbackCategories } = props.center || [];
  const { lessonId } = useParams();
  const [lesson, setLesson] = React.useState(undefined);
  const [attendance, setAttendance] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [attendanceStudent, setAttendanceStudent] = React.useState(undefined);


  /*  let navigate = useNavigate(); */

  React.useEffect(() => {
    net.get(`/lesson/${lessonId}`).then((data) => {
      console.log(data);
      setLesson(data.lesson);
      setAttendance(data.attendance);
    });
  }, [lessonId]);

  function drawFeedback(fbc, at) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {fbc.map((fb) => (
          <div
            key={fb}
            style={{
              width: 75,
              textAlign: "center",
              borderRadius: 5,
              marginLeft: 2,
              marginRight: 2,
              backgroundColor:
                at.feedback && at.feedback[fb]
                  ? scoreColours[at.feedback[fb]]
                  : scoreColours[0],
            }}
          >
            {at.feedback && at.feedback[fb] ? at.feedback[fb] : ""}
          </div>
        ))}{" "}
      </div>
    );
  }

  const updateLesson = (e, field) => {
    net
      .post(`/lesson/${lesson._id}`, { [field]: e.target.value })
      .then((updatedLesson) => {
        console.log(updatedLesson);
        setLesson({ ...lesson, [field]: updatedLesson[field] });
      });
  };

  /* const updateLessonBooks = () => {
    let lessonBooks = lesson.lessonBooks;

    net.post(`/lesson/${lesson._id}`, { lessonBooks }).then((updatedLesson) => {
      console.log(updatedLesson);
      setLesson({ ...lesson, lessonBooks: updatedLesson.lessonBooks });
    });
  }; */

  const updateAttendance = (e, attendanceIn, field) => {
    net
      .post(`/attendance/${attendanceIn._id}`, { [field]: e.target.value })
      .then((returnedAttendance) => {
        setAttendance(
          attendance.map((a) =>
            a._id === returnedAttendance._id ? returnedAttendance : a
          )
        );
      });
  };

  if (!lesson) return <Loading />;

  /* let books = getBooks(lesson); */

  /* if (lesson.classId.books && lesson.classId.books.length > 0)
    books = lesson.classId.books; */

 /*  const updateBookUnit = (_id, unit) => {
    let lessonBooks = lesson.lessonBooks;
    let checkBook = lessonBooks.find((b) => b._id === _id);

    if (!checkBook) {
      lessonBooks.push({ _id, unit });
    } else {
      checkBook.unit = unit;
    }
    console.log(lessonBooks);
    setLesson({ ...lesson, lessonBooks });
  }; */

 /*  const updateBookLesson = (_id, lessonin) => {
    let lessonBooks = lesson.lessonBooks;
    let checkBook = lessonBooks.find((b) => b._id === _id);

    if (!checkBook) {
      lessonBooks.push({ _id, lesson: lessonin });
    } else {
      checkBook.lesson = lessonin;
    }
    console.log(lessonBooks);
    setLesson({ ...lesson, lessonBooks });
  };
 */
  const openLink = (link) => {
    window.open(lesson.lessonPlanLink);
  };

  return (
    <div>
      {/*  <Button onClick={() => navigate(`/lessonplan/${lesson._id}`)}>
        {l.lessonPlan[language]}
      </Button> */}
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <div style={{display:"flex", justifyContent: "space-between",alignItems:"center"}}>
          <h1>{lesson.classId.name}</h1>
            <UnitLesson htClass={lesson.classId} setLesson={setLesson} />
            </div>
       {/*    <TextField
            value={lesson.notes}
            onChange={(e) => setLesson({ ...lesson, notes: e.target.value })}
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 1 }}
            onBlur={(e) => updateLesson(e, "notes")}
            label={l.goalsForThisLesson[language]}
          /> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Lesson sheet/plan link"
              fullWidth
              value={lesson.lessonPlanLink}
              onChange={(e) =>
                setLesson({ ...lesson, lessonPlanLink: e.target.value })
              }
              onBlur={(e) => updateLesson(e, "lessonPlanLink")}
              sx={{ mt: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    <IconButton
                      aria-label="open link in new window"
                      onClick={(e) => openLink()}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {/* <div style={{ paddingLeft: 10, width: 300 }}>
          <h3 style={{ marginTop: 0, marginBottom: 0 }}>Books</h3>
          {books.map((book) => (
            <DrawBook
              book={book}
              updateBookUnit={updateBookUnit}
              updateBookLesson={updateBookLesson}
              updateLessonBooks={updateLessonBooks}
            />
          ))}
        </div> */}
      </div>
      <h3>Feedback for students</h3>
      <div
        style={{ display: "flex", fontSize: 11, padding: 10, paddingBottom: 0 }}
      >
        <div style={{ width: 300 }}>Name</div>
        {feedbackCategories.map((fb) => (
          <div key={fb} style={{ width: 75, textAlign: "center" }}>
            {fb}
          </div>
        ))}
        <div style={{ flexGrow: 1, marginLeft: 10 }}>Notes</div>
      </div>
      {attendance.map((at) => (
        <div
          key={at._id}
          className={
            at.attendance < 2 ? "feedback-frame" : "feedback-frame-disabled"
          }
          onClick={() => {
            if (at.attendance < 2) {
              setOpen(true);
              setAttendanceStudent(at);
            }
          }}
          {...(at.attendance > 1 && { disabled: true })}
        >
          <div className="feedback-students-name" style={{ display: "flex" }}><StudentAvatar id={at.student._id} noLink /></div>
          {at.attendance < 2 ? (
            drawFeedback(feedbackCategories, at)
          ) : (
            <div className="feedback-absent-reason">{at.absentReason}</div>
          )}
   
          <div className="feedback-notes">{at.notes ? at.notes : ""}</div>
        </div>
      ))}

      <FeedbackDialog
        attendanceStudent={attendanceStudent}
        open={open}
        setOpen={setOpen}
        updateAttendance={updateAttendance}
        setAttendanceStudent={setAttendanceStudent}
        setAttendance={setAttendance}
        attendance={attendance}
        {...props}
      />
      <div id="secretDiv" style={{ display: "none" }}></div>
    </div>
  );
}

/* function DrawBook(props) {
  let { book, updateBookUnit, updateBookLesson, updateLessonBooks } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      <div>{book.name}</div>
      <TextField
        type="number"
        label="Unit"
        size="small"
        sx={{ mt: 1 }}
        onChange={(e) => updateBookUnit(book._id, e.target.value)}
        onBlur={() => updateLessonBooks()}
        value={book.unit}
      />
      <TextField
        type="number"
        label="Lesson"
        size="small"
        sx={{ mt: 1 }}
        onChange={(e) => updateBookLesson(book._id, e.target.value)}
        onBlur={() => updateLessonBooks()}
        value={book.lesson}
      />
    </div>
  );
}

function getBooks(lesson) {
  if (lesson.lessonBooks) return lesson.lessonBooks;
  if (lesson.classId.books && lesson.classId.books.length > 0)
    return lesson.classId.books;

  return [];
} */


function UnitLesson({htClass,setLesson}) {
  
  return   <div style={{display:"flex", justifyContent: "space-between", gap:10}}>
              <TextField label="Unit" value={htClass.currentUnit || ""} type="number" onChange={(e) => {
      setLesson(prev => {
        let { classId } = prev
        classId.currentUnit = e.target.value
        return {...prev, classId}
               })
            }
            } 
            onBlur={()=>  net.post(`/classes/${htClass._id}/update`,{currentUnit: htClass.currentUnit})}    
            size="small" style={{ width: 80 }} /> 
              <TextField label="Lesson" value={htClass.currentLesson || ""} type="number" onChange={(e) => {
                let currentLesson = e.target.value
           
              setLesson(prev => {
        let { classId } = prev
        classId.currentLesson = e.target.value
        return {...prev, classId}
               })
            }
            }
                 onBlur={()=>  net.post(`/classes/${htClass._id}/update`,{currentLesson: htClass.currentLesson})}   
                size="small" style={{ width: 80 }} /> 
            </div>
}