import React, { useContext } from "react";
import "./absent.css";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Button, Grid, IconButton, TextField} from "@mui/material";

import { Link } from "react-router-dom";
import { formatPhone } from "../../../../functions";
import { socket } from "../../../../App";
import AddMakeUpClassDialog from "./AddMakeUpClassDialog";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import Lang from "../../components/Lang";
import HtClass from "../../components/HtClass";
import SLink from "../../components/SLink";
import HtTabs from "../../components/HtTabs";
import { CenterContext, ClassesContext } from "../../../../Context";

export default function Absent(props) {
  const [absences, setAbsences] = React.useState(undefined);
  const {center} = useContext(CenterContext)

  React.useEffect(() => {
    
    net
      .get(`/absent`)
      .then((r) => {
        console.log(r)
        setAbsences(r);
      });
   /*  socket.on("absentUpdate", (r) => {
      setAbsences(r);
    });



    return () => {
      socket.off("absentUpdate");
    }; */
  }, []);


 



  if (!absences) return <Loading />;

  let htClasses = [...new Set(absences.map((a) => a.htClass))];

  

  const labels = center.locations.filter(l=>l.name!=="All locations").map(l => l.name)
  const components = center.locations.filter(l=>l.name!=="All locations").map(l => <DrawAbsentLocation htClasses={htClasses} absences={absences} location={l._id} setAbsences={setAbsences} />)
 
  return (
    <div style={{ padding: 10 }}>
      <div className="dflex-sb">
     
        <h1><Lang w="absentStudents" /></h1>
      </div>
    
      <HtTabs labels={labels} components={components} localStorage="location"/>
    </div>
  );
}

function DrawAbsentLocation({ htClasses, absences, location, setAbsences }) {

  const { findClass } = useContext(ClassesContext)
  let filClasses = []
  for (let htc of htClasses) {
    let c = findClass(htc)
    if(c.location===location) filClasses.push(htc)
  }

  const updateAbsentReason = (absence) => {

    net
      .post(`/students/absentReason/${absence._id}`, {
        absentReason: absence.absentReason,
      })
      .then((attendance) => {
   
        absence = { ...absence, ...attendance };
        setAbsences(absences);
      });
  };
   const addAbsentReason = (absentReason, absence) => {
    absence = { ...absence, absentReason };
    setAbsences(absences.map((a) => (a._id === absence._id ? absence : a)));
  };
  return (
    <div>
      <Grid container className="absent-table-labels">
        <Grid item xs={3}>
         <Lang w="name" />
        </Grid>
        <Grid item xs={1}>
          <Lang w="type" />
        </Grid>
        <Grid item xs={3}>
          <Lang w="reason" />
        </Grid>
      </Grid>
      {filClasses.map((id) => (
        <div>
          <h3>
            <HtClass htClass={id} link/>
          </h3>

          {absences
            .filter((a) => a.htClass === id)
            .map((absence) => (
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={3}>
                  <SLink id={absence.student} showPhone/>
                
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    color: absence.attendance === 2 ? "#ffae00" : "#ff0055",
                  }}
                >
                  {absence.attendance === 2 ? "P" : "O"}
                </Grid>
                <Grid item xs={3}>
                  {absence.attendance < 3 ? (
                    absence.absentReason
                  ) : (
                    <TextField
                      value={absence.absentReason ? absence.absentReason : ""}
                      size="small"
                      label="Reason"
                      onChange={(e) => addAbsentReason(e.target.value, absence)}
                        onBlur={() => updateAbsentReason(absence)}
                        fullWidth
                    />
                  )}
                </Grid>
              
              </Grid>
            ))}
        </div>
      ))}</div>)
}