import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, List, ListItemButton, Stack, TextField } from "@mui/material";

import React from "react";
import { net } from "../../../../../database";
import { add } from "date-fns";
import Location from "../../../components/Location";
import Lang from "../../../components/Lang";

export default function ChangeClassDialog(props) {
  const [classes, setClasses] = React.useState(undefined);
  const [filter, setFilter] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [reasonErr, setReasonErr] = React.useState(false);
  const [changeClassDate, setChangeClassDate] = React.useState("");
  const [htClassId, setHtClassId] = React.useState(undefined);
  const { l, language } = props;
  React.useEffect(() => {
    net.get("/classes").then((classes) => {
      let sclasses = props.student.classes.map((c) => c._id);

      setClasses(
        classes
          .filter((c) => sclasses.includes(c._id) === false)
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      );
    });
  }, []);

  const addClass = () => {
    if (reason === "") {
      setReasonErr(true);
      return;
    }
    let change = {
      newClass: htClassId,
      oldClass: props.classToChange._id,
      reason,
      changeClassDate,
    };
    net
      .post(`/student/${props.student._id}/classes/change`, change)
      .then((classes) => {
        props.setStudent({ ...props.student, classes });
        setReasonErr(false);
        setReason("");
        props.handleClose();
      });
  };

  const selectClass = (htClass) => {
    setHtClassId(htClass._id);
    let dt = new Date();
    let days = htClass.classDays.map((cd) => cd.day);
    let count = 0;

    while (!days.includes(dt.getDay()) && count < 7) {
      dt = add(dt, { days: 1 });

      count++;
    }
    setChangeClassDate(dt.toISOString().substring(0, 10));
    console.log(htClass);
  };

  if (classes === undefined) return <div></div>;

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>
        Change {props.student.name} from{" "}
        {props.classToChange ? props.classToChange.name : ""}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label={"Reason for changing"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            {...(reasonErr && { error: true })}
            sx={{ mt: 1 }}
          />
          <TextField
            label={"First day in the new class"}
            value={changeClassDate}
            onChange={(e) => setChangeClassDate(e.target.value)}
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label={<Lang w="search" />}
            onChange={(e) => setFilter(e.target.value.toLocaleLowerCase())}
            fullWidth
          />
        </Stack>
        <List>
          {classes
            .filter((c) => c.name.toLowerCase().includes(filter))
            .map((c) => (
              <ListItemButton
                key={c._id}
                onClick={() => selectClass(c)}
                selected={c._id === htClassId}
                className="MenuItem"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>{c.name}</div>
                <div>
                <Location id={c.location} />
                </div>
              </ListItemButton>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="error">
          Close
        </Button>
        <Button onClick={addClass} color="success">
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
