import React from "react";
import { Button, Paper, Stack, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { net } from "../../../../../database";
import { useNavigate } from "react-router-dom";

export default function GetPhone(props) {
  let { l, language } = props;

  console.log(l);
  const [phoneError, setPhoneError] = React.useState("false");

  let navigate = useNavigate();
  const checkPhone = () => {
    if (props.phone === "" || props.phone.length < 10) {
      setPhoneError(true);
      return;
    }
    net.get(`/customer/phone/${props.phone}`).then((r) => {
      if (r === null) {
        console.log(r);
        props.setPhoneCheck(true);
        return;
      } else {
        console.log(r);
        navigate("/customer/" + r._id);
        return;
      }
    });
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 100 }}>
      <Paper elevation={3} sx={{ padding: 2, width: 400 }}>
        <Stack spacing={2}>
          <div>{l.pleaseTypeInTheCustomersPhoneNumber[language]}</div>
          <NumberFormat
            label={l.phone[language]}
            variant="standard"
            customInput={TextField}
            format="(###) ###-####"
            value={props.phone}
            onValueChange={(value) => props.setPhone(value.value)}
            {...(phoneError === true && {
              error: true,
              helperText: "Please type in a phone number",
            })}
          ></NumberFormat>
          <Button onClick={checkPhone}>{l.check[language]}</Button>
        </Stack>
      </Paper>
    </div>
  );
}
