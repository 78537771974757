import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";

export default function SalaryDetailDialog(props) {
  const { open, setOpen, shifts, center } = props;
  shifts.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
  if (shifts.length === 0) return <div></div>;

 

  return (
    <Dialog open={open}>
      <DialogTitle>Shifts for {shifts[0].user.name}</DialogTitle>
      <DialogContent>
        <DrawTotals
          shifts={shifts}
          center={center}
        />
        <hr />
        {shifts.map((shift) => (
          <DrawShift
            {...shift}
            locations={center.locations}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function DrawShift(props) {
  let { user, location, date, startTime, endTime, hours, locations } = props;
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 150 }}>{format(new Date(date), "dd/MM/yyyy")}</div>
      <div style={{ width: 150 }}>
        {locations.find((l) => l._id === location).name}
      </div>
      <div style={{ width: 150 }}>{startTime}</div>
      <div style={{ width: 150 }}>{endTime}</div>
      <div style={{ width: 150 }}>{hours}</div>
    </div>
  );
}

function DrawTotals(props) {
  let { shifts,center } = props;
  const { locations } = center;
    let subTotals = [];
   for (let location of locations) {
    let shiftsAtLocation =  shifts.filter((s) => s.location === location._id)
   
     if (shiftsAtLocation.length > 0) {
       let sub = {
        name: location.name,
       shifts: shiftsAtLocation.length,
       hours: shiftsAtLocation.reduce((a, b) => a + b.hours, 0)
       }
        subTotals.push(sub);
     }
    
     }

  return (
    <div>
      {subTotals.map((s) => (
        <div style={{display:"flex"}}>
          <div style={{ width: 150 }}>{s.name}</div>
          <div style={{ width: 150 }}></div>
          <div style={{ width: 150 }}>{s.shifts} shifts</div>
          <div style={{ width: 150 }}></div>
          <div style={{ width: 150 }}>{s.hours} hours</div>
        </div>
      ))}
      <div style={{ display: "flex", fontWeight:"bold" }}>
        <div style={{ width: 150 }}>Total:</div>
        <div style={{ width: 150 }}></div>
        <div style={{ width: 150 }}>{shifts.length} shifts</div>
        <div style={{ width: 150 }}></div>
        <div style={{ width: 150 }}>
          {shifts.reduce((a, b) => a + b.hours, 0)} hours
        </div>
      </div>
    </div>
  );
}
