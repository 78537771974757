import React from "react";
import { net } from "../../../../database";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Loading from "../../Loading";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import SLink from "../../components/SLink";

export default function PaymentFrequency(props) {
  const { l, language } = props;
  const [students, setStudents] = React.useState([]);
  const [frequency, setFrequency] = React.useState(1);
  const [nextBillDate, setNextBillDate] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/payment-frequency`).then((res) => setStudents(res));
  }, []);

  const updateFrequency = (e, value) => {
    setFrequency(value);
  };

  const updateNextBillDate = (e, value) => {
    setNextBillDate(value);
  };

  const DrawStudent = (props) => {
    let { student } = props;

    return (
      <tr>
        <td>
         {/*  <Link to={`/student/${student._id}`} className="neat">
            {student.name}
          </Link>{" "} */}
          <SLink id={student._id} showPhone/>
        </td>
        <td>{student.classes.map((c) => c.name).toString()}</td>
        <td>{format(new Date(student.nextBillDate), "dd/MM/yyyy")}</td>
      </tr>
    );
  };

  let d = new Date();

  let nextThreeMonths = [
    new Date(d.getFullYear(), d.getMonth() + 1, 1, 7),
    new Date(d.getFullYear(), d.getMonth() + 2, 1, 7),
    new Date(d.getFullYear(), d.getMonth() + 3, 1, 7),
    new Date(d.getFullYear(), d.getMonth() + 4, 1, 7),
    new Date(d.getFullYear(), d.getMonth() + 5, 1, 7),
    new Date(d.getFullYear(), d.getMonth() + 6, 1, 7),
  ];

  if (!students) return <Loading />;



 const st =
   nextBillDate !== 0
     ? students
         .filter(
           (student) =>
             student.paymentFrequency === frequency &&
             new Date(student.nextBillDate).getTime() === nextBillDate
         )
         .sort((a, b) =>
           a.nextBillDate > b.nextBillDate
             ? 1
             : b.nextBillDate > a.nextBillDate
             ? -1
             : 0
         )
         
     : students
         .filter((student) => student.paymentFrequency === frequency)
         .sort((a, b) =>
           a.nextBillDate > b.nextBillDate
             ? 1
             : b.nextBillDate > a.nextBillDate
             ? -1
             : 0
         )
        


  return (
    <div>
      <div className="dflex-sb">
        <div>
          <div style={{ fontSize: 14, textAlign: "center" }}>
            Payment Frequency
          </div>
          <ToggleButtonGroup
            value={frequency}
            onChange={updateFrequency}
            exclusive
          >
            <ToggleButton value={1}>1 Month</ToggleButton>{" "}
            <ToggleButton value={3}>3 Months</ToggleButton>{" "}
            <ToggleButton value={6}>6 Months</ToggleButton>{" "}
          </ToggleButtonGroup>
        </div>
        <div>
          <div style={{ fontSize: 14, textAlign: "center" }}>
            Next Bill Date
          </div>
          <ToggleButtonGroup
            value={nextBillDate}
            onChange={updateNextBillDate}
            exclusive
          >
            {" "}
            <ToggleButton value={0}>None</ToggleButton>
            {nextThreeMonths.map((month) => (
              <ToggleButton value={month.getTime()}>
                {format(month, "M/yy")}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </div>
      <table style={{ width: "100%", marginTop: 10 }} className="table">
        <tr style={{ textAlign: "left" }}>
          <th>Name</th>
          <th>Class</th>
          <th>Next Bill Date</th>
        </tr>
        {st.length} students
        {st.map(s => <DrawStudent student={s} key={s._id} />)}
      </table>
    </div>
  );
}
