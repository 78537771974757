import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import { net } from "../../../../../database";
import "./tuitionCalculator.css";
import NumberFormat from "react-number-format";
import { StudentsContext } from "../../../../../Context";

export default function TuitionCalculator(props) {
  const [bills, setBills] = React.useState({
    oneMonth: [],
    threeMonths: [],
    sixMonths: [],
  });
  const [startDate, setStartDate] = React.useState(
    new Date().toISOString().substring(0, 10)
  );
  const { findStudent } = useContext(StudentsContext)
  let student = findStudent(props.student._id)
  React.useEffect(() => {
    if (props.open) {
      net
        .get(`/student/${props.student._id}/calculatebills/${startDate}`)
        .then((bills) => {
          console.log(bills)
          setBills(bills);
        });
    }
  }, [props.open, startDate]);

  const sendBills = (bills) => {
    net
      .post(`/student/${props.student._id}/addcalculatedbills`, {
        bills,
      })
      .then((unpaidBills) => {
        props.setStudent({ ...props.student, unpaidBills });
        props.close();
        props.setBills(undefined);
      });
  };

  if (!bills) return <div></div>;
  return (
    <Dialog open={props.open} fullWidth={true}>
      <DialogTitle>Add tuition for {student.name}</DialogTitle>
      <DialogContent>
        <div className="dflex-sb">
          <div>Start Date:</div>
          <TextField
            type="date"
            size="small"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: "flex-start",
          }}
        >
          <Accordion style={{ flexGrow: 1 }} className="tuition-accordian">
            <AccordionSummary
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }}>1 Month</div>
              <div>
                {" "}
                <NumberFormat
                  value={
                    bills.oneMonth
                      ? bills.oneMonth.reduce((a, b) => a + b.amount, 0)
                      : 0
                  }
                  thousandSeparator
                  displayType="text"
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {bills.oneMonth.map((bill, i) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  key={`OneMonth${i}`}
                >
                  <div>{bill.description}</div>
                  <div>
                    <NumberFormat
                      value={bill.amount}
                      thousandSeparator
                      displayType="text"
                    />
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          <Button
            style={{ marginLeft: 10, marginTop: 10 }}
            onClick={() => sendBills(bills.oneMonth)}
          >
            Choose
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: "flex-start",
          }}
        >
          <Accordion style={{ flexGrow: 1 }} className="tuition-accordian">
            <AccordionSummary
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }}>3 Months</div>
              <div>
                {" "}
                <NumberFormat
                  value={bills.threeMonths.reduce((a, b) => a + b.amount, 0)}
                  thousandSeparator
                  displayType="text"
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {bills.threeMonths.map((bill, i) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  key={`ThreeMonths${i}`}
                >
                  <div>{bill.description}</div>
                  <div>
                    <NumberFormat
                      value={bill.amount}
                      thousandSeparator
                      displayType="text"
                    />
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          <Button
            style={{ marginLeft: 10, marginTop: 10 }}
            onClick={() => sendBills(bills.threeMonths)}
          >
            Choose
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Accordion style={{ flexGrow: 1 }} className="tuition-accordian">
            <AccordionSummary
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }}>6 Months</div>
              <div>
                {" "}
                <NumberFormat
                  value={bills.sixMonths.reduce((a, b) => a + b.amount, 0)}
                  thousandSeparator
                  displayType="text"
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {bills.sixMonths.map((bill, i) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  key={`sixMonths${i}`}
                >
                  <div>{bill.description}</div>
                  <div>
                    <NumberFormat
                      value={bill.amount}
                      thousandSeparator
                      displayType="text"
                    />
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          <Button
            style={{ marginLeft: 10, marginTop: 10 }}
            onClick={() => sendBills(bills.sixMonths)}
          >
            Choose
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
