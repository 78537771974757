import "./newLostStudents.css";
import React, { useContext } from "react";
import MonthChoice from "../../components/MonthChoice";
import Changes from "./Changes";
import NewStudents from "./NewStudents";
import LostStudents from "./LostStudents";
import HtTabs from "../../components/HtTabs";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { LanguageContext } from "../../../../Context";
import PendingToActive from "./PendingToActive";
import StoppedToActive from "./StoppedToActive";
import ActiveToPending from "./ActiveToPending";

export default function NewLostStudents(props) {
  let {language } = useContext(LanguageContext)
 
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth());

 
  const cprops = {year,month}


  const labels=[`Today`,`New`,`Stopped`,<>Pending {<ArrowRightIcon />} Active</>,<>Stopped {<ArrowRightIcon />} Active</>,<>Active {<ArrowRightIcon />} Pending</>]
  const components = [<Changes {...cprops} />, <NewStudents {...cprops} />, <LostStudents {...cprops} />, <PendingToActive {...cprops} />, <StoppedToActive {...cprops} />,<ActiveToPending {...cprops} />]


  return (
  
      <HtTabs labels={labels} components={components} nextToTabs={ <MonthChoice
          month={month}
          year={year}
          language={language}
          setMonth={setMonth}
          setYear={setYear}
        /> } localStorage="newLostTabs"/>
 
   
  );
}
