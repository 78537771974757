import React from "react";
import { format } from "date-fns";
import { net } from "../../../../database";
import { Paper, TextField } from "@mui/material";
import Staff from "../../components/Staff";

export default function Notes({ lead, setLeads }) {
  const [open, setOpen] = React.useState(false);
  const focus = React.useRef(null);

  const keyDown = (e) => {
    console.log(e);
    if (e.keyCode === 13) {
      e.preventDefault();
      net
        .post(`/leads/${lead._id}/add-note`, { note: e.target.innerText })
        .then((res) => {
          setLeads((prev) => {
            return prev.map((l) => (l._id === res._id ? res : l));
          });
        });
      e.target.innerText = "";
    }
  };

  return (
    <div className="lead-notes-outline">
      <div className="lead-notes-title">
        Notes ({lead.marketingNotes ? lead.marketingNotes.length : 0})
      </div>
      <div className="leads-notes-container">
        {/* {open ? (
          <TextField
            sx={{ ml: 0, mr: 0, mt: 0.5, border: 0 }}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={(e) => keyDown(e)}
            size="small"
            placeholder="Add new note"
            inputRef={(input) => input && input.focus()}
            InputProps={{
              sx: { fontSize: 12, p: 0, m: 0, backgroundColor: "white" },
            }}
          />
        ) : (
          <a
            onClick={() => {
              setOpen(true);
            }}
            className="leads-open-note"
          >
            add new note...
          </a>
        )}
 */}

        {lead.marketingNotes
          ? lead.marketingNotes
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((note) => (
                <Paper height={2} sx={{ ml: 0, mt: 1, p: 0.7 }} key={note._id}>
                  <div className="lead-notes">{note.note}</div>
                  <div className="small-text" style={{ textAlign: "right" }}>
                    {<Staff id={note.user} />} at{" "}
                    {format(new Date(note.createdAt), "dd/MM/yyyy h:mm aaa")}
                  </div>
                </Paper>
              ))
          : "No notes"}
      </div>
      {open ? (
        <div
          className="enter-note-div"
          contentEditable
          onKeyDown={(e) => {
            keyDown(e);
          }}
          onBlur={() => setOpen(false)}
          ref={(input) => {
            open && input && input.focus();
          }}
        ></div>
      ) : (
        <div
          className="enter-note-div"
          style={{ color: "#ccc" }}
          onClick={() => {
            setOpen(true);
            focus.current?.focus();
          }}
        >
          Add a new note
        </div>
      )}
    </div>
  );
}
