import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";

export default function ContactCustomerDialog(props) {
  const { open, setOpen, user, id } = props;
  const [notes, setNotes] = React.useState("");
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add contact</DialogTitle>
      <DialogContent>
        <TextField
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          label="Notes"
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() => {
            net
              .post(`/sales/cold-data/contact/${id}`, {
                notes,
                user: user._id,
              })
              .then((res) => {
                setOpen(false);
              });
          }}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
