import React, { useContext } from "react";
import { net } from "../../../../database";
import "./attendance.css";
import ListClasses from "./components/ListClasses";
import Loading from "../../Loading";
import AttendanceDialog from "./components/AttendanceDialog";
import { CenterContext } from "../../../../Context";
import { socket } from "../../../../App";
import MakeUpClassDialog from "./components/MakeUpClassDialog";
import HtTabs from "../../components/HtTabs";
import Lang from "../../components/Lang";
import HtDate from "../../components/HtDate";
import { Button } from "@mui/material";


export default function Attendance(props) {
  const {center} = useContext(CenterContext)
  const [todaysClasses, setTodaysClasses] = React.useState(undefined);
  const [openAttendance, setOpenAttendance] = React.useState(false);
  const [currentClassId, setCurrentClassId] = React.useState(undefined);
  const [showDate,setShowDate] = React.useState(new Date())
  const [openMakeUpClassDialog, setOpenMakeUpClassDialog] =
    React.useState(false);

  const updateTodaysClasses = (lesson) => {
    setTodaysClasses(prev => prev.map(p=> p._id===lesson.classId ? {...p, lesson} :  p));
  };

  React.useEffect(() => {
    net.get("/todaysclasses/"+showDate.toISOString().substring(0,10)).then((r) => {
      setTodaysClasses(r.classes);
    });

    socket.on("attendance", (lesson) => {
      console.log("Attendance update",lesson)
      updateTodaysClasses(lesson);
    });
   
    return () => {
      socket.off("attendance");

    };
  }, [openAttendance, openMakeUpClassDialog,showDate]);


  if (!todaysClasses) return <Loading />;
  
  let startTimes = [
    ...new Set(
      todaysClasses.map((c) =>
        c.startTime ? c.startTime : c.classDay.startTime.substring(0, 5)
      )
    ),
  ];
  startTimes.sort();


  const showAttendance = (htclassId) => {
    setCurrentClassId(htclassId);
    setOpenAttendance(true);
  };

  const showMakeUp = (htclassId) => {
    setCurrentClassId(htclassId);
    setOpenMakeUpClassDialog(true);
  };

  const closeAttendance = () => {
    setCurrentClassId(undefined);
    setOpenAttendance(false);
  };

  const updateAttendance = (htclass) => {
    setTodaysClasses({
      classes: todaysClasses.map((c) => (c._id === htclass._id ? htclass : c)),
      teachers: todaysClasses.teachers,
    });
  };

  const updateLesson = (htClass) => {
    setTodaysClasses(
      todaysClasses.map((c) => (c._id === htClass._id ? htClass : c))
    );
  };

  const labels = center.locations
    .filter((l) => todaysClasses.filter((c) => c.location === l._id).length >0)
    .map(
      (l) =>
        l.name +
        ` (${todaysClasses.filter((c) => c.location === l._id).length})`
  );
  
  const components = center.locations.filter(l=>l.name!=="All locations").map(l=>  <ListClasses
              classes={todaysClasses.filter((c) => c.location === l._id)}
              showAttendance={showAttendance}
              currentClassId={currentClassId}
              openAttendance={openAttendance}
              showMakeUp={showMakeUp}
              closeAttendance={closeAttendance}
              setCurrentClassId={setCurrentClassId}
              updateAttendance={updateAttendance}
              setTodaysClasses={setTodaysClasses}
              todaysClasses={todaysClasses}
              updateLesson={updateLesson}
              {...props}
            />)

  return (
    <div>
      <div className="dflex-sb" style={{alignItems:"center"}}>
        <h1><Lang w="attendance" /></h1>
        <HtDate value={showDate} setValue={setShowDate} buttons />
        <div></div>
      </div>
      <HtTabs labels={labels} components={components} localStorage="location" nextToTabs={<Button variant="contained" size="small" sx={{height: 40}} onClick={()=>printClasses(todaysClasses)}><Lang w="print" /></Button>} />
      {openAttendance ? (
        <AttendanceDialog
          open={openAttendance}
          setOpen={setOpenAttendance}
          currentClassId={currentClassId}
          setCurrentClassId={setCurrentClassId}
          classes={todaysClasses}
          updateAttendance={updateAttendance}
          updateLesson={updateLesson}
          showDate={showDate}
      
        />
      ) : (
        ""
      )}
      {openMakeUpClassDialog ? (
        <MakeUpClassDialog
          open={openMakeUpClassDialog}
          setOpen={setOpenMakeUpClassDialog}
          currentClassId={currentClassId}
        />
      ) : (
        ""
      )}
     
    </div>
  );
}

function printClasses(classes) {
  var printWindow = window.open('', 'PRINT');
  let d = document.getElementById("printDiv")
  printWindow.document.write(`<html><head><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"></head><body>`)
  printWindow.document.write(d.innerHTML)
  printWindow.document.write(`</body></html>`)
 
}