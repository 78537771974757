import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext } from "react";
import Title from "./screenReceiptComponents/Title";
import Cashier from "./screenReceiptComponents/Cashier";
import PaidBills from "./screenReceiptComponents/PaidBills";
import Totals from "./screenReceiptComponents/Totals";
import Footer from "./screenReceiptComponents/Footer";
import { copyReceiptToClipboard } from "../../../components/copyToClipboard";
import { UserContext } from "../../../../../Context";

export default function ScreenReceipt(props) {
  const { open, setOpen, receipt } = props;
  const {user} = useContext(UserContext)

  return (
    <Dialog open={open}>
      <DialogContent style={{ fontSize: 14 }} id="receiptDialog">
        <Title />
        <Cashier receipt={receipt} />
        <hr />
        <PaidBills receipt={receipt} />
        <Totals receipt={receipt} />
        <br />
        <Footer />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="info" disabled>
          Print
        </Button>
        <Button color="success" onClick={() => copyReceiptToClipboard(receipt,user)}>
          Copy
        </Button>
      </DialogActions>
    {/*   <div id="secretDiv" style={{ display: "none" }}></div> */}
    </Dialog>
  );
}
