import { Checkbox } from "@mui/material";
import React from "react";


export default function SCheckBox(props) {


const { value, setValue, label } = props;

return (
  <div style={{ display: "flex", alignItems: "center", fontWeight: 200 }}>
    <Checkbox
      checked={value}
      onClick={() => setValue(value ? false : true)}
    />
    <div>{label}</div>
  </div>
);
}