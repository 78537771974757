import React from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { format } from "date-fns";

export default function MakeUpClassDetails(props) {
  //const {l,language} = props;
  let { id } = useParams();
  let [muClass, setMuClass] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/makeup-class/${id}`).then((res) => {
      setMuClass(res);
    });
  }, [id]);

  if (!muClass) return <Loading />;
  console.log(muClass);
  return (
    <div>
      <h3>Students in this class</h3>
      {muClass.students.map((student) => (
        <DrawStudent key={student._id} student={student} />
      ))}
    </div>
  );
}

function DrawStudent(props) {
  let { student } = props;
  let { lesson, htClass } = student;
  return (
    <div className="student-details">
      <Link to={`/student/${student.student._id}`} className="neat">
        {student.student.name}{" "}
      </Link>{" "}
      missed class{" "}
      <Link to={`/class/${htClass._id}`} className="neat">
        {htClass.name}{" "}
      </Link>{" "}
      on {format(new Date(lesson.createdAt), "dd/MM/yyyy")}
      <div>
        <div style={{ fontSize: 11 }}>Lesson plan/sheet link</div>
        <div>
          {lesson.lessonPlanLink ? (
            <a
              href={lesson.lessonPlanLink}
              alt="No link"
              target="_blank"
              rel="noreferrer"
            >
              {lesson.lessonPlanLink}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
