import {
  Button,
  Paper,

  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import NewLeadDialog from "./NewLeadDialog";
import Search from "../../components/Search";



import StageTwo from "./StageTwo";
import "./leads.css";
import StageThree from "./StageThree";

import StageOne from "./StageOne";

/**
 * @typedef {Object} lead
 * @property {string} name how the person is called
 * @property {number} age how many years the person lived
 */

export default function Leads(props) {
 
  const [stage, setStage] = React.useState(1);
  const [leads, setLeads] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [openCD, setOpenCD] = React.useState(false);
  const [openCC, setOpenCC] = React.useState(false);

  React.useEffect(() => {
    setLeads([]);
    if (stage < 4) {
      net.get(`/leads/stage/${stage}`).then((res) => {
        console.log(res)
        setLeads(res);
      });
    } else {
      net.get(`/leads-waiting`).then((res) => {
        setLeads(res);
      });
    }
  }, [stage, open, openCD, openCC]);

  return (
    <div>
      <Paper className="dflex-sb" sx={{ p: 1 }}>
        <ChooseStage stage={stage} setStage={setStage} />
        <Search size="small" search={search} setSearch={setSearch} />
        <Button
          onClick={() => setOpen(true)}
          {...(stage > 2 && { disabled: true })}
        >
          + New
        </Button>
      </Paper>
      <DrawLeads leads={leads} setLeads={setLeads} stage={stage} {...props} />
      <NewLeadDialog open={open} setOpen={setOpen} stage={stage} />
    </div>
  );
}

function ChooseStage({ stage, setStage }) {
  return (
    <ToggleButtonGroup
      value={stage}
      exclusive
      onChange={(e, newValue) => setStage(newValue)}
      size="small"
    >
      <ToggleButton value={1} sx={{ width: 200 }}>
        THÔNG TIN CƠ BẢN
      </ToggleButton>
      <ToggleButton value={2} sx={{ width: 200 }}>
        TƯ VẤN VÀ CHỐT
      </ToggleButton>
      <ToggleButton value={3} sx={{ width: 200 }}>
        CHĂM SÓC 3 BUỔI ĐẦU
      </ToggleButton>
      <ToggleButton value={4} sx={{ width: 200 }}>
        Waiting
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function DrawLeads({ leads, setLeads, stage, staff }) {
  if (!leads) return <Loading />;
  for (let l of leads) {
    l.id = l._id;
  }
  if (stage === 1)
    return <StageOne leads={leads} setLeads={setLeads} staff={staff} />;
  if (stage === 2) {
    return <StageTwo leads={leads} setLeads={setLeads} staff={staff} />;
  }
  if (stage === 3) {
    return <StageThree leads={leads} setLeads={setLeads} staff={staff} />;
  }
  if (stage === 4) {
    return <StageTwo leads={leads} setLeads={setLeads} staff={staff} waiting />;
  }
  return <div>more leads</div>;
}

/* function buildColsStageOne(setSelected, setOpenCD) {
  return [
    { field: "name", headerName: "Họ tên", maxWidth: 200 },
    {
      field: "phone",
      headerName: "Điện thoại",
      width: 150,
      renderCell: (params) => <PhoneNumber value={params.value} />,
    },
    {
      field: "dateOfBirth",
      headerName: "Năm sinh",
      type: "date",
      width: 150,
      renderCell: (params) => <FormatDate value={params.value} />,
    },
    {
      field: "createdAt",
      headerName: "Ngày tiếp nhận",
      type: "date",
      width: 150,
      renderCell: (params) => <FormatDate value={params.value} />,
    },
    {
      field: "source",
      headerName: "Hình thức đăng ký",
      minWidth: 300,
    },

    {
      headerName: "Actions",
      width: 250,
      renderCell: (props) => (
        <div style={{ width: 250 }}>
          <Button
            style={{ aligntItems: "center" }}
            startIcon={<ArrowBackIcon />}
          >
            Cold
          </Button>
          <Button
            color="success"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              setSelected(props.id);
              setOpenCD(true);
            }}
          >
            Consultant
          </Button>
        </div>
      ),
    },
  ];
}
 */
/* function DrawStageOne({ lead, setLeads }) {
  let { _id, name, phone, dateOfBirth, address, source } = lead;

  const updateField = (e, field) => {
    setLeads((prev) => {
      let l = prev.find((p) => p._id === _id);
      l[field] = e;
      return prev.map((p) => (p._id === _id ? l : p));
    });
  };
  let tfprops = { size: "small", sx: { ml: 1, mt: 1 } };
  return (
    <div style={{ display: "flex" }}>
      <TextField
        {...tfprops}
        value={name}
        onChange={(e) => updateField(e.target.value, "name")}
      />
      <NumberFormat
        {...tfprops}
        customInput={TextField}
        format="(###) ###-####"
        value={phone}
        onValueChange={(value) => updateField(value.value, "phone")}
      ></NumberFormat>
      <TextField
        {...tfprops}
        value={dateOfBirth}
        type="date"
        onChange={(e) => updateField(e.target.value, "dateOfBirth")}
      />
      <TextField
        {...tfprops}
        value={address}
        onChange={(e) => updateField(e.target.value, "address")}
      />
      <TextField
        {...tfprops}
        value={source}
        onChange={(e) => updateField(e.target.value, "source")}
      />
    </div>
  );
} */
