import { format } from "date-fns";
import React from "react";
import Chats from "../../../components/chatboxComponents/Chats";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function StudentRecent({ student }) {
  //const {l,language} = props;

  let events = buildEvents(student);
  events = events.sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
  );

  return (
    <div>
      {events.map((e, i) => (
        <DrawEvent event={e} key={"event" + i} />
      ))}
    </div>
  );
}

function DrawEvent({ event }) {
  return (
    <div className="student-recent">
      <div className="student-recent-title">{event.title}</div>
      <div>
        <div>
          {event.who} on{" "}
          {format(new Date(event.createdAt), "eeee dd/MM/yyyy hh:mm aaa")}
        </div>
      </div>
      <div>{event.body}</div>
      <hr />
    </div>
  );
}

function buildEvents(student) {
  // event.title
  // event.who
  // event.createdAt
  // event.body

  let events = [];

  for (let message of student.messages) {
    let e = {
      title: message.type,
      who: message.recordedBy ? message.recordedBy.name : "Unknown",
      createdAt: message.createdAt,
      body: <DrawMessage message={message} />,
    };
    events.push(e);
  }

  /* for (let attendance of student.attendance) {
    if (attendance.salesFeedback && attendance.salesFeedback !== "") {
      let e = {
        title: "Customer feedback",
        who: attendance.salesPerson ? attendance.salesPerson.name : "Unknown",
        createdAt: attendance.createdAt,
        body: <DrawSalesFeedback attendance={attendance} />,
      };
      events.push(e);
    }
  } */

  return events;
}

function DrawSalesFeedback({ attendance }) {
  let { salesFeedback, homeWorkNotes, notes } = attendance;
  return (
    <div className="dflex-sb">
      <div style={{ width: "50%" }}>
        <div>Homework: {homeWorkNotes}</div>
        <div>Notes: {notes}</div>
      </div>
      <div style={{ width: "50%" }}>Feedback: {salesFeedback}</div>
    </div>
  );
}

function DrawMessage({ message }) {
  return (
    <div className="dflex-sb">
      <div>
        {" "}
        <div>Status: {message.status === 0 ? "Open" : "Closed"}</div>
        <div>Description: {message.description}</div>
      </div>
      <Accordion style={{ width: 300 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {message.chats && message.chats.length > 0 ? (
            <DrawChat chat={message.chats[message.chats.length - 1]} />
          ) : (
            "No chats"
          )}
        </AccordionSummary>
        <AccordionDetails>
          {message.chats.map((chat) => (
            <DrawChat chat={chat} accordion key={chat._id} />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function DrawChat({ chat, accordion }) {
  return (
    <div className="student-chat-box">
      <div className="student-chat-title">
        <div>{chat.user ? chat.user.name : ""}</div>
      </div>
      <div className="student-chat-message">{chat.message}</div>
      <div className="student-chat-date">
        {format(new Date(chat.createdAt), "eeee dd/MM/yyyy hh:mm aaa")}
      </div>
      {accordion ? <hr /> : ""}
    </div>
  );
}
