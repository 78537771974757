import React from "react";
import DrawStudent from "./DrawStudent";
import Loading from "../../Loading";
import Month from "../../components/Month";
import Title from "./Title";
import useDB from "../../hooks/useDB";

export default function StoppedToActive(props) {
  const {year,month} = props
    const [students, setStudents,error, loading] = useDB(
      `/stopped-to-active/${year}/${month}`
    );

    if (loading) return <Loading />

return(<div><h3>Students who changed from stopped to active in <Month value={month+1} /> ({students.length})</h3><Title />{students.map(s=> <DrawStudent student={s} />)}</div>)

}