import React, { useContext } from "react";
import { CenterContext } from "../../../../Context";

/**
 * 
 * @param {array} id array of stopped reasons ids 
 * @returns 
 */
export default function StoppedReason({id}) {
  const { center } = useContext(CenterContext);
  if (!Array.isArray(id) || id.length < 1) return <></>;
  console.log(id)
let reasons = []
    for (const i of id) {
        let r = center.stoppedReasons.find(sr=>sr._id && sr._id===i)
    if(r) reasons.push(r.name)
}   
  return <div>{reasons.toString()}</div>;
}