import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
export default function ClassButtons(props) {
  const { l, language, classes } = props;
  let navigate = useNavigate();

  if (classes.length < 1) return <div>No classes today</div>;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ marginBottom: 10 }}>Today's Attendance</div>
      {classes.map((htClass) => (
        <Button
          variant="contained"
          size="large"
          style={{ width: "80vw", height: "8vh", marginBottom: 20 }}
          onClick={() => navigate(`/attendance/${htClass._id}`)}
        >
          {htClass.name}
        </Button>
      ))}
    </div>
  );
}
