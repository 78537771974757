import React, { useContext, useState } from "react";
import { CenterContext } from "../../../../../Context";
import AddButton from "../../../components/AddButton";
import StoppedReasonDialog from "./StoppedReasonDialog";


export default function StoppedReasons(props) {
    let { center } = useContext(CenterContext)
    const [reasons,setReasons] = useState(center.stoppedReasons || [])
    const [open, setOpen] = useState(false)
  

    return (<div>{reasons.map(r => <Reason key={r._id} reason={r} />)}<AddButton action={() => setOpen(true)} /><StoppedReasonDialog open={open} setOpen={setOpen} setReasons={setReasons} /></div>)
}


function Reason(props) {
    const {reason} = props
    return <div>{reason.name}</div>
}