import React, { useState } from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Link, useParams } from "react-router-dom";
import Bcs from "./Bcs";
import AddButton from "../../components/AddButton";
import DDialog from "../../components/DDialog";
import { Stack } from "@mui/material";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";


export default function Syllabus(props) {
    const { id } = useParams();
    const [curriculum, setCurriculum, error, loading] = useDB(`/units/${id}`);
    const [open,setOpen] = useState(false)
   
    if (loading) return <Loading />
    if (error) return <Error />
    
    const fields = [
        { name: "name", label: "Name"},
        { name: "unitNumber", type: "number", value: curriculum.units.length + 1 },
        { name: "curriculum", value: curriculum._id, type: "hidden" }
    ]

    return (
      <div>
        <Bcs syllabus={curriculum} />

        <DDialog
          fields={fields}
          open={open}
          setOpen={setOpen}
          route="/unit"
          title="Add new unit"
          required={["name"]}
          ok="Add unit"
          newData={(newUnit) =>
            setCurriculum((prev) => {
              let units = [...prev.units, newUnit];
              return { ...prev, units };
            })
          }
        />
        <div className="cur-box">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}>
            <h2>{curriculum.name}</h2>
            <AddButton action={() => setOpen(true)} tooltip="Add new unit" />
          </div>
          <table className="cur-table">
            <tr>
              <th>Unit</th>
              <th>Name</th>
            </tr>
            {curriculum.units.map((u) => (
              <tr>
                <td>{u.unitNumber}</td>
                <td>
                  <Link
                    to={`/curriculum/${curriculum._id}/unit/${u._id}`}
                    className="cur-link"
                  >
                    {u.name}
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    );
}
