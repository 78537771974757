import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { scoreColoursBold } from "../../../../enums";
import html2canvas from "html2canvas";

import { net } from "../../../../database";
import Rating from "../../components/Rating";
const { ClipboardItem } = window;
export default function FeedbackDialog(props) {
  const {
    handleClose,
    open,
    attendanceStudent,
    setOpen,
    setAttendanceStudent,
    center,
    updateAttendance,
    setAttendance,
    attendance,
  } = props;
  let { feedbackCategories } = center;

  const changeRating = (e, attendanceIn, field) => {
    if (!attendanceIn.feedback) attendanceIn.feedback = {};
    attendanceIn.feedback[field] = e;
    console.log(attendanceIn.feedback);
    net
      .post(`/attendance/${attendanceIn._id}`, {
        feedback: attendanceIn.feedback,
      })
      .then((returnedAttendance) => {
        setAttendance(
          attendance.map((a) =>
            a._id === returnedAttendance._id ? returnedAttendance : a
          )
        );
      });
    setAttendance(
      attendance.map((a) =>
        a._id === attendanceIn._id ? attendanceIn : a
      )
    );
  };

  const copyToClipboard = () => {
    let dialog = document.getElementById("feedbackDialog");
    let notes = document.getElementById("feedbackNotes");
    let hwnotes = document.getElementById("homeWorkNotes");
    /* let text = notes.value; */
    console.log(hwnotes.value);
    let newDiv = document.createElement("div");
    newDiv.innerText = notes.value;
    notes.parentNode.replaceChild(newDiv, notes);

    let newDiv2 = document.createElement("div");
    newDiv2.innerText = hwnotes.value;
    hwnotes.parentNode.replaceChild(newDiv2, hwnotes);

    html2canvas(dialog).then((canvas) => {
      document.getElementById("secretDiv").appendChild(canvas);
      canvas.toBlob(function (blob) {
        navigator.clipboard.write([
          new ClipboardItem(
            Object.defineProperty({}, blob.type, {
              value: blob,
              enumerable: true,
            })
          ),
        ]);
      });
    });
    newDiv.parentNode.replaceChild(notes, newDiv);
    newDiv2.parentNode.replaceChild(hwnotes, newDiv2);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
    >
      {attendanceStudent ? (
        <div id="feedbackDialog">
          <DialogTitle>
            Feedback for {attendanceStudent.student.name}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {feedbackCategories.map((fbc) => (
                <div style={{ fontSize: 11 }}>
                  {fbc}
                  <div>
                    <Rating
                      value={
                        attendanceStudent.feedback &&
                        attendanceStudent.feedback[fbc]
                          ? parseInt(attendanceStudent.feedback[fbc])
                          : 0
                      }
                      setValue={(e) => changeRating(e, attendanceStudent, fbc)}
                    />
                  </div>
                </div>
              ))}
              <TextField
                multiline
                id="homeWorkNotes"
                label="Homework feedback"
                rows={6}
                size="small"
                sx={{ fontSize: 12 }}
                fullWidth
                onBlur={(e) =>
                  updateAttendance(e, attendanceStudent, "homeWorkNotes")
                }
                value={attendanceStudent.homeWorkNotes}
                onChange={(e) => {
                  setAttendanceStudent({
                    ...attendanceStudent,
                    homeWorkNotes: e.target.value,
                  });
                }}
              />
              <TextField
                multiline
                id="feedbackNotes"
                label="Notes"
                rows={8}
                size="small"
                sx={{ fontSize: 12 }}
                fullWidth
                onBlur={(e) => updateAttendance(e, attendanceStudent, "notes")}
                value={attendanceStudent.notes}
                onChange={(e) => {
                  setAttendanceStudent({
                    ...attendanceStudent,
                    notes: e.target.value,
                  });
                }}
              />
            </Stack>
          </DialogContent>
        </div>
      ) : (
        ""
      )}
      <DialogActions>
        <Button
          color="success"
          onClick={copyToClipboard}
        >
          Copy
        </Button>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
