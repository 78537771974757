import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumericFormat from "react-number-format";
import { debounce } from "../hooks/debounce";
//import "./VND.css"

/**
 * 
 * @param {number} value the value for the component
 * @param {function} setValue Converts component to input and places result in this function
 * @param {string} suffix The suffix to add to the number, default = đ
 * @param {string} url optional The url to post any changes to
 * @param {string} field optional The field to update
 * @param {number} delay optional The delay in seconds for the debounce 
 * @returns 
 */

export default function VND(props) {
  const { url, field, value, setValue, style, suffix = "đ", delay=1000 } = props;
  const [v, setV] = useState(value);
  const [first, setFirst] = useState(false); // don't fire debounce on first render
  
  useEffect(() => {
    let db = debounce(url, field, v, first, delay);
    setFirst(true);
    return db
  }, [v]);

  if (!setValue || props.readOnly)
    return (
      <NumericFormat
        {...props}
        displayType={"text"}
        thousandSeparator
        suffix={suffix}
        decimalScale={0}
      />
    );

  if (props.noFormat)
    return (
      <NumericFormat
        {...props}
        onValueChange={(value) => setValue(value.value)}
        displayType={"input"}
        thousandSeparator
        suffix={suffix}
        decimalScale={0}
        style={props.style ? props.style : {}}
      />
    );

  return (
    <NumericFormat
      {...props}
      onValueChange={(value) => {
        setValue(parseInt(value.value));
        setV(parseInt(value.value));
      }}
      displayType={"input"}
      thousandSeparator
      suffix={suffix}
      decimalScale={0}
      customInput={TextField}
      inputProps={{
        style: { textAlign: "right" },
        className:"checked",
      }}
      style={style ? style : {}}
    />
  );
}
