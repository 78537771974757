import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  LinearProgress,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { net } from "../../../../../database";
import AttendanceButtons2 from "./AttendanceButtons2";
import Loading from "../../../Loading";
import { useContext } from "react";
import { CenterContext, StudentsContext } from "../../../../../Context";

import StaffChooserContext from "../../../components/StaffChooserContext";
import HtClass from "../../../components/HtClass";
import MultiLine from "../../../components/MultiLine";
import useDB from "../../../hooks/useDB";
import Error from "../../../components/Error";
import mut from "../../../hooks/mut";


export default function AttendanceDialog(props) {
  let { currentClassId, setCurrentClassId, setOpen, showDate } = props;
  let d = new Date(showDate);

  const [htClass, setHtClass, loading, error] = useDB(
    `/class-attendance/${currentClassId}/${d.getFullYear()}/${d.getMonth()}/${d.getDate()}`
  );
  const { center } = useContext(CenterContext);
  const { locations } = center;

  if (!currentClassId) return <div />;
  if (loading) return <Loading />;
  if (error) return <Error />;
  console.log(htClass);
  const url = `/lesson/${htClass.lesson}`;

  /* const changeTeacher = (teacher) => {
    net.post(url, { teacher }).then((lesson) => {
      setHtClass({ ...htClass, teacher: lesson.teacher });
    });
     setHtClass({ ...htClass, teacher });
  };

  const changeRoom = (room) => {
    net.post(url, { room }).then((lesson) => {
      setHtClass({ ...htClass, room: lesson.room });
    });
     setHtClass({ ...htClass, room });
  }; */

  const closeDialog = () => {
    setCurrentClassId(undefined);
    setOpen(false);
  };
  if (!htClass) return <Loading />;

  let progress = calculateProgress(htClass);

  return (
    <div>
      <Dialog
        onClose={props.onClose}
        open={props.open}
        className="dialog-box"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ paddingBottom: 2, paddingRight: 3 }}>
          <HtClass htClass={htClass._id} link/>, {htClass.students.length} student
          {htClass.students.length === 1 ? "" : "s"}
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{ pt: 1 }}
            style={{
              marginTop: 0,
              marginBottom: 10,
              display: "flex",
            }}
          >
            <StaffChooserContext
              value={htClass.teacher}
              setValue={(teacher) => {
                mut("post", url, { teacher })
                setHtClass(prev=> ({...prev, teacher}))
              }}
              filter="Teacher"
              fullWidth
              size="small"
            />
            <div style={{ width: 20 }}></div>
            <SelectRoom
              htClass={htClass}
              locations={locations}
              changeRoom={(room) => {
                mut("post", url, { room })
                setHtClass(prev=> ({...prev, room}))
              }}
            />
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            {...(progress === 100 && { color: "success" })}
            sx={{ marginBottom: 2, marginTop: 0 }}
          />
          <MultiLine
            value={htClass.adminNotes}
            setValue={(adminNotes) =>
              setHtClass((prev) => ({ ...prev, adminNotes }))
            }
            fontSize={12}
            rows={2}
            url={url}
            field="adminNotes"
            label="Notes"
          />
          <DrawStudents
            htClass={htClass}
            setHtClass={setHtClass}
            showDate={showDate}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const calculateProgress = (htClass) => {
/*   if (htClass && htClass.lesson) {
    return (
      ((htClass.lesson.attended.length +
        htClass.lesson.absent.length +
        htClass.lesson.noShow.length) /
        htClass.students.length) *
      100
    );
  } */
  return 0;
};



const SelectRoom = (props) => {
  let { htClass, locations, changeRoom } = props;
  return (
    <TextField
      label="Room"
      size="small"
      value={htClass.room ||  ""}
      select
      fullWidth
      onChange={(e) => changeRoom(e.target.value)}
    >
      {locations
        .find((l) => l._id === htClass.location)
        .rooms.map((r) => (
          <MenuItem value={r} key={`roomid${r}`}>
            {r}
          </MenuItem>
        ))}
    </TextField>
  );
};

const DrawStudents = (props) => {
  let { htClass, setHtClass, showDate } = props;
  const {findStudent} = useContext(StudentsContext)
  return (
    <div>
      {htClass.students.length === 0 ? (
        <div>No Students</div>
      ) : (
          htClass.students.filter(s => {
            let student = findStudent(s._id)
            if (!student) return false
            if(student.doesntStudy.includes(htClass.classDay)) return false
            return true
        }).map((s) => (
          <AttendanceButtons2
            student={s}
            htClass={htClass}
            setHtClass={setHtClass}
            key={s._id}
             showDate={showDate}
          />
        ))
      )}
    </div>
  );
};
