import React from "react";
import { TextField, Stack, MenuItem, Button } from "@mui/material";
import { net } from "../../../../database";

import StaffChooser from "../../components/StaffChooser";
import FormatDate from "../../components/FormatDate";
import Time from "../../components/Time";
import Info from "../../components/Info";
import Staff from "../../components/Staff";
import { format } from "date-fns";

export default function Test({
  test,
  lead,
  setLeads,
  i,
  chooseTestTime,
  testDate,startTime,testId
}) {


  const saveUpdates = (data) => {
    console.log(data);
    net.post(`/placement-test-student2/${lead._id}/${test._id}`, data);
  };
 


    const studentTest = test.students.find(t => t.student === lead._id)
    const [notes, setNotes] = React.useState(studentTest.notes || "");
    return (
      <div style={{ width: 400, minWidth: 400 }}>
        {" "}
        <Stack
          spacing={2}
          sx={{ p: 0 }}
        >
          <TextField
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            size="small"
            multiline
            rows={2}
            sx={{ width: 400 }}
            onBlur={(e) => saveUpdates({ notes: e.target.value })}
            label="Bài test đầu vào dự kiến"
            InputProps={{
              sx: { fontSize: 12 },
            }}
          />
          <TestStatus
            lead={lead}
            test={test}
            i={i}
            chooseTestTime={chooseTestTime}
            studentTest={studentTest}
          />
        </Stack>
      </div>
    );
  }
  


function TestStatus(props) {
  let { test, lead, i, studentTest,chooseTestTime } = props;
 

  if (studentTest.status==="Waiting")
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <Info
              title="Scheduled for"
              body={<FormatDate value={test.date} />}
            />
            <div style={{ width: 30 }}></div>
            <Info
              title="at"
              body={<Time time={test.startTime} />}
            />
          </div>
        {/*   <Button
            onClick={() => chooseTestTime(lead, test)}
            className="lead-choose-test"
            style={{ width: 180 }}
          >
            Reschedule
          </Button> */}
        </div>
      </div>
    );
  return (
    <div style={{ fontSize: 14 }}>
      <div>
        Test completed on{" "}
        {studentTest.updatedAt
          ? format(new Date(test.updatedAt), "dd/MM/yyyy h:mm aaa")
          : "Unknown"}
      </div>
      <hr />
      <div>
        <b>Tested by:</b> <Staff id={test.teacher} />
      </div>
      <div>
        <b>Speaking score:</b> {studentTest.speaking}
      </div>
      <div>
        <b>Writing score:</b> {studentTest.writing}
      </div>
      <div>
        <b>Conclusion:</b> {studentTest.conclusion}
      </div>
    </div>
  );
}
