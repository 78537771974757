import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";
import NumberFormat from "react-number-format";
import Loading from "../../Loading";

export default function EditLeadDialog(props) {
  const { open, setOpen, selected, setLeads } = props;
  const [lead, setLead] = React.useState(undefined);
  const [options, setOptions] = React.useState([]);
  const [ms, setMs] = React.useState("");
  const [errors, setErrors] = React.useState({});
  let fields = [
    { name: "name", label: "Họ tên Học Sinh", type: "text" },
    { name: "phone", label: "Điện thoại", type: "text" },
    { name: "dateOfBirth", label: "Năm sinh", type: "date" },
    { name: "address", label: "Địa chỉ", type: "text" },
    { name: "parentsName", label: "Họ tên Phụ Huynh", type: "text" },
    { name: "marketingNote", label: "Notes", type: "text" },
  ];
  React.useEffect(() => {
    console.log(selected);
    net.get(`/leads/${selected._id}`).then((res) => setLead(res));
  }, [selected]);

  React.useEffect(() => {
    net.get("/center/marketing-sources").then((res) => {
      setOptions(res);
    });
  }, []);
  if (!lead) return <Loading />;

  const addMarketingSource = (marketingSource) => {
    if (!options.includes(marketingSource)) {
      net.post("/center/marketing-sources", { marketingSource }).then((res) => {
        setOptions((prev) => {
          prev.push(res.source);
          return prev;
        });
      });
      setMs(marketingSource);
    }
  };

  const addLead = () => {
    setErrors({});
    let ers = false;
    if (!lead.name || lead.name === "") {
      setErrors((prev) => {
        return { ...prev, name: true };
      });
      ers = true;
    }
    if (!lead.phone || lead.phone === "") {
      setErrors((prev) => {
        return { ...prev, phone: true };
      });
      ers = true;
    }
    if (ers === true) return;
    net
      .post(`/leads/${lead._id}`, {
        ...lead,
        source: ms,
      })
      .then((res) => {
        setOpen(false);
        setLeads((prev) => {
          return prev.map((l) =>
            l._id === lead._id ? { ...lead, ...res } : l
          );
        });
        setMs("");
      });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Khách hàng mới</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          {fields.map((field) =>
            field.name === "phone" ? (
              <NumberFormat
                label={field.label}
                customInput={TextField}
                format="(###) ###-####"
                value={lead.phone}
                onValueChange={(value) =>
                  setLead({ ...lead, phone: value.value })
                }
                {...(errors[field.name] && { error: true })}
              ></NumberFormat>
            ) : (
              <TextField
                fullWidth
                label={field.label}
                value={lead[field.name]}
                type={field.type}
                onChange={(e) =>
                  setLead((prev) => {
                    return { ...prev, [field.name]: e.target.value };
                  })
                }
                {...(field.type === "date" && {
                  InputLabelProps: { shrink: true },
                })}
                {...(errors[field.name] && { error: true })}
              />
            )
          )}
          <Autocomplete
            options={options}
            renderInput={(params) => (
              <TextField {...params} label="Hình thức đăng ký" />
            )}
            onBlur={(e) => addMarketingSource(e.target.value)}
            value={ms}
            onChange={(e, newValue) => setMs(newValue)}
          />
          {/*  <TextField
            fullWidth
            label={"Ghi chú"}
            value={lead.marketingNote}
            type={"text"}
            onChange={(e) =>
              setLead((prev) => {
                prev.marketingNote = e.target.value;

                return prev;
              })
            }
          /> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" tabIndex={100} onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={() => addLead()}>
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
