import React from "react";
import FormatDate from "../../components/FormatDate";
import Status from "../../components/Status";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SLink from "../../components/SLink";


export default function DrawStudent(props) {
let { student } = props;
let d = new Date();
let status =
  props.student.statusHistory[props.student.statusHistory.length - 1] || ""
let oldStatus =  props.student.statusHistory[props.student.statusHistory.length - 2] || ""

return (
  <div style={{ display: "flex", marginBottom:10 }} >
    <div style={{ width: 300,minWidth:300 }}>
      <SLink
        id={student._id}
        showClasses
      />
    </div>
    <div style={{ width: 120,minWidth:120 }}><FormatDate value={student.statusChanged} /></div>
    <div style={{ width: 200,minWidth:200 }}><StatusChange oldStatus={oldStatus} status={status} /></div>
    <div style={{ flexGrow: 1,fontSize:14 }}>
      {status &&
      ["Pending", "Stopped", "Lead"].includes(status.status) &&
      status.reason
        ? status.reason
        : null}
    </div>
    
      <div style={{ width: 150,minWidth:150 }}>
      {status && status.returnDate ? <FormatDate value={new Date(status.returnDate)} /> : null}
      </div>
  
  </div>
);
}

function StatusChange({ oldStatus, status }) {
  
  return <div style={{display:"flex"}}>{oldStatus ? <div style={{display:"flex"}}><Status status={oldStatus.status} /><ArrowRightIcon style={{color:"grey"}} /></div> : null}<Status status={status.status} /></div>
}