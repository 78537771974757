import {Button,Dialog,DialogActions,DialogContent,DialogTitle,Paper} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import Staff from "../Staff";


export default function PopoutDialog(props) {
  const { open, setOpen, style, notes, keyDown } = props;
  const focus = React.useRef(null);
  return (
    <Dialog open={open} fullWidth>
          <DialogTitle>Notes ({notes.length})</DialogTitle>
      <DialogContent>
        <div className="lead-notes-outline" style={style}>
         
          <div className="leads-notes-container">
            {notes
              ? notes.map((note) => (
                  <Paper height={2} sx={{ ml: 0, mt: 1, p: 0.7 }}>
                    <div className="lead-notes">{note.note}</div>
                    <div className="small-text" style={{ textAlign: "right" }}>
                      {<Staff id={note.user} />} at{" "}
                      {format(new Date(note.createdAt), "dd/MM/yyyy h:mm aaa")}
                    </div>
                  </Paper>
                ))
              : "No notes"}
          </div>
          {open ? (
            <div
              className="enter-note-div"
              contentEditable
              onKeyDown={(e) => {
                keyDown(e);
              }}
              onBlur={() => setOpen(false)}
              ref={(input) => {
                open && input && input.focus();
              }}
            ></div>
          ) : (
            <div
              className="enter-note-div"
              style={{ color: "#ccc" }}
              onClick={() => {
                setOpen(true);
                focus.current?.focus();
              }}
            >
              Add a new note
            </div>
          )}
      
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success">Go</Button>
      </DialogActions>
    </Dialog>
  );
}