import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { net } from "../../../../../database";
import Loading from "../../../Loading";

export default function CustomerCarePerson(props) {
  const [sales, setSales] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/salesstaff").then((sales) => {
      setSales(sales);
    });
  }, []);
  if (!sales) return <Loading />;
  return (
    <div>
      {" "}
      <TextField
        value={props.customer.carePerson ? props.customer.carePerson : 0}
        select
        label="Cared by"
        sx={{ width: 200, textAlign: "left" }}
        onChange={(e) => {
          net
            .post(`/customers/${props.customer._id}`, {
              carePerson: e.target.value,
            })
            .then((r) => {
              if (!r.err)
                props.setCustomer({
                  ...props.customer,
                  carePerson: e.target.value,
                });
            });
        }}
        {...(props.customer.carePerson === 0 && { error: true })}
        {...(props.customer.carePerson === undefined && { error: true })}
      >
        <MenuItem key="noOneCares" value={0}>
          No one
        </MenuItem>
        {sales
          ? sales.map((sales) => (
              <MenuItem key={sales._id} value={sales._id}>
                {sales.name}
              </MenuItem>
            ))
          : []}
      </TextField>
    </div>
  );
}
