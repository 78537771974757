import React from "react";
import SelectFromList from "../../components/SelectFromList";
import VND from "../../components/VND";
import AddButton from "../../components/AddButton";
import Text from "../../components/Text";
import DeleteButton from "../../components/DeleteButton";
import mut from "../../hooks/mut";

export default function Salaries({member,setMyStaff,myStaff}) {

 

return (
  <div >
    <div style={{ display: "flex", gap: 5, marginBottom:10 }}>
      <SelectFromList
        value={member.salaryType}
        choices={["Monthly", "Hourly", "Multiple"]}
        label="Salary Type"
        setValue={(salaryType) => {
          member.salaryType = salaryType;
          setMyStaff([...myStaff]);
        }}
        size="small"
        style={{ width: 150 }}
        url={`/user/${member._id}`}
        field="salaryType"
      />

      {member.salaryType !== "Multiple" && (
        <VND
          value={member.salary}
          setValue={(salary) => {
            member.salary = salary;
            setMyStaff([...myStaff]);
          }}
          label="Salary"
          size="small"
          url={`/user/${member._id}`}
          field="salary"
        />
      )}
    </div>
    {member.salaryType === "Multiple" && (
      <MultiSalary
        member={member}
        myStaff={myStaff}
        setMyStaff={setMyStaff}
      />
    )}
  </div>
);
}

function MultiSalary({member,myStaff,setMyStaff}) {
  
  return <div>{member.roleSalary.map(r => <div>
    <div style={{display:"flex", gap:5, marginBottom:10}}><SelectFromList value={r.salaryType} choices={["Monthly", "Hourly"]} label="Salary Type" setValue={(salaryType) => {
    
            r.salaryType = salaryType
            setMyStaff([...myStaff])
}}
      size="small"
      style={{ width: 150 }}
      url={`/user/${member._id}/role-salary/${r._id}`}
      field="salaryType"
    />
      <Text value={r.name} setValue={(name) => {
       r.name = name
      setMyStaff([...myStaff])
      }}
        label="Description"
        size="small"
        url={`/user/${member._id}/role-salary/${r._id}`}
        field="name"/>
      <VND
        value={r.salary }
        setValue={(salary) => {
          r.salary = salary
          setMyStaff([...myStaff])
        }}
      label = "Salary"
      size="small"
      url={`/user/${member._id}/role-salary/${r._id}`}
      field="salary"
      />
      
      <DeleteButton route={`/user/${member._id}/role-salary/${r._id}`} success={user => {
        member.roleSalary = user.roleSalary
      setMyStaff([...myStaff])
      }}
      tooltip="Delete this salary"
      />
    </div>

  
  </div>)}
    <AddButton action={async () => {
      let user= await mut("post",`/user/${member._id}/new-role-salary`)
      member.roleSalary = user.roleSalary
      setMyStaff([...myStaff])
    }}
    tooltip="Add a new salary"
    /></div>
}