import React from "react";
import {net} from "../../../../database"


export default function NeedMakeUp(props) {
//const {l,language} = props;
const [students,setStudents] = React.useState(undefined)   
    React.useEffect(() => {
        net.get("/need-makeup-class").then(res=>{
        setStudents(prev=>res)
    })
},[]);

return(<div>NeedMakeUp</div>)
}