import React, { useContext, useState } from "react";
import SLink from "../../components/SLink";
import HtTabs from "../../components/HtTabs";
import { CenterContext, StudentsContext } from "../../../../Context";
import ScoreBox from "../../components/ScoreBox";
import AttendanceBox from "../../components/AttendanceBox";
import MonthChoice from "../../components/MonthChoice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import DateOfBirth from "../../components/DateOfBirth";
import { Checkbox } from "@mui/material";


export default function StudentData(props) {
    const {center} = useContext(CenterContext)
    const labels = ["Attendance", ...center.feedbackCategories]
    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())
       const components = labels.map((l) => (
         <DrawStudents
           {...props}
           stat={l}
           month={month}
           year={year}
           attendance={props.attendance.filter(a=> new Date(a.createdAt).getFullYear() === year &&
           new Date(a.createdAt).getMonth() === month)}
         />
       ));
return (
  <div>
        <HtTabs labels={labels} components={components} nextToTabs={<MonthChoice month={month} setMonth={setMonth} year={year} setYear={setYear} />} />
  </div>
);
}

function DrawStudents(props){


  const { students, pendingStudents, attendance } = props
  const [showAll,setShowAll] = useState(false)
    let sts = []
    if (props.month === new Date().getMonth() && props.year === new Date().getFullYear()) {
        sts = [...new Set([...students, ...attendance.map((a) => a.student)])];
    } else {
         sts = [...new Set(attendance.map((a) => a.student))];
    }
    const {findStudent} = useContext(StudentsContext)
    sts = sts
      .filter((s) => (showAll ? true : ["New","Active"].includes(findStudent(s).status)))
      .sort((a, b) =>
        findStudent(a).name > findStudent(b).name
          ? 1
          : findStudent(b).name > findStudent(a).name
          ? -1
          : 0
      );
 
   
const dates = [...new Set(attendance.map(a => format(new Date(a.createdAt),"yyyy-MM-dd")))]
return (
  <div>
    <div>Show stopped and lead students <Checkbox checked={showAll} onClick={()=>setShowAll(!showAll)} /></div>
    <div style={{ display: "flex" }}>
      <div style={{ width: 450 }}></div>{" "}
      <DrawDates
        dates={dates}
        attendance={attendance}
      />
    </div>
    {sts.map((student) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <SLink
          id={student}
          showPhone
          status
          style={{ width: 300 }}
        />
        <div style={{ width: 150, minWidth: 150 }}>
          <DateOfBirth
            dateOfBirth={findStudent(student).dateOfBirth}
            noDate
          />
        </div>
        <div>
          <DrawStats
            {...props}
            student={student}
            dates={dates}
          />
        </div>
      </div>
    ))}
    {pendingStudents.length > 0 ? (
      <div>
        <h3>Current students pending</h3>
        {pendingStudents.map((student) => (
          <SLink
            id={student}
            showPhone
          />
        ))}
      </div>
    ) : (
      ""
    )}
  </div>
);
}

function DrawStats({ attendance, stat, student, dates }) {
  
    const navigate = useNavigate()

    if (stat === "Attendance") return (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        {dates.map((d) => (
          <div
            style={{
              width: 50,
              paddingLeft: 10,
              paddingRight: 10,
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(
                `/feedback/${
                  attendance.find(
                    (a) =>
                      new Date(a.createdAt).toISOString().substring(0, 10) === d
                  ).lesson
                }`
              )
            }
          >
            <AttendanceBox
              value={
                attendance.find(
                  (a) =>
                    a.student === student &&
                    new Date(a.createdAt).toISOString().substring(0, 10) === d
                )
                  ? attendance.find(
                      (a) =>
                        a.student === student &&
                        new Date(a.createdAt).toISOString().substring(0, 10) ===
                          d
                    ).attendance
                  : 4
              }
            />
          </div>
        ))}
      </div>
    );


return (
  <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
    {dates.map((d) => (
      <div
        style={{ width: 50, paddingLeft: 10, paddingRight: 10, cursor:"pointer" }}
        onClick={() =>
          navigate(
            `/feedback/${
              attendance.find(
                (a) =>
                  new Date(a.createdAt).toISOString().substring(0, 10) === d
              ).lesson
            }`
          )
        }
      >
        <ScoreBox
          value={
            attendance.find(
              (a) =>
                a.student === student &&
                new Date(a.createdAt).toISOString().substring(0, 10) === d
            ) &&
            attendance.find(
              (a) =>
                a.student === student &&
                new Date(a.createdAt).toISOString().substring(0, 10) === d
            ).feedback
              ? attendance.find(
                  (a) =>
                    a.student === student &&
                    new Date(a.createdAt).toISOString().substring(0, 10) === d
                ).feedback[stat]
              : 0
          }
        />
      </div>
    ))}
  </div>
);
}

function DrawDates({dates,attendance}) {
 
    return (
      <div style={{ display: "flex", gap: 5 }}>
        {dates.map((a) => (
          <div key={a} style={{ width: 50, textAlign: "center", fontSize: 12 }}>
            {format(new Date(a), "d/MM")}
          </div>
        ))}
      </div>
    );
}