import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import Error from "../../components/Error";
import { useContext } from "react";
import { UserContext } from "../../../../Context";
//import SLink from "../../components/SLink";
import { TextField } from "@mui/material";
import HtClass from "../../components/HtClass";
import NumStudents from "../../components/NumStudents";
import StudentAvatar from "../../components/StudentAvatar";


export default function TestRecord(props) {
    const {id} = useParams()
    const {user} = useContext(UserContext)
    const [testStudents, setTestStudents, error, loading] = useDB(`/students-to-test/${id}`)
    if(loading) return <Loading />
    if (error) return <Error />
    const {test,students} = testStudents

    const extra = test.extrateachers ? test.extraTeachers.find(ext=>ext.teacher===user._id) : undefined

    console.log(test)

    return (
      <div>
        <div style={{ fontSize: 24, fontWeight: 300, display: "flex", justifyContent:"space-between" }}>
          <HtClass htClass={test.htClass} />{" "}
          <div>
            <NumStudents value={students.length} />
          </div>
            </div>
            <hr />
        {students.map((st) => (
          <DrawStudent
            studentId={st.student}
            skills={extra ? extra.skillToTest : test.skillsToTest}
          />
        ))}
      </div>
    );
}

function DrawStudent(props){


    const { studentId, skills } = props
    const [comments, setComments] = useState("")
    const [skillsToTest, setSkillsToTest] = useState(() => {
        let o = {}
        for (let key in skills) {
            o[key] = 0
        }
        return o
    })
console.log(studentId)

    return (
        <div style={{ border: "1px solid #ccc", borderRadius: 5, padding: 5, marginBottom: 5 }}>
        
            <StudentAvatar id={studentId} />
                
        <div style={{display:"flex", gap:10, paddingBottom: 5, paddingTop:5}}>
          {skills.map((skill) => (
            <TextField
              type="number"
              style={{ width: 150 }}
              label={skill}
              value={skillsToTest[skill]}
              onChange={(e) =>
                setSkillsToTest((prev) => ({ ...prev, skill: e.target.value }))
              }
            />
          ))}
        </div>
            <TextField
                style={{marginTop:5}}
          label="Comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
                fullWidth
                InputProps={{ style: { fontSize: 12 } }}
                multiline
                rows={2}
        />
      </div>
    );
}