import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../../database";

export default function ChangeCredit(props) {
  const { open, setOpen, customer, setCustomer } = props;
  const [credit, setCredit] = React.useState(customer.credit);

  const update = () => {
    net.post(`/customers/${customer._id}/update`, { credit }).then((res) => {
      customer.credit = credit;
      setCustomer(customer);
      setOpen(false);
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Change credit</DialogTitle>
      <DialogContent>
        <TextField
          value={credit}
          onChange={(e) => setCredit(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={() => update()}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
