import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";
import NumberFormat from "react-number-format";

export default function NewLeadDialog(props) {
  const { open, setOpen, stage } = props;
  const [lead, setLead] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [ms, setMs] = React.useState("");
  const [errors, setErrors] = React.useState({});
  let fields = [
    { name: "name", label: "Họ tên Học Sinh", type: "text" },
    { name: "phone", label: "Điện thoại", type: "text" },
    { name: "dateOfBirth", label: "Năm sinh", type: "date" },
    { name: "address", label: "Địa chỉ", type: "text" },
    { name: "parentsName", label: "Họ tên Phụ Huynh", type: "text" },
    { name: "facebook", label: "Facebook", type: "text" },
  ];

  React.useEffect(() => {
    net.get("/center/marketing-sources").then((res) => {
      setOptions(res);
    });
  }, []);

  const addMarketingSource = (marketingSource) => {
    if (!options.includes(marketingSource)) {
      net.post("/center/marketing-sources", { marketingSource }).then((res) => {
        setOptions((prev) => {
          prev.push(res.source);
          return prev;
        });
      });
      setMs(marketingSource);
    }
  };

  const addLead = () => {
    setErrors({});
    let ers = false;
    if (!lead.name || lead.name === "") {
      setErrors((prev) => {
        return { ...prev, name: true };
      });
      ers = true;
    }
    if (!lead.phone || lead.phone === "") {
      setErrors((prev) => {
        return { ...prev, phone: true };
      });
      ers = true;
    }
    if (ers === true) return;
    net
      .put("/leads", {
        ...lead,
        source: ms,
        stage,
      })
      .then((res) => {
        setLead({});
        setMs("");
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>Khách hàng mới</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{ mt: 2 }}
        >
          {fields.map((field) =>
            field.name === "phone" ? (
              <NumberFormat
                key={field}
                label={field.label}
                customInput={TextField}
                format="(###) ###-####"
                value={lead.phone}
                onValueChange={(value) =>
                  setLead({ ...lead, phone: value.value })
                }
                {...(errors[field.name] && { error: true })}
              ></NumberFormat>
            ) : (
              <TextField
                key={field}
                fullWidth
                label={field.label}
                value={lead[field.name]}
                type={field.type}
                onChange={(e) =>
                  setLead((prev) => {
                    prev[field.name] = e.target.value;

                    return prev;
                  })
                }
                {...(field.type === "date" && {
                  InputLabelProps: { shrink: true },
                })}
                {...(errors[field.name] && { error: true })}
              />
            )
          )}
          <Autocomplete
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Hình thức đăng ký"
              />
            )}
            onBlur={(e) => addMarketingSource(e.target.value)}
            value={ms}
            onChange={(e, newValue) => setMs(newValue)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          tabIndex={100}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={() => addLead()}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
