import React from "react";
import Loading from "../../Loading";
import { useParams } from "react-router-dom";
import Bcs from "./Bcs";
import { Stack, TextField } from "@mui/material";
import ResourceContainer from "./ResourceContainer";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";
import mut from "../../hooks/mut";


export default function CLesson(props) {
    const { lid } = useParams();
    const [lesson, setLesson, error, loading] = useDB(`/clesson/${lid}`);
   
    if (loading) return <Loading />
    if (error) return <Error />
  
    return (
      <div>
        <Bcs
          syllabus={lesson.curriculum}
          unit={lesson.unit}
          cLesson={lesson}
        />
        <h1>
          Lesson {lesson.lessonNumber} - {lesson.name}
        </h1>
        <div style={{ display: "flex", gap: 10 }}>
        <Information lesson={lesson} setLesson={setLesson} />
          <Resources
            lesson={lesson}
            setLesson={setLesson}
          />
        </div>
      </div>
    );
}

function Information(props) {
    const { lesson, setLesson } = props
    
    const fields = [
        { label: "Content", name: "content", value: lesson.content || "" },
        { label: "Vietnamese Teacher", name: "vnTeacher", value: lesson.vnTeacher || "" },
        { label: "Lesson objectives", name: "lessonObjectives", value: lesson.lessonObjectives || "" },
        { label: "Homework", name: "homework",value: lesson.homework || ""}
    ]

    const update = (field) => {
        mut("post",`/clesson/${lesson._id}`,field,setLesson)
    }

    return (
      <div style={{ flexGrow: 1 }}>
            <Stack spacing={2}>
                {fields.map(field=>
          <TextField
            value={field.value}
            onChange={(e) =>
              setLesson((prev) => ({ ...prev, [field.name]: e.target.value }))
            }
            onBlur={(e)=>update({[field.name]: e.target.value })}
            multiline
            rows={3}
            label={field.label}
            style={{ flexGrow: 1 }}
          />)}
        </Stack>
      </div>
    );
}

function Resources(props) {
    const {lesson,setLesson} = props
    const rcs = [
      {
        label: "Lesson sheet",
        background: "blue",
        url: `/clesson/${lesson._id}/lessonsheet`,
      },
      {
        label: "Sample lesson plans",
        background: "blue",
        url: `/clesson/${lesson._id}/samplelessonplans`,
      },
      {
        label: "Other lesson plans",
        background: "blue",
        url: `/clesson/${lesson._id}/otherlessonplans`,
      },
      {
        label: "Videos",
        background: "blue",
        url: `/clesson/${lesson._id}/videos`,
      },
      {
        label: "Powerpoints",
        background: "blue",
        url: `/clesson/${lesson._id}/ppts`,
      },
      {
        label: "Activities",
        background: "blue",
        url: `/clesson/${lesson._id}/activities`,
      },
      {
        label: "Resources",
        background: "blue",
        url: `/clesson/${lesson._id}/resources`,
      },
    ];
    return (
      <div className="resource-container">
        <Stack spacing={2}>
          {rcs.map((r) => (
            <ResourceContainer
              label={r.label}
              background={r.background}
                  url={r.url}
              setLesson={setLesson}    
            />
          ))}
        </Stack>
      </div>
    );
}
