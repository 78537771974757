import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function MonthChoice(props) {
  const { months, setMonths } = props;
  const choices = [1, 3, 6, 12];
  return (
    <div>
      <div style={{ fontSize: 11, textAlign: "center" }}>Months</div>
      <ToggleButtonGroup
        value={months}
        exclusive
        onChange={(e) => setMonths(parseInt(e.target.value))}
        size="small"
      >
        {choices.map((choice) => (
          <ToggleButton key={`choice${choice}`} value={choice}>
            {choice}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
