import React from "react";
import { net } from "../../../../database";
import { Avatar } from "@mui/material";

export default function ShowTags(props) {
  //const {l,language} = props;
  let { staff } = props;

  if (!staff) return <div></div>;

  return (
    <div className="chat-tags">
      {staff.slice(0, 4).map((s, i) => (
        <DrawStaff staffMember={s} key={staff._id} index={i} {...props} />
      ))}
    </div>
  );
}

function DrawStaff(props) {
  let { staffMember, message, addUser, index, selected } = props;

  const addStaff = () => {
    net
      .post(`/message/${message._id}/add-user`, { newPerson: staffMember._id })
      .then((res) => addUser(res, staffMember));
  };
  let background = "white";
  if (index === selected) background = "#eee";
  return (
    <div
      className="tags-staff"
      onClick={() => addStaff()}
      style={{ background }}
    >
      <Avatar></Avatar>
      <div style={{ padding: 5 }}>{staffMember.name}</div>
    </div>
  );
}
