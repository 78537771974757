import { Avatar } from "@mui/material";
import React, { useContext,useState } from "react";
import { StudentsContext } from "../../../Context";


export default function UploadAvatar(props) {

  const { id } = props
 
  const { findStudent } = useContext(StudentsContext)
    let student = findStudent(id)
    const [image,setImage] = useState(student ? student.image : "")
  
    if(!student) return <div></div>
   
  
  
 function sendPic() {
    var myInput = document.getElementById('fileToUpload' + id);
 
 const formData = new FormData();
formData.append('fileToUpload', myInput.files[0]);
fetch('/api/upload/'+id, {
  method: 'POST',
  body: formData
}).then(res=>res.json()).then(image=>setImage(image.image))
.catch(error => {
  console.error(error);
});
 
}
    return (<div>
      <Avatar style={props.style} /* src={`/images/${student.image}`} */ onClick={() => { if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) document.getElementById("fileToUpload"+id).click() }} src={image ? `/images/${image}` : ""} />
      <input type="file" name="fileToUpload" id={"fileToUpload"+id} capture="environment" accept="image/*" onChange={()=>sendPic()} style={{display:"none"}} /></div>)
}