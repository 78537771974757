import React from "react";
import Loading from "../../content/Loading";
import { net } from "../../../database";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VND from "../../content/components/VND";
import { format } from "date-fns";
import Changes from "../../content/pages/newLostStudents/Changes";
import { gradients } from "../../../enums";

export default function Stats(props) {

    const [ps, setPs] = React.useState(undefined)
    const [updated,setUpdated] = React.useState(undefined)

    React.useEffect(() => {
        net.get("/dailystats-mobile").then(res => { setPs({ today: res[0], yesterday: res[1] }); setUpdated(new Date()) })
        let update = setInterval(() => {
            net.get("/dailystats-mobile").then(res => { setPs({ today: res[0], yesterday: res[1] }); setUpdated(new Date())})
        }, 60000)
        return ()=> clearInterval(update)
    },[])
    
    if (!ps) return <Loading />

    return (
      <div
        style={{
          border: "1px solid #ccc",
          padding: 10,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginTop: 10,
        }}
      >
        <div>
          Updated at {updated ? format(updated, "dd-MM-yyyy h:mm aaa") : ""}
        </div>
        <DrawStat
          {...ps}
          name="activeStudents"
          label="Active Students"
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="newStudents"
          label="New Students"
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="stoppedStudentsThisMonth"
          label="Stopped Students"
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="pendingStudents"
          label="Pending Students"
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="payments"
          label="Payments"
          money
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="revenue"
          label="Revenue"
          money
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="potentialPayments"
          label="Potential Payments"
          money
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="classRefunds"
          label="Class refunds"
          money
          background={gradients.darkBlue}
        />
        <DrawStat
          {...ps}
          name="costs"
          label="Costs"
          money
          background={gradients.darkBlue}
        />
        <div>
          <Changes
            year={new Date().getFullYear()}
            month={new Date().getMonth()}
          />
        </div>
      </div>
    );
}

function DrawStat({ name, label, today, yesterday, money, background }) {
   
    return <div style={{borderRadius: 5, background, color: "white", padding: 10, fontSize: 18}}><div style={{display:"flex",justifyContent:"space-between"}}>
        <div>{label} </div><div style={{ display: "flex", gap: 10 }}>{money ? <VND value={today[name]} /> : today[name]}</div> 
        </div>
        <Difference difference={today[name] - yesterday[name]} money={money} />
    </div>
}

function Difference({ difference, money}) {  
    return <div style={{ display: "flex", alignItems: "center", fontSize:16,color: "#ccc" }}>{difference < 0 ? <ArrowDropDownIcon style={{ color: "red" }} /> : <ArrowDropUpIcon style={{ color: "green" }} />} {money ? <VND value={difference} /> : difference}</div>
}