import { Stack, TextField } from "@mui/material";
import React from "react";
import LocationChoice from "../../../components/LocationChoice";
import StaffChooserContext from "../../../components/StaffChooserContext";
import VND from "../../../components/VND";
import ChooseDate from "../../../components/ChooseDate";
import SelectFromList from "../../../components/SelectFromList";


export default function StepOne(props) {
    const { newClass, setNewClass } = props
    console.log(props)
    return (
      <Stack spacing={2}>
        <TextField
          value={newClass.name || ""}
          onChange={(e) =>
            setNewClass((prev) => ({ ...prev, name: e.target.value }))
          }
          label="Name"
          fullWidth
          sx={{ mt: 1 }}
        />
        <LocationChoice
          value={newClass.location || ""}
          onChange={(e) =>
            setNewClass((prev) => ({ ...prev, location: e.target.value }))
          }
          fullWidth
        />
        <StaffChooserContext
          value={newClass.teacher || ""}
          setValue={(teacher) => setNewClass((prev) => ({ ...prev, teacher }))}
          label="Teacher"
          filter="Teacher"
        />
        <SelectFromList choices={["Coming","Active"]} value={newClass.status} setValue={(status)=>setNewClass(prev=>({...prev, status}))} label="Status"/>
        <VND
          value={newClass.fee}
          setValue={(fee) => setNewClass((prev) => ({ ...prev, fee:parseInt(fee) }))}
          label="Fee"
        />
      </Stack>
    );
}