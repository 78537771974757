import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Content from "./components/content/Content";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { net } from "./database";
import CssBaseline from "@mui/material/CssBaseline";
import { red, cyan } from "@mui/material/colors";
import Login from "./Login";
import io from "socket.io-client";
import MobileHome from "./components/mobile/MobileHome";
export var socket = io(
  {
    auth: (cb) => cb({ token: localStorage.apiKey }),
  },
  { transports: ["websocket"] }
);

/**
 * 
 * @returns the last item of the array
 */

Array.prototype.getLast = function () {
  if (this.length > 0) return this[this.length - 1];
  return undefined;
};

export var apiKey = localStorage.getItem("apiKey") || undefined;
export function deleteApiKey() {
  apiKey = undefined;
}
function setApiKey(newKey) {
  apiKey = newKey
}

/* const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}; */

// …

//registerServiceWorker();

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function App({user,setUser,center,setCenter,setLanguage,language,staffAll,l}) {
  
  
   const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      setColorMode: (newmode) => {
        setMode(newmode);
      },
    }),
    []
  ); 

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: cyan[700],
          },
          secondary: {
            main: red[500],
          },
          customSuccess: {
            main: "#00FF00",
          },
          customWarning: {
            main: "#f7c92f",
          },
          customError: {
            main: "#f57a8b",
          },
        },
      }),
    [mode]
  );

 /*  const login = (apikeyIn, u) => {
    apiKey = apikeyIn;
    localStorage.setItem("apiKey", apikeyIn);
    setUser((prev) => u);
  }; */


  if (user === undefined)
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Login apiKey={apiKey} setUser={setUser} setApiKey={setApiKey} />
        </ThemeProvider>
      </div>
    );

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  )
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MobileHome language={language} l={l} user={user} />{" "}
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  return (
    <div>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <div style={{ display: "flex", width: "100%" }}>
            <Sidebar
              language={language}
              user={user}
              center={center}
            />

            <div style={{flexGrow:1}}>
              
                            <Topbar
                              language={language}
                              setLanguage={setLanguage}
                              net={net}
                              center={center}
                              colorMode={mode}
                              setColorMode={setMode}
                              user={user}
                              setUser={setUser}
                              l={l}
                            />
                            <Content
                              net={net}
                              user={user}
                              setUser={setUser}
                              language={language}
                              l={l}
                              center={center}
                              setCenter={setCenter}
                              className="content"
                              staff={staffAll}
                            />
                         
            </div>
          </div>
          {/*  {window.screen.width <= 450 ? <BottomBar language={language} /> : ""} */}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  );
}
