import { MenuItem, Stack, TextField } from "@mui/material";
import React from "react";

import ChooseDate from "../../../../components/ChooseDate";
import { addMonths, format } from "date-fns";
import Lang from "../../../../components/Lang";
export default function DrawNextBillDate(props) {
  let { student, payment, setPayment } = props;
  let hasTuition = student.unpaidBills.find((b) => b.billType === "Tuition");

  const [nbd, setNbd] = React.useState(
    hasTuition
      ? addMonths(new Date(student.nextBillDate), student.paymentFrequency)
      : new Date(student.nextBillDate)
  );

  React.useEffect(() => {
    setNbd((prev) =>
      hasTuition
        ? addMonths(new Date(student.nextBillDate), student.paymentFrequency)
        : new Date(student.nextBillDate)
    );
  }, [student.paymentFrequency]);

  React.useEffect(() => {
    setPayment((prev) => {
      let students = prev.students;
      let st = students.find((s) => s._id === student._id);
      st.newNextBillDate = format(nbd, "yyyy-MM-dd");
      return { ...prev, students };
    });
  }, [nbd]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Stack spacing={2} sx={{ p: 1, ml: 2 }}>
        <ChooseDate
          value={nbd}
          setValue={setNbd}
          label="Next bill date"
          enable={[
            function (d) {
              return d.getDate() === 1;
            },
          ]}
        />
        <TextField
          label={<Lang w="howOftenTheyPay" />}
          select
          fullWidth
          sx={{ ml: 1 }}
          value={student.paymentFrequency}
          onChange={(e) => {
            setPayment((prev) => {
              let students = prev.students;
              let stu = students.find((s) => s._id === student._id);
              stu.paymentFrequency = e.target.value;
              return { ...prev, students };
            });
          }}
          {...(payment.excludedStudents.includes(student._id) && {
            disabled: true,
          })}
        >
          <MenuItem value={1}>{<Lang w="oneMonth" />}</MenuItem>
          <MenuItem value={3}>{<Lang w="threeMonths" />}</MenuItem>
          <MenuItem value={6}>{<Lang w="sixMonths" />}</MenuItem>
        </TextField>
      </Stack>
    </div>
  );
}
