import React, { useContext, useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { Button } from "@mui/material";
import { format } from "date-fns";
import SLink from "../../components/SLink";
import Staff from "../../components/Staff";
import HtTabs from "../../components/HtTabs";
import { CenterContext } from "../../../../Context";



export default function Pending2(props) {


    const [students, setStudents, error, loading] = useDB(`/pending-students`)
    const [selectedDate, setSelectedDate] = useState("")
    const { locations } = useContext(CenterContext)

if (loading) return <Loading />;
if (error) return <Error />;



    const labels = locations.map(
      (l) => `${l.name} (${students.filter((s) => s.location === l._id).length})`
    );
    const components = locations.map((l) => (
      <div>
   
        <PendingCalendar
          students={students.filter(s=>s.location===l._id)}
          setSelectedDate={setSelectedDate}
        />
        {selectedDate && (
          <DrawStudents
            students={students.filter((s) => s.returnDate === selectedDate && s.location===l._id)}
          />
        )}
      </div>
    ));


    return (<div>
        <HtTabs labels={labels} components={components}/>
       
    </div>)
}


function DrawStudents({ students }) {
    console.log(students)
    return (
      <div>
        {students.map((student) => (
          <div style={{ display: "flex", justifyContent:"space-between", borderBottom:"1px gray solid", marginBottom:5 }}>
            <SLink
              id={student._id}
              showPhone
              showClasses
            />
            <div>
              Changed by <Staff id={student.changedBy} /> on{" "}
              {format(new Date(student.startDate), "dd/MM/yyyy")}
                </div>
            
          </div>
        ))}
      </div>
    );
}

function PendingCalendar({ students,setSelectedDate }) {

   let dates =  [...new Set(students.filter(s=>s.returnDate).map((s) => s.returnDate))].sort();
   
  
    
    return (
      <div style={{ borderBottom: "1px gray solid" }}>
        {dates.map((d) => (
          <DateButton
            d={d}
            setSelectedDate={setSelectedDate}
            length={students.filter((s) => s.returnDate === d).length}
          />
        ))}
      </div>
    );
}

function DateButton({ d, setSelectedDate,length }) {
    let color = "primary"
    if(new Date(d)< new Date()) color="secondary"
    return<Button onClick={() => setSelectedDate(d)} color={color}>
            {format(new Date(d), "dd/MM/yyyy")} ({length}
                )
                
          </Button>
}