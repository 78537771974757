import React from "react";
import StudentNamePhone from "../student/components/StudentNamePhone";
import { Button, TextField, MenuItem } from "@mui/material";
import { format } from "date-fns/esm";
import { net } from "../../../../database";
export default function DrawMakeUpClass(props) {
  let {
    student,
    refundButton,
    showTeacher,
    setStudents,
    students,
    l,
    language,
    teacher,
  } = props;
  const setScheduleDate = (e, student) => {
    net
      .post(`/manage/schedulemakeupclass`, {
        ...student,
        scheduledDate: e.target.value,
      })
      .then((studentsNew) => {
        setStudents(studentsNew);
      });
  };
  const changeTeacher = (e, student) => {
    net
      .post(`/manage/changeteachermakeupclass`, {
        ...student,
        teacher: e.target.value,
      })
      .then((studentsNew) => {
        setStudents(studentsNew);
      });
  };

  const deleteClass = (student, refund) => {
    net
      .post(`/manage/refundmakeupclass`, {
        ...student,
        refund,
      })
      .then((student) => {
        setStudents(students.map((s) => (s._id === student._id ? student : s)));
      });
  };

  const taught = () => {
    net
      .post(`/taughtmakeupclass`, student)
      .then((student) =>
        setStudents(students.map((s) => (s._id === student._id ? student : s)))
      );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <StudentNamePhone
        student={{
          name: student.name,
          phone: student.phone,
          _id: student.student_id,
        }}
      />
      <DrawMissedClass student={student} />
      {showTeacher ? (
        <DrawTeacher {...props} changeTeachers changeTeacher={changeTeacher} />
      ) : (
        ""
      )}
      <ScheduledDate
        student={student}
        setScheduleDate={setScheduleDate}
        {...props}
      />
      {refundButton ? (
        <div>
          <RefundButton
            label={l.refund[language]}
            student={student}
            {...props}
            deleteClass={deleteClass}
            amount={0.5}
          />
          <RefundButton
            label={l.refund100[language]}
            student={student}
            {...props}
            deleteClass={deleteClass}
            amount={1}
          />
          <RefundButton
            label={l.delete[language]}
            student={student}
            {...props}
            deleteClass={deleteClass}
            amount={0}
          />
        </div>
      ) : (
        ""
      )}
      {teacher ? <Button onClick={taught}>{l.taught[language]}</Button> : ""}
    </div>
  );
}

const DrawMissedClass = (props) => {
  let { student } = props;

  console.log(student);
  return (
    <div
      style={{ display: "flex", width: 200, justifyContent: "space-around" }}
    >
      <div>{student.htClass.name}</div>
      <div>
        {format(
          new Date(student.year, student.month, student.day),
          "dd/MM/yyyy"
        )}{" "}
      </div>
    </div>
  );
};

const ScheduledDate = (props) => {
  let { student, l, language, setScheduleDate } = props;

  return (
    <TextField
      type="date"
      size="small"
      defaultValue={
        student.scheduledDate
          ? format(new Date(student.scheduledDate), "yyyy-MM-dd")
          : ""
      }
      label={l.scheduledDate[language]}
      InputLabelProps={{ shrink: true }}
      onBlur={(e) => setScheduleDate(e, student)}
    />
  );
};
const RefundButton = (props) => {
  let { student, deleteClass, amount, label } = props;
  let color = "error";
  if (amount === 0.5) color = "success";
  if (amount === 1) color = "warning";
  return (
    <Button onClick={() => deleteClass(student, amount)} color={color}>
      {label}
    </Button>
  );
};

const DrawTeacher = (props) => {
  let { student, teachers, changeTeachers, changeTeacher } = props;

  return (
    <div>
      {changeTeachers ? (
        <SelectTeacher
          teachers={teachers}
          teacher={student.teacher._id}
          changeTeacher={changeTeacher}
          student={student}
          {...props}
        />
      ) : (
        <div style={{ width: 200 }}>{student.teacher.name}</div>
      )}
    </div>
  );
};

const SelectTeacher = (props) => {
  let { teacher, teachers, changeTeacher, student, l, language } = props;

  return (
    <TextField
      select
      size="small"
      value={teacher}
      label={l.teacher[language]}
      sx={{ width: 200 }}
      onChange={(e) => changeTeacher(e, student)}
    >
      {teachers.map((t) => (
        <MenuItem key={t._id} value={t._id}>
          {t.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
