import React from "react";
import format from "date-format";
import "./studentHistory.css";

export default function StudentHistory(props) {
  let { student } = props;
  return (
    <div>
      <div style={{ display: "flex", fontSize: 11 }}>
        <div className="history-status">Status</div>
        <div className="history-changedBy">Changed By</div>
        <div className="history-startTime">Date</div>
        <div className="history-endTime">Until</div>
        <div className="history-reason">Reason</div>
        <div className="history-returnDate">Return Date</div>
      </div>
      {student.statusHistory
        .sort((a, b) =>
          new Date(a.startDate) < new Date(b.startDate) ? 1 : -1
        )
        .map((status) => (
          <div key={status._id} style={{ display: "flex" }}>
            <div className="history-status">{status.status}</div>
            <div className="history-changedBy">
              {status.changedBy ? status.changedBy.name : ""}
            </div>
            <div className="history-startTime">
              {format.asString("dd/MM/yyyy", new Date(status.startDate))}
            </div>
            <div className="history-endTime">
              {status.endDate
                ? format.asString("dd/MM/yyyy", new Date(status.endDate))
                : "Current Status"}
            </div>
            <div className="history-reason">{status.reason}</div>
            {status.status === "Pending" ? (
              <div className="history-returnDate">
                {format.asString("dd/MM/yyyy", new Date(status.returnDate))}
              </div>
            ) : (
              <div className="history-returnDate"></div>
            )}
          </div>
        ))}
    </div>
  );
}
