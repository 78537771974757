import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { net } from "../../../database";
import NumberFormat from "react-number-format";

/**
 * A standard dialog for entering data into the database
 * @param {array} fields - Fields you want to show on your dialog, label, type, value
 * @param {function} newData - function to handle the new data created
 * @param {string} route - place to send the new data
 * @param {boolean} open - show or hide the dialog
 * @param {function} setOpen - show or hide the dialog
 * @param {string} title - title of the dialogTitle
 * @param {string} succcessLabel - label for the success button
 * @param {string} content - message to the user about the dialog, can be text or component
 */

export default function SimpleDialog(props) {
  const [fields, setFields] = React.useState(
    props.fields.map((a) => {
      return { ...a };
    })
  );
  const [render, setRender] = React.useState(0);
  const handleClose = () => {
    setFields(
      props.fields.map((a) => {
        return { ...a };
      })
    );
    props.setOpen(false);
  };
  const handleNewData = () => {
    if (checkerrors() === true) return;

    let newData = {};
    fields.forEach((f) => {
      if (f.type === "number") {
        newData[f.name] = parseInt(f.value.replace(",", ""));
      } else {
        newData[f.name] = f.value;
      }
    });
  
    net.post(props.route, newData).then((r) => {
      // if (r.insertedId) newData._id = r.insertedId;
      if (props.newData) props.newData(r);
      setFields(
        props.fields.map((a) => {
          return { ...a };
        })
      );

      props.setOpen(false);
    });
  };

  const updateFields = (e, i, field) => {
    let newFields = [...fields];
    newFields[i].value = e.target.value;
    setFields(newFields);
  };

  const checkerrors = () => {
    let errors = false;
    let newFields = fields;
    newFields.forEach((f) => {
      if (f.value === "") {
        f.error = true;
        errors = true;
      
      } else {
        delete f.error;
      }
    });
    setFields(newFields);
    setRender(render + 1);
    return errors;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      className="dialog-box"
      fullWidth={props.fullWidth}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {props.content ? props.content : ""}
        {fields
          .filter((f) => f.type !== "hidden")
          .map((f, i) => (
            <div
              key={f.name + i}
              style={{ padding: "10px" }}
            >
              {f.type === "number" ? (
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator={true}
                  onChange={(e) => updateFields(e, i)}
                  autoComplete="off"
                  value={f.value}
                  label={f.label}
                  {...(f.error && { error: true })}
                />
              ) : (
                <TextField
                  id={f.name}
                  label={f.label}
                  type={f.type}
                  value={f.value}
                  fullWidth
                  onChange={(e) => updateFields(e, i)}
                  {...(f.type.includes("select") && {
                    select: true,
                  })}
                  {...(f.error && { error: true })}
                >
                  {f.type === "select"
                    ? f.items.map((item) => (
                        <MenuItem
                          key={"mi" + item}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))
                    : f.type === "selectNameId"
                    ? f.items.map((item) => (
                        <MenuItem
                          key={"mi" + item._id}
                          value={item._id}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    : ""}
                </TextField>
              )}
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">Close</Button>
        <Button onClick={handleNewData} color="success">{props.ok}</Button>
      </DialogActions>
    </Dialog>
  );
}
