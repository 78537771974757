import React from "react";
import GetPhone from "./components/GetPhone";
import SetupNewCustomer from "./components/SetupNewCustomer";
import { net } from "../../../../database";
import { useNavigate } from "react-router-dom";

export default function NewCustomer(props) {
  let { l, language } = props;
  const [phone, setPhone] = React.useState("");
  /* const [phoneError, setPhoneError] = React.useState("false"); */
  const [phoneCheck, setPhoneCheck] = React.useState(false);
  /*  const [customer, setCustomer] = React.useState(undefined); */
  const [newCustomer, setNewCustomer] = React.useState({
    name: "",
    title: "",
    paymentFrequency: "",
    reasonForLearning: "",
    income: 0,
    address: "",
    jobs: ["", ""],
    dob: "",
    englishKnowledge: 0,
    problemsWithChildren: "",
    friends: [],
    marketingSource:""
  });

  const [customerErrors, setCustomerErrors] = React.useState([]);
  let navigate = useNavigate();

  const addCustomer = () => {
    newCustomer.phone = phone;
    let errors = [];
    for (var keys in newCustomer) {
      if (newCustomer[keys] === "") {
        errors.push(keys);
      }
    }
    if (newCustomer.englishKnowledge === 0) errors.push("englishKnowledge");
    if (newCustomer.income === 0) errors.push("englishKnowledge");
    if (newCustomer.jobs[0] === "") errors.push("jobs0");
    if (newCustomer.jobs[1] === "") errors.push("jobs1");
    if  (newCustomer.marketingSource==="") errors.push("marketingSource")
    if (errors.length > 0) {
      setCustomerErrors(errors);
      return false;
    }
    net.post("/customer", newCustomer).then((r) => {
      console.log(r);
      setCustomerErrors([]);
      navigate("/customer/" + r._id);
      return;
    });
  };

  if (phoneCheck === false)
    return (
      <GetPhone
        setPhone={setPhone}
        phone={phone}
        setPhoneCheck={setPhoneCheck}
        language={language}
        l={l}
      />
    );
  return (
    <div>
      {" "}
      <SetupNewCustomer
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        customerErrors={customerErrors}
        addCustomer={addCustomer}
        language={language}
        l={l}
      />
    </div>
  );
}
