import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { net } from "../../../../database";
import Uploady from "@rpldy/uploady";
//import UploadButton from "@rpldy/upload-button";
//import UploadPreview from "@rpldy/upload-preview";
import { asUploadButton } from "@rpldy/upload-button";

import { useItemFinishListener } from "@rpldy/uploady";
import "./profile.css";
import { Avatar, Card } from "@mui/material";
import PasswordDialog from "./PasswordDialog";

export default function Profile(props) {
  let { user, setUser } = props;
  React.useEffect(() => {
    net
      .get("/user/" + props.user._id)

      .then((r) => {
        setUser(r);
      });
  }, []);

  const update = (field, data) => {
    net
      .post("/user/" + props.user._id, { [field]: data })
      .then((r) => setUser({ ...user, [field]: data }));
  };

  if (!user) return <div>Loading...</div>;
  console.log(user);
  return (
    <div>
      <h1>Profile</h1>
      <div style={{ display: "flex" }}>
        <DrawAvatar user={user} />
        <Stack spacing={2} style={{ margin: 10, flexGrow: 1 }}>
          {profileLayout.map((p) => (
            <TextField
              key={p.name}
              className="profileData"
              id={p.name}
              label={p.label}
              type={p.type}
              value={user[p.name] || ""}
              onChange={(e) => setUser({ ...user, [p.name]: e.target.value })}
              onBlur={(e) => update(p.name, e.target.value)}
              size="small"
              {...(p.type === "date" && {
                InputLabelProps: { shrink: true },
              })}
            />
          ))}
        </Stack>
      </div>

      {/*  <UploadPreview
          style={{ width: 100 }}
          fallbackUrl="https://icon-library.net/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
        />
        <UploadButton className="upload-button" />

        <UploadFinishListener setUser={setUser} user={user} /> 
      </Uploady>*/}
    </div>
  );
}

const DivUploadButton = asUploadButton((props) => {
  let { image } = props;
  return (
    <Avatar
      {...props}
      src={`./images/${image}`}
      style={{
        width: 100,
        height: 100,
        cursor: "pointer",
      }}
    />
  );
});

function DrawAvatar({ user }) {
  const [password, setPassword] = React.useState(false)
  return (
    <Card style={{ width: 300 }}>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <Uploady destination={{ url: "/fileupload/" + user._id }}>
          <DivUploadButton extraProps={user} />
        </Uploady>
      </div>
      <div style={{ textAlign: "center", marginTop: 15 }}>{user.fullName}</div>
      <div style={{ textAlign: "center", marginTop: 15 }}>{user.position}</div>
      <div style={{ textAlign: "center", marginTop: 15 }}><Button onClick={()=>setPassword(true)}>Change password</Button></div>
      <PasswordDialog open={password} setOpen={setPassword} />
    </Card>
  );
}

function UploadFinishListener(props) {
  useItemFinishListener((item) => {
    console.log(
      `item ${item.id} finished uploading, response was: `,
      props.setUser(item.uploadResponse.data),
      item.uploadStatus
    );
  });
  return <div></div>;
}

let profileLayout = [
  { name: "fullName", type: "text", label: "Full name" },
  { name: "phone", type: "text", label: "Phone" },
  { name: "address", type: "text", label: "Street Address" },
  { name: "dateOfBirth", type: "date", label: "Date of birth" },
  { name: "emergency", type: "text", label: "Emergency contact phone number" },
  { name: "bankAccountBank", type: "text", label: "Bank Name" },
  { name: "bankAccountNumber", type: "text", label: "Bank account number" },
  { name: "bankAccountName", type: "text", label: "Name on bank account" },
];
