import React, { useContext } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";

import HtClass from "../../components/HtClass";
import Staff from "../../components/Staff";
import Info from "../../components/Info";
import RoomChoice from "../../components/RoomChoice";
import mut from "../../hooks/mut";
import { CenterContext, ClassesContext } from "../../../../Context";
import Time from "../../components/Time";
import TimeChooser from "../../components/TimeChooser";
import { addMinutes, format } from "date-fns";
import HtTabs from "../../components/HtTabs";



export default function Tests(props) {


const [tests, setTests, error, loading] = useDB(`/tests`)
const {center} = useContext(CenterContext)

if (loading) return <Loading />;
if (error) return <Error />;



console.log(tests);
let labels = center.locations.filter(l=>l.name!=="All locations").map(l=>l.name)
  let components = center.locations.map(l => <TestsAtLocation tests={tests.filter(t => t.location === l._id)} setTests={setTests} />)
return (
  <div>
   
      <h3>Tests</h3>
    <HtTabs labels={labels} components={components} localStorage="location" />

  
   
  </div>
);
}


function TestsAtLocation(props) {
  const {tests,setTests} = props
  let dates = [...new Set(tests.map((t) => t.tDate))];
  return (
    <div>
      {dates.map((d) => (
        <div style={{ border: "1px solid #eee", padding: 5, borderRadius: 5, marginBottom:10 }}>
          <div style={{ paddingTop: 5, paddingBottom: 5, fontWeight: 200 }}>
            {format(new Date(d), "eeee d/M/yyyy")}
          </div>
          {tests
            .filter((t) => t.tDate === d)
            .map((test) => (
              <DrawTest
                test={test}
                setTests={setTests}
                date={d}
              />
            ))}
        </div>
      ))}
    </div>
  );
}

function DrawTest(props){


const { test, setTests,date } = props;
  const { findClass } = useContext(ClassesContext)
  const htClass = findClass(test.htClass)
  const dt = new Date(date)
  const classDay = htClass.classDays.find(cd => cd.day === dt.getDay())
  const times = []
  let d = new Date("2020-01-01 "+ classDay.startTime)
  for (let i = 0; i < 3; i++){
    times.push(format(addMinutes(d,i*30),"HH:mm"))
    console.log(i)
  }
  console.log(times)
  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: 5,
        display: "flex",
      }}
    >
      <div style={{ width: 300 }}>
        <Info
          title="Class"
          body={
            <div>
              <HtClass
                htClass={test.htClass}
                link
              />
              <div style={{ fontSize: 12 }}>
                <div>{classDay.room}</div>
                <div>
                  <Time time={classDay.startTime} />
                </div>
              </div>
            </div>
          }
        />

        <Info
          title="Teacher"
          body={<Staff id={htClass.teacher} />}
        />

        <Info
          title="Skills to test"
          body={test.skillsToTest.toString()}
        />
      </div>
      <div>
        {test.extraTeachers.map((t, i) => (
          <div style={{ display: "flex", gap: 10,padding:10 }}>
            <div style={{display:"flex", flexDirection:"column", gap:10, width:200}}>
            <Staff
              id={t.teacher}
              label="Teacher"
             
              setValue={async (teacher) => {
                let newTest = await mut(
                  "post",
                  `/tests/${test._id}/change-extra-teacher`,
                  { teacher, _id: t._id }
                );
                setTests((prev) =>
                  prev.map((p) => (p._id === newTest._id ? newTest : p))
                );
              }}
                fullWidth
            />
            <RoomChoice
              location={test.location}
              value={t.room}
           
              setValue={async (room) => {
                let newTest = await mut(
                  "post",
                  `/tests/${test._id}/extra-teacher`,
                  { teacher: t.teacher, room }
                );
                setTests((prev) =>
                  prev.map((p) => (p._id === newTest._id ? newTest : p))
                );
              }}
                fullWidth
              />
              </div>
            <TimeChooser
              value={t.startTime}
              times={times}
              label="Time"
              setValue={async (startTime) => {
                let newTest = await mut(
                  "post",
                  `/tests/${test._id}/extra-teacher`,
                  { teacher: t.teacher, startTime }
                );
                setTests((prev) =>
                  prev.map((p) => (p._id === newTest._id ? newTest : p))
                );
              }}
            />
            <Info
              title="Skills to test"
              body={t.skillsToTest.toString()}
            />
          </div>
        ))}
      </div>
    </div>
  );
}