import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import SLink from "../../components/SLink";
import { format } from "date-fns";
import VND from "../../components/VND";


export default function CheckBills(props) {


const [students, setStudents, error, loading] = useDB(`/students-bills`)


if (loading) return <Loading />;
if (error) return <Error />;


console.log(students);


    return (
      <div>
        {students.map((s) => (
          <div style={{ display: "flex", gap: 10 }}>
            <div style={{ width: 200, minWidth: 200 }}>
              <SLink id={s._id} />
            </div>
            <div style={{ width: 100, minWidth: 100 }}>
              {s.nextBillDate
                ? format(new Date(s.nextBillDate), "dd/MM/yyyy")
                : "No next bill date"}
            </div>
            <div style={{ width: 100, minWidth: 100 }}>
              {s.paymentFrequency
                ? s.paymentFrequency
                : "No payment frequency"}
                </div>
                <div>
                    {s.bills.map(b => <div style={{display:"flex"}}>
                        <div style={{width:400,minWidth:400}}>{b.description}</div>
                        <div><VND value={b.amount} /></div>
                    </div>)}
                </div>
          </div>
        ))}
      </div>
    );
}