import React from "react";
import Loading from "../../Loading";
import HtDate from "../../components/HtDate";
import HtTabs from "../../components/HtTabs";
import useDB from "../../hooks/useDB";
import ByTeacher from "./ByTeacher";
import HtClass from "../../components/HtClass";
import FormatDate from "../../components/FormatDate";
import Staff from "../../components/Staff";
import DrawAttendance from "./DrawAttendance";


export default function ManageFeedback(props) {


 
  const components = [<ByDate />,<ByTeacher />]
 
  return (
    <div>
     <HtTabs labels={["By Date","By Teacher","By Class"]} components={components} />
    </div>
  );
}

function ByDate(props){


  const [fbDate, setFbDate] = React.useState(new Date());
  const [feedback, setFeedback, loading, error] = useDB(
    `/feedback-by-date/${fbDate.toISOString().substring(0, 10)}`
  );

  if (!feedback) return <Loading />;

  const {lessons,attendance} = feedback
return (
  <div>
    {" "}
    <div
      className="dflex-sb"
      style={{ alignItems: "center" }}
    >
      <h3>Feedback by date</h3>
      <HtDate
        value={fbDate}
        setValue={setFbDate}
        buttons
        style={{ width: 120 }}
      />
    </div>
    {lessons.map((l) => (
      <DrawLesson
        attendance={attendance.filter((f) => f.lesson === l._id)}
        lesson={l}
      />
    ))}
  </div>
);
}




function DrawLesson(props) {
  const { lesson, attendance } = props;

  return (
    <div>
      <div
        style={{
          background: "#888",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          padding: 5,
          borderRadius: 5,
          marginBottom: 5,
        }}
      >
        <div style={{display:"flex",gap:5}}>
          <HtClass
            htClass={lesson.classId}
            link
            style={{ width: 200, color: "white", textDecoration: "none" }}
          />{" "}
          <Staff id={lesson.teacher} />
        </div>
        <FormatDate value={lesson.tDate} />
        {attendance &&
          attendance.length &&
          attendance[0].feedbackComplete > 0 && (
            <FormatDate value={attendance[0].feedbackComplete} />
          )}
      </div>
      {attendance.map((a) => (
        <DrawAttendance at={a} />
      ))}
    </div>
  );
}
