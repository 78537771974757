import React, { useContext, useState } from "react";
import SelectFromList from "../../components/SelectFromList";
import { StaffContext } from "../../../../Context";
import ChooseDate from "../../components/ChooseDate";
import Text from "../../components/Text";
import Rating from "../../components/Rating";
import PhoneNumber from "../../components/PhoneNumber";
import Staff from "../../components/Staff";
import Lang from "../../components/Lang";
import DatePicker from "../../components/DatePicker";


export default function DrawContact({contact, showResult=false, showRating=true}) {



  const [consultant, setConsultant] = useState(contact.consultant || "");

  const [score, setScore] = useState(contact.score);
  const [consultantNotes, setConsultantNotes] = useState(
    contact.consultantNotes);
 const [contacted, setContacted] = useState(
   new Date(contact.consultantContacted) || ""  
 );
 const [testDate, setTestDate] = useState(contact.testDate || "");
  
  
  
 
   const { getStaffRole } = useContext(StaffContext);
  /* const [result, setResult] = useState(contact.result || "");
   const results = [
     { _id: "New student", name: <Lang w="newStudent" /> },
     { _id: "Potential", name: <Lang w="potential" /> },
     { _id: "Not interested", name: <Lang w="notInterested" /> },
   ]; */
 
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
        marginBottom: 5,
        marginRight: 10,
      }}
    >
      <div style={{ width: 150 }}>
        <PhoneNumber value={contact.phone} />
        {contact.timeToCall && <div>{contact.timeToCall}</div>}
        <div style={{ fontSize: 11 }}>
          <Staff id={contact.marketingPerson} />
        </div>
      </div>
      <div style={{ width: 100 }}>
        <div>{contact.name}</div>
        <div style={{ width: 100, fontSize: 11 }}>{contact.location}</div>
      </div>
      <div style={{ width: 200, fontSize: 11 }}>{contact.notes}</div>
      <div style={{ width: 150 }}>
        <SelectFromList
          label="Consultant"
          choices={getStaffRole("Sales")}
          fullWidth
          url={`/marketingcontacts/${contact._id}`}
          field="consultant"
          value={consultant}
          setValue={setConsultant}
        />
      </div>
      <div style={{ width: 150 }}>
        <ChooseDate
          size="normal"
          url={`/marketingcontacts/${contact._id}`}
          field="consultantContacted"
          value={contacted}
          setValue={setContacted}
        />
      </div>
      <div style={{ flexGrow: 0.8 }}>
        <Text
          label="Kết quả"
          fullWidth
          fontSize={12}
          url={`/marketingcontacts/${contact._id}`}
          field="consultantNotes"
          multiline
          rows={2}
          value={consultantNotes}
          setValue={setConsultantNotes}
        />
      </div>
      <div>
        <div style={{display:"flex", alignItems:"center",gap:5}}>
          <DatePicker
            value={new Date(testDate)}
            setValue={setTestDate}
            url={`/marketingcontacts/${contact._id}`}
            field="testDate"
            label={<Lang w="testDate" />}
          />
          {showRating && (
            <div style={{ width: 100, minWidth: 100 }}>
              <Rating
                value={score}
                setValue={setScore}
                height={30}
                url={`/marketingcontacts/${contact._id}`}
                field="score"
              />
            </div>
          )}
        </div>
        <div style={{padding:5}}>{contact.result ? <DrawResult result={contact.result} /> : ""}</div>
      </div>
      {/*   {showResult && (
        <div>
          <SelectFromList
            choices={results}
            value={result}
            setValue={setResult}
            field="result"
            url={`/marketingcontacts/${contact._id}`}
            none
            style={{ width: 150, minWidth: 150 }}
            size="small"
          />
        </div>
      )} */}
    </div>
  );
}

function DrawResult({ result }) {
  let color = "#2bb800";
  if (["Potential", "Reschedule"].includes(result)) color = "#c4b804"
  if (["Cannot contact"].includes(result)) color = "#c4043e";
  return (
    <div style={{display:"flex", gap:5}}>
      <div>Result: </div>
      <div style={{ color }}>{result}</div>
    </div>
  );
}