import {Button,Dialog,DialogActions,DialogContent,DialogTitle, TextField} from "@mui/material";
import React, { useContext } from "react";
import { StudentsContext } from "../../../../Context";
import SelectFromList from "../../components/SelectFromList";
import { startOfDay } from "date-fns";

import ChooseDateInline from "../../components/ChooseDateInline";
import AvailableClasses from "./AvailableClasses";

export default function MakeUpChoiceDialog(props) {
    const { open, setOpen, selected, timetable } = props;
     let choices = [...new Set(timetable.map((t) => t.level))];
    const [classType, setClassType] = React.useState(choices[0])
    const [dateChosen, setDateChosen] = React.useState(undefined)
    const [notes,setNotes] = React.useState("")
    const { studentsName } = useContext(StudentsContext)

    const daysOfWeek = timetable.filter(t => t.level === classType).map(t => parseInt(t.dayOfWeek))
    
    const enable = [function (date) { return daysOfWeek.includes(date.getDay()) && date>=startOfDay(Date.now()) }]
    
    if(!selected) return <React.Fragment></React.Fragment>

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Add makeup class for {studentsName(selected.student)}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SelectFromList
          choices={choices}
          value={classType}
          setValue={setClassType}
          style={{ width: 310, marginTop: 10 }}
          label="Type of class"
          size="small"
        />
        <ChooseDateInline
          value={dateChosen}
          setValue={setDateChosen}
          style={{ margin: 10 }}
          enable={enable}
        />
        <TextField
          multiline
          rows={2}
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          sx={{ mt: 1,mb:1 }}
        />
        <div
          style={{
            height: 150,
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 5,
            padding: 10,
            color: "#888",
            overflowY: "scroll",
          }}
        >
          {dateChosen ? (
            <AvailableClasses
              dateChosen={dateChosen}
              timetable={timetable.filter((t) => t.level === classType)}
            />
          ) : (
            <div>Choose a date to see the available classes</div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
      
      </DialogActions>
    </Dialog>
  );
}