import "./Home.css";
import React, { useContext, useState } from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import Messages from "./components/Messages";
/* import TeacherMakeUpClasses from "./components/TeacherMakeUpClasses"; */
import UpcomingMakeUpClasses from "./components/UpcomingMakeUpClasses";
import Info from "../../components/Info";
import DrawClass from "./components/DrawClass";
import DrawClassYesterday from "./components/DrawClassYesterday";
import { UserContext } from "../../../../Context";
import DrawTests from "./components/DrawTests";
import Days from "./components/Days";


export default function Home(props) {
  const { user } = useContext(UserContext);
  const [classes, setClasses] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
 /*  const [placementTests, setPlacementTests] = React.useState(undefined); */
   const [yesterdaysLessons, setYesterdaysLessons] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [tests,setTests] = useState([])
  

  React.useEffect(() => {
    net.get("/myclassestoday").then((r) => {
      setClasses(r.classes);
      setMessages(r.messages);
/*       setPlacementTests((prev) => r.placementTests); */
      setYesterdaysLessons(prev => r.yesterdaysLessons)
      setTests(prev=>r.tests)
      setLoaded((prev) => true);
    });
  }, []);

  if (!loaded || !user) return <Loading />;

  const removeMessage = (message) => {
    setMessages(messages.filter((m) => m._id !== message));
  };

  return (
    <div>
      <h1>Hello {user.name}</h1>
      <Days />
     {/*  {classes ? (
        <Info
          title="Todays classes"
          body={
            <div>
              {classes.map((c) => (
                <DrawClass htClass={c} />
              ))}
            </div>
          }
        />
      ) : null}
      {yesterdaysLessons && yesterdaysLessons.length > 0 ? (
        <Info
          title="Yesterday's classes"
          body={
            <div>
              {yesterdaysLessons.map((l) => (
                <DrawClassYesterday lesson={l} />
              ))}
            </div>
          }
        />
      ) : null}
      {tests && tests.length > 0 ? (
        <Info
          title="Tests"
          body={
            <div>
              {tests.map((t) => (
                <DrawTests test={t} />
              ))}
            </div>
          }
        />
      ) : null} */}
      {messages && messages.length > 0 ? (
        <div>
          <h3>Messages</h3>

          <Messages
            messages={messages}
            removeMessage={removeMessage}
            setMessages={setMessages}
            user={user}
          />
        </div>
      ) : null}
    </div>
  );
}
