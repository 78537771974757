import React from "react";
import HtTabs from "../../components/HtTabs";
import MonthView from "./MonthView";
import NewContacts from "./NewContacts";
import MyContacts from "./MyContacts";
import Tests from "./Tests";
import SearchContacts from "./SearchContacts";
import BonusLevel from "./BonusLevel";
import FollowUp from "./FollowUp";


export default function Marketing(props) {

  const labels = ["New", "Monthly", "My contacts", "Tests","Follow up", "Search"]
  const components = [<NewContacts />, <MonthView />, <MyContacts />, <Tests />,<FollowUp />, <SearchContacts />]

  return (
    <div>
      <HtTabs
        labels={labels}
        components={components}
        nextToTabs={<BonusLevel />}
     
      />
    </div>
  );
}