import React from "react";
import { net } from "../../../../database";
import { Button, TextField } from "@mui/material";
import VND from "../../components/VND";


export default function SalaryStat(props) {

    const [stats, setStats] = React.useState(undefined)
    const [newStat,setNewStat] = React.useState({year:2023,month:7,amount:0})
    React.useEffect(() => {
        net.get("/salarystats").then(res=>setStats(res))
    },[]);

    if(!stats) return <div>Loading...</div>

    return (<div>{stats.map(s => <div>
        {s.year} - {s.month} -- <VND value={s.amount} />
    </div>)}
    
        <TextField type="number" label="Year" value={newStat.year} onChange={(e) => setNewStat({ ...newStat, year: e.target.value })} />
        <TextField type="number" label="month" value={newStat.month} onChange={(e) => setNewStat({ ...newStat, month: e.target.value })} />
        <TextField type="number" label="amount" value={newStat.amount} onChange={(e) => setNewStat({ ...newStat, amount: e.target.value })} />
        
        <Button onClick={() => {
            net.put("/salarystats", newStat).then(res=>{
                setStats(prev =>[...prev, newStat])
                setNewStat({year:2023,month:7,amount:0})
            })
        }}>add</Button>
    
    </div>)
}