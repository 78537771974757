import React from "react";
import VND from "../../../../components/VND";

export default function Totals(props) {
  const { receipt } = props;

  return (
    <div>
      <hr />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <b>Tổng học phí cần thanh toán:</b>
        </div>
        <div>
          <b>
            <VND value={receipt.totalToPay} />
          </b>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <b>Học phí đã thanh toán:</b>
        </div>
        <div>
          <b>
            <VND value={receipt.paymentAmount} />
          </b>
        </div>
      </div>
      {receipt.credit > 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <b>Số tiền trả lại:</b>
          </div>
          <div>
            <b>
              <VND value={receipt.credit > 0 ? receipt.credit : 0} />
            </b>
          </div>
        </div>
      ) : null}

      {receipt.owed > 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <b>Học phí chưa hoàn thành:</b>
          </div>
          <div>
            <b>
              <VND value={receipt.owed - receipt.credit} />
            </b>
          </div>
        </div>
      ) : null}
    </div>
  );
}
