import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import React from "react";
import TextField from "@mui/material/TextField";


export default function ChooseDateForClass(props) {
  const { htClass, value, setValue, style } = props;
  if (!htClass || !htClass.classDays) return <div>Error</div>;
  const enable = [
    function (date) {
      let days = htClass.classDays.map((c) => c.day);
      return (
        days.includes(date.getDay()) &&
        date >=
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 1
          )
      );
    },
  ];
  return (
    <div>
      <Flatpickr
        value={value}
        onChange={(dates) => setValue((prev) => dates[0])}
        options={{ enable, dateFormat: "d/m/Y" }}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <TextField
              value={value}
              inputRef={ref}
              {...(new Date(value) < new Date() && {
                error: true,
                helperText: "This date is in the past",
              })}
              size="small"
              style={style}
            />
          );
        }}
      />
    </div>
  );
}
