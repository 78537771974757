import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { format } from "date-fns";
import { net } from "../../../../../database";
import Lang from "../../../components/Lang";
import { StaffContext, UserContext } from "../../../../../Context";


export function DrawNote(props) {
  const { date, recordedBy } = props.note;

  const [note, setNote] = React.useState(props.note.note);


  return (
    <div>
      <div className="dflex-sb">
        <div className="note-title">
          {<Lang w="recordedBy" />}:{" "}
          <b>{recordedBy && recordedBy.name ? recordedBy.name : "Unknown"}</b>{" "}
        </div>
        <div className="note-title">
          {date ? format(new Date(date), "dd/MM/yyyy h:mm aaa") : "No date"}
        </div>
      </div>
      <div className="note-text">{note}</div>
      {/*  <Button onClick={() => translateNote()}>Translate</Button> */}
    </div>
  );
}

export default function StudentNotes(props) {
  const [open, setOpen] = React.useState(false);
  /*   const [users, setUsers] = React.useState([]); */
  const {staff} = useContext(StaffContext)
  const {user} = useContext(UserContext)

  const [errors, setErrors] = React.useState({ note: false });
  const [newNote, setNewNote] = React.useState({
    note: "",
    userId: user._id,
    assigned: 0,
    assignedTo: "",
  });

  const { notes } = props.student;

  const insertNote = () => {
    if (newNote.note === "") {
      setErrors({ note: true });
    } else {
      net.post(`/student/${props.student._id}/notes/add`, newNote).then((r) => {
        /*    setNotes([...notes, r]); */
        let notes = props.student.notes;

        notes.push(r);
        props.setStudent({ ...props.student, notes });
        setOpen(false);
        setNewNote({
          note: "",
          userId: props.user._id,
          assigned: 0,
          assignedTo: "",
        });
        setErrors({ note: false });
      });
    }
  };
  // console.log(newNote.assigned);

  // console.log("as: " + newNote.assigned);
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button onClick={() => setOpen(true)} startIcon={<AddIcon />}>
            New Note
          </Button>
        </div>
      </div>
      <div style={{ padding: 5 }}>
        {notes.map((n) => (
          <DrawNote
            note={n}
            key={n._id}
          
          />
        ))}
      </div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>New note for {props.student.name}</DialogTitle>
        <DialogContent>
          <FormControl>
            <Stack spacing={2}>
              <TextField
                autoFocus
                margin="dense"
                type="text"
                variant="standard"
                fullWidth
                label="Note"
                value={newNote.note}
                multiline
                rows={3}
                onChange={(e) =>
                  setNewNote({ ...newNote, note: e.target.value })
                }
                style={{ width: 500 }}
                {...(errors.note && {
                  error: true,
                  helperText: "Please type a note",
                })}
              />
              <FormLabel id="demo-radio-buttons-group-label">
                Assign to
              </FormLabel>
              <RadioGroup
                value={newNote.assigned}
                name="radio-buttons-group"
                onChange={(e) =>
                  setNewNote({ ...newNote, assigned: e.target.value })
                }
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Don't assign"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Assign to: "
                />
              </RadioGroup>

              <TextField
                variant="standard"
                value={newNote.assignedTo}
                select
                onChange={(e) =>
                  setNewNote({ ...newNote, assignedTo: e.target.value })
                }
                {...(parseInt(newNote.assigned) === 0 && { disabled: true })}
              >
                {staff.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="success" onClick={insertNote}>
            Add note
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
