import React from "react";
import { useParams } from "react-router";
import StudentBills from "./components/StudentBills";
import Loading from "../../Loading";
import StudentClasses from "./components/StudentClasses";
import StudentNotes from "./components/StudentNotes";
import SalesInfo from "./components/SalesInfo";
import { net } from "../../../../database";
import StudentHistory from "./components/StudentHistory";
import "./student.css";
import StudentRecent from "./components/StudentRecent";
import BasicInfo from "./components/BasicInfo";
import HtTabs from "../../components/HtTabs";
import Lang from "../../components/Lang";
import Feedback from "./components/Feedback";
import useDB from "../../hooks/useDB";


  
export default function Student(props) {
  let { id } = useParams();
  const [student, setStudent, error, loading] = useDB(`/students/${id}`)

  const updateStudent = () => {
    net.post(`/student/${id}/update`, student).then((r) => console.log(r));
  };



  if (error) return <div>No Student with that id</div>;
  if (loading) return <Loading />;
 
  let labels = [
    "Recent",
    <Lang w="classes" />,
    <Lang w="general" />,
    student.unpaidBills.length > 0
      ? `Bills (${student.unpaidBills.length})`
      : `No Bills`,
    <Lang w="feedback" />,
    student.notes.length > 0
      ? "Notes"
      + `(${student.notes.length})`
      : <Lang w="noNotes"/>,<Lang w="history" />
  ];

  let tabs = [
    <StudentRecent student={student} />,
    <StudentClasses
      student={student}
      setStudent={setStudent}
    />,
    <SalesInfo
      student={student}
      setStudent={setStudent}
      updateStudent={updateStudent}
 
    />,
    <StudentBills
      studentId={student._id}
    />,
    <Feedback id={student._id} />,
    <StudentNotes
      student={student}  
      setStudent={setStudent}     
    />,
    <StudentHistory
      student={student}
      setStudent={setStudent}
    />,
  ];

  return (
    <div>
      <BasicInfo student={student} setStudent={setStudent} />
      <HtTabs labels={labels} components={tabs} />
    </div>
  );
}
