import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import Attendance from "./attendance/Attendance";
import MakeUpClassAttendance from "./attendance/MakeUpClassAttendance";

export default function MobileHome(props) {
  return (
    <div>
  
      <Routes>
        <Route path="/" element={<Home {...props} />} />
        <Route path="/attendance/:id" element={<Attendance {...props} />} />
        <Route
          path="/makeupclass/:id"
          element={<MakeUpClassAttendance {...props} />}
        />
      </Routes>
    </div>
  );
}
