import React from "react";
import "./chatbox.css";
import ChatInput from "./chatboxRComponents/ChatInput";
import Chats from "./chatboxRComponents/Chats";
import { net } from "../../../database";
import { Skeleton } from "@mui/material";
export default function ChatBoxR(props) {
  const {
    chatId, style={}
  } = props;
 
  const [chat, setChat] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/chat/${chatId}`).then((res) => {
      setChat(res);
    });
  }, [chatId]);

  if (!chat) return <Skeleton width={400} height={100} rounded />;

  return (
    <div className="chatbox-container" style={style}>
      <Chats chats={chat.chats} />

      <ChatInput
        chat={chat}
        setChat={setChat}
        chatId={chatId}
      />
    </div>
  );
}
