import React, { useContext } from "react";
import { CenterContext } from "../../../../../Context";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VND from "../../../components/VND";

export default function Sites({stats,field,fieldType=false}) {

    const {center} = useContext(CenterContext)

    return (
      <div>
        {center.locations
          .filter((l) => l.name !== "All locations")
          .map((l) => (
            <DrawLocation stats={stats} field={field} location={l} fieldType={fieldType} />
          ))}
      </div>
    );
}


function DrawLocation({ stats, field,location,fieldType }) {
    

    if(stats.length===1 || !stats[stats.length - 1] || !stats[stats.length - 1].locations) return <></>
    const ls = stats[stats.length - 1].locations.find(l => l.location=== location._id)
    const lsy = stats[stats.length - 2].locations.find(
      (l) => l.location === location._id
    );
    
  
  
    const amount = ls && ls[field] ? ls[field] : 0
    const yesterday = lsy && lsy[field] ? lsy[field] : 0
    const difference = amount-yesterday

    
    return (
      <div style={{ fontSize: 12, fontWeight: 200, marginLeft: 10 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {location.name}: {fieldType ? <VND value={amount} /> : amount}
          {difference >= 0 ? (
            <>
              <ArrowDropUpIcon style={{ color: "green" }} />
              {fieldType ? <VND value={difference} /> : difference}
            </>
          ) : (
            <>
              <ArrowDropDownIcon style={{ color: "red" }} />
              {fieldType ? <VND value={difference} /> : difference}
            </>
          )}
        </div>
      </div>
    );
}