import React from "react";
import Lang from "../../components/Lang";

export default function DrawTitles({showRating=true, showResult=false}) {


 return (
   <div
     style={{
       display: "flex",
       fontSize: 11,
       color: "#888",
       gap: 10,
       marginRight: 10,
     }}
   >
     <div style={{ width: 150 }}>SĐT</div>
     <div style={{ width: 100 }}>THÔNG TIN/ĐỊA ĐIỂM</div>
     <div style={{ width: 200 }}>
       <Lang w="notes" />
     </div>
     <div style={{ width: 150 }}>
       <Lang w="consultant" />
     </div>
     <div style={{ width: 150 }}>
       <Lang w="dateContacted" />
     </div>
     <div style={{ flexGrow: 0.9 }}>Kết quả</div>
     {showRating && <div style={{ width: 100, minWidth: 100 }}>Rating</div>}
     {showResult && <div style={{ width: 100, minWidth: 100 }}>Result</div>}
   </div>
 );
}