import React, { useContext, useState } from "react";
import MonthChoice from "../../components/MonthChoice";
import { ClassesContext } from "../../../../Context";
import StaffChooser from "../../components/StaffChooser";
import Staff from "../../components/Staff";
import useDB from "../../hooks/useDB";
import { Checkbox } from "@mui/material";



export default function Observation(props) {

    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())
    const { classes } = useContext(ClassesContext)
    const [observations, setObservations] = useDB(`/observations?year=${year}&month=${month}`)
    

    return (<div>
        <MonthChoice month={month} setMonth={setMonth} year={year} setYear={setYear} />
        {classes.filter(c => c.status === "Active").sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(c =>
            <div style={{display:"flex"}} >
                <div style={{width:200,minWidth:200}}>{c.name}</div>
                <div style={{width:200,minWidth:200}} ><Staff id={c.teacher} /></div>
                <Checkbox />
            </div>
        )}
</div>)
}