
import React, { useContext } from "react";
import { StaffContext } from "../../../Context";
import { Autocomplete, TextField } from "@mui/material";


export default function StaffAC(props) {
    const { staff,findStaff } = useContext(StaffContext)
    const {value,setValue} = props

    return (<Autocomplete
        options={staff.map(s=>({id: s._id, label: s.name}))}
        value={findStaff(value) ? findStaff(value).name : "" }
        onChange={(event, newValue) => {
          setValue(newValue.id);
        }}
        renderInput={(params) => <TextField {...params} label="Ordered By" />} />)
    
}