import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React from "react";
import { net } from "../../../../../database";

export default function ConfirmSendToLead(props) {
  const { open, setOpen,student,setStudent } = props;

  return (
    <Dialog open={open}>
          <DialogTitle>Send {student.name} to Potential</DialogTitle>
          <DialogContent>
              Are you sure you want to send {student.name} back to potential?
              (This will remove them from any classes you have added them to. It does not delete any bills you have added for this student, please check.)
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>Close</Button>
              <Button color="success" onClick={() => {
                  net.post(`/student/${student._id}/return-to-potential`, {}).then(res => {
                      setStudent(prev => ({ ...prev, ...res }))
                      setOpen(false)
                  })
        }}>Go</Button>
      </DialogActions>
    </Dialog>
  );
}