import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function ContactDialog(props) {
  let {
    open,
    setOpen,
    customer,
    copyBillsToClipboard,
    contact,
    setContact,
    contactCustomer,
  } = props;
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Contact {customer.name} about payment{" "}
        <div style={{ float: "right" }}>
          <Tooltip title="Copy Bills">
            <IconButton
              onClick={() => {
                copyBillsToClipboard();
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>

      <DialogContent>
        <div style={{ fontSize: 11 }}>Students</div>
        <div id="customersBills">
          {customer.students
            .filter((student) => student.unpaidBills.length > 0)
            .map((student) => (
              <div>
                <div
                  key={student._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Link to={`/student/${student._id}`} className="neat">
                      {" "}
                      {student.name}
                    </Link>
                  </div>
                  <div>
                    <NumberFormat
                      displayType="text"
                      value={student.unpaidBills.reduce(
                        (a, b) => a + b.amount,
                        0
                      )}
                      thousandSeparator
                      style={{ marginRight: 14 }}
                    />
                  </div>
                </div>
                {[...new Set(student.unpaidBills.map((b) => b.date))].map(
                  (dates) => (
                    <div key={dates}>
                      <div style={{ fontSize: 11, fontWeight: "bold" }}>
                        {format(new Date(dates), "dd/MM/yyyy")}
                      </div>
                      {student.unpaidBills
                        .filter((b) => b.date === dates)
                        .map((bill) => (
                          <div
                            key={bill._id}
                            className="dflex-sb"
                            style={{ fontSize: 12, marginRight: 14 }}
                          >
                            <div>{bill.description}</div>
                            <div>
                              <NumberFormat
                                value={bill.amount}
                                displayType="text"
                                thousandSeparator
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )
                )}
              </div>
            ))}
          {customer.credit > 0 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Credit</div>
              <NumberFormat
                displayType="text"
                value={customer.credit}
                thousandSeparator={true}
              ></NumberFormat>
            </div>
          ) : (
            ""
          )}
          <hr />
          <div style={{ fontSize: 11 }}>Total amount owed</div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <NumberFormat
              displayType="text"
              value={customer.owed}
              thousandSeparator={true}
            ></NumberFormat>
          </div>
        </div>
        <hr />
        <div style={{ fontSize: 11 }}>Last payment</div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {customer.payments && customer.payments.length > 0
              ? format(
                  new Date(
                    customer.payments[customer.payments.length - 1].date
                  ),
                  "dd/MM/yyyy"
                )
              : "No payments"}
          </div>
          {customer.payments && customer.payments.length > 0 ? (
            <NumberFormat
              displayType="text"
              value={customer.payments[customer.payments.length - 1].amount}
              thousandSeparator={true}
            />
          ) : (
            ""
          )}
        </div>

        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            label="Notes"
            rows={4}
            multiline
            onChange={(e) => setContact({ ...contact, notes: e.target.value })}
            value={contact.notes}
          ></TextField>
          <TextField
            label="Follow up date"
            type="Date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setContact({ ...contact, followUp: e.target.value })
            }
            value={contact.followUp}
          ></TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpen(false)}>Close</Button>
        <Button color="success" onClick={(e) => contactCustomer()}>
          Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
}
