import React, { useContext } from "react";
import { StudentsContext } from "../../../Context";
import { Avatar } from "@mui/material"


export default function StudentAvatar(props) {
    const { id, link } = props
    const {findStudent} = useContext(StudentsContext)
    const student = findStudent(id)
    return (<div style={{display:"flex", alignItems:"center", gap:10}}>
        <Avatar src={student.image ? `/images/${student.image}` : ``} />
        <div>{student.name}</div>
    </div>)
}