import { MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import { weekdays } from "../../../enums";
import { LanguageContext } from "../../../Context";

export default function DayOfWeekChoice(props) {
  let { value, setValue } = props;
  const {language} = useContext(LanguageContext)
  return (
    <TextField {...props} select value={value} onChange={(e) => setValue(e)}>
      {weekdays.map((day, i) => (
        <MenuItem value={i} key={`day${i}`}>
          {day[language]}
        </MenuItem>
      ))}
    </TextField>
  );
}
