import { Chip, TextField } from "@mui/material";
import React from "react";
import mut from "../hooks/mut";


/**
 * TimeChooser
 * 
 * A component that displays a list of times as chips, and allows the user to select one of them.
 * If the user selects "other", a text field is displayed for the user to enter a specific time.
 * If the user enters a time that is not in the list, the component will display a "filled" chip with the entered time.
 * 
 * @param {string} value the value of the component
 * @param {function} setValue a function to call when the value changes
 * @param {array} times an array of strings, each representing a time
 * @param {string} label an optional label to display above the field
 * @param {string} size an optional parameter to set the size of the component. Valid values are "normal" and "small".
 * @param {string} url an optional parameter to set the url to post to when the value changes
 * @param {string} field an optional parameter to set the field to update when the value changes
 * 
 * @returns a JSX element
 */
export default function TimeChooser(props) {
    const { value, setValue,times,label,size="normal",url,field } = props
    const [other, setOther] = React.useState(false)

     const change = (val) => {
       if (url && field) {
         mut("post", url, { [field]: val });
       }
       setValue(val);
     };
    
    if (!times) return (
      <TextField
        value={value || ""}
        onChange={(e) => change(e.target.value)}
        type="time"
        label={label}
        size={size}
        InputLabelProps={{ shrink: true }}
      />
    );

    return (<div style={{ border: "1px solid #bbb", borderRadius: 5 }}>
        {label ? <div style={{fontSize:12,padding: 3,paddingLeft:10,color:"#666"}}>{label}</div>: null}
        <div style={{ margin: 5 }}>{times.map(t => <Chip label={t} variant="outlined" style={{margin:3}} onClick={() => { setValue(t);setOther(false) }} {...(t===value && !other && {
        variant:"filled" 
        })} />)} <Chip label="other" variant={other ? "filled" : "outlined"} color="info" onClick={() => setOther(true)} /></div>
        <div style={{margin:5}}>{other ? <TextField value={value || ""} onChange={(e)=>change(e.target.value)} type="time" fullWidth size="small"/>: null}</div>
</div>)
}