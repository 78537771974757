import React, { useContext, useState } from "react";
import { CenterContext, ClassesContext } from "../../../Context";
import { Button } from "@mui/material";
import { addDays, addMonths, getDaysInMonth } from "date-fns";


export default function CalculateFees({selectedClass = "645ca2997718b0f27ac7e43b"}) {
    const { findClass } = useContext(ClassesContext)
    const htClass = findClass(selectedClass)
    const [sMonths, setSMonths] = useState(1)
    console.log(htClass)
    if(!htClass) return <></>
    const months = [1, 2, 3, 4, 5, 6]
    

    return (<div><div style={{display:"flex",gap:5}}>
        {months.map(month => <Button {...(month===sMonths && {
        variant : "contained" 
        })}
        onClick={()=>setSMonths(month)}
        >{month} month{month > 1 ? "s" : null}</Button>)}</div>
        <DrawMonths htClass={htClass} months={sMonths} />
</div>)
}

function DrawMonths(props){


const {months,htClass} = props
   
    const { center } = useContext(CenterContext)
     const { count, dates } = calculateClasses(months - 1, htClass,center.holidays)
    return <div>
        <div>{count} {count * htClass.currentFee}</div>
        {dates.map(d => <div>{d.toString()}</div>)}
    </div>
}

function calculateClasses(months, htClass, holidays) {
    let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),0,0,0)
    let endDate = addMonths(startDate, months)
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), getDaysInMonth(endDate), 0, 0, 0)
    const days = htClass.classDays.map(cd => cd.day)
    let count = 0
    let dates = []
    
    while (startDate <= endDate) {
        if (days.includes(startDate.getDay()) && checkHolidays(startDate, holidays)) {
            count++
            dates.push(startDate)
        }
        startDate = addDays(startDate, 1)
    }

    //console.log(endDate)

    return { count, dates }
}

function checkHolidays(inDate, holidays) {

    for (let holiday of holidays) {
        if (inDate >= new Date(holiday.range[0]) && inDate <= new Date(holiday.range[1])) {
            console.log(holiday.name)
            return false
        }
    }
    return true
}