import { MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import { StaffContext } from "../../../Context";

export default function StaffChooserContext(props) {
  const { value, setValue, save, filter, none, active } = props;
  const { staff } = useContext(StaffContext);
  let st = [];
  if (filter) {
    for (let sta of staff) {
      let s = st.find((s) => s.id === sta._id);
      if (!s) {
        let hit = false;
        if (sta.roles) {
          for (let role of sta.roles) {
            if (filter.includes(role)) hit = true;
          }
          if (active) {
             if (hit && sta.status===0) st.push({ id: sta._id, label: sta.name });
          } else {
             if (hit) st.push({ id: sta._id, label: sta.name });
          }
         
        }
      }
    }
  } else {
    if (active) {
        st = staff.filter(s=>s.status===0).map((s) => ({ id: s._id, label: s.name }));
  
    } else {
      st = staff.map((s) => ({ id: s._id, label: s.name }));
    }
    }
  

  st = st.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

  return (
    <TextField
      value={value}
      onChange={(e) => {
        if (save) save({ user: e.target.value });
        setValue(e.target.value);
      }}
      select
      {...props}
    >
      {none ? <MenuItem value={""}>None</MenuItem> : null}
      {st.map((staff) => (
        <MenuItem value={staff.id} key={staff.id}>
          {staff.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
