import React from "react";
import { format, parseISO } from "date-fns";
import VND from "../../../../components/VND";
import { Checkbox } from "@mui/material";
import L from "../../../../components/Lang";


export default function BillsToBePaid(props) {
  let { student, payment, setPayment } = props;
  let datesArray = [...new Set(student.unpaidBills.map((b) => b.date))];

  return (
    <div>
      {datesArray.map((dates) => (
        <div key={dates}>
          <div style={{ fontSize: 11, fontWeight: "bold" }}>
            {format(parseISO(dates), "dd/MM/yyyy")}
          </div>
          {student.unpaidBills
            .filter((b) => b.date === dates)
            .map((bill) => (
              <div
                key={bill._id}
                className="dflex-sb"
                style={{
                  fontSize: 12,
                  marginRight: 14,
                  color: !payment.excludedBills.includes(bill._id)
                    ? "black"
                    : "grey",
                }}
              >
                <div
                  className="dflex-sb"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={!payment.excludedBills.includes(bill._id)}
                    size="small"
                    sx={{ m: 0, p: 0, pr: 2 }}
                    onClick={(e) => billCheck(e, bill, payment, setPayment)}
                  />
                  <div>{bill.description}</div>
                </div>
                <div>
                  <VND value={bill.amount} />
                </div>
              </div>
            ))}
        </div>
      ))}
      <hr />
      <div
        className="dflex-sb"
        style={{
          fontSize: 12,
          marginRight: 14,
          fontWeight: "bold",
        }}
      >
        <div>
          <L w="total" />
        </div>
        <div>
          <VND value={student.unpaidBills.reduce((a, b) => a + b.amount, 0)} />
        </div>
      </div>
    </div>
  );
}

function billCheck(e, bill, payment, setPayment) {
  if (e.target.checked) {
    let excludedBills = payment.excludedBills.filter((b) => b !== bill._id);
    let amount = recalcBills({ ...payment, excludedBills });
    setPayment((prev) => ({...prev,amount,excludedBills}));
    return;
  }
  let excludedBills = [...payment.excludedBills,bill._id];
  let amount = recalcBills({ ...payment, excludedBills });
  setPayment((prev) => ({ ...prev, amount, excludedBills }));
  return
}

function recalcBills(payment) {
  let amount = 0;
  payment.students
    .filter((student) => !payment.excludedStudents.includes(student._id))
    .forEach((student) => {
      amount += student.unpaidBills
        .filter((bill) => !payment.excludedBills.includes(bill._id))
        .reduce((a, b) => a + b.amount, 0);
    });
  return amount;
}