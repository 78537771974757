import React from "react";
import { format } from "date-fns";
import { net } from "../../../database";
import { IconButton, Paper } from "@mui/material";
import Staff from "./Staff";
import PopoutDialog from "./notescomponents/PopoutDialog";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";


export default function Notes(props) {
  const {notes, route, success, style} = props
  const [open, setOpen] = React.useState(false);
  const [openD, setOpenD] = React.useState(false);
  const focus = React.useRef(null);

  const keyDown = (e) => {
 
    if (e.keyCode === 13) {
      e.preventDefault();
      net
        .post(route, { note: e.target.innerText })
        .then((res) => success(res));
      e.target.innerText = "";
    }
  };

  return (
    <div className="lead-notes-outline" style={style}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <div className="lead-notes-title">
          Notes ({notes ? notes.length : 0})
        </div>{" "}
        <IconButton size="small" onClick={()=>setOpenD(true)}>
          <OpenInFullIcon sx={{fontSize:11,color:"white"}} />
        </IconButton>
      </div>
      <div className="leads-notes-container">
        {notes
          ? notes.map((note) => (
              <Paper height={2} sx={{ ml: 0, mt: 1, p: 0.7 }}>
                <div className="lead-notes">{note.note}</div>
                <div className="small-text" style={{ textAlign: "right" }}>
                  {<Staff id={note.user} />} at{" "}
                  {format(new Date(note.createdAt), "dd/MM/yyyy h:mm aaa")}
                </div>
              </Paper>
            ))
          : "No notes"}
      </div>
      {open ? (
        <div
          className="enter-note-div"
          contentEditable
          onKeyDown={(e) => {
            keyDown(e);
          }}
          onBlur={() => setOpen(false)}
          ref={(input) => {
            open && input && input.focus();
          }}
        ></div>
      ) : (
        <div
          className="enter-note-div"
          style={{ color: "#ccc" }}
          onClick={() => {
            setOpen(true);
            focus.current?.focus();
          }}
        >
          Add a new note
        </div>
      )}
      <PopoutDialog open={openD} setOpen={setOpenD} {...props} keyDown={keyDown} />
    </div>
  );
}
