import { format } from "date-fns";
import { formatVND } from "../../../functions";

const { ClipboardItem } = window;

export function copyBillsToClipboard(customer) {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = 500
  let height = 70
  customer.students.filter(
    (student) => student.unpaidBills && student.unpaidBills.length > 1).forEach(student => {
      height += 50
      height += student.unpaidBills.length * 20
      if(customer.students.filter(
    (student) => student.unpaidBills && student.unpaidBills.length > 1).length>1) height+=50
    })
  canvas.height=height
  paintBills(customer, ctx);

  canvas.toBlob(function (blob) {
    navigator.clipboard.write([
      new ClipboardItem(
        Object.defineProperty({}, blob.type, {
          value: blob,
          enumerable: true,
        })
      ),
    ]);
  });
}

export function copyReceiptToClipboard(receipt,user) {let canvas = document.createElement("canvas");
let ctx = canvas.getContext("2d");
canvas.width = 500;
let height = 700;
receipt.students
  .filter((student) => student.paidBills && student.paidBills.length > 1)
  .forEach((student) => {
    height += 50;
    height += student.paidBills.length * 20;
    if (
      receipt.students.filter(
        (student) => student.paidBills && student.paidBills.length > 1
      ).length > 1
    )
      height += 50;
  });
canvas.height = height;
paintReceipt(receipt, ctx,user);

canvas.toBlob(function (blob) {
  navigator.clipboard.write([
    new ClipboardItem(
      Object.defineProperty({}, blob.type, {
        value: blob,
        enumerable: true,
      })
    ),
  ]);
});}

function paintBills(customer, ctx) {
  setup(ctx);

  let line = 40;
  let count = 1;
  for (let student of customer.students.filter(
    (student) => student.unpaidBills && student.unpaidBills.length > 1
  )) {
    drawName(ctx, student.name, line);
    line += 10;
    drawLine(ctx, line);

    for (let b of student.unpaidBills) {
      line += 20;
      drawBill(b, ctx, line);
    }
    if (
      customer.students.filter(
        (student) => student.unpaidBills && student.unpaidBills.length > 1
      ).length > 1
    ) {
      line += 10;
      drawLine(ctx, line);
      line += 20;
      drawSubTotal(
        ctx,
        line,
        student.unpaidBills.reduce((a, b) => a + b.amount, 0)
      );
      line += 10;
      drawLine(ctx, line);
    }
    if (
      count <
      customer.students.filter(
        (student) => student.unpaidBills && student.unpaidBills.length > 1
      ).length
    ) {
      line += 40;
    } else {
      line += 20;
    }
  }
  drawLine(ctx, line);
  line += 20;
  drawTotal(ctx, line, customer.owed);
  line += 10;
  drawLine(ctx, line);
}

function paintReceipt(receipt, ctx,user) {
  setup(ctx);

  const img = document.getElementById("htlogo");
  
  ctx.drawImage(img,25,25);
  


  let line = 340;
  let count = 1;
  drawAddress(ctx, line)
  line += 40
  drawTitle(ctx, line)
  line += 40
  drawDateAndCashier(ctx,line,receipt.date,user.name)
  line += 40
  drawLine(ctx, line)
  line+=30

  for (let student of receipt.students.filter(
    (student) => student.paidBills && student.paidBills.length > 1
  )) {
    drawName(ctx, student.name, line);
    line += 10;
    drawLine(ctx, line);

    for (let b of student.paidBills) {
      line += 20;
      drawBill(b, ctx, line);
    }
    if (
      receipt.students.filter(
        (student) => student.paidBills && student.paidBills.length > 1
      ).length > 1
    ) {
      line += 10;
      drawLine(ctx, line);
      line += 20;
      drawSubTotal(
        ctx,
        line,
        student.paidBills.reduce((a, b) => a + b.amount, 0)
      );
      line += 10;
      drawLine(ctx, line);
    }
    if (
      count <
      receipt.students.filter(
        (student) => student.paidBills && student.paidBills.length > 1
      ).length
    ) {
      line += 40;
    } else {
      line += 20;
    }
  }
  drawLine(ctx, line);
  line += 20;
  receiptTotal(ctx, line,"Tổng học phí cần thanh toán:", receipt.totalToPay);
  line += 10;
  drawLine(ctx, line);
  line += 20;
  receiptTotal(ctx, line, "Học phí cần thanh toán:", receipt.paymentAmount);
  line += 10;
  drawLine(ctx, line);
  if (receipt.credit) {
    line += 20;
    receiptTotal(ctx, line, "Số tiền trả lại:", receipt.credit);
    line += 10;
    drawLine(ctx, line);
  }
  if (receipt.owed) {
    line += 20;
    receiptTotal(ctx, line, "Học phí chưa hoàn thành:", receipt.owed - receipt.credit);
    line += 10;
    drawLine(ctx, line);
  }
  drawLine(ctx, line);
  line += 50
  drawFooter(ctx,line)
}

function drawAddress(ctx, line) {
   ctx.font = "12px arial";
   ctx.textAlign = "center";
   ctx.fillText("51B Kẻ Vẽ, 80 Kẻ Vẽ, ph: 036-860-5499", 250, line);
}

function drawTitle(ctx, line) {
   ctx.font = "bold 14px arial";
   ctx.textAlign = "center";
   ctx.fillText("HÓA ĐƠN HỌC PHÍ", 250, line);
}

function drawFooter(ctx, line) {
   ctx.font = "14px arial";
   ctx.textAlign = "center";
   ctx.fillText("Trân trọng cảm ơn quý phụ huynh!", 250, line);
}

function drawDateAndCashier(ctx, line, inDate, cashier) {
  ctx.font = "14px arial";
  ctx.textAlign = "left";
  ctx.fillText(format(new Date(inDate), "dd/MM/yyyy h:mmaaa"), 20, line);
  line += 20
  ctx.fillText(`Thu ngân: ${cashier}`, 20, line);
}

function drawTotal(ctx, line, total) {
  ctx.font = "bold 14px arial";
  ctx.textAlign = "left";
  ctx.fillText("Tổng học phí cần thanh toán:", 20, line);
  ctx.textAlign = "right";
  ctx.fillText(formatVND(total) + "đ", 480, line);
}

function receiptTotal(ctx, line,text, total) {
  ctx.font = "bold 14px arial";
  ctx.textAlign = "left";
  ctx.fillText(text, 20, line);
  ctx.textAlign = "right";
  ctx.fillText(formatVND(total) + "đ", 480, line);
}

function drawSubTotal(ctx, line, total) {
  ctx.font = "14px arial";
  ctx.textAlign = "left";
  ctx.fillText("học phí chưa thanh toán:", 20, line);
  ctx.textAlign = "right";
  ctx.fillText(formatVND(total) + "đ", 480, line);
}

function drawName(ctx, name, line) {
  ctx.textAlign = "left";
  ctx.font = "bold 14px arial";
  ctx.fillText(name, 20, line);
}

function drawBill(b, ctx, line) {
  ctx.font = "14px arial";
  ctx.textAlign = "left";
  ctx.fillText(b.description, 20, line);
  ctx.textAlign = "right";
  ctx.fillText(formatVND(b.amount) + "đ", 480, line);
}

function drawLine(ctx, line) {
  ctx.fillStyle = "#aaa";
  ctx.fillRect(20, line, 460, 1);
  ctx.fillStyle = "black";
}

function setup(ctx) {
  ctx.width = 600;
  ctx.height = 800;
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, 600, 800);
  ctx.fillStyle = "black";
}

/* module.exports = { copyBillsToClipboard, copyReceiptToClipboard }; */
