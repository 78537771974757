import React from "react";
import VND from "../../../../components/VND";

export default function PaidBills(props) {
  const { receipt } = props;
  console.log(receipt);
  return (
    <div>
      {receipt.students
        .filter((student) => student.paidBills)
        .map((s) => (
          <div>
            <div style={{ fontWeight: "bold" }}>{s.name}</div>
            {s.paidBills.map((b) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>{b.description}</div>
                <div>{<VND value={b.amount} />}</div>
              </div>
            ))}

            {receipt.students.filter((student) => student.paidBills).length >
            1 ? (
              <div>
                <hr />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>học phí chưa thanh toán:</div>
                  <div>
                    {
                      <VND
                        value={s.paidBills.reduce((a, b) => a + b.amount, 0)}
                      />
                    }
                  </div>
                </div>
                <hr />
              </div>
            ) : null}
          </div>
        ))}
    </div>
  );
}
