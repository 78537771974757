import React, { useContext } from "react";
import { StaffContext } from "../../../../../Context";



export default function PrintTimetable({classes,startTimes,locations}) {

    

    return (<div>{startTimes.map(st => <ClassesAtTime time={st} classes={classes.filter(c=>c.makeUpClass || c.extraClass ? c.startTime.substring(0,5)===st : c.classDay.startTime.substring(0,5)===st)} />)}</div>)
}

function ClassesAtTime({ time, classes }) {
    
   const {findStaff} = useContext(StaffContext)
    return <div>
        <table className="table-sm" style={{ width: "100%" }}>
            <tr>
         <th colSpan={5} style={{textAlign:"center", background:"#888", color: "white"}}>
               {time}
                </th>
            </tr>
            <tr>
                <th style={{width: "15%"}}>Name</th>
                <th  style={{width: "15%"}}>Teacher</th>
                <th  style={{width: "15%"}}>Room</th>
                <th style={{ width: "15%" }}>Extra teachers</th>
                 <th  style={{width: "15%"}}>Notes</th>
            </tr>
            {classes.map(c => <tr>
                <td  style={{width: "15%"}}>{getName(c)}</td>
                <td  style={{width: "15%"}}>{getTeacher(c,findStaff)}</td>
                <td  style={{width: "15%"}}>{getRoom(c)}</td>
                <td style={{ width: "15%" }}>{c.makeUpClass || c.extraClass ? null : c.classDay.otherTeachers.map(t => <div style={{ display: "flex" }}><div style={{ width: 100 }}>{findStaff(t.teacher).name}</div> <div>{t.startTime} - {t.endTime}</div></div>)}</td>
                <td  style={{width: "40%"}}>{c.lesson && c.lesson.adminNotes ? c.lesson.adminNotes : ""}</td>
            </tr>)}    
        </table>
        </div>
}


function getRoom(htClass) {
    if (htClass.makeUpClass || htClass.extraClass) return htClass.room || "No room"
    return htClass.classDay.room || "No room"
}

function getName(htClass) {
    if (htClass.makeUpClass) return "MUC: " + htClass.level
    if (htClass.extraClass) return "Extra"
    return htClass.name
}

function getTeacher(htClass, findStaff) {
    if (htClass.lesson) {
        return findStaff(htClass.lesson.teacher).name
    }
    if (htClass.teacher) {
        return findStaff(htClass.teacher).name
    }
    return "No teacher"
}
