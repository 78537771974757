import React, { useContext } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { UserContext } from "../../../../Context";
import DrawContact from "./DrawContact";
import DrawTitles from "./DrawTitles";


export default function MyContacts(props) {

const {user} = useContext(UserContext)

const [contacts, setContacts, error, loading] = useDB(`/sales-person/${user._id}`);


if (loading) return <Loading />;
if (error) return <Error />;


console.log(contacts);


return (
    <div>
        <DrawTitles />
    {contacts.map((c) => (
      <DrawContact contact={c} key={c._id} />
    ))}
  </div>
);
}