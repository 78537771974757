import React from "react";
import FormatDate from "../../components/FormatDate";
import { Stack, TextField } from "@mui/material";
import ChooseDateForClass from "../../components/ChooseDateForClass";
import Info from "../../components/Info";

export default function LeadClass({ lead }) {
  //const {l,language} = props;
  console.log(lead);
  return (
    <div style={{ marginLeft: 10, width: 300 }}>
      <Stack spacing={1}>
        <Info title="Class" body={lead.classes.map((c) => c.name).toString()} />
        <Info
          title="Date of first class"
          body={
            <ChooseDateForClass
              htClass={lead.classes[0]}
              value={lead.firstClassDate}
            />
          }
        />
        <Info
          title="Attended classes"
          body={lead.attendance ? lead.attendance.length : 0}
        />
      </Stack>
    </div>
  );
}
