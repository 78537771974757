import { Avatar } from "@mui/material";
import React, { useContext } from "react";
import { StudentsContext } from "../../../Context";
import SLink from "./SLink";



export default function AvatarSLink({id}) {
    const { findStudent } = useContext(StudentsContext)
    const student = findStudent(id)
    if(!student) return <div>No student</div>

    return (<div style={{ display: "flex", gap: 5, padding: 5 }}><Avatar src={student.image ? student.image : ''} alt={student.name} /><SLink id={id} showPhone /> </div>)
}