import { format } from "date-fns";
import React from "react";

/**
 * 
 * @param {string | date} value the date to be formated 
 * @returns div with formated date and time
 */

export default function FormatDateTime(props) {
  let { value } = props;
  if (!value) return <div>No date</div>;
  let dateIn = value;
  if (typeof value === "string") dateIn = new Date(value);
  return <div {...props}>{format(dateIn, "dd/MM/yyyy h:mmaaa")}</div>;
}
