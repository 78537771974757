import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import { useTheme } from "@mui/styles";
import HtClass from "../content/components/HtClass";
import { CustomersContext, StudentsContext } from "../../Context";
import PhoneNumber from "../content/components/PhoneNumber";

export default function SearchStudents(props) {
  const theme = useTheme();
  const { search } = props;
  const { customers, setCustomers } = useContext(CustomersContext) || [];
  const { students, setStudents } = useContext(StudentsContext) || [];

  return (
    <div className="search-students">
      {students
        .filter(
          (s) =>
            s.name?.toLowerCase().includes(search.toLowerCase()) ||
            s.phone?.includes(search)
        )
        .sort(
          (a, b) =>
            a.name.localeCompare(b.name) && a.status.localeCompare(b.status)
        )
        .slice(0, 50)
        .map((s) => (
          <Link
            to={`/student/${s._id}`}
            className="student-search-link"
            key={s._id}
          >
            <Box
              className="student-search"
              onClick={() => props.setSearch("")}
              sx={[
                { backgroundColor: "background.default" },
                theme.palette.mode === "light" && {
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                },
                theme.palette.mode === "dark" && {
                  "&:hover": {
                    backgroundColor: "#222222",
                  },
                },
              ]}
            >
              <div
                style={{
                  width: 40,
                  color: theme.palette.text.primary,
                }}
              >
                <SchoolIcon />
              </div>
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    color:
                      s.status === "Stopped" || s.status === "Lead"
                        ? theme.palette.error.main
                        : s.status === "Pending"
                        ? theme.palette.warning.main
                        : theme.palette.text.primary,
                  }}
                >
                  {s.name}
                </div>
                <div style={{ fontSize: 12, color: "grey" }}>
                  <PhoneNumber value={s.phone} />
                </div>
              </div>
              <div
                style={{
                  width: 150,
                  textAlign: "right",
                  display: "flex",
                  color: theme.palette.text.primary,
                }}
              >
                {s.classes
                  ? s.classes.map((s) => <HtClass htClass={s} key={s} />)
                  : ""}
              </div>
            </Box>
          </Link>
        ))}
      {customers
        .filter(
          (c) =>
            c.ref?.toLowerCase().includes(search.toLowerCase()) ||
            c.name?.toLowerCase().includes(search.toLowerCase()) ||
            c.phone?.includes(search)
        )
        .slice(0, 50)
        .map((s) => (
          <Link
            to={`/customer/${s._id}`}
            className="student-search-link"
            key={s._id}
          >
            <Box
              className="student-search"
              onClick={() => props.setSearch("")}
              sx={[
                { backgroundColor: "background.default" },
                theme.palette.mode === "light" && {
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                },
                theme.palette.mode === "dark" && {
                  "&:hover": {
                    backgroundColor: "#222222",
                  },
                },
              ]}
            >
              <div style={{ width: 40, color: theme.palette.text.primary }}>
                <PersonIcon />
              </div>
              <div style={{ flexGrow: 1 }}>
                <div>{s.name}</div>
                <div style={{ fontSize: 12, color: "grey" }}>
                  <PhoneNumber value={s.phone} />
                </div>
              </div>
              <div
                style={{
                  width: 150,
                  textAlign: "right",
                  display: "flex",
                  color: theme.palette.text.primary,
                }}
              >
                {s.ref}
              </div>
            </Box>
          </Link>
        ))}
    </div>
  );
}
