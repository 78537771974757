import React from "react";
import Loading from "../../Loading";
import { net } from "../../../../database";
import { TextField } from "@mui/material";

export default function RouteRecord(props) {
  //const {l,language} = props;
  const [rr, setRR] = React.useState(undefined);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    net
      .get("/route-record")
      .then((res) =>
        setRR(
          res.sort((a, b) =>
            a.count > b.count ? 1 : b.count > a.count ? -1 : 0
          )
        )
      );
  }, []);

  if (!rr) return <Loading />;
  console.log(rr);
  return (
    <div>
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        label="Search"
        size="small"
      />
      {rr
        .filter((r) => r.path.includes(search)).sort((a,b) => (b.count-a.count))
        .map((route) => (
          <div style={{ display: "flex" }}>
            <div style={{ width: 300 }}>{route.path}</div>
            <div style={{ width: 50 }}>{route.count}</div>
            <div style={{ fontSize: 12 }}>
              {route.users.map((user) => (
                <div>
                  {user.name} {user.count}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}
