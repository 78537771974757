
const { ClipboardItem } = window;

/**
 * 
 * @param {function} paintFunction function(ctx) to draw on the canvas  
 * @param {number} width
 * @param {number} height
 */

export function copyToClipboard(paintFunction,width,height) {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  ctx.fillStyle = "white"
  ctx.fillRect(0,0,width,height)
  let trimheight = paintFunction(ctx)
let canvas2 = document.createElement("canvas");
    let ctx2 = canvas2.getContext("2d")
    canvas2.width = 400
    canvas2.height = trimheight
    ctx2.drawImage(canvas,0,0)
  canvas2.toBlob(function (blob) {
    navigator.clipboard.write([
      new ClipboardItem(
        Object.defineProperty({}, blob.type, {
          value: blob,
          enumerable: true,
        })
      ),
    ]);
  });
}
