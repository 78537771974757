import React from "react";
import { exportFile } from "table-xlsx";
import { Button, Table } from "antd";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { weekdays } from "../../../../enums";
import { format } from "date-fns";

export default function ClassesReport(props) {
  //const {l,language} = props;
  const [classes, setClasses] = React.useState(undefined);
  React.useEffect(() => {
    net.get("/classes").then((res) => {
      setClasses(res);
    });
  }, []);

  if (!classes) return <Loading />;
  console.log(classes);
  const dataSource = [];
  /* {
      key: "1",
      name: "Tim",
      age: 32,
      address: "some street",
    },
    {
      key: "2",
      name: "Dave",
      age: 42,
      address: "another street",
    },
  ]; */

  classes.forEach((htClass, index) => {
    let location = props.center.locations.find(
      (l) => l._id === htClass.location
    );
    let c = {
      key: index,

      location: location.name,
      name: htClass.name,
      teacher:
        htClass.teacher && htClass.teacher.name
          ? htClass.teacher.name
          : "Unknown",
      students: htClass.students.length,
      books: htClass.books.map((b) => b.name).toString(),
      room: [...new Set(htClass.classDays.map((cd) => cd.room))].toString(),
      timetable: htClass.classDays.map(
        (cd, i) =>
          `${weekdays[cd.day][1]}: ${cd.startTime.substring(
            0,
            5
          )}-${cd.endTime.substring(0, 5)}${
            i < htClass.classDays.length - 1 ? "," : ""
          } `
      ),
      startDate: classes.startDate
        ? format(new Date(htClass.startDate), "dd/MM/yyyy")
        : "",
      endDate: classes.startDate
        ? format(new Date(htClass.endDate), "dd/MM/yyyy")
        : "",
    };
    dataSource.push(c);
  });

  const columns = [
    {
      title: "Cơ sở",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "TÊN LỚP",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Giáo trình sử dụng",
      dataIndex: "books",
      key: "books",
    },
    {
      title: "Phòng học",
      dataIndex: "room",
      key: "room",
    },

    {
      title: "Sĩ số",
      dataIndex: "students",
      key: "students",
    },
    {
      title: "Tên GV Việt",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Ngày học",
      dataIndex: "timetable",
      key: "timetable",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      key: "endDate",
    },
  ];

  const onExportFileClick = () => {
    exportFile({
      columns: columns,
      dataSource: dataSource,
      fileName: "Classes.xlsx",
    });
  };
  return (
    <div>
      <Button onClick={() => onExportFileClick()}>Export to Excel</Button>
      <div>
        <Table
          style={{ marginTop: 20 }}
          dataSource={dataSource}
          columns={columns}
          bordered
          size={"small"}
          pagination={false}
        />
      </div>
    </div>
  );
}
