import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import React, { useContext } from "react";
import { net } from "../../../../../database";
import { CenterContext } from "../../../../../Context";

export default function ChangeStatusDialog(props) {
  const [reason, setReason] = React.useState("");
  const [stoppedReasons,setStoppedReasons] = React.useState([])
  const [returnDate, setReturnDate] = React.useState("");
  const [status, setStatus] = React.useState(props.student.status);
  const {center} = useContext(CenterContext)
  const handleClose = () => {
    console.log(stoppedReasons)
    let body = {
      status,
    };
    if (status === "Stopped" || status === "Pending" || status === "Lead")
      body.reason = reason;
    if (status === "Pending") body.returnDate = returnDate;
    if(["Stopped","Lead"].includes(status)) body.stoppedReasons = stoppedReasons.map(r=>r.id)
    net
      .post(`/student/${props.student._id}/changestatus`, body)
      .then((student) => {
       
        props.setStudent({
          ...props.student,
          statusHistory: student.statusHistory,
          status,
        });
        props.setOpen(false);
        setReason("");
        setStoppedReasons([])
      });
    props.setOpen(false);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setStatus(e.target.value);
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Change Status for {props.student.name}</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            value={status}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="Active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="New"
              control={<Radio />}
              label="New"
            />
            <FormControlLabel
              value="Pending"
              control={<Radio />}
              label="Pending"
            />

            <FormControlLabel
              value="Lead"
              control={<Radio />}
              label="Lead"
            />
            <FormControlLabel
              value="Stopped"
              control={<Radio />}
              label="Stopped"
            />
          </RadioGroup>
        </FormControl>
        <Stack
          spacing={2}
          sx={{ mt: 1 }}
        >
          <div>
            {status === "Active" || status==="New" ? (
              ""
            ) : (
                <div>
                  <Stack spacing={2}>
                    <Autocomplete value={stoppedReasons} onChange={(e,newValue)=>setStoppedReasons(newValue)} options={center.stoppedReasons.map(r=> ({id: r._id, label: r.name}))} multiple renderInput={(params) => <TextField {...params} label="Reason" />} />
              <TextField
                label={`Details...`}
                rows={3}
                fullWidth
                multiline
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                    />
                    </Stack>
                  </div>
            )}
          </div>
          <div>
            {status === "Pending" ? (
              <TextField
                label="Return date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={returnDate}
                onChange={(e) => setReturnDate(e.target.value)}
              />
            ) : (
              ""
            )}
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { props.setOpen(false); setReason(""); setStoppedReasons([]) }} color="error">Close</Button>
        <UpdateButton status={status} handleClose={handleClose} stoppedReasons={stoppedReasons} returnDate={returnDate}/>
      </DialogActions>
    </Dialog>
  );
}

function UpdateButton({ status, handleClose, stoppedReasons, returnDate }) {
  
if(status==="New" || status==="Active") return (
  <Button
    onClick={handleClose}
    color="success"
  >
    Update
  </Button>
  );
if(status==="Pending") return (
  <Button
    onClick={handleClose}
    color="success"
    {...(!returnDate && {
      disabled: true,
    })}
  >
    Update
  </Button>
);

  return (
    <Button
      onClick={handleClose}
      color="success"
      {...(stoppedReasons.length === 0 && {
        disabled: true,
      })}
    >
      Update
    </Button>
  );
}