import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { net } from "../../../../database";

export default function AddConsultantDialog(props) {
  const { open, setOpen, lead, staff, setLeads } = props;
  const [consultant, setConsultant] = React.useState("");

  if (!staff || !lead) return <div></div>;

  const go = () => {
    net.post(`/leads/${lead._id}`, { stage: 2, consultant }).then((res) => {
      setConsultant("");
      setLeads((prev) => prev.filter((p) => p._id !== lead._id));
      setOpen(false);
    });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Move {lead.name} to consultant</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          value={consultant}
          onChange={(e) => setConsultant(e.target.value)}
        >
          {staff
            .filter((s) => s.roles.includes("Sales"))
            .map((s) => (
              <MenuItem key={s._id} value={s._id}>
                {s.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={() => go()}>
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}
