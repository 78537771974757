import React from "react";
import "./customer.css";
import { net } from "../../../../database";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import Students from "./components/Students";
import UnpaidBills from "./components/UnpaidBills";
import SalesInfo from "./components/SalesInfo";
import {
  Tab,
  Tabs,
  Button,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import EditIcon from "@mui/icons-material/Edit";
import CustomerCarePerson from "./components/CustomerCarePerson";
import Recent from "./components/Recent";
import Info from "../../components/Info";
import PhoneNumber from "../../components/PhoneNumber";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Customer(props) {
  let { id } = useParams();
  let { l, language } = props;
  const [customer, setCustomer] = React.useState(undefined);
  const [editCustomer, setEditCustomer] = React.useState(false);
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    net.get("/customer/" + id).then((r) => {
      console.log(r);
      setCustomer(r);
    });
  }, [id]);
  //console.log(customer);
  const updateCustomer = async () => {
    net
      .post(`/customers/${customer._id}/update`, {
        ...customer,
        students: undefined,
      })
      .then((r) => {
        setCustomer((prev) => ({ ...prev, ...r }));
      });
  };

  if (customer === undefined) return <Loading />;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let unpaidBills = 0;
  customer.students.forEach((s) => {
    unpaidBills += s.unpaidBills.length;
  });

  // console.log(customer);
  return (
    <div>
      <div style={{ float: "right", textAlign: "right" }}>
        <IconButton onClick={() => setEditCustomer(true)}>
          <EditIcon />
        </IconButton>
        <div>
          <CustomerCarePerson customer={customer} setCustomer={setCustomer} />
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ width: 60, height: 60, boxShadow: "3px 3px 5px #555555" }}
            src={customer.image ? `/images/${customer.image}` : ""}
            alt={customer.name}
          />
          {/*   <div style={{ fontSize: 24, marginTop: 5, marginBottom: 5 }}> */}
          <div style={{ marginLeft: 20 }}>
            <h1 style={{ marginBottom: 0, marginTop: 0 }}>
              {customer.name ? customer.name : "No name"}
            </h1>
            <div style={{ display: "flex" }}>
              <Info
                title="Primary Phone"
                body={
                  <a
                    href={
                      "https://zalo.me/" + customer.phone.replace("0", "84")
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    className="neat"
                  >
                    <PhoneNumber value={customer.phone} />
                  </a>
                }
              />
              <div style={{ width: 30 }}></div>
              {customer.phone2 ? (
                <Info
                  title="Zalo Phone"
                  body={
                    <a
                      href={
                        "https://zalo.me/" + customer.phone2.replace("0", "84")
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="neat"
                    >
                      <PhoneNumber value={customer.phone2} />
                    </a>
                  }
                />
              ) : null}
            </div>
            <Info title="Address" body={customer.address} />
          </div>
        </div>

        {/*  </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", width: 150 }}>
            {/*  <img
                src={ZaloLogo}
                alt="Zalo"
                width="20px"
                height="20px"
                style={{ float: "right" }}
              /> */}
          </div>
          <div></div>
        </div>
        <Students customer={customer} />
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Customer information"
      >
        <Tab label="Recent" {...a11yProps(0)} />
        <Tab label="General" {...a11yProps(1)} />
        <Tab
          label={unpaidBills > 0 ? `Bills (${unpaidBills})` : "No Bills"}
          {...a11yProps(2)}
        />
        <Tab label="Notes" {...a11yProps(3)} />
        <Tab label="History" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Recent customer={customer} l={props.l} language={props.language} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {" "}
        <SalesInfo
          customer={customer}
          setCustomer={setCustomer}
          l={props.l}
          language={props.language}
          customerErrors={[]}
          updateCustomer={updateCustomer}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {" "}
        <UnpaidBills
          customer={customer}
          user={props.user}
          setCustomer={setCustomer}
          center={props.center}
          l={l}
          language={language}
        />
      </TabPanel>
      <TabPanel value={value} index={3}></TabPanel>
      <TabPanel value={value} index={4}></TabPanel>
      <Dialog open={editCustomer}>
        <DialogTitle>Edit Customers Details</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Name"
              value={customer.name}
              onChange={(e) =>
                setCustomer({ ...customer, name: e.target.value })
              }
            ></TextField>
            <NumberFormat
              label={props.l.phone[props.language]}
              customInput={TextField}
              format="(###) ###-####"
              value={customer.phone}
              onValueChange={(value) => {
                setCustomer({ ...customer, phone: value.value });
              }}
              /*   {...(phoneError === true && {
                error: true,
                helperText: "Please type in a phone number",
              })} */
            ></NumberFormat>
            <NumberFormat
              label="Zalo phone"
              customInput={TextField}
              format="(###) ###-####"
              value={customer.phone2 || ""}
              onValueChange={(value) => {
                setCustomer({ ...customer, phone2: value.value });
              }}
              /*   {...(phoneError === true && {
                error: true,
                helperText: "Please type in a phone number",
              })} */
            ></NumberFormat>
            <TextField
              label="Address"
              value={customer.address}
              onChange={(e) =>
                setCustomer({ ...customer, address: e.target.value })
              }
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateCustomer().then((r) => setEditCustomer(false));
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
