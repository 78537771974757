import React from "react";
import "./chatbox.css";

import ChatInput from "./chatboxComponents/ChatInput";
import Chats from "./chatboxComponents/Chats";
export default function ChatBox(props) {
  const { message, user, setMessages, messages } = props;
  const { chats } = message;

  return (
    <div className="chatbox-container">
      <Chats chats={chats} user={user} message={message} />

      <ChatInput
        message={message}
        user={user}
        setMessages={setMessages}
        messages={messages}
      />
    </div>
  );
}
