import React, { useContext, useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import DrawTest from "./DrawTest";
import { Button } from "@mui/material";
import SelectFromList from "../../components/SelectFromList";
import LocationChoice from "../../components/LocationChoice";
import { CenterContext } from "../../../../Context";


export default function FollowUp(props) {


    const [filter, setFilter] = useState("Potential");
    const { getAllLocations } = useContext(CenterContext)
    const allLocationsId = getAllLocations()._id;
    const [location,setLocation] = useState(allLocationsId)
const [contacts, setContacts, error, loading] = useDB(`/marketing-contacts/follow-up/${filter}`)
const results = [`Potential`,"Cannot contact","Reschedule","No result"]

if (loading) return <Loading />;
if (error) return <Error />;





    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            {results.map((r) => (
              <Button
                onClick={() => setFilter(r)}
                {...(filter === r && {
                  color: "success",
                })}
              >
                {r}
              </Button>
            ))}
          </div>
          <div>
            <LocationChoice
              value={location}
              setValue={setLocation}
            />
          </div>
        </div>
        <hr style={{ marginBottom: 20 }} />
        <h3>
          {
            contacts.filter((c) => {
              if (location === allLocationsId) return true;
              if (c.testLocation === location) return true;
            }).length
          }{" "}
          customers
        </h3>
        {contacts
          .filter((c) => {
            if (location === allLocationsId) return true;
            if (c.testLocation === location) return true;
          })
          .map((c) => (
            <DrawTest
              contact={c}
              key={c._id}
            />
          ))}
      </div>
    );
}