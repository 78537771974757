import React from "react";
import { DrawNote } from "../../student/components/StudentNotes";
import { format } from "date-fns";

export default function Recent(props) {
  let recentEvents = [];
  console.log(props);
  let { students } = props.customer;

  students.forEach((student) => {
    student.notes.forEach((note) => {
      note.type = "note";
      note.name = student.name;
      recentEvents.push(note);
    });
    student.classHistory.forEach((classHistory) => {
      classHistory.type = "classHistory";
      classHistory.name = student.name;
      classHistory.date = classHistory.startDate;
      recentEvents.push(classHistory);
    });
    student.statusHistory.forEach((statusHistory) => {
      statusHistory.type = "statusHistory";
      statusHistory.name = student.name;
      recentEvents.push(statusHistory);
    });
    student.salesFeedback.forEach((attendance) => {
      attendance.type = "salesFeedback";
      attendance.name = student.name;
      recentEvents.push(attendance);
    });
  });

  recentEvents = recentEvents.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  console.log(recentEvents);

  return (
    <div>
      {recentEvents
        .sort((e) => e.createdAt - e.createdAt || 0)
        .map((event) => (
          <RecentEvent event={event} l={props.l} language={props.language} />
        ))}
    </div>
  );
}

function RecentEvent(props) {
  let { event } = props;

  if (event.type === "note")
    return (
      <div>
        <div className="student-notes-name">
          Note about {event.name ? event.name : ""}
        </div>
        <DrawNote note={event} l={props.l} language={props.language} />
      </div>
    );
  if (event.type === "classHistory")
    return (
      <div className="dflex-sb">
        <div className="student-notes-name">
          {event.name ? event.name : ""} changed to{" "}
          {event.htClass && event.htClass.name ? event.htClass.name : ""}{" "}
          {event.reason ? event.reason : ""}
        </div>
        <div className="note-title">
          {" "}
          {event.startDate ? format(new Date(event.startDate), "dd/MM/yyyy h:mm aaa") : "No date"}
        </div>
      </div>
    );
  if (event.type === "salesFeedback")
    return (
      <div>
        <div className="student-notes-name">
          Feedback about {event.name ? event.name : ""}
        </div>
        <div className="dflex-sb" style={{ fontSize: 14 }}>
          <div>{event.salesPerson ? event.salesPerson.name : "Someone"}</div>
          <div>{format(new Date(event.createdAt), "dd/MM/yyyy")}</div>
        </div>
        <div className="dflex-sb" style={{ fontSize: 12 }}>
          <div style={{ width: "50%" }}>
            <div>Notes: {event.notes}</div>
            <div>Homework: {event.homeWorkNotes}</div>
          </div>
          <div style={{ width: "50%" }}>
            Customer Feedback: {event.salesFeedback}
          </div>
        </div>
      </div>
    );
  return <div>Recent event</div>;
}
