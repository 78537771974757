import React from "react";

/**
 * 
 * @param {number} value The number of students 
 * @returns 
 */

export default function NumStudents(props) {
    const { value } = props
    let text = value===1 ? "student" : "students"
    return (<>{value} {text}</>)
}