import React, { useState } from "react";
import { net } from "./database";
import Loading from "./components/content/Loading";
import { TextField, Stack, Box, Button } from "@mui/material";

export default function Login({apiKey, setUser,setApiKey}) {
  const [checkKey, setCheckKey] = useState(
   apiKey === undefined ? false : true
  );
  const [error, setError] = useState("");
  const [loggingIn, setLoggingIn] = useState(false)
  
  const [username,setUsername] = useState("")
  const [password, setPassword] = useState("");

  const login = () => {
    if (!loggingIn) {
      setLoggingIn(true)
      net.post("/login", { username, password }).then((r) => {
        if (r.error) {
          setError(r.error);
        }
        setLoggingIn(false)
        localStorage.setItem("apiKey", r.jwt);
        setApiKey(r.jwt)
        setUser(r.user)

      });
    }
  };

  React.useEffect(() => {
    if (checkKey === true) {
      net.post("/loginwithtoken", { }).then((r) => {
        if (r.error) {
          setError(r.error);
          setCheckKey(false);
          return;
        }
        if (r.accessToken) {
          localStorage.setItem("apiKey", r.jwt);
          apiKey = r.accessToken;
          setUser(r.user);
          return;
        }
        localStorage.setItem("apiKey", apiKey);
        setUser(r.user);
      });
    }
  }, [checkKey]);

  if (checkKey === true)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background:
          "linear-gradient(28deg, rgba(255,253,253,1) 0%, rgba(221,221,221,1) 100%)",
        fontFamily: "Roboto, Helvetica",
      }}
    >
      <div>
        <Box
          sx={{ width: 300, textAlign: "center", backgroundColor: "white" }}
          borderRadius={5}
          border={1}
          borderColor="#CCCCCC"
          padding={2}
          boxShadow={3}
        >
          <h4
            style={{
              color: "grey",
              fontWeight: "normal",
            }}
          >
            Welcome to HT Systems
          </h4>
          <Stack spacing={2}>
            <TextField
              id="username"
              name="username"
              type="text"
              autoComplete="username"
              required
              autoFocus
              label="Email"
              value={username}
              onChange={(e)=>setUsername(e.target.value)}
                />

            <TextField
              id="current-password"
              name="password"
              type="password"
              autoComplete="current-password"
              label="Password"
              required
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              onKeyUp={(e) => {
             
                if (e.key === "Enter") login();
              }}
            />
            <div style={{ color: "red", fontSize: 14 }}>{error}</div>
            <Button onClick={login} variant="contained" {...(loggingIn && {
            disabled: true
            })}>Sign in</Button>
          </Stack>
        </Box>
        <div style={{ height: 200 }}></div>
      </div>
    </div>
  );
}
