import React from "react";
import useDB from "../hooks/useDB";
import Loading from "./Loading";
import Error from "./Error";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

/**
 * 
 * @param {*} props 
 * @returns 
 */

export default function Chart(props) {
  const {url} = props
  const [lineData, setLineData, error, loading] = useDB(url);
 

  if (loading) return <Loading />;
  if (error) return <Error />;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
          text: "Total students and attendance",
        color: "white"
      },
    },
    layout: {
      padding: 15,
    },
    scales: {
      x: {
        ticks: { color: "white", font: { weight: 100 } },
      },
      y: {
        ticks: { color: "white" },
        min: 0,
      },
    },
  };

  const data = {
    labels: lineData.map((l) => l.date.substring(5,10)),
    datasets: [
      {
        data: lineData.map((l) => l.total),
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointRadius: 0,
        tension: 0.2,
        fill: "origin",
      },
      {
        data: lineData.map((l) => l.attended),
        borderColor: "rgb(0, 255, 0)",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointRadius: 0,
        tension: 0.2,
        fill: "origin",
      },
      {
        data: lineData.map((l) => l.absent),
        borderColor: "rgb(255, 0, 0)",
        borderWidth: 2,
        pointBorderWidth: 0,
        pointRadius: 0,
        tension: 0.2,
        fill: "origin",
      },
    ],
  };

  return (
    <div style={{ width: 430 }}>
      <Line
        options={options}
        data={data}
        /*  plugins={[plugin]} */
        style={{
          borderRadius: 10,
          background:
            "linear-gradient(56deg, rgba(0,101,153,1) 0%, rgba(81,193,236,1) 100%, rgba(81,105,236,1) 100%, rgba(0,61,255,1) 100%)",
        }}
      />
    </div>
  );
}