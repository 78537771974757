import { IconButton,} from "@mui/material";
import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { net } from "../../../../database";
import Loading from "../../Loading";
import AddShiftDialog from "./AddShiftDialog";
import Staff from "../../components/Staff";
import DeleteButton from "../../components/DeleteButton";
import Location from "../../components/Location";
import HtDate from "../../components/HtDate";
import Time from "../../components/Time";
import { CenterContext } from "../../../../Context";
import HtTabs from "../../components/HtTabs";
import StaffAC from "../../components/StaffAC";
import FormatDateTime from "../../components/FormatDateTime";
/* import ForeignTeacher from "./ForeignTeacher"; */

export default function StaffShifts(props) {
  const [newShiftDialog, setNewShiftDialog] = useState(false);
  const {center} = useContext(CenterContext)
  let d = new Date();
  let [showDate, setShowDate] = useState(
    new Date(d.getFullYear(), d.getMonth(), d.getDate(), 7)
  );

  const [shifts, setShifts] = useState(undefined);
  const [foreign,setForeign] = useState(undefined)

  React.useEffect(() => {
    net
      .get(
        `/staff/shifts2/${showDate.getFullYear()}/${showDate.getMonth()}/${showDate.getDate()}`
      )
      .then((r) => {
        setShifts(r.shifts);
        setForeign(groupForeign(r.ft))
      });
  }, [showDate]);

  if (!shifts) return <Loading />;

  const updateDate = (newDate) => {
    let d = new Date(newDate);
    d.setHours(d.getHours() + 7);

    setShowDate(d);
  };

  const labels = center.locations.filter(l=>l.name!=="All locations").map(l => `${l.name} (${shifts.filter(s=>s.location===l._id).length})`)
  const components = center.locations.filter(l=>l.name!=="All locations").map(l => <DrawLocation shifts={shifts} setShifts={setShifts} location={l._id} ft={foreign} />)

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Shifts for staff today</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
        
          <HtDate
            value={showDate}
            setValue={updateDate}
            buttons
            style={{ width: 120 }}
            future
          />
       
            <IconButton
              sx={{ height: 40 }}
            onClick={() => setNewShiftDialog(true)}
            color="success"
            >
              <AddIcon />
            </IconButton>
         
        </div>
      </div>

      <HtTabs labels={labels} components={components} localStorage="location"/>

      <AddShiftDialog
        open={newShiftDialog}
        setOpen={setNewShiftDialog}
        showDate={showDate}
        setShifts={setShifts}
      />
    </div>
  );
}

function DrawLocation({shifts,setShifts, location,ft}) {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: 300, fontSize: 11 }}>Name</div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Location
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Start Time
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Finish Time
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Hours
        </div>
        <div style={{ width: 150, fontSize: 11, textAlign: "center" }}>
          Recorded by
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Date and time
        </div>
      </div>
      {/*  <hr />
    <ForeignTeacher teachers={ft} /> */}
      <hr />
      {shifts
        .filter((s) => s.location === location)
        .map((shift) => (
          <DrawShift
            shift={shift}
            setShifts={setShifts}
          />
        ))}
    </div>
  );
}

function DrawShift(props) {
  let { shift, setShifts } = props;
  return (
    <div>
      <div
        key={shift._id}
        style={{ display: "flex" }}
      >
        <div style={{ width: 300 }}>
          <Staff id={shift.user} />
        </div>
        <Location
          id={shift.location}
          style={{ width: 100, textAlign: "center" }}
        />
        <div style={{ width: 100, textAlign: "center" }}>
          <Time time={shift.startTime} />
        </div>
        <div style={{ width: 100, textAlign: "center" }}>
          <Time time={shift.endTime} />
        </div>
        <div style={{ width: 100, textAlign: "center" }}>{shift.hours}</div>
        <div style={{width:150,minWidth:150}}><Staff id={shift.recordedBy} /></div>
        <FormatDateTime value={shift.updatedAt} />
        <div style={{ flexGrow: 1 }}></div>

        <DeleteButton
          route="/shifts"
          id={shift._id}
          success={() =>
            setShifts((prev) => prev.filter((s) => s._id !== shift._id))
          }
          size={30}
        />
      </div>

      <hr />
    </div>
  );
}

function groupForeign(shifts) {
  let grouped = []
  for (let shift of shifts) {
    let group = grouped.find(g => g.teacher === shift.teacher)
    if (!group) {
      group = {
        teacher: shift.teacher, shifts: []
        
      }
      group.shifts.push(shift)
      grouped.push(group)
    } else {
        group.shifts.push(shift)
    }

    
 
  }
  for (let g of grouped) {
    g.shifts.sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0))
  }
  return grouped
}