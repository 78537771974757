import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ClassesContext, StudentsContext } from "../../../Context";

/**
 * Description placeholder
 * @date 6/14/2023 - 6:04:46 PM
 *
 * @export
 * @param student must have _id, name
 * @param showClasses Boolean Show the classes of the student
 * @returns {*}
 */
export default function StudentLink(props) {
  let { student, showClasses, style } = props;

  
  if (typeof student === "string") return <StringStudent {...props} />;

  if (!student || !student.name) return <React.Fragment>No Student</React.Fragment>;
  return (
    <Link to={`/student/${student._id}`} className="neat" style={style}>
      {student.name},{" "}
      {showClasses && student.classes ? (
        <Classes classes={student.classes} />
      ) : (
        ""
      )}
    </Link>
  );
}

function StringStudent(props) {
  
  let { student, showClasses, style } = props;
  let { findStudent} = useContext(StudentsContext);
  let st = findStudent(student)

  return (
    <Link to={`/student/${student}`} className="neat" style={style}>
      {st.name}{" "}
      {showClasses && st.classes ? (
        <Classes classes={st.classes} />
      ) : (
        ""
      )}
    </Link>
  );
}

function Classes(props) {
  return (
    <React.Fragment>
      {props.classes.map((c, i) => (
        <React.Fragment key={c}>
          <DrawClass cl={c} />
          {i < props.classes.length - 1 ? ", " : ""}{" "}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function DrawClass(props) {
  const { classes } = useContext(ClassesContext);

  let sClass = classes.find((c) => c._id && c._id === props.cl);
  if(!sClass) return <>No class</>

  return <React.Fragment>{sClass.name}</React.Fragment>;

 /*  return <FindClass {...props} setClasses={setClasses} classes={classes} />; */
}

/* function FindClass(props) {
  const { classes, setClasses } = props;
  const [cl, setCl] = React.useState(undefined);
  React.useEffect(() => {
    net.get(`/classes/${props.cl}`).then((res) => {
      if (res) {
        setCl((prev) => res);
        setClasses((prev) => [...prev, res]);
      } else {
        setCl({ _id: props.cl, name: "Class Deleted" });
        if (!classes.find((c) => c._id === props.cl)) {
          setClasses((prev) => [
            ...prev,
            { _id: props.cl, name: "Class Deleted" },
          ]);
        }
      }
    });
  }, []);
  if (!cl) return <React.Fragment></React.Fragment>;
  return <React.Fragment>{cl.name}</React.Fragment>;
}
 */