import React from "react";

/**
 * 
 * @param {number} value the value of the box
 * @param {number} width default 30
 * @param {number} height default 30  
 * @returns 
 */

export default function ScoreBox(props) {
    const { width = 30, height = 30, value } = props
    const background = [
      "white",
      "linear-gradient(229deg, #F2002C 0%, #A9030D 100%)",
      "linear-gradient(229deg, #FF8A00 0%, #C26B04 100%)",
      "linear-gradient(229deg, #E4E901 0%, #D3B202 100%)",
      "linear-gradient(229deg, #A9F803 0%, #84C200 100%)",
      "linear-gradient(229deg, #04FB58 0%, #00AE1C 100%)",
    ];

return (
  <div
    style={{
      width,
      height,
      background: background[value],
      border: "1px solid rgba(147, 147, 147, 0.47)",
      boxShadow: "2px 2px 3px -2px #939393",
    }}
  >
  </div>
);
}