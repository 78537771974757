import React, { useEffect, useState } from "react";
import { socket } from "../../App";


export default function Connected(props) {

    const [connected, setConnected] = useState(false)
    
    useEffect(() => {
        
        socket.emit("question", "question");
       var timeOut = undefined;
        setInterval(() => {
            socket.emit("question","question");
            timeOut = setTimeout(to,2000);
        }, 10000);
        socket.on("answer", (data) => {
             setConnected(true)
            clearTimeout(timeOut);
        })
        
      return ()=> socket.off("answer")
    }, [])

    const to = () => {
        setConnected(false)
    }


    return (<div style={{ padding:10, marginRight: 5 }}><Light on={connected} /></div>)
}

function Light(props){


const {on} = props


return (
  <svg
    width="10"
    height="10"
  >
    <circle
      cx="5"
      cy="5"
      r="4"
      fill={on ? "limegreen" : "red"}
    />
  </svg>
);
}