import React, { useEffect } from "react";
import Search from "../../components/Search";
import { net } from "../../../../database";
import DrawTitles from "./DrawTitles";
import DrawContact from "./DrawContact";



export default function SearchContacts(props) {

    const [search, setSearch] = React.useState("")
    const [contacts, setContacts] = React.useState(undefined)
    console.log(contacts)
    useEffect(() => {
        async function getData() {
             setContacts(await net.get(`/marketing-contacts/search/${search}`));
        }

        if (search.length > 2) { getData() } else {setContacts([])}
}, [search])

   
    return (
      <div style={{display:"flex", flexDirection:"column", gap:10}}>
        <Search
          search={search}
                setSearch={setSearch}
                type="number"
            />
        {contacts && <div>
        <DrawTitles />
        {contacts.map((c) => (
          <DrawContact contact={c} key={c._id} />
        ))}
         </div>   }
      </div>
    );
}