import React from "react";
import Message from "./Message";
import { net } from "../../../../../database";
import Loading from "../../../Loading";

export default function Messages(props) {
  let { messages } = props;
  const [staff, setStaff] = React.useState(undefined);

  React.useEffect(() => {
    net.get(`/users`).then((res) => setStaff(res));
  }, []);

  if (!staff) return <Loading />;

  return (
    <div>
      {messages.length > 0 ? (
        <div>
          {messages.map((message) => (
            <Message
              key={message._id}
              message={message}
              removeMessage={props.removeMessage}
              staff={staff}
              {...props}
            />
          ))}
        </div>
      ) : (
        "No messages"
      )}
    </div>
  );
}
