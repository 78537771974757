import React from "react";
import { net } from "../../../../../database";
import { IconButton } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./orgtree.css";
export default function OrgTree(props) {
  //const {l,language} = props;
  const [users, setUsers] = React.useState(undefined);

  React.useEffect(() => {
    net.get("/usersdetails").then((res) => {
      setUsers(res);
    });
  }, []);

  const drop = (e) => {
    e.preventDefault();
    console.log(setUsers);
    let staffId = e.dataTransfer.getData("id");
    let managerId = e.target.id;
    net.post("/addstafftomanager", { staffId, managerId }).then((response) => {
      console.log(users);
      setUsers(users.map((s) => (s._id === response._id ? response : s)));
    });
  };

  if (!users) return <div>Loading...</div>;

  let tree = [buildTree(users)];
  console.log(users);
  return (
    <div>
      <DrawUsers users={tree} setUsers={setUsers} drop={drop} />
    </div>
  );
}

function DrawUsers(props) {
  const [expand, setExpand] = React.useState(true);
  let { users, drop } = props;

  return (
    <div style={{ paddingLeft: "100px" }}>
      {users.map((user) => (
        <div
          className="user-panel"
          onDrop={(e) => drop(e)}
          onDragOver={(e) => e.preventDefault()}
          draggable
          id={user._id}
          onDragStart={(e) => e.dataTransfer.setData("id", user._id)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{user.name}</div>
            {user.reports.length > 0 ? (
              <IconButton onClick={() => setExpand(!expand)}>
                <ArrowRightIcon />
              </IconButton>
            ) : (
              ""
            )}
          </div>
          <div>
            {user.reports && expand && (
              <DrawUsers users={user.reports} drop={drop} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function buildTree(users) {
  let boss = users.find((user) => user.name === "Tim");
  users.forEach((user) => {
    user.reports = users.filter((u) => u.reportsTo === user._id);
  });
  console.log(users);
  return boss;
}
