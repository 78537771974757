import { format } from "date-fns";
import React from "react";



/**
 * Description placeholder
 * @date 8/5/2023 - 7:12:21 PM
 *
 * @export
 * @param {string|date} value the date you want to format
 * @returns {*}
 */
export default function FormatDate(props) {
  let { value } = props;
  if (!value) return <div>No date</div>;
  let dateIn = value;
  if (typeof value === "string") dateIn = new Date(value);
  return <div {...props}>{format(dateIn, "dd/MM/yyyy")}</div>;
}
