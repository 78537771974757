import React from "react";
import "./star.css"
import one from "./rating/1.svg"
import two from"./rating/2.svg";
import three from "./rating/3.svg";
import four from "./rating/4.svg";
import five from "./rating/5.svg";
import empty from "./rating/empty.svg"
import { net } from "../../../database";

/**
 * 
 * @param {number} value
 * @param {function} setValue  
 * @param {string} url optional the url to update when this is changed
 * @param {string} field optional required when using url to choose the field to update
 * @param {number} height the size of the stars
 * @param {boolean} readOnly  
 * @returns 
 */

export default function Rating(props) {
  const {value, setValue,url,field, height=45, readOnly } = props
  const [hoverValue, setHoverValue] = React.useState(undefined)
  const stars = [empty, one, two, three, four, five];
  const score = []
  let v = hoverValue || value
  for (let i = 1; i <= 5; i++){
    if (v >= i) { score.push(stars[v]) }
    else {
      score.push(stars[0])
    }
  }

  const update = (score) => {
    if (url && field) {
      net.post(url,{[field]:score})
    }
    setValue(score)
  }

  if(readOnly) return (
    <div style={{ display: "flex", alignItems: "center", height }}>
      {score.map((s, i) => (
        <div
          style={{ width: height, cursor: "pointer" }}
          key={field + i}
        >
          <img
            src={s}
            alt={s}
            height={height}
            width={height}
          />
        </div>
      ))}
    </div>
  );

    return (
      <div style={{ display: "flex", alignItems: "center", height}}>
        {score.map((s, i) => (
          <div
            onClick={() => update(i + 1)}
            onMouseEnter={() => setHoverValue(i+1)}
            onMouseLeave={() => setHoverValue(undefined)}
            style={{ width: height, cursor: "pointer" }}
            key={field+i}
          >
            <img
              src={s}
              alt={s}
              height={height}
              width={height}
            />
          </div>
        ))}
      </div>
    );
}
