import "./notification.css";
import React from "react";
import { Button, Menu } from "@mui/material";
import { net } from "../../database";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import { socket } from "../../App";
import { format } from "date-fns";

function DrawNotification(props) {
  let { notification } = props;
  let { title, message, notes, students, classes, customers, timeStamp } =
    notification;
  var titleLinks = "";
  try {
    titleLinks = title.split(" ").map((item, i) => {
      if (item === "<s0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/student/${students[0]._id}`}
          >
            {" "}
            {students[0].name}
          </Link>
        );
      }
      if (item === "<htc0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/class/${classes[0]._id}`}
          >
            {" "}
            {classes[0].name}
          </Link>
        );
      }
      if (item === "<htc1>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/class/${classes[1]._id}`}
          >
            {" "}
            {classes[1].name}
          </Link>
        );
      }
      if (item === "<c0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/customer/${customers[0]._id}`}
          >
            {" "}
            {customers[0].name}
          </Link>
        );
      }
      return <span key={`${item}${i}`}> {item}</span>;
    });
  } catch (error) {}

  var messageLinks = "";
  try {
    messageLinks = message.split(" ").map((item, i) => {
      if (item === "<s0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/student/${students[0]._id}`}
          >
            {" "}
            {students[0].name}
          </Link>
        );
      }
      if (item === "<htc0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/class/${classes[0]._id}`}
          >
            {" "}
            {classes[0].name}
          </Link>
        );
      }
      if (item === "<htc1>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/class/${classes[1]._id}`}
          >
            {" "}
            {classes[1].name}
          </Link>
        );
      }
      if (item === "<c0>") {
        return (
          <Link
            key={`${item}${i}`}
            className="notification-link"
            to={`/customer/${customers[0]._id}`}
          >
            {" "}
            {customers[0].name}
          </Link>
        );
      }
      return <span key={`${item}${i}`}> {item}</span>;
    });
  } catch (error) {}

  const dismissNotification = (id) => {
    net
      .post(`/user/${props.user._id}/dismissnotification`, { id })
      .then((notification_id) => {});
    let unreadNotifications = props.user.unreadNotifications.filter(
      (notification) => notification._id !== id
    );
    props.setUser({ ...props.user, unreadNotifications });
    props.setNotificationsCount(unreadNotifications.length);
  };

  return (
    <div className="notification">
      <div>
        <div className="notification-title">{titleLinks}</div>
        <div className="notification-message">{messageLinks}</div>
        <div>{notes}</div>
        <div className="notification-timestamp">
          {format(new Date(timeStamp), "h:mm aaa dd/MM/yy")}
        </div>
      </div>
      <div>
        <CancelIcon
          color="error"
          fontSize="small"
          onClick={() => dismissNotification(notification._id)}
        />
      </div>
    </div>
  );
}

export default function Notification(props) {
  let { notifications, l, language,user,setUser } = props;
  React.useEffect(() => {
 /*    setInterval(() => {
      net.get(`/notifications/${props.user._id}`).then((user) => {
        let { unreadNotifications } = user;
        console.log(user);
        props.setUser({ ...props.user, unreadNotifications });
      });
    }, 100000); */
  },[user])
socket.on("notification", (notification) => {
      let alreadyNotified = props.user.unreadNotifications.find(
        (n) => n._id === notification._id
      );

      if (!alreadyNotified) {
        console.log(notification);
        props.user.unreadNotifications.push(notification);

        if (Notification.permission === "granted") {
          const newNotification = new Notification(notification.title, {
            body: notification.message,
          });
        }
        props.setNotificationsCount(props.user.unreadNotifications.length);
        props.setUser(props.user);
      }
    });

    /*   setInterval(() => {
      net.get(`/notifications/${props.user._id}`).then((user) => {
        let { unreadNotifications } = user;
        console.log(user);
        props.setUser({ ...props.user, unreadNotifications });
      });
    }, 10000); */
   /*  return () => socket.off("notification");
  }, [props, props.user._id]); */

  /*  React.useEffect(() => {
   
    return () => socket.off("notification", addNotification); 
  }, []); */

  if (!notifications || notifications.length === 0) return <div></div>;

  const clearNotifications = () => {
    net.post(`/user/${user._id}`, { unreadNotifications: [] }).then(res => {
      setUser(prev=>({...prev, unreadNotifications:[]}))
    })
  }

  /* let oldFavicon = document.getElementById("favicon");
  var link = document.createElement("link");
  link.id = "favicon";
  link.type = "image/x-icon";
  link.rel = "icon";
  link.href = "faviconnote-96x96.png";
  if (oldFavicon) {
    document.head.removeChild(oldFavicon);
  }
  document.head.appendChild(link); */
  return (
    <div>
      <Menu
        anchorEl={props.NanchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={props.isNMenuOpen}
        onClose={props.handleNotificationsClose}
      >
        <div style={{display:"flex", justifyContent:"space-between",alignItems:"center",padding: 5, marginTop:0, paddingTop:0}}>
          <h3 className="notifications-hero">Notifications</h3>
          <Button size="small" color="error" onClick={()=>clearNotifications()}>Clear all</Button>
        </div>
        {notifications.map((notification) => (
          <DrawNotification
            key={notification._id}
            notification={notification}
            setUser={props.setUser}
            user={props.user}
            setNotificationsCount={props.setNotificationsCount}
            l={l}
            language={language}
          />
        ))}
      </Menu>
    </div>
  );
}

/*{" "}
      <div key={n._id} className="notification">
        <div onClick={props.handleNotificationsClose}>
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: 12 }}>{n.title}</div>
            <CloseIcon
              style={{ fontSize: 14, marginLeft: 5 }}
              onClick={() => {
                net
                  .post(`/user/${props.user._id}/dismissnotification`, {
                    id: n._id,
                  })
                  .then((user) => {
                    props.setUser({ ...props.user, user });
                  });
              }}
            />
          </div>
          <div style={{ fontSize: 12 }}>{n.message}</div>
          <div style={{ fontSize: 12 }}>{n.notes}</div>
        </div>
      </div>{" "}
      */
