import React from "react";
import VND from "../../../../components/VND";

export default function DrawCustomerCredit(props) {
  let { customerCredit } = props;
  return (
    <div>
      {" "}
      {customerCredit > 0 ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Credit</div>
          <VND value={customerCredit} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
