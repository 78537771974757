import React, { useContext } from "react";

import {
  Tooltip,
  Button,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import format from "date-format";

import NumberFormat from "react-number-format";
import SchoolIcon from "@mui/icons-material/School";

import BillsDetail from "./BillsDetail";
import TuitionCalculator from "./TuitionCalculator";
import AddNewBill from "./AddNewBill";
import { net } from "../../../../../database";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import PaymentDialog from "../../owed/components/PaymentDialog";
import Loading from "../../../Loading";
import Lang from "../../../components/Lang";
import { CenterContext } from "../../../../../Context";

export default function StudentBills(props) {
  const { studentId } = props;
  const {center} = useContext(CenterContext)
  const [student, setStudent] = React.useState(undefined);
  const [MAddBill, setMAddBill] = React.useState(false);
  const [openTuition, setOpenTuition] = React.useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);

  let navigate = useNavigate();

  React.useEffect(() => {
    net
      .get(`/students/${studentId}/bills`)
      .then((res) => setStudent((prev) => res));
  }, [openPaymentDialog, studentId]);

  if (!student) return <Loading />;
  console.log(student);
  let dates = [...new Set(student.unpaidBills.map((b) => b.date))];

  const updateStudent = (field) => {
    net.post(`/student/${student._id}`, field).then((result) => {
      console.log(result)
      setStudent((prev) => { return { ...prev, ...result } });
    });
    setStudent(prev => { return { ...prev, field } })
  };

  /* const closePaymentDialog = (customer) => {
    let retStudent = customer.students.find((s) => s._id === student._id);
    setStudent({ ...student, ...retStudent });

    setOpenPaymentDialog(false);
  }; */

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>

        <div>
          <TextField
            label="Next bill date"
            value={
              student.nextBillDate ? student.nextBillDate.substring(0, 10) : ""
            }
            sx={{ width: 150 }}
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => updateStudent({ nextBillDate: e.target.value })}
            {...(!student.nextBillDate && { error: true })}
          />
        </div>
        <div>
          <TextField
            select
            label="How often they pay"
            value={student.paymentFrequency}
            sx={{ width: 150 }}
            size="small"
            onChange={(e) =>
              updateStudent({ paymentFrequency: e.target.value })
            }
          >
            <MenuItem value={1}>1 Month</MenuItem>
            <MenuItem value={3}>3 Months</MenuItem>
            <MenuItem value={6}>6 Months</MenuItem>
          </TextField>
        </div>
        <div>
          <Tooltip title="Add individual bill">
            <Button onClick={() => setMAddBill(true)} startIcon={<AddIcon />}>
              <Lang w="bills" />
            </Button>
          </Tooltip>
          <Tooltip title="Add tuition">
            <Button
              onClick={() => setOpenTuition(true)}
              startIcon={<SchoolIcon />}
            >
              {<Lang w="tuition" />}
            </Button>
          </Tooltip>
          <Tooltip title="Make payment">
            <Button
              onClick={() => setOpenPaymentDialog(true)}
              startIcon={<SchoolIcon />}
            >
              {<Lang w="payment" />}
            </Button>
          </Tooltip>
          <IconButton
            aria-label="delete"
            onClick={() =>
              navigate(`/customer/${student.customer}/paymenthistory`)
            }
          >
            <HistoryIcon />
          </IconButton>
        </div>

        <AddNewBill
          student={student}
          setStudent={setStudent}
          open={MAddBill}
          setOpen={setMAddBill}
        />
        {openTuition ? (
          <TuitionCalculator
            open={openTuition}
            close={() => setOpenTuition(false)}
            student={student}
            setStudent={setStudent}
          />
        ) : (
          ""
        )}
      </div>

      {student.unpaidBills === undefined || dates.length === 0 ? (
        "No Bills"
      ) : (
        <div>
          {dates.map((d) => (
            <div key={`date${d}`}>
              <div
                style={{
                  fontSize: 11,
                  paddingLeft: 5,
                }}
              >
                {format("dd/MM/yyyy", new Date(d))}
              </div>
              <BillsDetail
                bills={student.unpaidBills.filter((b) => b.date === d)}         
                student={student}
                setStudent={setStudent}
             
              />
            </div>
          ))}
          <hr style={{ marginRight: 5, marginLeft: 5 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 5,
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            <div>Total</div>
            <div>
              <NumberFormat
                value={student.unpaidBills.reduce(
                  (a, b) => a + parseInt(b.amount),
                  0
                )}
                thousandSeparator={true}
                displayType="text"
              />
            </div>
          </div>
        </div>
      )}
      <PaymentDialog
        customerId={student.customer}
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
    
      
      />
    </div>
  );
}
