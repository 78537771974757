import React, { useContext} from "react";
import AvatarSLink from "../../components/AvatarSLink";
import { CenterContext, ClassesContext, StudentsContext } from "../../../../Context";
import Rating from "../../components/Rating";
import Info from "../../components/Info";
import MultiLine from "../../components/MultiLine";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import SCheckBox from "../../components/SCheckBox";
import mut from "../../hooks/mut";
import { copyToClipboard } from "../../hooks/copyToClipboard";
import { format } from "date-fns";

export default function StudentFeedback(props) {
  const { attendance, setLesson } = props
  const { findStudent } = useContext(StudentsContext);
  const {findClass} = useContext(ClassesContext)
   
  const copy = (id) => {
    copyToClipboard(paintFeedback, 400, 1000)
    mut("post", `/teachers-feedback/${id}`, { feedbackSent:new Date() });
  }
  
  function paintFeedback(ctx) {
    const student = findStudent(attendance.student)
    const htClass = findClass(attendance.htClass)
    const margin = 20
     let line = 30;
    ctx.font = "24px arial";
    ctx.fillStyle = "black";
    ctx.fillText(student.name, margin, 34);
     ctx.font = "16px arial";
    ctx.fillStyle = "gray";
     line += 30;
     ctx.fillText(`${htClass.name}`, margin, line);
    line += 20
      ctx.fillText(`${format(new Date(attendance.classDate),"dd/MM/yyyy")}`, margin, line);
      line += 30;
    ctx.font = "12px arial";
    ctx.fillStyle = "black";
    for (let item in attendance.feedback) {
      ctx.fillText(item, margin, line);
      line += 5
      let oneImage = document.getElementById(`score${attendance.feedback[item]}`);
      //ctx.fillText(attendance.feedback[item], margin, line);
      ctx.drawImage(oneImage,margin,line)
      line += 60;
    }
    line += 10;
    if (attendance.notes) {
      ctx.font = "bold 14px arial";
     
      ctx.fillText("Nhận xét buổi học", margin, line);
      line += 20;
       ctx.font = "14px arial";
      line = wrapText(ctx, attendance.notes, margin, line, 360, 24)
      // ctx.fillText(attendance.notes, 50, line);
      line += 20;
    }

     line += 10;
    if (attendance.homeWorkNotes) {
       ctx.font = "bold 14px arial";
      ctx.fillText("Bài tập về nhà", margin, line);
      line += 20;
       ctx.font = "14px arial";
      line = wrapText(ctx, attendance.homeWorkNotes, margin, line, 360, 24)
    }
    return line+20
  }
  
    
    if(attendance.attendance>1) return (
      <div style={{ display: "flex" }}>
        <div>
          <AvatarSLink id={attendance.student} />
          Absent
        </div>
      </div>
    );

    return (
      <div
        style={{
          padding: 5,
          /*  border: "1px solid #ccc", */
          borderRadius: 5,
          marginBottom: 5,
          /* boxShadow:"3px 3px 5px #aaa" */
        }}
      >
        <img
          src="/system/score5.png"
          alt="5"
          id="score5"
          style={{ display: "none" }}
        />
        <img
          src="/system/score4.png"
          alt="4"
          id="score4"
          style={{ display: "none" }}
        />
        <img
          src="/system/score3.png"
          alt="3"
          id="score3"
          style={{ display: "none" }}
        />
        <img
          src="/system/score2.png"
          alt="2"
          id="score2"
          style={{ display: "none" }}
        />
        <img
          src="/system/score1.png"
          alt="1"
          id="score1"
          style={{ display: "none" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 5,
          }}
        >
          <AvatarSLink id={attendance.student} />
          <div style={{ display: "flex", gap: 5 }}>
            <SCheckBox
              value={
                attendance.homeworkCompleted &&
                new Date(attendance.homeworkCompleted) > new Date(0, 0, 0)
                  ? true
                  : false
              }
              label="Homework completed"
              setValue={(e) =>
                setLesson((prev) => {
                  let at = prev.attendance.find(
                    (a) => a._id === attendance._id
                  );
                  attendance.homeworkCompleted = e;
                  let update = e
                    ? { homeworkCompleted: new Date() }
                    : { $unset: { homeworkCompleted: "" } };
                  mut("post", `/attendance/${at._id}`, update);
                  return {
                    ...prev,
                    attendance: prev.attendance.map((a) =>
                      a._id === at._id ? at : a
                    ),
                  };
                })
              }
            />
            <SCheckBox
              value={
                attendance.feedbackComplete &&
                new Date(attendance.feedbackComplete) > new Date(0, 0, 0)
                  ? true
                  : false
              }
              label="Voice feedback completed"
              setValue={(e) =>
                setLesson((prev) => {
                  let at = prev.attendance.find(
                    (a) => a._id === attendance._id
                  );
                  attendance.feedbackComplete = e;
                  let update = e
                    ? { feedbackComplete: new Date() }
                    : { $unset: { feedbackComplete: "" } };
                  mut("post", `/attendance/${at._id}`, update);
                  return {
                    ...prev,
                    attendance: prev.attendance.map((a) =>
                      a._id === at._id ? at : a
                    ),
                  };
                })
              }
            />
            <IconButton>
              <ContentCopyIcon onClick={()=>copy(attendance._id)} />
            </IconButton>
          </div>
        </div>
        <div style={{ display: "flex", gap: 5 }}>
          <div>
            <Stats
              feedback={attendance.feedback}
              id={attendance._id}
              setLesson={setLesson}
            />
          </div>
          <MultiLine
            value={attendance.notes}
            setValue={(notes) =>
              setLesson((prev) => {
                let att = prev.attendance;
                let at = att.find((a) => a._id === attendance._id);
                at.notes = notes;
                return { ...prev, attendance: att };
              })
            }
            rows={7}
            label="Nhận xét buổi học"
            fontSize={14}
            url={`/teachers-feedback/${attendance._id}`}
            field="notes"
            style={{ height: 174 }}
          />
          <MultiLine
            value={attendance.homeWorkNotes}
            rows={7}
            label="Bài tập về nhà"
            fontSize={14}
            url={`/teachers-feedback/${attendance._id}`}
            field="homeWorkNotes"
            setValue={(homeWorkNotes) =>
              setLesson((prev) => {
                let att = prev.attendance;
                let at = att.find((a) => a._id === attendance._id);
                at.homeWorkNotes = homeWorkNotes;
                return { ...prev, attendance: att };
              })
            }
            style={{ height: 174 }}
          />
        </div>
      </div>
    );
}

function Stats(props){
    const { id,feedback,setLesson } = props
   
    const { center } = useContext(CenterContext)
    const { feedbackCategories } = center

    return (
      <div style={{ padding: 10, border: "1px solid #ccc", borderRadius: 5 }}>
        {feedbackCategories.map((f) => (
          <Info
            title={f}
            body={
              <Rating
                value={feedback && feedback[f] ? feedback[f] : 0}
                url={`/teachers-feedback-stats/${id}`}
                field={`${f}`}
                setValue={(score) =>
                  setLesson((prev) => {
                    let attendance = prev.attendance;
                    let at = attendance.find((a) => a._id === id);
                    if (!at.feedback) at.feedback = {};
                    at.feedback[f] = score;
                    // console.log(attendance)
                    return { ...prev, attendance };
                  })
                }
              />
            }
          />
        ))}
      </div>
    );
}


 function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';
       
        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
        return y
      }
      
  
  
