import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditButton from "./EditButton";
import SimpleDialog from "./SimpleDialog";

/**
 * 
 * @param {string} link the link to show
 * @param {string} url  the url to update
 * @param {string} field the field to update
 * @returns 
 */

export default function UpdateLink({href="",url,field,name="No name", nameField="", newData, label=""}) {
    const [open, setOpen] = useState(false)
    const fields = [
      { label: "Name of syllabus", type: "text", value: name, name: nameField },
      { label: "Link to syllabus", type: "text", value: href, name: field },
    ];
    
    return (
        <div style={{fontSize:14}}>
            <div style={{fontSize:11, color:"#888"}}>{label}</div>
        <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}>
          <div style={{ width: 150 }}>
            {href ? (
              <a
                href={href}
                target="_blank"
                className="neat"
              >
                {name}
              </a>
            ) : (
              "No syllabus"
            )}
          </div>
          <EditButton
            toolTip="Edit"
                    action={() => setOpen(true)}
                    size={10}
          />
        </div>
        <SimpleDialog
          open={open}
          setOpen={setOpen}
          fields={fields}
          ok="Update"
          fullWidth
          route={url}
          newData={newData}
        />
      </div>
    );
}