import React from "react";
import useDB from "../../hooks/useDB";
import Lang from "../../components/Lang";
import VND from "../../components/VND";


export default function BonusLevel(props) {


const [bonusLevel, setBonusLevel, error, loading] = useDB(`/marketing-bonus-level`)


if (loading) return <></>;
if (error) return <></>;




    return (
      <div style={{ display: "flex", flexDirection: "column", fontSize: 14, width:250 }}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div>
            <Lang w="currentBonusLevel" />:
          </div>
          <div>{bonusLevel.level}</div>
        </div>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                    <Lang w="toNextLevel" />:
                    </div>
                     <div>
                         <VND value={bonusLevel.toNext} />
                    </div>
                 </div>
        
            </div>
      </div>
    );
}