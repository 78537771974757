import React from "react";
import Lang from "../../components/Lang";

export default function Title({returnDate,reason}) {
 return  <div className="new-lost-students-table-title">
      <div style={{ width: 300 }}>
        <Lang w="name" />
      </div>
      <div style={{ width: 120 }}>
        Date
      </div>
      <div style={{ width:200 }}>
        Status
      </div>
      <div style={{ flexGrow: 1 }}>
         {reason ? <Lang w="reason" /> : null}
      </div>

      <div style={{ width: 150 }}>
         {returnDate ? <Lang w="returnDate" /> : null}
      </div>
    </div>
}