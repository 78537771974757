import React, { useContext } from "react";
import Chat from "./Chat";
import { UserContext } from "../../../../Context";
export default function Chats(props) {
  const { chats } = props;
  const {user} = useContext(UserContext)

  return (
    <div className="chats">
      <div>
        {chats.sort().map((chat) => (
          <Chat chat={chat} user={user} key={chat._id} />
        ))}
      </div>
    </div>
  );
}
