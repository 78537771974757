import React from "react";

/**
 * 
 * @param {string} status 
 * @returns div with colored status
 */

export default function Status({status,style={}}) {

let statusColors = {
  Active: "#2bb800",
  New: "#2bb800",
  Pending: "#c4b804",
  Stopped: "#c4043e",
  Lead: "#c4043e",
  HotLead: "#0467c4",
};
    return (<div style={{ color: statusColors[status],...style }}>{status}</div>)
}